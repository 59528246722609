
import Vue, { PropType } from "vue";
import AccreditationForm, {
  SubmitFormEmit
} from "../../components/heritage/forms/AccreditationForm.vue";
import { extractContainer } from "../../ioc/util";
import TYPES from "../../ioc/types";

import { getModule } from "vuex-module-decorators";
import SettingsModule from "../../store/modules/settings";
import mixins from 'vue-typed-mixins'
import LabelMixin from '@/mixins/labels'
import AccreditationClient from "@/client/accreditation.client";
import { NamedBlob } from "@/client/download.client";
import UsersModule from "@/store/modules/users";

export default mixins(LabelMixin).extend({
  name: "SuggestResourceView",
  components: {
    AccreditationForm
  },
  props: {
    id: Number
  },
  data(): { showForm:boolean } {
    return {
      showForm: true
    };
  },
  created() {
    if(!this.id) location.href = window.origin + "/splash"
  },
  methods: {
    async blobToBase64(blob:NamedBlob):Promise<string> {
      return new Promise((resolve, _) => {
        const reader = new FileReader();
        reader.onloadend = () => {
          const data = reader.result as string;
          const base64 = data.substring(data.indexOf(",")+1);
          resolve(base64) 
        };
        reader.readAsDataURL(blob.blob);
      });
    },
    async onAccreditationSubmit(submit: SubmitFormEmit) {
      const container = extractContainer(this);
      const settingsModule = getModule(SettingsModule, this.$store);
      const userModule = getModule(UsersModule, this.$store);
      submit.accreditation.id_user = this.id;

      if(submit.library) {
        submit.accreditation.company_library = await this.blobToBase64(submit.library)
        submit.accreditation.company_library_filename = submit.library.name
      }

      if(submit.presentation) {
        submit.accreditation.company_presentation = await this.blobToBase64(submit.presentation)
        submit.accreditation.company_presentation_filename = submit.presentation.name
      }
      

      const accreditationClient = container.get<AccreditationClient>(TYPES.AccreditationClient);

      await accreditationClient.create(settingsModule.language, submit.accreditation)
      userModule.setInReview();
      this.showForm = false;
 
      
    }
  },
  inject: ["container"]
});
