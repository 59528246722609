
import Vue from 'vue';

import { Label } from '../client/label.client';
import LabelsModule from '@/store/modules/labels';
import mixins from 'vue-typed-mixins'
import LabelMixin from '../mixins/labels';

export default mixins(LabelMixin).extend({
    name: 'PageNotFound',
    inject: ['container'],
    components: {
    },
    beforeCreate: function() {
        document.body.className = '';
    },    
    async created() {

    },
})
