import { injectable, inject, interfaces } from 'inversify';
import { AxiosStatic } from 'axios';
import Environment from '@/env/environment';
import TYPES from '@/ioc/types';
import { SupportedLanguage } from './supported-lang.type';
import { PagingOption, ListOptionRequest } from './user.client';
import AuthClient from './auth.client';

export interface NotificationResponse {
  id: number,
  sender_name: string,
  type: number,
  creation_date: string,
  target_id: number,
  sender_id: number,
  comment_title: string,
  readed: boolean,
  parent_id: number | null,
  forum_category_id: number | null,
  author_image_id: number | null,
  author_has_avatar: boolean
}
export interface NotificationTable {
  comment_id: number,
  creation_date: string,
  id: number,
  readed: boolean,
  sender_id: number,
  target_id: number,
  topic_id: number,
  type: number,
  parent_id: number | null,
  forum_category_id: number | null
}

export const DEFAULT_PAGING_OPTION: PagingOption = { pageLength: 100, currentPage: 1 }
export const DEFAULT_LIST_OPTION_REQUEST: ListOptionRequest = {paging: DEFAULT_PAGING_OPTION, sort: [], filter: []};

@injectable()
export default class NotificationMessageClient {
  constructor(
    @inject(TYPES.AxiosStatic) private readonly axios: AxiosStatic,
    @inject(TYPES.Environment) private readonly environment: Environment,
    @inject(TYPES.AuthClient) private readonly authClient: AuthClient,
  ) { }

  async getById(lang: SupportedLanguage, id: number): Promise<NotificationResponse> {
    const response = this.axios.get<NotificationResponse>(`${this.environment.backendUrl}/api/v1/form/${lang}/v_forum_comments/id/id::${id}`, {
      withCredentials: true,
    });
    return (await response).data;
  }

  async getAllNotificationsForum(lang: SupportedLanguage): Promise<NotificationResponse[]> {
    const fullRequest = {
      filter: [{column: "visible",operator: "=", value: "true"}],//{column:"readed",operator: "=", value: "false"}
      sort: [{column: "creation_date", order: "desc"}],
    };
    const response = this.axios.post<NotificationResponse[]>(`${this.environment.backendUrl}/api/v1/entity/${lang}/v_forum_notifications/list`, fullRequest, {
      withCredentials: true,
    });
    return (await response).data.map(r => <NotificationResponse>(r));
  }
  async getAllNotificationsResource(lang: SupportedLanguage): Promise<NotificationResponse[]> {
    const fullRequest = {
      filter: [{column: "visible",operator: "=", value: "true"}],//{column:"readed",operator: "=", value: "false"}
      sort: [{column: "creation_date", order: "desc"}],
      //paging: request,
    };
    const response = this.axios.post<NotificationResponse[]>(`${this.environment.backendUrl}/api/v1/entity/${lang}/v_resource_notifications/list`, fullRequest, {
      withCredentials: true,
    });
    return (await response).data.map(r => <NotificationResponse>(r));
  }

  async UserHasNotifications(lang: SupportedLanguage,id: number): Promise<boolean> {
    const fullRequest = {
      filter: [{column:"target_id", operator: "=", value: ""+id},{column:"readed", operator: "=", value: false}],
      sort: [
      ],
      //paging: request,
    };
    const response = this.axios.post<NotificationResponse[]>(`${this.environment.backendUrl}/api/v1/entity/${lang}/v_forum_notifications/list`, fullRequest, {
      withCredentials: true,
    });
    const totalNotifications = (await response).data.map(r => <NotificationResponse>(r));
    if(totalNotifications.length == 0){
      return false;
    }else{
      return true;
    }

  }

  async updateReaded(lang: SupportedLanguage, notification_id: number, type_number: number, state: boolean): Promise<void> {

    let model = type_number == 2 ? 'forum_notifications' : 'resource_notifications'
   
    const response = this.axios.get<NotificationResponse>(`${this.environment.backendUrl}/api/v1/entity/${lang}/${model}/id/id::${notification_id}`, {
      withCredentials: true,
    });

    const getCurrentNotification = (await response).data;
    getCurrentNotification.readed = state;    

    await this.axios.put<void>(
      `${this.environment.backendUrl}/api/v1/entity/${lang}/${model}/id/id::${getCurrentNotification.id}`,
      getCurrentNotification,
      { withCredentials: true, });
    
  }

  async updateAllReaded(lang: SupportedLanguage, notificationsResources: NotificationResponse[], notificationsForum: NotificationResponse[], type_number: number): Promise<void> {
    notificationsResources.forEach(element => {if(element.readed == false) this.updateReaded(lang,element.id, type_number, true)});
    notificationsForum.forEach(element => {if(element.readed == false) this.updateReaded(lang,element.id, type_number, true)});
  }

  async setupWebsocketForum(onNewNotification: () => void) {
    //in order to use the websocket we need a valid session cookie
    const websocketUrl = `${this.environment.websocketUrl}/api/v1/notifications/forum_notification`
    const websocketClient = new WebSocket(websocketUrl)
    websocketClient.onmessage = (msg) => {
      switch(JSON.parse(msg.data)) {
        case 'newComment': onNewNotification(); break;
        default: 
      }
    }
    websocketClient.onerror = (event) => {
      console.error("WebSocket error observed:", event);
    };

    websocketClient.onclose = function(event) {
      console.log("WebSocket is closed now.");
      console.log(event)
    };
  }

  async setupWebsocketResources(onNewNotification: () => void) {
    //in order to use the websocket we need a valid session cookie
    const websocketClient = new WebSocket(`${this.environment.websocketUrl}/api/v1/notifications/resource_notification`)
    websocketClient.onmessage = (msg) => {
      switch(JSON.parse(msg.data)) {
        case 'newComment': onNewNotification(); break;
        default: 
      }
    }
    websocketClient.onerror = (event) => {
      console.error("WebSocket error observed:", event);
    };

    websocketClient.onclose = function(event) {
      console.log("WebSocket is closed now.");
      console.log(event)
    };
  }
  
  
}
