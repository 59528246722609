
import Vue from 'vue';
import ResourcesModule from '@/store/modules/resources';
import { getModule } from 'vuex-module-decorators';
import ResourceClient, { ResourceResponse, Attachment, Embedded } from '../client/resource.client';
import Breadcrumbs, { BreadcrumbsItems } from '@/components/Breadcrumbs.vue';
import UserClient, { UserResponse } from '../client/user.client';
import UsersModule from '../store/modules/users';
import OdImage from '../components/ui/OdImage.vue';
import VideoPlayer from '../components/ui/VideoPlayer.vue';
import OdDownloadButton from '../components/ui/OdDownloadButton.vue';
import FavoriteForum from '@/components/ui/FavoriteForum.vue';
import ForumDiscussion, {
    SubmitFormEmit
} from '@/components/forms/ForumDiscussion.vue';
import CommentsChild from '@/components/CommentsChild.vue';
import TagResolver from '../components/containers/TagResolver.vue';
import { formatPublication } from '@/service/ui';
import { format, parse } from 'date-fns';

import { Label } from '../client/label.client';
import PartnerClient, { Partner } from '../client/partner.client';
import ForumCommentClient, { ForumComment } from '../client/forum-comment.client';
import LabelsModule from '@/store/modules/labels';
import mixins from 'vue-typed-mixins'
import LabelMixin from '../mixins/labels';
import SettingsModule from '@/store/modules/settings';
import { extractContainer } from '@/ioc/util';
import TYPES from '@/ioc/types';
import ForumCategoryClient, { ForumCategory } from '@/client/forum-category.client';

export default mixins(LabelMixin).extend({
  name: 'TopicView',
  props: {
    id: Number,
    category: Number,
    type: Number,
    id_parent: Number,
    id_comment: Number
  },
  inject: ['container'],
  asyncComputed: {
    async topic(): Promise<ForumComment> {
      const settingsModule = getModule(SettingsModule, this.$store);
      const container = extractContainer(this);
      const ForumCommentClient =  container.get<ForumCommentClient>(TYPES.ForumCommentClient);
      return await ForumCommentClient.getTopicById(settingsModule.language, this.id);
    },
  },
  async created() {
    
      getModule(UsersModule,this.$store).dispatchUserMention()

      const settingsModule = getModule(SettingsModule, this.$store);
      const container = extractContainer(this);
      const forumCategoryClient = container.get<ForumCategoryClient>(TYPES.ForumCategoryClient);
      this.categoryInner = await forumCategoryClient.getById(settingsModule.language,this.category);

      this.breadcrumbsItems.push({ url: '/forum/'+this.category, label: this.categoryInner.name })
      this.breadcrumbsItems.push({ url: '/forum/'+this.category+'/'+this.id, label: 'Current topic' })
  },
  computed: {
  },
  components: {
    Breadcrumbs,
    OdImage,
    OdDownloadButton,
    TagResolver,
    FavoriteForum,
    VideoPlayer,
    CommentsChild,
    ForumDiscussion
  },
  data(): {
    breadcrumbsItems: BreadcrumbsItems[],
    lastId: number,
    reloadComments0: number,    
    categoryInner: ForumCategory | null,
    currentTopic: ForumComment | null,
  } { 
    return {
      breadcrumbsItems: [
        { url: '/forum', label: 'Forum Area' },
        // { url: '/resources/category', label: 'Category' }  // AM Category title wiwh link
      ],
      lastId: 0,
      reloadComments0: 0,
      categoryInner: null,
      currentTopic: null
    };
  },
  methods: {
    async onCommentSubmit(submit: SubmitFormEmit) {
        const { comment } = submit;
        const container = extractContainer(this);
        const settingsModule = getModule(SettingsModule, this.$store);

        const ForumCommentClient = container.get<ForumCommentClient>(TYPES.ForumCommentClient);
        this.lastId = await ForumCommentClient.create(settingsModule.language, comment)
        this.reloadComments0++;
        //this.showForm = false;
        
      },
      scrollToDiscussion(){
        this.$scrollTo('#discussionpart', 500, { easing: 'ease-in-out', offset: -200})
      }
      /*
      async onLikeSubmit(submit: SubmitLikeEmit) {
        const { like } = submit;
        const container = extractContainer(this);
        const settingsModule = getModule(SettingsModule, this.$store);

        const LikeClient = container.get<LikeClient>(TYPES.LikeClient);
        this.lastId = await LikeClient.create(settingsModule.language, like)
        //this.showForm = false;
        this.componentKey++;
        
      }
      */
    },
  filters: {
    formatDate(date: Date): string {
      return formatPublication(date);
    },
    resourceLabels(resource: ResourceResponse): string {
      return [
        (r: ResourceResponse) => r.embedded?.length > 0 && 'Video',
        (r: ResourceResponse) => r.links?.length > 0 && 'Link',
        (r: ResourceResponse) => r.attachments.length > 0 && 'Document',
      ] .map(r => r(resource))
        .filter(r => !!r)
        .join(', ');
    },
    formatPublication(d: string): string {
      return formatPublication(parse(d, 'yyyy-MM-dd HH:mm', new Date()));
    },
  },
});

