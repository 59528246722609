import { injectable, inject, interfaces } from 'inversify';
import { AxiosStatic } from 'axios';
import Environment from '@/env/environment';
import TYPES from '@/ioc/types';
import { SupportedLanguage } from './supported-lang.type';
import { PagingOption, ListOptionRequest } from './user.client';

export interface Category {
  name: string;
  description: string;
  subcategory: any[];
  id: number;
  order: number;
}

export interface CategoryLight {
  id: number;
  name: string;
  name_mobile: string;
  order: number;
}

export const DEFAULT_PAGING_OPTION: PagingOption = { pageLength: 100, currentPage: 1 }
export const DEFAULT_LIST_OPTION_REQUEST: ListOptionRequest = {paging: DEFAULT_PAGING_OPTION, sort: [], filter: []};

@injectable()
export default class CategoryClient {
  constructor(
    @inject(TYPES.AxiosStatic) private readonly axios: AxiosStatic,
    @inject(TYPES.Environment) private readonly environment: Environment,
  ) { }

  async getById(lang: SupportedLanguage, id: number): Promise<Category> {
    const response = this.axios.get<Category>(`${this.environment.backendUrl}/api/v1/form/${lang}/resource_categories/id/id::${id}`, {
      withCredentials: true,
    });
    return (await response).data;
  }

  async getList(
    lang: SupportedLanguage,
    request: PagingOption,
  ): Promise<CategoryLight[]> {
    const fullRequest = {
      filter: [
      ],
      sort: [
        {column:"order", order:"asc"}
      ],
      paging: request,
    };
    const response = this.axios.post<CategoryLight[]>(`${this.environment.backendUrl}/api/v1/form/${lang}/resource_categories/list`,
      fullRequest,
      {
        withCredentials: true,
      });
    return (await response).data;
  }
}
