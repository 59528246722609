import { injectable, inject } from 'inversify';
import TYPES from '@/ioc/types';
import { AxiosStatic } from 'axios';
import Environment from '@/env/environment';
import { SupportedLanguage } from './supported-lang.type';
import { Film, DefaultListOptionRequest, ListOptionRequest } from './user.client';

function deserializeFilm(film: any): Film {
  return {
    ...film
  };
}

export function getDefaultFilm(): Film {
  return {
    id: null,
    user_id: null,
    title: '',
    year: null,
    director: '',
    producer: '',
    production_company: '',
    length: null,
    genre_id: null,
    genre_name: '',
    production_country_id: null,
    festival_awards: '',
    imdb: '',
    role: '',
    logline: '',
    trailer: '',
    image_name: null,
    languages: [],
    subtitles: [],
    license_territories: [],
    restriction_countries: [],
    restriction_activities: [],
    countries: [],
    license_type: [],
    license_exclusivity: [],
    license_availability: '',
    company: []
  }
}

export interface FilmLight{
  id: number;
  title: string;
  year: number;
  director: string;
  length: number;
  company_id: number;
  company_name: string;
  screening_room: boolean;
  image: string;
  countries: string;
  activity_hidden: boolean;
  genre: string;
  genre_name: string;
  languages: string;
  subtitles: string;
}

export interface ScreeningRoomFilm{
  id: string;
  name: string;
  m3u8: string;
}


export interface FilmAnalytics{
  film_id: number;
  film_company_id: number;
  film_title: string;
  viewer_name: string;
  viewer_email: string;
  viewer_id: number;
  viewer_company_id: number;
  viewer_company: string;
  views: number;
  trailer_played:number;
  full_film_played: number;
}

export function indexToLight(r:FilmIndexResponse):FilmLight {
  return {
    id: r.id,
    title: r.title,
    year: r.year,
    director: r.director,
    length: r.length,
    company_id: r.company_id,
    company_name: r.company_name,
    screening_room: r.screening_room,
    image: r.image,
    countries: r.countries,
    activity_hidden: r.activity_hidden,
    genre: r.genre,
    genre_name: r.genre_name,
    languages: r.languages,
    subtitles: r.subtitles
  }
}

export interface FilmUltraLight {
  id:number, company_id:number, screening_room:boolean
}

export interface FilmIndexResponse{
  id: number;
  title: string;
  year: number;
  director: string;
  length: number;
  company_id: number;
  company_name: string;
  screening_room: boolean;
  image: string;
  countries: string;
  activity_hidden: boolean;
  genre: string;
  genre_name: string;
  languages: string;
  subtitles: string;
}
export interface Subtitle{
  id: number;
  film_id: number;
  lang_id: number;
  subtitle_file: string | null;
  subtitle_file_name: string | null;
}

@injectable()
export default class UserFilmClient {
  constructor(
    @inject(TYPES.AxiosStatic) private readonly axios: AxiosStatic,
    @inject(TYPES.Environment) private readonly environment: Environment,
  ) { }

  async getById(lang: SupportedLanguage, id: number): Promise<Film> {
    const response = this.axios.get<Film>(`${this.environment.backendUrl}/api/v1/form/${lang}/user_films/id/id::${id}`, {
      withCredentials: true,
    });
    return deserializeFilm((await response).data);
  }
  
  async getSubtitleByFilmLang(lang: SupportedLanguage, film_id: number, lang_id: number): Promise<Subtitle> {

    const request:ListOptionRequest = {
      sort: [],
      filter: [{column:"film_id", operator: "=", value: ""+film_id},{column:"lang_id", operator: "=", value: ""+lang_id}],
      paging: {pageLength: 1, currentPage: 1}
    }
    const response = this.axios.post<Subtitle[]>(`${this.environment.backendUrl}/api/v1/entity/${lang}/film_subtitles/list`, request, {
      withCredentials: true,
    });
    return (await response).data[0];
  }
  async getSubtitleIdByFilmLang(lang: SupportedLanguage, film_id: number, lang_id: number): Promise<number> {

    const request:ListOptionRequest = {
      sort: [],
      filter: [{column:"film_id", operator: "=", value: ""+film_id},{column:"lang_id", operator: "=", value: ""+lang_id}],
      paging: {pageLength: 1, currentPage: 1}
    }
    const response = this.axios.post<Subtitle[]>(`${this.environment.backendUrl}/api/v1/entity/${lang}/film_subtitles/list`, request, {
      withCredentials: true,
    });
    return (await response).data[0].id;
  }
  async removeSubtitleByFilmLang(lang: SupportedLanguage, film_id: number, lang_id: number): Promise<void> {
    const subtitle = this.getSubtitleByFilmLang(lang,film_id,lang_id);
    (await subtitle).subtitle_file = null;
    await this.axios.put<void>(
      `${this.environment.backendUrl}/api/v1/form/${lang}/film_subtitles/id/id::${(await subtitle).id}`,
      subtitle,
      { withCredentials: true, });
  }

  async getList(lang: SupportedLanguage): Promise<Film[]> {
    const response = this.axios.post<Film[]>(`${this.environment.backendUrl}/api/v1/form/${lang}/user_films/list`, DefaultListOptionRequest, {
      withCredentials: true,
    });
    return (await response).data.map(deserializeFilm);
  }

  async getUltalight(): Promise<FilmUltraLight[]> {
    const requestIds:ListOptionRequest = {
      sort: [],
      filter: [],
      paging: {pageLength: 10000, currentPage: 1}
    }
    const request = this.axios.post<FilmUltraLight[]>(`${this.environment.backendUrl}/api/v1/entity/en/v_heritage_film_ultralight/list`, requestIds, {
      withCredentials: true,
    });
    return (await request).data
  }

  async getListHome(ids:number[], lang: SupportedLanguage): Promise<FilmLight[]> {
    
    const request:ListOptionRequest = {
      sort: [],
      filter: [{column:"id", operator: "in", value: ids.join(",")},{column:"activity_hidden", operator: "=", value: "false"}],
      paging: {pageLength: ids.length, currentPage: 1}
    }
    const response = this.axios.post<FilmLight[]>(`${this.environment.backendUrl}/api/v1/entity/${lang}/v_heritage_film_filtered/list`, request, {
      withCredentials: true,
    });
    const result = (await response).data.sort(() => .5 - Math.random());
    return result
  }

  async getScreeningRoom(ids:number[], lang: SupportedLanguage): Promise<FilmLight[]> {

    const request:ListOptionRequest = {
      sort: [],
      filter: [{column:"id", operator: "in", value: ids.join(",")},{column:"activity_hidden", operator: "=", value: "false"}],
      paging: {pageLength: ids.length, currentPage: 1}
    }
    const response = this.axios.post<FilmLight[]>(`${this.environment.backendUrl}/api/v1/entity/${lang}/v_heritage_film_filtered/list`, request, {
      withCredentials: true,
    });
    const result = (await response).data.sort(() => .5 - Math.random());
    return result
  }

  async getScreeningRoomFilm(lang: SupportedLanguage, id:string): Promise<ScreeningRoomFilm> {

    const request:ListOptionRequest = {
      sort: [],
      filter: [{column:"id", operator: "=", value: id}],
      paging: {pageLength: 1, currentPage: 1}
    }
    const response = this.axios.post<ScreeningRoomFilm[]>(`${this.environment.backendUrl}/api/v1/entity/${lang}/v_osr_films/list`, request, {
      withCredentials: true,
    });
    return (await response).data[0];
  }

  async getCompanyFilms(lang: SupportedLanguage, count:number, company_id:number): Promise<FilmLight[]> {

    const request:ListOptionRequest = {
      sort: [],
      filter: [{column:"company_id", operator: "=", value: ""+company_id}],
      paging: {pageLength: count, currentPage: 1}
    }
    const response = this.axios.post<FilmLight[]>(`${this.environment.backendUrl}/api/v1/entity/${lang}/v_heritage_film_filtered/list`, request, {
      withCredentials: true,
    });
    return (await response).data;
  }
  async getCompanyFilmsGuest(lang: SupportedLanguage, count:number, company_id:number): Promise<FilmLight[]> {

    const request:ListOptionRequest = {
      sort: [],
      filter: [{column:"company_id", operator: "=", value: ""+company_id},{column:"activity_hidden", operator: "=", value: "false"}],
      paging: {pageLength: count, currentPage: 1}
    }
    const response = this.axios.post<FilmLight[]>(`${this.environment.backendUrl}/api/v1/entity/${lang}/v_heritage_film_filtered/list`, request, {
      withCredentials: true,
    });
    return (await response).data;
  }

  async update(lang: SupportedLanguage, film: Film): Promise<void> {
    await this.axios.put<void>(
      `${this.environment.backendUrl}/api/v1/form/${lang}/user_films/id/id::${film.id}`,
      film,
      { withCredentials: true, });
  }

  async create(lang: SupportedLanguage, film: Film): Promise<number> {
    const response = await this.axios.post<any>(
      `${this.environment.backendUrl}/api/v1/form/${lang}/user_films`,
      film,
      { withCredentials: true, }
    );
    return +(response.data.id || -1);
  }

  async delete(film:Film):Promise<any> {
    await this.axios.delete(`${this.environment.backendUrl}/api/v1/entity/en/user_films/id/id::${film.id}`)
  }

  async touchView(film_id: number,user_id: number) { return this.touch(film_id,user_id,1) }
  async touchTrailer(film_id: number,user_id: number) { return this.touch(film_id,user_id,2) }
  async touchFull(film_id: number,user_id: number) { return this.touch(film_id,user_id,3) }

  private async touch(
    film_id: number,
    user_id: number,
    action: number
  ): Promise<any> {
    const response = this.axios.post(`${this.environment.backendUrl}/api/v1/entity/en/film_views`,
      {
        film_id: film_id,
        user_id: user_id,
        action: action
      },
      {
        withCredentials: true,
      });
    return (await response).data;
  }

  async getAnalytics(company_id:number): Promise<FilmAnalytics[]> {

    const request:ListOptionRequest = {
      sort: [],
      filter: [{column:"film_company_id", operator: "=", value: ""+company_id}],
      paging: {pageLength: 10000, currentPage: 1}
    }
    const response = this.axios.post<FilmAnalytics[]>(`${this.environment.backendUrl}/api/v1/entity/en/v_heritage_film_views/list`, request, {
      withCredentials: true,
    });
    return (await response).data;
  }

  async getIndexList(
    lang: SupportedLanguage
  ): Promise<FilmIndexResponse[]> {
  
    //const request = DefaultListOptionRequest
    //request.paging.pageLength = 100000

    const request:ListOptionRequest = {
      sort: [],
      filter: [{column:"activity_hidden", operator: "=", value: "false"}],
      paging: {pageLength: 100000, currentPage: 1}
    }
  
    const response = this.axios.post<FilmIndexResponse[]>(`${this.environment.backendUrl}/api/v1/entity/${lang}/v_heritage_film_filtered/list`,
      request,
      {
        withCredentials: true,
      });
      return (await response).data;
  }

}


