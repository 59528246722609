
import Vue from "vue";
import { Container } from "inversify";
import TYPES from "@/ioc/types";
import { AxiosStatic } from "axios";
import { Buffer } from "buffer";
import Environment from "../../env/environment";
import { extractContainer } from "../../ioc/util";

export const Modes = {
  WIDTH: "width",
  FIT: "fit",
  COVER: "cover",
  RAW: "raw"
}

export default Vue.extend({
  props: {
    imageId: {
      type: Number,
    },
    imageClass: {
      type: String,
      required: false
    },
    file: {
      type: String,
      required: false
    },
    resource: {
      type: String,
      required: true,
    },
    width: {
      type: Number,
      required: false,
    },
    height: {
      type: Number,
      required: false,
    },
    color: {
      type: String,
      required: false,
    },
    mode: {
      type: String,
      required: false,
    },
    url:{
      type: String,
      required: false
    },
    noCache:{
      type: String,
      required: false,
      default: ''
    }
  },
  computed: {
    imgData():string {
      if(this.resource && this.imageId) {
        //if(this.imageClass != "category"){
        const container = extractContainer(this);
        const axios = container.get<AxiosStatic>(TYPES.AxiosStatic);
        const env = container.get<Environment>(TYPES.Environment);
      //console.log(`${env.backendUrl}/api/v1/file/${this.resource}/id::${this.imageId}`);
        const base = `${env.backendUrl}/api/v1/file/${this.resource}/id::${this.imageId}`

        switch(this.mode) {
          case Modes.WIDTH: return base + `/width/${this.width}`
          case Modes.COVER: return base + `/cover/${this.width}/${this.height}`
          case Modes.FIT: 
            const fit =  base + `/fit/${this.width}/${this.height}`
            return this.color ? `${fit}?color=${this.color}` : fit
          case Modes.RAW:
          default: return base
        }/*
      }else{
        const container = extractContainer(this);
        const axios = container.get<AxiosStatic>(TYPES.AxiosStatic);
        const env = container.get<Environment>(TYPES.Environment);
        const base = `${env.backendUrl}/api/v1/file/${this.resource}/id::${this.imageId}`
        switch(this.mode) {
          case Modes.WIDTH: return this.file + `/width/${this.width}`
          case Modes.COVER: return this.file + `/cover/${this.width}/${this.height}`
          case Modes.FIT: 
            const fit =  this.file + `/fit/${this.width}/${this.height}`
            return this.color ? `${fit}?color=${this.color}` : fit
          
          default: return this.file
        }
      }*/

      } else {
        return `data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAAJCAIAAAC0SDtlAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAyVpVFh0WE1MOmNvbS5hZG9iZS54bXAAAAAAADw/eHBhY2tldCBiZWdpbj0i77u/IiBpZD0iVzVNME1wQ2VoaUh6cmVTek5UY3prYzlkIj8+IDx4OnhtcG1ldGEgeG1sbnM6eD0iYWRvYmU6bnM6bWV0YS8iIHg6eG1wdGs9IkFkb2JlIFhNUCBDb3JlIDYuMC1jMDAyIDc5LjE2NDM2MCwgMjAyMC8wMi8xMy0wMTowNzoyMiAgICAgICAgIj4gPHJkZjpSREYgeG1sbnM6cmRmPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5LzAyLzIyLXJkZi1zeW50YXgtbnMjIj4gPHJkZjpEZXNjcmlwdGlvbiByZGY6YWJvdXQ9IiIgeG1sbnM6eG1wPSJodHRwOi8vbnMuYWRvYmUuY29tL3hhcC8xLjAvIiB4bWxuczp4bXBNTT0iaHR0cDovL25zLmFkb2JlLmNvbS94YXAvMS4wL21tLyIgeG1sbnM6c3RSZWY9Imh0dHA6Ly9ucy5hZG9iZS5jb20veGFwLzEuMC9zVHlwZS9SZXNvdXJjZVJlZiMiIHhtcDpDcmVhdG9yVG9vbD0iQWRvYmUgUGhvdG9zaG9wIDIxLjEgKE1hY2ludG9zaCkiIHhtcE1NOkluc3RhbmNlSUQ9InhtcC5paWQ6NzkzNDUyMDdBNzZDMTFFQTkyMTdFMjc3QzNEMzM2NDIiIHhtcE1NOkRvY3VtZW50SUQ9InhtcC5kaWQ6NzkzNDUyMDhBNzZDMTFFQTkyMTdFMjc3QzNEMzM2NDIiPiA8eG1wTU06RGVyaXZlZEZyb20gc3RSZWY6aW5zdGFuY2VJRD0ieG1wLmlpZDo3OTM0NTIwNUE3NkMxMUVBOTIxN0UyNzdDM0QzMzY0MiIgc3RSZWY6ZG9jdW1lbnRJRD0ieG1wLmRpZDo3OTM0NTIwNkE3NkMxMUVBOTIxN0UyNzdDM0QzMzY0MiIvPiA8L3JkZjpEZXNjcmlwdGlvbj4gPC9yZGY6UkRGPiA8L3g6eG1wbWV0YT4gPD94cGFja2V0IGVuZD0iciI/PjX3RZ8AAAAaSURBVHjaYvz06RMDKYCJgUQwqoEmGgACDADucALoDhIgegAAAABJRU5ErkJggg==`
      }
    }
  },
  inject: ["container"]
});
