

import mixins from 'vue-typed-mixins'
import LabelMixin from '@/mixins/labels';
import sanitizeHTML from 'sanitize-html';
import { extractContainer } from '@/ioc/util';
import { getModule } from "vuex-module-decorators";
import UserClient, { UserHeritageResponse } from '@/client/user.client';
import TYPES from '@/ioc/types';
import UsersModule from "@/store/modules/users";


export default mixins(LabelMixin).extend({
    props: {
        body: { 
            type: String 
        },
        isNotification: {
            type: Boolean
        }
    },
    inject: ['container'],
    data():{
        //users: UserHeritageResponse[],
    } {
        return {
            //users: []
        }
    },
    methods: {
        renderMentions (body:string):string {
            const regex = /(?:@\[[^\d]+)(\d+)\]/g;
            const html =  body.replace(regex, (v,id) => {
                const user = this.users.find(x => x.id == parseInt(id))
                if(user) {
                    if(!this.isNotification)
                        return `<a href="/profile/${id}" >${user.first_name} ${user.last_name}</a>`
                    else
                        return `${user.first_name} ${user.last_name}`
                } else {
                    return v
                }
            })
            if(!this.isNotification){
                return sanitizeHTML(html)
            }
            if(html.length < 30){
                return sanitizeHTML(html)
            }else{
                return sanitizeHTML(html).substring(0,30)+"...";
            }
        },
    },
    async mounted() {
        //this.loadUsers()
        //this.users = getModule(UsersModule,this.$store).listMentionUsers
    },
    computed:{
        users(){
            return getModule(UsersModule,this.$store).listMentionUsers
        }
    }
})
