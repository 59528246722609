import Environment from '@/env/environment';
import TYPES from '@/ioc/types';
import { AxiosStatic } from 'axios';
import { inject, injectable } from 'inversify';
import { SupportedLanguage } from './supported-lang.type';


export interface ForumNewTopic {
  forum_category_id: number;
  parent_id: number;
  author_id: number;
  //pubblication_date: string;
  body: string;
  title: string;
  visible: boolean
}

export function emptyTopic(user_id: number, forum_category_id: number) {
  return {
      forum_category_id: forum_category_id,
      parent_id: 0,
      author_id: user_id,
      //pubblication_date: format(new Date(), "yyyy-MM-dd HH:mm:ss"),
      body: "",
      title: "",
      visible: true,
  }
}

@injectable()
export default class ForumNewTopicClient {
  constructor(
    @inject(TYPES.AxiosStatic) private readonly axios: AxiosStatic,
    @inject(TYPES.Environment) private readonly environment: Environment,
  ) { }

  async create(lang: SupportedLanguage, topic: ForumNewTopic): Promise<number> {
    const response = await this.axios.post<any>(
      `${this.environment.backendUrl}/api/v1/entity/${lang}/forum_comments`,
      topic,
      { withCredentials: true, }
    );
    return +(response.data.id || -1);
  }
}