
import Vue, { PropType } from 'vue';
import { mixin as clickaway } from 'vue-clickaway';
import lunr from "lunr";

import { getModule } from 'vuex-module-decorators';
import { FilmIndexResponse, indexToLight } from '../../client/user-film.client';
import ResourcesModule from '../../store/modules/resources';
import { AuthorIndex, DefaultListOptionRequest, UserResponse, UserHeritageIndex, indexStreamersToLight,UserHeritageResponse } from '../../client/user.client';
import UsersModule from '@/store/modules/users';
import ValuesModule from '@/store/modules/values';
import { format } from 'date-fns';
import { extractContainer } from '../../ioc/util';
import TYPES from '../../ioc/types';
import FilmLayout from '@/components/FilmLayout.vue';

import { Label } from '../../client/label.client';
import LabelsModule from '@/store/modules/labels';
import mixins from 'vue-typed-mixins'
import LabelMixin from '../../mixins/labels';
import SettingsModule from '@/store/modules/settings';
import { SearchModuleHeritage } from '@/store/modules/search';
import { Organization, OrganizationLight, fromHeritageUser } from '../../client/organization.client';

import SearchStreamerLayout from '@/components/heritage/SearchStreamerLayout.vue';
import SearchRightholderLayout from '@/components/heritage/SearchRightholderLayout.vue';
import SearchUserLayout from '@/components/heritage/SearchUserLayout.vue';

export default mixins(LabelMixin).extend({
  name: 'SearchView',
  mixins: [clickaway],
  props: {
    words: String,
    chapter: String,
  },
  components: {
    SearchStreamerLayout,
    SearchRightholderLayout,
    FilmLayout,
    SearchUserLayout,
  },
  computed: {
    
  },
  watch: {
    words: {
      immediate: false,
      handler(w: string) {
        this.doSearch(w)
      },
    }
  },
  async mounted() {  
    await getModule(SearchModuleHeritage, this.$store).loadFilms();
    await getModule(SearchModuleHeritage, this.$store).loadStreamers();
    await getModule(SearchModuleHeritage, this.$store).loadRightholders();
    await getModule(SearchModuleHeritage, this.$store).loadUsers();

    await this.doSearch(this.words)

  },
  data():{
      films:FilmIndexResponse[],
      streamers:OrganizationLight[],
      rightholders:OrganizationLight[],
      users:UserHeritageResponse[],
      loading: boolean
  } {
      return {
          films: [],
          streamers: [],
          rightholders: [],
          users: [],
          loading: true
      }
  },
  methods: {
    async doSearch(q:string) {
      const searchModule = getModule(SearchModuleHeritage, this.$store)

      const resultFilms = searchModule.indexFilms.search('*'+q+'*')
      this.films = resultFilms.flatMap(r => {
          const obj = searchModule.films.find(x => x.id == parseInt(r.ref))
          return obj ? [indexToLight(obj)] : []
      })
      
      const resultStreamers = searchModule.indexStreamers.search('*'+q+'*')
      this.streamers = resultStreamers.flatMap(r => {
          const obj = searchModule.streamers.find(x => x.id == parseInt(r.ref))
          return obj ? [fromHeritageUser(obj)] : []
      })
      //this.streamers = resultStreamers.map(fromHeritageUser).filter((v,i,a)=>a.findIndex(t=>(t.id === v.id))===i && v.name).sort((x,y) => x.name.localeCompare(y.name))
      //this.streamers.map(fromHeritageUser).filter((v,i,a)=>a.findIndex(t=>(t.id === v.id))===i && v.name).sort((x,y) => x.name.localeCompare(y.name))

      const resultRightholders = searchModule.indexRightholders.search('*'+q+'*')
      this.rightholders = resultRightholders.flatMap(r => {
          const obj = searchModule.rightholders.find(x => x.id == parseInt(r.ref))
          return obj ? [fromHeritageUser(obj)] : []
      })
      //this.rightholders = [...this.rightholders];

      const resultUsers = searchModule.indexUsers.search('*'+q+'*')
      this.users = resultUsers.flatMap(r => {
          const obj = searchModule.users.find(x => x.id == parseInt(r.ref))
          return obj ? [obj] : []
      })
      //this.users = [...this.rightholders];
      //this.main_activities = this.mainActivities(this.filteredRightHolders)
      /*
      const resultAuthors = searchModule.indexAuthors.search(q)
      this.authors = resultAuthors.flatMap(r => {
          const obj = searchModule.authors.find(x => x.id == parseInt(r.ref))
          return obj ? [obj] : []
      })

      console.log(this.authors)
      */
      if(this.chapter == 'any') {
        const results:number[] = [this.films.length,this.streamers.length,this.rightholders.length,this.users.length/*,this.agenda.length,this.discussions.length,this.authors.length*/]
        console.log(results.findIndex(x => x > 0))
        switch(results.findIndex(x => x > 0)) {
          case 1: this.$router.push('/search/'+this.words+'/streamers'); break;
          case 2: this.$router.push('/search/'+this.words+'/rightholders'); break;
          case 3: this.$router.push('/search/'+this.words+'/users'); break;
          default: this.$router.push('/search/'+this.words+'/films')
        }
      }
      this.loading = false;
    }
  },
});
