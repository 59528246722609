
import Vue, { PropType } from 'vue';
import StreamersLayout from '@/components/heritage/StreamersLayout.vue';
import { getModule } from 'vuex-module-decorators';
import Filters, { FilterValue } from '@/components/heritage/Filters.vue';
import { DEFAULT_LIST_OPTION_REQUEST } from '@/client/category.client';
import UserClient, { DefaultListOptionRequest, Film, UserResponse } from '@/client/user.client';
import { extractContainer } from '@/ioc/util';
import SettingsModule from '@/store/modules/settings';
import { ListOptionRequest, FilterOption, SortOption, CountResponse } from '../../client/user.client';
import TYPES from '@/ioc/types';
import { SiteConfig } from '../../client/value.client';
import ValuesModule from '../../store/modules/values';
import UserFilmClient from '../../client/user-film.client';
import { DownloadStatus } from '@/client/download.client';

import { Label } from '../../client/label.client';
import LabelsModule from '@/store/modules/labels';
import mixins from 'vue-typed-mixins'
import LabelMixin from '../../mixins/labels';
import { flatten, pickBy, identity } from 'lodash';
import { Organization, fromHeritageUser, OrganizationLight } from '../../client/organization.client';

const PAGE_LENGTH = 20;
const PICKS_LENGTH = 20;
const CURRENT_PAGE_DEFAULT = 1;

export default mixins(LabelMixin).extend({
  name: 'StreamersView',
  inject: ['container'],
  components: {
    StreamersLayout,
    Filters,
  },
  props: {

  },
  beforeCreate: function() {
    document.body.className = '';
  },
  async created() {
      this.status = 'LOADING';
      const settingsModule = getModule(SettingsModule, this.$store);
      const container = extractContainer(this);
      const userClient =  container.get<UserClient>(TYPES.UserClient);
      const result = await userClient.getListHeritage(settingsModule.language,{currentPage: 1, pageLength: 1000}, {column: 'roles', operator: 'like', value:'JP-Heritage-Streamer'});
      this.status = 'SUCCESS';
      this.streamers = result.map(fromHeritageUser).filter((v,i,a)=>a.findIndex(t=>(t.id === v.id))===i).filter(x => x.name ? true : false).sort((x,y) => x.name.localeCompare(y.name))
      this.filteredStreamers = [...this.streamers]
      this.main_activities = this.mainActivities(this.filteredStreamers)

      this.filmCountries = [...new Set(this.streamers.flatMap(x => {
        //return x.country ? x.country.split(",").map(x => x.trim()) : []
        return x.country ? x.country.split(",").map(x => getModule(ValuesModule, this.$store).countries.find(y => y.name === x )?.name_it || x.trim()) : []
      }))].sort((x,y) => x.localeCompare(y))

      this.loaded = true;
  },
  computed: {

  },
  methods: {
    mainActivities(users:OrganizationLight[]):string[] {
      return [...new Set(users.flatMap(x => x.main_activities))].sort((x,y) => x.localeCompare(y))
    },
    onFilterChange(filters:FilterValue[]) {
      let newFilter = [...this.streamers]
      let newFilter1:number[] = []
      
      // check if filter is active
        let checkFilters = false;
        filters.forEach(element => {
          if(element.filter) {
            checkFilters = true;
          }
        });
        console.log(filters.length);
        if(checkFilters){
          newFilter.splice(0);

        filters.forEach(element => {
          if(element.filter) {
            if(element.filter.column == "country"){
            //console.log(element.filter.column);
            let allData = [...this.streamers]
            let tempFilter = allData.filter(x => {
              return x.country ? (x.country as string).includes((element.filter?.value as string)) : false
            })
            tempFilter = allData.filter(x => {
              return x.country ? ((getModule(ValuesModule, this.$store).countries.find(y => y.name === x.country )?.name_it || x.country) as String).includes((element.filter?.value as string)) : false
            })
            //newFilter = newFilter.concat(tempFilter);
            tempFilter.forEach(x => {
              newFilter1.push(x.id)
            })
            }
            if(element.filter.column == "main_activity"){
            //console.log(element.filter.column);
            let allData = [...this.streamers]
            let tempFilter = allData.filter(x => {
              return x.main_activities.some( ma => ma === (element.filter?.value as string))
            })
            newFilter = newFilter.concat(tempFilter);
            }
          }
        });

        //newFilter = [ ...new Set(newFilter)];
        
        let intersection:number[];
        intersection = newFilter1
        
        newFilter = [...this.streamers];
        newFilter = newFilter.filter(x => {
          //console.log(x.id + " CHECK IN "+ intersection + " result: "+ intersection.includes(x.id));
          return intersection.includes(x.id);
        })
        }

      filters.forEach(element => {
        if(element.fullText) {
          const q = (element.fullText as string).toLowerCase()
          newFilter = newFilter.filter(x => {
            return x.name.toLowerCase().includes(q) || 
            x.country.toLowerCase().includes(q) || 
            x.main_activities.some(ma => ma.toLowerCase().includes(q))
          })
        }/*
        if(element.filter) {
          newFilter = newFilter.filter(x => {
            return x.main_activities.some( ma => ma === (element.filter?.value as string))
          })
        }
        if(element.sort) {
          if(element.sort.order == "desc") {
            if(element.sort.column == "title") {
              newFilter = newFilter.sort((x,y) => -x.name.localeCompare(y.name))
            } else {
              newFilter = newFilter.sort((x,y) => -x.country.localeCompare(y.country))
            }
          } else {
            if(element.sort.column == "title") {
              newFilter = newFilter.sort((x,y) => x.name.localeCompare(y.name))
            } else {
              newFilter = newFilter.sort((x,y) => x.country.localeCompare(y.country))
            }
          }
        }*/
        if(element.sort) {
          //console.log(element.sort)
          if(element.sort.order == "desc") {
            if(element.sort.column == "title") {
              newFilter = newFilter.sort((x,y) => -x.name.localeCompare(y.name))
            } else if(element.sort.column == "country") {
              newFilter = newFilter.sort((x,y) => -x.country.localeCompare(y.country))
            }
          } else {
            if(element.sort.column == "title") {
              newFilter = newFilter.sort((x,y) => x.name.localeCompare(y.name))
            } else if(element.sort.column == "country") {
              newFilter = newFilter.sort((x,y) => x.country.localeCompare(y.country))
            }
          }
        }
      });
      this.filteredStreamers = newFilter
    }
  },
  data(): {
    status: DownloadStatus; 
    streamers: OrganizationLight[]
    filteredStreamers: OrganizationLight[]
    main_activities: string[] | null
    filmCountries: String[];
    loaded: Boolean;
  } {
    return {
      status: 'NOT_YET',
      streamers: [],
      filteredStreamers: [],
      main_activities: null,
      filmCountries: [],
      loaded: false
    };
  }
});
