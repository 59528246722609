
import Vue, { PropType } from 'vue';

import { Label } from '@/client/label.client';
import LabelsModule from '@/store/modules/labels';
import mixins from 'vue-typed-mixins'
import LabelMixin from '@/mixins/labels';
import { sanitizeNumber } from '@/service/form';

import { getModule } from 'vuex-module-decorators';

import CommentsResourceChild from '@/components/CommentsResourceChild.vue';
import UsersModule from '@/store/modules/users';
import { formatPublication } from '@/service/ui';

import Swiper, { SwiperOptions } from 'swiper';
import SettingsModule from '@/store/modules/settings';
import { extractContainer } from '@/ioc/util';
import TYPES from '@/ioc/types';

import OdImage from '@/components/ui/OdImage.vue';

import { mapState } from 'vuex';
import { RootState } from '@/store/modules/root';

import ResourceCommentClient, { emptyComment,emptyCommentInternal, ResourceComment, SendComment } from '@/client/resource-comment.client';

import UserMention from '@/components/ui/UserMention.vue';
import UserMentionRenderer from '@/components/ui/UserMentionRenderer.vue';

function sanitizeCommentForm(comment: SendComment): SendComment {
  return {
    ...comment
  }
}

export interface SubmitSubFormEmit {
  comment: SendComment;
}

export default mixins(LabelMixin).extend({
  name: 'ResourceSubDiscussion',
  components: {
    CommentsResourceChild,
    OdImage,
    UserMention,
    UserMentionRenderer
  },
  async created() {
    await getModule(UsersModule, this.$store).dispatchGetMe();
    //godown
    if(this.lastId != 0){
      setTimeout(() => this.scrollToElement(), 1000);
    }
  },
  inject: ['container'],
  props: {
    main_comment: {
      type: Object as PropType<ResourceComment>
    },
    resource_id: { 
      type: Number 
    },
    parent_id: {
      type: Number,
    },
    lastId:{
      type: Number
    },
    id_focus:{
      type: Number
    },
    id_focus2:{
      type: Number
    },
    type:{
      type: Number
    }

  },
  asyncComputed: {/*
    async getCommentsByResource(): Promise<ResourceComment[] | null> {
      const settingsModule = getModule(SettingsModule, this.$store);
      const container = extractContainer(this);
      const ResourceCommentClient = container.get<ResourceCommentClient>(TYPES.ResourceCommentClient);
      return await ResourceCommentClient.getCommentsByResource(settingsModule.language,this.resource_id);
    },*/
    async getComments(): Promise<ResourceComment[] | null> {
      const settingsModule = getModule(SettingsModule, this.$store);
      const container = extractContainer(this);
      const ResourceCommentClient = container.get<ResourceCommentClient>(TYPES.ResourceCommentClient);
      return await ResourceCommentClient.getCommentsByParent(settingsModule.language,this.main_comment.id);
    },
  },
  computed: mapState({
      isAdmin: (state:any) => {
        if((state as RootState).users.me?.admin_forum == 1){
          return true;
        }else if((state as RootState).users.me?.admin_forum == true){
          return true;
        }else{
          return false;
        }
        //return (state as RootState).users.me?.admin == 1 
      }
  }
 ),
  methods: {
    setMentionState(val: boolean){
      this.mentionState = val;
    },
    newline() {
      //this.comment.body = `${this.comment.body}<br/>`;
    },
    send() {
      if(this.comment.body != "" && !this.mentionState){
        this.onSubmitForm();
      }else{
        this.mentionState = false;
      }
    },
    async removeCommentAdmin(id: Number) {
      const container = extractContainer(this);
      const settingsModule = getModule(SettingsModule, this.$store);
      const ResourceCommentClient = container.get<ResourceCommentClient>(TYPES.ResourceCommentClient);
      let values = await ResourceCommentClient.remove(settingsModule.language, id)
      this.showComment = false;
    },
    async hideCommentAdmin(id: number) {
      const container = extractContainer(this);
      const settingsModule = getModule(SettingsModule, this.$store);
      const ResourceCommentClient = container.get<ResourceCommentClient>(TYPES.ResourceCommentClient);
      let values = await ResourceCommentClient.hideComment(settingsModule.language, id)
      this.isHidden = !this.isHidden;
      /*
      const element = document.getElementById("f"+this.comment.parent_id);
      if(element !== null){
        element.classList.add("hidden");
      }
      */

    },
    async onSubmitForm() {
      //event.preventDefault();
      this.loadingForm = true;
      const commentToPost = sanitizeCommentForm(this.comment)
      this.comment.body = "";
      const container = extractContainer(this);
      const settingsModule = getModule(SettingsModule, this.$store);

      const ResourceCommentClient = container.get<ResourceCommentClient>(TYPES.ResourceCommentClient);
      let values = await ResourceCommentClient.create(settingsModule.language, commentToPost)

      this.$asyncComputed.getComments.update();
      /*
      console.log("r"+this.comment.parent_id);
      const element = document.getElementById("r"+this.comment.parent_id);
      if(element !== null){
        element.classList.add("hidden");
      }
      this.testreload++;
      console.log("r"+commentToPost.parent_id);
      const id_1 = "r"+commentToPost.parent_id;
      const yOffset = -100; 
      const element_1 = document.getElementById(id_1);
      if(element_1 !== null){
        const y = element_1.getBoundingClientRect().top + window.pageYOffset + yOffset;
        window.scrollTo({top: y, behavior: 'smooth'});
      }*/
    },
    scrollToElement() {
      const el = this.$el.getElementsByClassName('lastElementofList')[0];

      if (el) {
        el.scrollIntoView();
      }
    },
    showComments(){
      //this.showComment = true;
      /*
    
      event.preventDefault();      
      const element = document.getElementById("p"+id);
      if(element !== null){
        element.classList.add("active");
      }
      */
    },
    showReply(id: Number){
      this.showReplyValue = true;
      setTimeout(() => {
        /*
          const element2 = document.getElementById("p"+this.main_comment.id);
          const element = document.getElementById("r"+this.main_comment.id);          
          const id_1 = "r"+id;
          const yOffset = -300; 
          const element_1 = document.getElementById(id_1);
          if(element_1 !== null){
            const y = element_1.getBoundingClientRect().top + window.pageYOffset + yOffset;
            window.scrollTo({top: y, behavior: 'smooth'});
          }*/
          const element_2 = document.getElementById("textarea"+this.main_comment.id);
          if(element_2 !== null){
            
            this.$scrollTo('#r'+this.main_comment.id, 500, { easing: 'ease-in-out', offset: -200})
            element_2.focus();
          }
      }, 500);
    }
  },
  data(): {
    comment: SendComment,
    loadingForm: boolean,
    testreload: number,
    showadmin: boolean,
    showComment: boolean,
    isHidden: boolean,
    showReplyValue: boolean,
    mentionState: boolean
  } {
    return {
      comment: emptyComment(getModule(UsersModule,this.$store).me?.id || -1,this.parent_id,this.resource_id),
      loadingForm: false,
      testreload: 0,
      showadmin: false,
      showComment: true,
      isHidden: !this.main_comment.visible,
      showReplyValue: false,
      mentionState: false
    };
  },
  filters: {
    formatPublication(d: Date): string {
      return formatPublication(d);
    },
  },
  mounted() {
    if(this.type == 1 && this.id_focus == this.parent_id){
          this.$scrollTo('#f'+this.parent_id, 500, { easing: 'ease-in-out', offset: -200})
    }
  }
});
