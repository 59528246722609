import {
  VuexModule, Module,
} from 'vuex-module-decorators';
import { SupportedLanguage } from '@/client/supported-lang.type';
import { SettingsState } from './state';


@Module({ namespaced: true, name: 'settings' })
export default class SettingsModule extends VuexModule implements SettingsState {
  language: SupportedLanguage = 'en';
}
