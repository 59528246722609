import { injectable, inject } from 'inversify';
import { AxiosStatic } from 'axios';
import Environment from '@/env/environment';
import TYPES from '@/ioc/types';
import { SupportedLanguage } from './supported-lang.type';
import { PagingOption,SortOption, DefaultListOptionRequest, FilterOption, ListOptionRequest } from './user.client';
import { Site } from '@/env/site';

export interface ValueResponse {
  id: number;
  name: string;
  site_id?: number
  activity_id?:number
}


export interface Country {
  id: number;
  name: string;
  name_it?: string;
  continent_id:number
}

export interface ValActivitiesResponse {
  id: number;
  name: string;
  rightholder:boolean;
  other:boolean;
}

export interface SiteConfig {
  enable_popular: boolean;
  intro: string;
  need_help:string;
  allowed_countries: number[];
  info: string;
}

export interface HeritageSiteConfig {
  enable_public_view: boolean;

}

export interface Faq {
  id:number;
  question: string;
  answer:string;
}

export interface Continent{
  id: number;
  name: string,
  countries: Country[]
}

@injectable()
export default class ValueClient {
  constructor(
    @inject(TYPES.AxiosStatic) private readonly axios: AxiosStatic,
    @inject(TYPES.Environment) private readonly environment: Environment,
    @inject(TYPES.Site) private readonly site: Site,
  ) { }

  async getCountries(
    lang: SupportedLanguage,
  ): Promise<Country[]> {
    const request:ListOptionRequest = {
      filter: [],
      sort: [
      ],
      paging: {currentPage: 1, pageLength: 1000},
    };
    const response = this.axios.post<Country[]>(`${this.environment.backendUrl}/api/v1/public/val_countries/list`,
      request,
      {
        withCredentials: true,
      });
    return (await response).data;
  }

  async getContinents(
    lang: SupportedLanguage,
  ): Promise<Continent[]> {
    
    const response = this.axios.post<Continent[]>(`${this.environment.backendUrl}/api/v1/form/${lang}/val_continent/list`,
      DefaultListOptionRequest,
      {
        withCredentials: true,
      });
    const result =  (await response).data
    return result
  }

  async getProfessions(
    lang: SupportedLanguage,
    request: PagingOption,
  ): Promise<ValueResponse[]> {
    return await this.getValues('val_professions', lang, request, {column: 'site_id', operator: '=', value: this.site.id+""});
  }

  async getTags(
    lang: SupportedLanguage,
    request: PagingOption,
  ): Promise<ValueResponse[]> {
    return await this.getValues('tags', lang, request);
  }

  async getGendres(
    lang: SupportedLanguage,
    request: PagingOption,
  ): Promise<ValueResponse[]> {
    return await this.getValues('val_genders', lang, request);
  }

  async getFilmGendres(
    lang: SupportedLanguage,
    request: PagingOption,
  ): Promise<ValueResponse[]> {
    return await this.getValues('val_film_genre', lang, request);
  }

  async getFilmFeatureType(
    lang: SupportedLanguage,
    request: PagingOption,
  ): Promise<ValueResponse[]> {
    return await this.getValues('val_film_feature_type', lang, request);
  }

  async getFilmLanguages(
    lang: SupportedLanguage,
    request: PagingOption,
  ): Promise<ValueResponse[]> {
    const sortOption:SortOption[] = []
    return await this.getValues('val_film_languages', lang, request, null, {column: "name", order: "asc"});
  }

  async getLicenseExclusivity(
    lang: SupportedLanguage,
    request: PagingOption,
  ): Promise<ValueResponse[]> {
    return await this.getValues('val_license_exclusivity', lang, request);
  }

  async getLicenseType(
    lang: SupportedLanguage,
    request: PagingOption,
  ): Promise<ValueResponse[]> {
    return await this.getValues('val_license_type', lang, request);
  }

  async getSiteConfig(): Promise<SiteConfig> {
    const response = this.axios.post<SiteConfig[]>(`${this.environment.backendUrl}/api/v1/public/site/list`,
      DefaultListOptionRequest,
      {
        withCredentials: true,
      });
    return (await response).data[0];
  }

  async getHeritageSiteConfig(): Promise<HeritageSiteConfig> {
    const response = this.axios.post<HeritageSiteConfig[]>(`${this.environment.backendUrl}/api/v1/public/heritage_site_management/list`,
      DefaultListOptionRequest,
      {
        withCredentials: true,
      });
    return (await response).data[0];
  }

  async getValActivities(): Promise<ValActivitiesResponse[]> {
    const response = this.axios.post<ValActivitiesResponse[]>(`${this.environment.backendUrl}/api/v1/public/val_activities/list`,
      DefaultListOptionRequest,
      {
        withCredentials: true,
      });
    return (await response).data;
  }

  async getSiteFaq(): Promise<Faq[]> {
    const response = this.axios.post<Faq[]>(`${this.environment.backendUrl}/api/v1/public/site_faq/list`,
      DefaultListOptionRequest,
      {
        withCredentials: true,
      });
    return (await response).data;
  }

  private async getValues(resource: string, lang: SupportedLanguage, request: PagingOption, filter:FilterOption | null = null, sort: SortOption | null = null): Promise<ValueResponse[]> {
    const fullRequest:ListOptionRequest = {
      filter: filter ? [filter] : [],
      sort: sort ? [sort]: [],
      paging: request,
    };
    const response = this.axios.post<ValueResponse[]>(`${this.environment.backendUrl}/api/v1/public/${resource}/list`,
      fullRequest,
      {
        withCredentials: true,
      });
    return (await response).data;
  }
}