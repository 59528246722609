
import Vue, { PropType } from 'vue'
import SettingsModule from '@/store/modules/settings';
import { extractContainer } from '@/ioc/util';
import FloatLabel from "vue-float-label/components/FloatLabel.vue";
import {UserResponse } from '@/client/user.client';

import mixins from 'vue-typed-mixins';
import LabelMixin from '@/mixins/labels';
import { getModule } from 'vuex-module-decorators';
import ValuesModule from '@/store/modules/values';
import { Country, ValueResponse } from '@/client/value.client';
import TYPES from '@/ioc/types';
import UsersModule from '@/store/modules/users';
import { NamedBlob } from '@/client/download.client';
import AccreditationClient, { Accreditation, emptyAccreditation, ActivityResponse, Activities } from '@/client/accreditation.client';
import cloneDeep from 'clone-deep';

function sanitizeAccreditationForm(accreditation: Accreditation): Accreditation {
  return {
    ...accreditation,
  }
}

export interface SubmitFormEmit {
  accreditation: Accreditation;
  presentation: NamedBlob | null;
  library: NamedBlob | null;
}

export default mixins(LabelMixin).extend({
  components: {
    FloatLabel
  },
  inject: ['container'],
  async created() {
    const container = extractContainer(this);
    await getModule(UsersModule, this.$store).dispatchGetMe();
    const settingsModule = getModule(SettingsModule, this.$store);
    const AccreditationClient = container.get<AccreditationClient>(TYPES.AccreditationClient);

    this.activityRightholder = await AccreditationClient.getActivitiesRightholder(settingsModule.language);
    this.activityRightholder.forEach(v =>  this.activityRightholderChecked.push(false) )

    this.activityStreamer = await AccreditationClient.getActivitiesStreamer(settingsModule.language);
    this.activityStreamer.forEach(v =>  this.activityStreamerChecked.push(false) )

    this.activityOther = await AccreditationClient.getActivitiesOther(settingsModule.language);
    this.activityOther.forEach(v =>  this.activityOtherChecked.push(false) )

    this.setActivityValidity(false)

  },
  computed: {
    user():UserResponse | null {
      return getModule(UsersModule,this.$store).me
    },
    countries(): ValueResponse[] {
      //const allowedCountries = getModule(ValuesModule,this.$store).siteConfig.allowed_countries
      return cloneDeep(getModule(ValuesModule, this.$store).countries).sort(function(a:Country, b:Country){
        return (a.name.toLowerCase() > b.name.toLowerCase()) ? 1 : -1;
      });//.filter(x => allowedCountries.includes(x.id));
    },
  },
  methods: {
    onPresentationChange(event: Event) {
      const target: EventTarget | null = event?.target;
      if (!target) {
        return;
      }

      const file: File = ((target as HTMLInputElement).files ?? [])[0];

      if (!file) {
        return;
      }

      this.presentation = {
          name: file.name,
          blob: file
      }
    },
    onLibraryChange(event: Event) {
      const target: EventTarget | null = event?.target;
      if (!target) {
        return;
      }

      const file2: File = ((target as HTMLInputElement).files ?? [])[0];

      if (!file2) {
        return;
      }

      this.library = {
          name: file2.name,
          blob: file2
      }
    },
    isAllSelected(activity:number):boolean {
      return this.activityChecked[activity];
    },
    toggleActivity(value:boolean, activity: number) {
      
        this.activityChecked[0] = false;
        this.activityChecked[1] = false;
        this.activityChecked[2] = false;

         if(value){
          this.activityChecked[activity] = true;
          this.accreditation.company_activity = activity==0?'Rightholder':activity==1?'Streamer':'Other';
        }else{
          this.accreditation.company_activity = '';
        }
        /*
      if(value){
        this.activityChecked[activity] = true;
        this.accreditation.company_activity = activity==0?'Rightholder':activity==1?'Streamer':'Other';
      }
      */
     
      //console.log(this.accreditation.company_activity);
      
      this.$forceUpdate()
    },
    setActivityValidity(valid:boolean) {
      const activityElement = (this.$refs['activity'] as HTMLInputElement)
      if(!valid)
        activityElement.setCustomValidity("Please select at least one activity");
      else 
        activityElement.setCustomValidity("")
    },
    checkActivity() {

      const checks = [... this.activityRightholderChecked,this.activityStreamerChecked,this.activityOtherChecked]
      const valid = checks.some(x => x)
      this.setActivityValidity(valid);
      
    },
    uploadPresentation() {
      (this.$refs.presentationFile as HTMLInputElement).click()
    },
    uploadLibrary() {
      (this.$refs.libraryFile as HTMLInputElement).click()
    },
    onCancel(event: MouseEvent) {
      event.preventDefault();
      this.$emit('onCancel');
    },
    onSubmitForm(event: Event) {
      event.preventDefault();

      this.loadingForm = true;

      //metti attivita
      this.activityChecked.forEach((value, index) => {
            if(value){
              this.accreditation.company_activity = this.activity[index];
              //concatena subactivity
              if(this.activity[index] == "Rightholder"){
                var total = 0;
                this.activityRightholderChecked.forEach((value2, index2) => {
                    if(value2){
                      //if(total > 0){
                      //  this.accreditation.company_sub_activity += ', ';
                      //}
                      //this.accreditation.company_sub_activity += this.activityRightholder[index2];
                      let newActivity: Activities = {activity_id: 0};
                      newActivity.activity_id = this.activityRightholder[index2].id;
                      this.accreditation.accreditation_request_activities.push(newActivity);
                      //total++;
                    }
                });
              }else if(this.activity[index] == "Streamer"){
                var total = 0;
                this.activityStreamerChecked.forEach((value2, index2) => {
                    if(value2){
                      let newActivity: Activities = {activity_id: 0};
                      newActivity.activity_id = this.activityStreamer[index2].id;
                      this.accreditation.accreditation_request_activities.push(newActivity);
                    }
                });
              }else  if(this.activity[index] == "Other"){
                var total = 0;
                this.activityOtherChecked.forEach((value2, index2) => {
                    if(value2){
                      let newActivity: Activities = {activity_id: 0};
                      newActivity.activity_id = this.activityOther[index2].id;
                      this.accreditation.accreditation_request_activities.push(newActivity);
                    }
                });
              }
            }
        });

      

      const submitEmit: SubmitFormEmit = {
        accreditation: sanitizeAccreditationForm(this.accreditation),
        presentation: this.presentation,
        library: this.library,
      };
      this.loadingForm = false;
      this.$emit('onSubmit', submitEmit);
    }
  },
  data(): { 
    presentation: NamedBlob | null,
    library: NamedBlob | null,
    accreditation: Accreditation,
    loadingForm: boolean,
    activity: string[],
    activityChecked: boolean[],
    activityRightholder: ActivityResponse[],
    activityRightholderChecked: boolean[],
    activityStreamer: ActivityResponse[],
    activityStreamerChecked: boolean[],
    activityOther: ActivityResponse[],
    activityOtherChecked: boolean[],
    companyExist: boolean,
  } {
    return {
      presentation: null,
      library: null,
      accreditation: emptyAccreditation(getModule(UsersModule,this.$store).me?.id || -1),
      loadingForm: false,
      activity: ['Rightholder','Streamer','Other'],
      activityChecked: [false,false,false],
      activityRightholder: [],
      activityRightholderChecked: [],
      activityStreamer: [],
      activityStreamerChecked: [],
      activityOther: [],
      activityOtherChecked: [],
      companyExist: false,
    };
  }
})
