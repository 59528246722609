
import Vue, { PropType } from 'vue';
import { UserLightResponse, DefaultListOptionRequest, UserResponse } from '../../client/user.client';
import OdImage from '@/components/ui/OdImage.vue';

import { Label } from '../../client/label.client';
import LabelsModule from '@/store/modules/labels';
import mixins from 'vue-typed-mixins'
import LabelMixin from '../../mixins/labels';
import { getModule } from 'vuex-module-decorators';
import UsersModule from '../../store/modules/users';

export default mixins(LabelMixin).extend({
  name: 'SearchUserLayout',
  components: {
    OdImage,
  },
  props: {
    className: {
      type: String,
      validator: (c:string) => [
        'user',
      ].includes(c),
    },
    user: {
      type: Object as PropType<UserLightResponse>,
      required: true,
    }
  },
  filters: {

  }
});


