
import Vue, { PropType } from 'vue';
import ResourceListElementLayout from '@/components/ResourceListElementLayout.vue';
import { ResourceLightResponse } from '@/client/resource.client';

import { Label } from '../client/label.client';
import LabelsModule from '@/store/modules/labels';
import mixins from 'vue-typed-mixins'
import LabelMixin from '../mixins/labels';

export default mixins(LabelMixin).extend({
  name: 'PopularResources',
  props: {
    resources: {
      type: Array as PropType<ResourceLightResponse[]>,
      required: true,
    }
  },
  components: {
    ResourceListElementLayout,
  },
});
