
import Vue, { PropType } from "vue";
import { Social } from "@/client/user.client";
import SocialResolver from "@/components/containers/SocialResolver.vue";

export default Vue.extend({
  components: {
    SocialResolver
  },
  props: {
    socials: {
      type: Array as PropType<Social[]>
    }
  }
});
