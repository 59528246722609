
import Vue, { PropType } from 'vue';
import { mixin as clickaway } from 'vue-clickaway';

import { getModule } from 'vuex-module-decorators';
import VideoPlayer from '@/components/ui/VideoPlayer.vue';
import ResourceClient from '../../client/resource.client';
import ResourcesModule from '../../store/modules/resources';
import FilmLayout from '@/components/FilmLayout.vue';
import ProfileCompany from '@/components/heritage/ProfileCompany.vue';
import ProfileLibrary from '@/components/heritage/ProfileLibrary.vue';
import ProfileSocial from '@/components/ui/ProfileSocial.vue';
import OdImage from '@/components/ui/OdImage.vue';
import OdDownloadButton from '@/components/ui/OdDownloadButton.vue';
import UserClient, { UserResponse, Film, DefaultListOptionRequest, ListOptionRequest, UserHeritageResponse } from '../../client/user.client';
import UsersModule from '@/store/modules/users';
import ValuesModule from '@/store/modules/values';
import { format } from 'date-fns';
import { extractContainer } from '../../ioc/util';
import TYPES from '../../ioc/types';
import DownloadClient, { DownloadStatus } from '@/client/download.client';

import { Label } from '../../client/label.client';
import LabelsModule from '@/store/modules/labels';
import mixins from 'vue-typed-mixins'
import LabelMixin from '../../mixins/labels';
import SettingsModule from '@/store/modules/settings';
import OrganizationClient, { Organization, OrganizationFast } from '../../client/organization.client';
import { isHeritageRightHolder, isHeritageOther, isHeritageStreamer } from '@/service/user'
import UserFilmClient, { FilmLight } from '../../client/user-film.client';
import { STEP_AWAY_DEMO } from '@/router/heritage';
import Filters, { FilterValue } from '@/components/heritage/Filters.vue';

export default mixins(LabelMixin).extend({
  name: 'Company',
  mixins: [clickaway],
  props: {
    id: Number,
    chapter: String
  },
  components: {
    ProfileCompany,
    ProfileLibrary,
    ProfileSocial,
    OdImage,
    OdDownloadButton,
    VideoPlayer,
    FilmLayout,
    Filters
  },
  async created() {
    this.status = 'LOADING';
    const settingsModule = getModule(SettingsModule, this.$store);
    const container = extractContainer(this);
    const organizationClient = container.get<OrganizationClient>(TYPES.OrganizationClient);
    this.company = await organizationClient.getById(settingsModule.language, this.id);

    if(this.company) {
      const settingsModule = getModule(SettingsModule, this.$store);
      const container = extractContainer(this);
      const userClient = container.get<UserClient>(TYPES.UserClient);
      console.log(this.company)
      this.users = await userClient.getListHeritage(settingsModule.language,{currentPage: 1, pageLength: 10},{column: 'organization_id', operator: 'in', value: this.company.id.toString()})
    }

      if(this.company) {
        const settingsModule = getModule(SettingsModule, this.$store);
        const container = extractContainer(this);
        const filmClient =  container.get<UserFilmClient>(TYPES.UserFilmClient);
        this.films = await filmClient.getCompanyFilms(settingsModule.language,20,this.company.id);
        this.filteredFilms = [...this.films]
      } else {
        return []
      }

      
      
      this.filmTypes = [...new Set(this.films.flatMap(x => {
        return x.genre_name ? x.genre_name.split(",").map(x => x.trim()) : []
      }))].sort((x,y) => x.localeCompare(y))

      this.filmYears = [...new Set(this.films.flatMap(x => {
        return x.year ? x.year.toString() : []
      }))].sort((x,y) => (parseInt(x) > parseInt(y))?1:-1)
      
      this.filmDirectors = [...new Set(this.films.flatMap(x => {
        return x.director ? x.director.split(",").map(x => x.trim()) : []
      }))].sort((x,y) => x.localeCompare(y))

      this.filmLanguages = [...new Set(this.films.flatMap(x => {
        return x.languages ? x.languages.split(",").map(x => x.trim()) : []
      }))].sort((x,y) => x.localeCompare(y))

      this.filmSubtitles = [...new Set(this.films.flatMap(x => {
        return x.subtitles ? x.subtitles.split(",").map(x => x.trim()) : []
      }))].sort((x,y) => x.localeCompare(y))

      this.filmCountries = [...new Set(this.films.flatMap(x => {
        return x.countries ? x.countries.split(",").map(x => x.trim()) : []
      }))].sort((x,y) => x.localeCompare(y))


      this.loaded = true;

    
    this.status = 'SUCCESS';
  },
  data(): { 
    company:OrganizationFast | null;
    users: UserHeritageResponse[];
    isVideoPlaying: Boolean;
    status: DownloadStatus;
    films: FilmLight[];
    filteredFilms: FilmLight[];
    filmTypes: String[];
    filmYears: String[];
    filmDirectors: String[];
    filmCountries: String[];
    filmLanguages: String[];
    filmSubtitles: String[];
    loaded: Boolean;
   } {
    return {
      company: null,
      users: [],
      isVideoPlaying: false,
      status: 'NOT_YET',
      films: [],
      filteredFilms: [],
      filmTypes: [],
      filmYears: [],
      filmDirectors: [],
      filmCountries: [],
      filmLanguages: [],
      filmSubtitles: [],
      loaded: false
    }
  },
  asyncComputed: {
    async demoUser(): Promise<Boolean> {
      const me = await getModule(UsersModule, this.$store).dispatchGetMe();
      return me && me.roles && me.roles.includes("Demo") ? true : false
    },/*
    async films():Promise<FilmLight[]> {
      if(this.company) {
        const settingsModule = getModule(SettingsModule, this.$store);
        const container = extractContainer(this);
        const filmClient =  container.get<UserFilmClient>(TYPES.UserFilmClient);
        return await filmClient.getCompanyFilms(settingsModule.language,20,this.company.id);
      } else {
        return []
      }
    }*/
  },
  computed: {
    isOwner(): boolean {
      const result = getModule(UsersModule, this.$store).me?.user_organization.map(x => x.organization_id).includes(this.id)
      return result ? result : false;
    },
    rightholder():boolean {
      return isHeritageRightHolder(this.users[0])
    },
    other():boolean {
      return isHeritageOther(this.users[0])
    },
    streamer():boolean {
      return isHeritageStreamer(this.users[0])
    }
  },
  filters: {
    formatDate(date: Date): string {
      if (!date) {
        return '';
      }
      return format(date, 'd/M/yyyy');
    } 
  },
  methods: {
    formatWebsite(website: string) {
        // JavaScript/TypeScript
        if (website.substring(0, 7) !== 'http://' && website.substring(0, 8) !== 'https://'){
          website = 'http://' + website;
        }
          return website;
    },
    stepAway() {
      this.$router.push(STEP_AWAY_DEMO)
    },
    playVideo() {
        this.isVideoPlaying = !this.isVideoPlaying;
        // Play video
        (this.$refs.player as any).playVideo()
    },
    stopVideo() {
      // Stop video
      (this.$refs.player as any).stopVideo()
      this.isVideoPlaying = !this.isVideoPlaying;
    },
    countryName(name: string): string {
      return getModule(ValuesModule, this.$store).countries.find(x => x.name_it === name )?.name || name
    },
    /*
    countryName(id: number): string {
      return getModule(ValuesModule, this.$store).getCountryName(id);
    },
    */
    professionName(id: number): string {
      return getModule(ValuesModule, this.$store).getProfessionName(id);
    },
    filmGendre(id: number): string | undefined {
      return getModule(ValuesModule, this.$store).filmGendres.find(x => x.id === id)?.name;
    },
    languages(ids:{lang_id:number}[]):string[] {
      return getModule(ValuesModule, this.$store).filmLanguages.filter(x => ids.map(y => y.lang_id).includes(x.id)).map(x => x.name);
    },
    onFilterChange(filters:FilterValue[]) {
          let newFilter = [...this.films]
          let newFilter1:number[] = []
          let newFilter2:number[] = []
          let newFilter3:number[] = []
          let newFilter4:number[] = []
          let newFilter5:number[] = []
          let newFilter6:number[] = []


          // check if filter is active
            let checkFilters = false;
            filters.forEach(element => {
              if(element.filter) {
                checkFilters = true;
              }
            });
            console.log(filters.length);
            if(checkFilters){
              newFilter.splice(0);

            filters.forEach(element => {
              if(element.filter) {
                if(element.filter.column == "type_film"){
                console.log(element.filter.column);
                let allData = [...this.films]
                let tempFilter = allData.filter(x => {
                  return x.genre_name ? (x.genre_name as string).includes((element.filter?.value as string)) : false
                })
                //newFilter = newFilter.concat(tempFilter);
                tempFilter.forEach(x => {
                  newFilter1.push(x.id)
                })
                }else if(element.filter.column == "year_film"){
                console.log(element.filter.column);
                let allData = [...this.films]
                let tempFilter = allData.filter(x => {
                  return x.year ? (x.year.toString()).includes((element.filter?.value as string)) : false
                })
                //newFilter = newFilter.concat(tempFilter);
                tempFilter.forEach(x => {
                  newFilter2.push(x.id)
                })
                }else if(element.filter.column == "director_film"){
                console.log(element.filter.column);
                let allData = [...this.films]
                let tempFilter = allData.filter(x => {
                  return x.director ? (x.director as string).includes((element.filter?.value as string)) : false
                })
                //newFilter = newFilter.concat(tempFilter);
                tempFilter.forEach(x => {
                  newFilter3.push(x.id)
                })
                }else if(element.filter.column == "language_film"){
                console.log(element.filter.column);
                let allData = [...this.films]
                let tempFilter = allData.filter(x => {
                  const toCheck = x.languages.split(",").map(x => x.trim());
                  return x.languages ? toCheck.includes((element.filter?.value as string)): false
                  //return x.languages ? (x.languages as string).includes((element.filter?.value as string)) : false
                })
                //newFilter = newFilter.concat(tempFilter);
                tempFilter.forEach(x => {
                  newFilter4.push(x.id)
                })
                }else if(element.filter.column == "subtitle_film"){
                console.log(element.filter.column);
                let allData = [...this.films]
                let tempFilter = allData.filter(x => {
                  const toCheck = x.subtitles.split(",").map(x => x.trim());
                  return x.subtitles ? toCheck.includes((element.filter?.value as string)): false
                  //return x.subtitles ? (x.subtitles as string).includes((element.filter?.value as string)) : false
                })
                //newFilter = newFilter.concat(tempFilter);
                tempFilter.forEach(x => {
                  newFilter5.push(x.id)
                })
                }else if(element.filter.column == "country_film"){
                console.log(element.filter.column);
                let allData = [...this.films]
                let tempFilter = allData.filter(x => {
                  const toCheck = x.countries.split(",").map(x => x.trim());
                  return x.countries ? toCheck.includes((element.filter?.value as string)): false
                  //return x.countries ? (x.countries as string).includes((element.filter?.value as string)) : false
                })
                //newFilter = newFilter.concat(tempFilter);
                tempFilter.forEach(x => {
                  newFilter6.push(x.id)
                })
                }
              }
            });

            //newFilter = [ ...new Set(newFilter)];
            let intersection:number[];
            if(newFilter1.length > 0){
              intersection = newFilter1;
              if(newFilter2.length > 0)
                intersection = intersection.filter(x => newFilter2.includes(x));
              if(newFilter3.length > 0)
                intersection = intersection.filter(x => newFilter3.includes(x));
              if(newFilter4.length > 0)
                intersection = intersection.filter(x => newFilter4.includes(x));
              if(newFilter5.length > 0)
                intersection = intersection.filter(x => newFilter5.includes(x));
              if(newFilter6.length > 0)
                intersection = intersection.filter(x => newFilter6.includes(x));
            }else if(newFilter2.length > 0){
              intersection = newFilter2;
              if(newFilter3.length > 0)
                intersection = intersection.filter(x => newFilter3.includes(x));
              if(newFilter4.length > 0)
                intersection = intersection.filter(x => newFilter4.includes(x));
              if(newFilter5.length > 0)
                intersection = intersection.filter(x => newFilter5.includes(x));
              if(newFilter6.length > 0)
                intersection = intersection.filter(x => newFilter6.includes(x));
            }else if(newFilter3.length > 0){
              intersection = newFilter3;
              if(newFilter4.length > 0)
                intersection = intersection.filter(x => newFilter4.includes(x));
              if(newFilter5.length > 0)
                intersection = intersection.filter(x => newFilter5.includes(x));
              if(newFilter6.length > 0)
                intersection = intersection.filter(x => newFilter6.includes(x));
            }else if(newFilter4.length > 0){
              intersection = newFilter4;
              if(newFilter5.length > 0)
                intersection = intersection.filter(x => newFilter5.includes(x));
              if(newFilter6.length > 0)
                intersection = intersection.filter(x => newFilter6.includes(x));
            }else if(newFilter5.length > 0){
              intersection = newFilter5;
              if(newFilter6.length > 0)
                intersection = intersection.filter(x => newFilter6.includes(x));
            }else if(newFilter6.length > 0){
              intersection = newFilter6
            }

            newFilter = [...this.films];
            newFilter = newFilter.filter(x => {
              //console.log(x.id + " CHECK IN "+ intersection + " result: "+ intersection.includes(x.id));
              return intersection.includes(x.id);
            })
            }



          filters.forEach(element => {
            if(element.fullText) {
              const q = (element.fullText as string).toLowerCase()
              newFilter = newFilter.filter(x => {
                return x.title.toLowerCase().includes(q) || 
                x.countries.toLowerCase().includes(q) //|| 
                //x.main_activities.some(ma => ma.toLowerCase().includes(q))
              })
            }/*
            if(element.filter) {
              newFilter = newFilter.filter(x => {
                return x.main_activities.some( ma => ma === (element.filter?.value as string))
              })
            }*/
            if(element.sort) {
              if(element.sort.order == "desc") {
                if(element.sort.column == "title") {
                  newFilter = newFilter.sort((x,y) => -x.title.localeCompare(y.title))
                } else if(element.sort.column == "country") {
                  newFilter = newFilter.sort((x,y) => -x.countries.localeCompare(y.countries))
                } else if(element.sort.column == "company_name") {
                  newFilter = newFilter.sort((x,y) => -x.company_name.localeCompare(y.company_name))
                } else if(element.sort.column == "year") {
                  newFilter = newFilter.sort((x,y) => x.year < y.year?1:-1)
                }
              } else {
                if(element.sort.column == "title") {
                  newFilter = newFilter.sort((x,y) => x.title.localeCompare(y.title))
                } else if(element.sort.column == "country") {
                  newFilter = newFilter.sort((x,y) => x.countries.localeCompare(y.countries))
                } else if(element.sort.column == "company_name") {
                  newFilter = newFilter.sort((x,y) => x.company_name.localeCompare(y.company_name))
                } else if(element.sort.column == "year") {
                  newFilter = newFilter.sort((x,y) =>  x.year > y.year?1:-1)
                }
              }
            }
          });
          this.filteredFilms = newFilter
        }
    /*
    onFilterChange(filters:FilterValue[]) {
      let newFilter = [...this.films]
      filters.forEach(element => {
        if(element.fullText) {
          const q = (element.fullText as string).toLowerCase()
          newFilter = newFilter.filter(x => {
            return x.title.toLowerCase().includes(q) || 
            x.countries.toLowerCase().includes(q) //|| 
            //x.main_activities.some(ma => ma.toLowerCase().includes(q))
          })
        }/*
        if(element.filter) {
          newFilter = newFilter.filter(x => {
            return x.main_activities.some( ma => ma === (element.filter?.value as string))
          })
        }* /
        if(element.sort) {
          if(element.sort.order == "desc") {
            if(element.sort.column == "title") {
              newFilter = newFilter.sort((x,y) => -x.title.localeCompare(y.title))
            } else if(element.sort.column == "country") {
              newFilter = newFilter.sort((x,y) => -x.countries.localeCompare(y.countries))
            } else if(element.sort.column == "company_name") {
              newFilter = newFilter.sort((x,y) => -x.company_name.localeCompare(y.company_name))
            } else if(element.sort.column == "year") {
              newFilter = newFilter.sort((x,y) => x.year < y.year?1:-1)
            }
          } else {
            if(element.sort.column == "title") {
              newFilter = newFilter.sort((x,y) => x.title.localeCompare(y.title))
            } else if(element.sort.column == "country") {
              newFilter = newFilter.sort((x,y) => x.countries.localeCompare(y.countries))
            } else if(element.sort.column == "company_name") {
              newFilter = newFilter.sort((x,y) => x.company_name.localeCompare(y.company_name))
            } else if(element.sort.column == "year") {
              newFilter = newFilter.sort((x,y) =>  x.year > y.year?1:-1)
            }
          }
        }
      });
      this.filteredFilms = newFilter
    }*/
  },
  inject: ['container'],
});
