
import Vue, { PropType } from 'vue';

import { getModule } from 'vuex-module-decorators';
import { ResourceIndexResponse } from '@/client/resource.client';
import NotificationModule from '@/store/modules/notifications';
import { mapState } from 'vuex';
import { RootState } from '@/store/modules/root';

export default Vue.extend({
  name: 'DemoNotification',
  async mounted() {
    await getModule(NotificationModule, this.$store).load();
  },
  computed: mapState({
      notificationsResource: (state:any) => (state as RootState).notifications.listResource,
      notificationsForum: (state:any) => (state as RootState).notifications.listForum,
  }),
  methods: {
      async addNotification() {

        getModule(NotificationModule, this.$store).addNotificationResource({
            id: 1,
            sender_name: "test",
            type: 1,
            creation_date: "",
            target_id: 603,
            sender_id: 605,
            comment_title: "MANUALE",
            readed: false,
            parent_id: 0,
            forum_category_id: 0,
            author_has_avatar: false,
            author_image_id: null
        })
      }
  }

});
