import { format, isSameYear, isSameMonth } from 'date-fns';
import { parse } from 'date-fns';

export function formatPublication(date: Date): string {
  if (!date) {
    return '';
  }
  return format(date, 'MMM d, yyyy');
}

export function formatDateRange(start: Date | null | undefined, end: Date | null | undefined): string {
  if (!start || !end) {
    return [start, end].filter(d => !!d).map(d => formatPublication(d as Date)).join('');
  }
  if (!isSameYear(start, end)) {
    return `${format(start, 'MMMM d, yyyy')} - ${format(end, 'MMMM d, yyyy')}`;
  }
  if (!isSameMonth(start, end)) {
    return `${format(start, 'MMMM d')} - ${format(end, 'MMMM d, yyyy')}`;
  }
  if(start?.getFullYear() == end?.getFullYear() && start?.getMonth() == end?.getMonth() && start?.getDate() == end?.getDate() ) {
    return formatPublication(start as Date)
  }
  return `${format(start, 'MMMM d')} - ${format(end, 'd, yyyy')}`;
}
export function isSevenDays(date: string): boolean{
  const ourDate = new Date(Date.now() - 7 * 24 * 60 * 60 * 1000)  
  const toCheck = new Date(parse(date, 'yyyy-MM-dd HH:mm', new Date()));

  return toCheck < ourDate;
}