import Environment from '@/env/environment';
import TYPES from '@/ioc/types';
import { AxiosStatic } from 'axios';
import { inject, injectable } from 'inversify';
import { SupportedLanguage } from './supported-lang.type';

export interface ForumSuggestion {
  user_id: number;
  title: string;
  message: string;
}

export function emptySuggestion(user_id: number) {
  return {
      user_id: user_id,
      title: '',
      message: ''
  }
}

@injectable()
export default class ForumSuggestionClient {
  constructor(
    @inject(TYPES.AxiosStatic) private readonly axios: AxiosStatic,
    @inject(TYPES.Environment) private readonly environment: Environment,
  ) { }

  async create(lang: SupportedLanguage, suggestion: ForumSuggestion): Promise<number> {
    const response = await this.axios.post<any>(
      `${this.environment.backendUrl}/api/v1/entity/${lang}/suggested_forum_category`,
      suggestion,
      { withCredentials: true, }
    );
    return +(response.data.id || -1);
  }
}