import Environment from '@/env/environment';
import TYPES from '@/ioc/types';
import { AxiosStatic } from 'axios';
import { inject, injectable } from 'inversify';
import { SupportedLanguage } from './supported-lang.type';


export interface Suggestion {
  user_id: number;
  title: string;
  category_id: number;
  message: string,
  resource?: string
}

export function emptySuggestion(user_id: number) {
    return {
        user_id: user_id,
        title: '',
        category_id: 1,
        message: ''
    }
}

@injectable()
export default class SuggestionClient {
  constructor(
    @inject(TYPES.AxiosStatic) private readonly axios: AxiosStatic,
    @inject(TYPES.Environment) private readonly environment: Environment,
  ) { }

  async create(lang: SupportedLanguage, suggestion: Suggestion): Promise<number> {
    const response = await this.axios.post<any>(
      `${this.environment.backendUrl}/api/v1/entity/${lang}/suggested_resource`,
      suggestion,
      { withCredentials: true, }
    );
    return +(response.data.id || -1);
  }

}
