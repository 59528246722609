
import Vue from 'vue';
import SwiperEditorsPicks from '@/components/SwiperEditorsPicks.vue';
import RecentResources from '@/components/RecentResources.vue';
import Agenda from '@/components/Agenda.vue';
import SwiperAgenda from '@/components/SwiperAgenda.vue';
import PopularResources from '@/components/PopularResources.vue';
import PopularTopics from '@/components/PopularTopics.vue';
import OdImage from '@/components/ui/OdImage.vue';
import { getModule } from 'vuex-module-decorators';
import ResourceClient, { ResourceLightResponse } from '../client/resource.client';
import ForumCommentClient, { ForumComment } from '../client/forum-comment.client';
import ResourcesModule from '../store/modules/resources';
import { DEFAULT_LIST_OPTION_REQUEST } from '@/client/category.client';
import CategoriesModule from '../store/modules/categories';
import NotificationModule from '../store/modules/notifications';
import { extractContainer } from '@/ioc/util';
import SettingsModule from '@/store/modules/settings';
import TYPES from '@/ioc/types';
import { SiteConfig } from '../client/value.client';
import ValuesModule from '../store/modules/values';

const AGENDA_SWIPE_RESOURCE_COUNT = 5;
const AGENDA_RESOURCE_COUNT = 10;
const RECENT_RESOURCE_COUNT = 10;
const EDITOR_PICK_RESOURCE_COUNT = 10;
const AGENDA_CATEGORY_ID = 7;

export default Vue.extend({
  name: 'Home',
  inject: ['container'],
  components: {
    SwiperEditorsPicks,
    RecentResources,
    Agenda,
    SwiperAgenda,  
    PopularResources,
    PopularTopics,
    OdImage
  },
  beforeCreate: function() {
    document.body.className = '';
  },
  async created() {
      await getModule(ResourcesModule, this.$store).dispatchGetList(DEFAULT_LIST_OPTION_REQUEST);
      await getModule(CategoriesModule, this.$store).dispatchGetList();
      await getModule(NotificationModule, this.$store).load();
  },
  asyncComputed: {
    async popularResources(): Promise<ResourceLightResponse[]> {
      const settingsModule = getModule(SettingsModule, this.$store);
      const container = extractContainer(this);
      const resourcesClient =  container.get<ResourceClient>(TYPES.ResourceClient);
      return await resourcesClient.getPopular(settingsModule.language,4);
    },
    async popularTopics(): Promise<ForumComment[]> {
      const settingsModule = getModule(SettingsModule, this.$store);
      const container = extractContainer(this);
      const forumCommentsClient =  container.get<ForumCommentClient>(TYPES.ForumCommentClient);
      return await forumCommentsClient.getPopular(settingsModule.language,4);
    },
  },
  computed: {
    resources(): ResourceLightResponse[] {
      return getModule(ResourcesModule, this.$store).allLight;
    },
    siteConfig():SiteConfig {
      return getModule(ValuesModule, this.$store).siteConfig
    },
    recentResources(): ResourceLightResponse[] {
      return [...this.resources]
        .filter(r => r.category_id != AGENDA_CATEGORY_ID)
        //.sort(r => -(r.pubblication_date.valueOf() ?? 0))
        .sort((a, b) => a.pubblication_date.valueOf()<b.pubblication_date.valueOf()?1:-1)
        .slice(0, RECENT_RESOURCE_COUNT);
    },
    editorPickResources(): ResourceLightResponse[] {
      return getModule(ResourcesModule, this.$store)
        .lightFilterBy({isEditorPick: true})
        .sort((a, b) => a.pubblication_date.valueOf()<b.pubblication_date.valueOf()?1:-1)
        .slice(0, EDITOR_PICK_RESOURCE_COUNT);
    },
    agendaResources(): ResourceLightResponse[] {
      return getModule(ResourcesModule, this.$store)
        .lightFilterBy({categories: [AGENDA_CATEGORY_ID],isExpired: false})
        .sort((a, b) => (a.start?a.start.valueOf():0)> (b.start?b.start.valueOf():0)?1:-1)
        .slice(0, AGENDA_RESOURCE_COUNT);
    },
    agendaSwipeResources(): ResourceLightResponse[] {
      return getModule(ResourcesModule, this.$store)
        .lightFilterBy({categories: [AGENDA_CATEGORY_ID]})
//        .sort((a, b) => (a.start?a.start.valueOf():a.end?a.end.valueOf():a.pubblication_date.valueOf())< (b.start?b.start.valueOf():b.end?b.end.valueOf():b.pubblication_date.valueOf())?1:-1)
        .slice(0, AGENDA_SWIPE_RESOURCE_COUNT);
    }
  }
});
