import getDecorators from 'inversify-inject-decorators';
import container from '@/ioc/inversify.config';
import { Module, VuexModule, Mutation, Action } from 'vuex-module-decorators';
import { RootState } from '../root';
import { ValuesState } from './state';
import { DownloadStatus } from '@/client/download.client';
import ValueClient, { ValueResponse, SiteConfig, ValActivitiesResponse, Continent, Country, HeritageSiteConfig } from '@/client/value.client';
import TYPES from '@/ioc/types';
import { PagingOption } from '@/client/user.client';

const { lazyInject } = getDecorators(container);

function getName(id: number, values: ValueResponse[]): string {
  return values.find(v => v.id === id)?.name ?? '';
}

@Module({ namespaced: true, name: 'values' })
export default class ValuesModule extends VuexModule<ThisType<ValuesState>, RootState> implements ValuesState {
  status: DownloadStatus = 'NOT_YET';
  professions: ValueResponse[] = [];
  countries: Country[] = [];
  filmGendres: ValueResponse[] = [];
  filmFeaturesType: ValueResponse[] = [];
  filmLanguages: ValueResponse[] = [];
  licenseType: ValueResponse[] = [];
  licenseExclusivity: ValueResponse[] = [];
  gendres: ValueResponse[] = [];
  tags: ValueResponse[] = [];
  siteConfig: SiteConfig = {enable_popular: false, intro: '', need_help: '', allowed_countries: [], info: ''}
  heritageSiteConfig: HeritageSiteConfig = {enable_public_view: false}
  activities: ValActivitiesResponse[] = [];

  @lazyInject(TYPES.ValueClient)
  private readonly valueClient!: ValueClient;

  @Mutation
  setProfessions(professions: ValueResponse[]) {
    this.professions = professions;
  }

  @Mutation
  setCountries(countries: Country[]) {
    this.countries = countries;
  }

  @Mutation
  setTags(tags: ValueResponse[]) {
    this.tags = tags;
  }

  @Mutation
  setGendres(g: ValueResponse[]) {
    this.gendres = g;
  }

  @Mutation
  setFilmGendres(g: ValueResponse[]) {
    this.filmGendres = g;
  }

  @Mutation
  setFilmFeatureType(g: ValueResponse[]) {
    this.filmFeaturesType = g;
  }

  @Mutation
  setFilmLanguages(g: ValueResponse[]) {
    this.filmLanguages = g;
  }

  @Mutation
  setLicenseExclusivity(g: ValueResponse[]) {
    this.licenseExclusivity = g;
  }

  @Mutation
  setLicenseType(g: ValueResponse[]) {
    this.licenseType = g;
  }

  @Mutation
  setSiteConfig(g: SiteConfig) {
    this.siteConfig = g;
  }

  @Mutation
  setHeritageSiteConfig(g: HeritageSiteConfig) {
    this.heritageSiteConfig = g;
  }

  @Mutation
  setStatus(status: DownloadStatus) {
    this.status = status;
  }

  @Mutation
  setValActivities(a: ValActivitiesResponse[]) {
    this.activities = a;
  }


  get getProfessionName() {
    return (id: number): string => {
      return getName(id, this.professions);
    }
  }
  get getActivityName() {
    return (id: number): string => {
      return getName(id, this.activities);
    }
  }

  get getCountryName() {
    return (id: number): string => {
      return getName(id, this.countries);
    }
  }

  get getTagName() {
    return (id: number): string => {
      return getName(id, this.tags);
    }
  }

  @Action
  async dispatchGetAllLogged(request: PagingOption): Promise<{ 
    
  }> {
    this.setStatus('LOADING');

  

    this.setStatus('SUCCESS');
    return {  };
  }

  @Action
  async dispatchGetHeritageConfig():Promise<HeritageSiteConfig> {
    return await this.valueClient.getHeritageSiteConfig().then(t => { this.setHeritageSiteConfig(t); return t; })
  }

  @Action
  async dispatchGetAll(request: PagingOption): Promise<{ 
    countries: Country[];
    professions: ValueResponse[];
    tags: ValueResponse[];
    gendres: ValueResponse[];
    filmGendres: ValueResponse[];
    siteConfig: SiteConfig
    filmFeatureType: ValueResponse[];
    filmLanguages: ValueResponse[];
    licenseExclusivity: ValueResponse[];
    licenseType: ValueResponse[];
    activities: ValActivitiesResponse[];
  }> {
    this.setStatus('LOADING');

    const [countries, professions, tags, gendres, filmGendres, siteConfig, filmFeatureType, filmLanguages, licenseExclusivity, licenseType] = await Promise.all([
      this.valueClient.getCountries(
        this.context.rootState.settings.language
      ).then(c => { this.setCountries(c); return c; }),
      this.valueClient.getProfessions(
        this.context.rootState.settings.language,
        request
      ).then(p => { this.setProfessions(p); return p; }),
      this.valueClient.getTags(
        this.context.rootState.settings.language,
        request
      ).then(t => { this.setTags(t); return t; }),
      this.valueClient.getGendres(
        this.context.rootState.settings.language,
        request
      ).then(t => { this.setGendres(t); return t; }),
      this.valueClient.getFilmGendres(
        this.context.rootState.settings.language,
        request
      ).then(t => { this.setFilmGendres(t); return t; }),
      this.valueClient.getSiteConfig(
      ).then(t => { this.setSiteConfig(t); return t; }),
      this.valueClient.getFilmFeatureType(
        this.context.rootState.settings.language,
        request
      ).then(t => { this.setFilmFeatureType(t); return t; }),
      this.valueClient.getFilmLanguages(
        this.context.rootState.settings.language,
        request
      ).then(t => { this.setFilmLanguages(t); return t; }),
      this.valueClient.getLicenseExclusivity(
        this.context.rootState.settings.language,
        request
      ).then(t => { this.setLicenseExclusivity(t); return t; }),
      this.valueClient.getLicenseType(
        this.context.rootState.settings.language,
        request
      ).then(t => { this.setLicenseType(t); return t; }),
        
    ]);

    const activities = await this.valueClient.getValActivities().then(t => { this.setValActivities(t); return t; })

    await this.valueClient.getHeritageSiteConfig().then(t => { this.setHeritageSiteConfig(t); return t; })

    this.setStatus('SUCCESS');
    return { countries, professions, tags, gendres, filmGendres, siteConfig, filmFeatureType, filmLanguages, licenseExclusivity, licenseType, activities };
  }
}