
import Vue, { PropType } from "vue";
import PrivacyEditForm, {
  SubmitFormEmit
} from "../components/forms/PrivacyEditForm.vue";
import { extractContainer } from "../ioc/util";
import TYPES from "../ioc/types";
import UserFilmClient, { getDefaultFilm } from "@/client/user-film.client";
import UserClient, { Film, Cv, UserPrivacy, toPrivacy, fromPrivacy } from "@/client/user.client";
import { getModule } from "vuex-module-decorators";
import SettingsModule from "../store/modules/settings";
import DownloadClient from "../client/download.client";
import { sub } from "date-fns";
import { getFilmFormPath } from '@/router/toolbox';

export default Vue.extend({
  name: "ProfileEditView",
  props: {
    id: Number
  },
  components: {
    PrivacyEditForm
  },
  data(): { privacy: UserPrivacy | null } {
    return {
      privacy: null
    };
  },
  methods: {
    async onPrivacySubmit(submit: SubmitFormEmit) {
      const { privacy } = submit;
      const container = extractContainer(this);
      const userClient = container.get<UserClient>(TYPES.UserClient);
      const settingsModule = getModule(SettingsModule, this.$store);

      const user = await userClient.getMe(settingsModule.language)    
      
      if(user) {
        await userClient.update(settingsModule.language, fromPrivacy(user,privacy));
      }

      this.$router.go(-1)
      
    }
  },
  watch: {
    id: {
      immediate: true,
      async handler(id: number) {
        const container = extractContainer(this);
        const userClient = container.get<UserClient>(TYPES.UserClient);
        const settingsModule = getModule(SettingsModule, this.$store);

        if (this.id !== null) {
          const user = await userClient.getById(
            settingsModule.language,
            this.id
          );
          this.privacy = toPrivacy(user);
        }
      }
    }
  },
  inject: ["container"]
});
