import NotificationMessageClient, { NotificationResponse } from '@/client/notification-center.client';
import container from '@/ioc/inversify.config';
import TYPES from '@/ioc/types';
import getDecorators from 'inversify-inject-decorators';
import { Action, Module, Mutation, VuexModule } from 'vuex-module-decorators';
import { RootState } from '../root';
import { NotificationState } from './state';

import { isSevenDays } from '@/service/ui';


const { lazyInject } = getDecorators(container);

@Module({ namespaced: true, name: 'notifications'})
export default class NotificationModule extends VuexModule<ThisType<NotificationState>, RootState> implements NotificationState {
    listResource:NotificationResponse[] = []
    listForum:NotificationResponse[] = []
    forumListeners:(() => void)[] = []
    resourceListeners:(() => void)[] = []


    @lazyInject(TYPES.NotificationMessageClient)
    private readonly notificationClient!: NotificationMessageClient;

    @Mutation
    private setNotificationsResource(n: NotificationResponse[]) {
        this.listResource = n.filter(x => !x.readed || (!isSevenDays(x.creation_date) && x.readed));
    }
    @Mutation
    private setNotificationsForum(n: NotificationResponse[]) {
        this.listForum = n.filter(x => !x.readed || (!isSevenDays(x.creation_date) && x.readed));
    }

    @Mutation
    addNotificationResource(n: NotificationResponse) {
        this.listResource.push(n);
    }
    @Mutation
    addNotificationForum(n: NotificationResponse) {
        this.listForum.push(n);
    }
    
    @Action
    async updateNotificationResource(obj:{notification: NotificationResponse,state: boolean}) {
        await this.notificationClient.updateReaded("en",obj.notification.id, 1, obj.state);
        this._markAsReadResource(obj)
    }

    @Mutation
    private _markAsReadResource(obj:{notification: NotificationResponse,state: boolean}) {
        this.listResource = this.listResource.map(x => {
            if(x.id === obj.notification.id) 
                x.readed = obj.state;
            return x
        });
    }

    @Action
    async updateNotificationForum(obj:{notification: NotificationResponse,state: boolean}) {  
        await this.notificationClient.updateReaded("en",obj.notification.id, 2, obj.state);
        this._markAsReadForum(obj)
    }

    @Mutation
    private _markAsReadForum(obj:{notification: NotificationResponse,state: boolean}) {
        this.listForum = this.listForum.map(x => {
            if(x.id === obj.notification.id) 
                x.readed = obj.state;
            return x
        });
    }

    
    @Action
    async removeAllNotifications(type: number) {
        if(type == 1){
            this.listResource.forEach(async x => {
                await this.notificationClient.updateReaded("en",x.id, type, true);
                this._markAsReadResource({notification:x, state:true})
            });
            
        }else{
            this.listForum.forEach(async x => {
                await this.notificationClient.updateReaded("en",x.id, type, true);
                this._markAsReadForum({notification:x, state:true})
            });
        }   
    }

    
    @Mutation
    public addForumNotificationListener(listener: () => void) {
        this.forumListeners.push(listener)
    }

    @Mutation
    public addResourceNotificationListener(listener: () => void) {
        this.resourceListeners.push(listener)
    }
    
    @Action
    public async load(): Promise<boolean> { 
        this.notificationClient.setupWebsocketForum(async () => {
            this.setNotificationsForum(await this.notificationClient.getAllNotificationsForum("en"))
            this.forumListeners.forEach(l => l())

        })
        this.notificationClient.setupWebsocketResources(async () => {
            this.setNotificationsResource(await this.notificationClient.getAllNotificationsResource("en"))
            this.resourceListeners.forEach(l => l())
        })
        
        this.setNotificationsForum(await this.notificationClient.getAllNotificationsForum("en"))
        this.setNotificationsResource(await this.notificationClient.getAllNotificationsResource("en"))

        return true
    }
}
