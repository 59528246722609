import UserFilmClient, { FilmLight, FilmUltraLight } from "@/client/user-film.client";
import { Action, Module, Mutation, VuexModule } from "vuex-module-decorators";
import { RootState } from "../root";
import { FilmsState } from "./state";
import getDecorators from 'inversify-inject-decorators';
import container from '@/ioc/inversify.config';
import TYPES from "@/ioc/types";
import cloneDeep from 'clone-deep';

const { lazyInject } = getDecorators(container);

function filmShuffle(_films:FilmUltraLight[],count:number):number[] {
    const films = [..._films].sort(() => .5 - Math.random())
    const companies = [...new Set(films.map(x => x.company_id))].sort(() => .5 - Math.random())
    const result:number[] = [];
  
    if(companies.length == 0 || films.length == 0) return []
  
    if(films.length <= count) return films.map(x => x.id)
  
    let i = 0;
    while(result.length < count) {
      let j = films.findIndex(x => x.company_id == companies[i])
      if(j >=0) {
        result.push(films[j].id)
        films.splice(j,1)
      }
      i = (i + 1) % companies.length 
    }
    return result
  }

@Module({ namespaced: true, name: 'films' })
export default class FilmModule extends VuexModule<ThisType<FilmsState>, RootState> implements FilmsState {

    filmsLight:FilmUltraLight[] = [];

    @lazyInject(TYPES.UserFilmClient)
    private readonly filmClient!: UserFilmClient;

    @Mutation
    setFilmLight(films: FilmUltraLight[]) {
        this.filmsLight = films;
    }

 

    @Action
    async dispatchGetFilmList():Promise<FilmUltraLight[]> {
      if(this.filmsLight.length > 0 ) return this.filmsLight;
      return await this.filmClient.getUltalight().then(t => { this.setFilmLight(t); return t; })
    }
    
    get randomFilmsForHome() {
        const ids = filmShuffle(cloneDeep(this.filmsLight),24)
        return this.filmClient.getScreeningRoom(ids,"en")  
    }

    get randomScreeningRoomForHome() {
        const ids = filmShuffle(cloneDeep(this.filmsLight).filter(x => x.screening_room),10)
        return this.filmClient.getScreeningRoom(ids,"en")  
    }

    get allScreeningRoom() {
        const ids = filmShuffle(cloneDeep(this.filmsLight).filter(x => x.screening_room),10000)
        return this.filmClient.getScreeningRoom(ids,"en")  
    }

}