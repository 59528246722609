
import Vue, { PropType } from 'vue';
import ResourceListElementLayout from '@/components/ResourceListElementLayout.vue';
import { ResourceLightResponse } from '@/client/resource.client';
import { Label } from '../client/label.client';
import LabelsModule from '@/store/modules/labels';
import mixins from 'vue-typed-mixins'
import LabelMixin from '../mixins/labels';
import Swiper, { SwiperOptions } from 'swiper';

export default mixins(LabelMixin).extend({
  name: 'SwiperEditorsPicks',
  components: {
    ResourceListElementLayout,
  },  
  data(){
    return {
      loginOverlay: true,
    }
  },
  props: {
    resources: {
      type: Array as PropType<ResourceLightResponse[]>,
      required: true,
    }
  },
  mounted() {
      const swiperOpt: SwiperOptions = {
        slidesPerView: 1,
        spaceBetween: 25,
        slidesPerGroup: 1,
        preloadImages: true,
        // Enable lazy loading
        lazy: false,
        loop: true,
        loopAdditionalSlides: 10,
        centeredSlides: false,
        pagination: {
          el: '.swiper-pagination'
        },
        navigation: {
          nextEl: '.swiper-button-next',
          prevEl: '.swiper-button-prev',
        },
        breakpoints: {
          2000: {
            slidesPerView: 4,
            spaceBetween: 2,
            slidesPerGroup: 1,
            centeredSlides: true
          },
          1400: {
            slidesPerView: 3,
            spaceBetween: 2,
            slidesPerGroup: 1,
            centeredSlides: true
          },         
          850: {
            slidesPerView: 2,
            spaceBetween: 2,
            slidesPerGroup: 1,
            centeredSlides: true
          }
        }
    };
    setTimeout(() => {
      new Swiper(this.$refs.swiperContainer as HTMLElement, swiperOpt);
      setTimeout(() => {
        this.loginOverlay = false;
      }, 500);
    }, 1000);  
  }
});
