import { Container } from 'inversify';
import Environment from '@/env/environment';
import AuthClient from '@/client/auth.client';
import axios, { AxiosStatic } from 'axios';
import ResourceClient from '@/client/resource.client';
import TYPES from './types';
import LabelClient from '@/client/label.client';
import CategoryClient from '@/client/category.client';
import ForumCategoryClient from '@/client/forum-category.client';
import ForumCommentClient from '@/client/forum-comment.client';
import ResourceCommentClient from '@/client/resource-comment.client';
import NotificationMessageClient from '@/client/notification-center.client';
import UserClient from '@/client/user.client';
import DownloadClient from '@/client/download.client';
import ValueClient from '@/client/value.client';
import UserFilmClient from '@/client/user-film.client';
import SuggestionClient from '@/client/suggestion.client';
import AccreditationClient from '@/client/accreditation.client';
import ForumSuggestionClient from '@/client/forum.suggestion.client';
import ForumNewTopicClient from '@/client/forum.new.topic.client';
import PartnerClient from '@/client/partner.client';
import OrganizationClient from '@/client/organization.client';
import PageClient from '@/client/page.client';
import BannerClient from '@/client/banner.client';
import CompleteInviteClient from '@/client/complete-invite.client';

const container = new Container();

container.bind<Environment>(TYPES.Environment).to(Environment);
container.bind<AuthClient>(TYPES.AuthClient).to(AuthClient);
container.bind<ResourceClient>(TYPES.ResourceClient).to(ResourceClient);
container.bind<LabelClient>(TYPES.LabelClient).to(LabelClient);
container.bind<CategoryClient>(TYPES.CategoryClient).to(CategoryClient);
container.bind<ForumCategoryClient>(TYPES.ForumCategoryClient).to(ForumCategoryClient);
container.bind<ForumCommentClient>(TYPES.ForumCommentClient).to(ForumCommentClient);
container.bind<ResourceCommentClient>(TYPES.ResourceCommentClient).to(ResourceCommentClient);
container.bind<NotificationMessageClient>(TYPES.NotificationMessageClient).to(NotificationMessageClient);
container.bind<UserClient>(TYPES.UserClient).to(UserClient);
container.bind<DownloadClient>(TYPES.DownloadClient).to(DownloadClient);
container.bind<ValueClient>(TYPES.ValueClient).to(ValueClient);
container.bind<UserFilmClient>(TYPES.UserFilmClient).to(UserFilmClient);
container.bind<SuggestionClient>(TYPES.SuggestionClient).to(SuggestionClient);
container.bind<AccreditationClient>(TYPES.AccreditationClient).to(AccreditationClient);
container.bind<ForumSuggestionClient>(TYPES.ForumSuggestionClient).to(ForumSuggestionClient);
container.bind<ForumNewTopicClient>(TYPES.ForumNewTopicClient).to(ForumNewTopicClient);
container.bind<PartnerClient>(TYPES.PartnerClient).to(PartnerClient);
container.bind<OrganizationClient>(TYPES.OrganizationClient).to(OrganizationClient);
container.bind<PageClient>(TYPES.PageClient).to(PageClient);
container.bind<BannerClient>(TYPES.BannerClient).to(BannerClient);
container.bind<CompleteInviteClient>(TYPES.CompleteInviteClient).to(CompleteInviteClient);
container.bind<AxiosStatic>(TYPES.AxiosStatic).toConstantValue(axios);

export default container;
