
import Vue, { PropType } from 'vue';
import { getModule } from 'vuex-module-decorators';
import FloatLabel from "vue-float-label/components/FloatLabel.vue";
import { UserResponse, Cv, UserProfile, toProfile } from '@/client/user.client';
import UsersModule from '@/store/modules/users';
import ValuesModule from '@/store/modules/values';
import { format } from 'date-fns';

import {sanitizeNumber} from '../../../service/form';

import OdImage from '@/components/ui/OdImage.vue';
import ImageCropper from '@/components/ui/ImageCropper.vue';
import { ValueResponse, ValActivitiesResponse } from '@/client/value.client';

import { Label } from '../../../client/label.client';
import LabelsModule from '@/store/modules/labels';
import mixins from 'vue-typed-mixins'
import LabelMixin from '../../../mixins/labels';
import { NamedBlob } from '@/client/download.client';
import { CropEvent } from '../../ui/ImageCropper.vue';
import { Organization } from '../../../client/organization.client';
import { getVideoUrl, toVideoUrl } from '@/service/videoUrl'
import Multiselect from 'vue-multiselect'
import { isHeritageRightHolder, isHeritageOther } from '@/service/user';


function sanitizeOrganizationForm(profile: Organization): Organization {
  return {
    ...profile,
  }
}


export interface SubmitFormEmit {
  company: Organization;
  filmList: NamedBlob | null;
  image: NamedBlob | null;
}

export default mixins(LabelMixin).extend({
  components: {
    FloatLabel,
    OdImage,
    ImageCropper,
    Multiselect
  },
  props: {
    company: {
      required: true,
      type: Object as PropType<Organization>,
    }
  },
  methods: {
    uploadFilmList() {
      (this.$refs.filmListFile as HTMLInputElement).click()
    },
    onCancel(event: MouseEvent) {
      event.preventDefault();
      this.$emit('onCancel');
    },
    onSubmitForm(event: Event) {
      event.preventDefault();
      this.loadingForm = true;
      const submitEmit: SubmitFormEmit = {
        company: sanitizeOrganizationForm(this.company),
        filmList: this.filmList,
        image: this.image
      };
      this.$emit('onSubmit', submitEmit);
    },
    onImageCrop(event: CropEvent) {
      this.image = event.file;
    },
    onAvatarCrop(event: CropEvent) {
      this.image = event.file;
      /*
      this.company.image.push({
        image_name: this.image?.name,
        image_mime: this.image?.mime,
        organization_id: this.company.id
        });
      */
    },
    countryName(name: string): string {
      return getModule(ValuesModule, this.$store).countries.find(x => x.name_it === name )?.name || name
    },
    onFilmListChange(event: Event) {
      const target: EventTarget | null = event?.target;
      if (!target) {
        return;
      }

      const file: File = ((target as HTMLInputElement).files ?? [])[0];

      if (!file) {
        return;
      }

      

      this.filmList = {
          name: file.name,
          blob: file,
          mime: file.type
      }
    },

  
  },
  data(): { filmList: NamedBlob | null, image: NamedBlob | null, loadingForm: boolean } {
    return {
      image: null,
      filmList: null,
      loadingForm: false,
    };
  },
  computed: {
    countries(): ValueResponse[] {
      return getModule(ValuesModule, this.$store).countries;
    },
    filmGendres(): ValueResponse[] {
      return getModule(ValuesModule, this.$store).filmGendres;
    },
    professions(): ValueResponse[] {
      return getModule(ValuesModule, this.$store).professions;
    },
    rightholder():boolean {
      return isHeritageRightHolder(getModule(UsersModule, this.$store).me)
    },
    other():boolean {
      return isHeritageOther(getModule(UsersModule, this.$store).me)
    },
    activities():ValActivitiesResponse[] {
      return getModule(ValuesModule, this.$store).activities.filter(a => a.rightholder == this.rightholder && a.other == this.other)
    },
    trailerUrl: {
      get(): string {
        return toVideoUrl(this.company.trailer)
      },
      set(trailerUrl: string) {
          this.company.trailer = getVideoUrl(trailerUrl) || '';
      },
    },
    mainActivities: {
      get():{id:number, name:string | undefined}[] {
        const activities = getModule(ValuesModule, this.$store).activities
        return this.company.main_activities.map(x => {
          return {
            id: x.activity_id,
            name: activities.find(a => a.id === x.activity_id)?.name
          }
         } )
      },
      set(values: {id:number, name:string | undefined}[]) {
        this.company.main_activities = values.map(x => {
          return {activity_id: x.id, organization_id: this.company.id}
        })
      }
    }
  }
})
