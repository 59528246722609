
import Vue, { PropType } from "vue";
import ResourceListElementLayout from "@/components/ResourceListElementLayout.vue";
import { getModule } from 'vuex-module-decorators';
import { ValueResponse } from "@/client/value.client";
import ValuesModule from '../../store/modules/values';
import { FilterOption, SortOption } from "../../client/user.client";

import mixins from "vue-typed-mixins";
import LabelMixin from "../../mixins/labels";
import { isEqual, flatten } from 'lodash';

export interface FilterValue {
  name: string;
  checked: boolean;
  filter?: FilterOption;
  sort?: SortOption;
  fullText?:string;
}

interface MainFilterUi {
  name: string;
  hidden?:boolean;
  open: boolean;
  type: string;
  scrollable: boolean;
  children: FilterValue[];
}

export default mixins(LabelMixin).extend({
  name: "Filters",
  props: {
    addButton: Boolean,
    alignCenter: Boolean,
    main_activities: {
      type: Array as PropType<string[]>,
      required: false,
    },
    companyOrder: {
      type: Boolean,
      required: false,
    },
    disableMainActivity: {
      type: Boolean,
      required: false,
    },
    type: {
      type: String,
      required: false
    },
    filmType: {
      type: Array as PropType<string[]>,
      required: false,
    },
    filmYears: {
      type: Array as PropType<string[]>,
      required: false,
    },
    filmDirectors: {
      type: Array as PropType<string[]>,
      required: false,
    },
    filmLanguages: {
      type: Array as PropType<string[]>,
      required: false,
    },
    filmSubtitles: {
      type: Array as PropType<string[]>,
      required: false,
    },
    filmCountries: {
      type: Array as PropType<string[]>,
      required: false,
    },
  },
  components: {
    ResourceListElementLayout
  },
  computed: {
    appliedFilters(): FilterValue[] {
      return this.filtersList.flatMap(x => x.children).filter(c => c.checked);
    }
  },
  created() {
    if(this.type == "rightholder" ){
      this.filtersList[1].children.length = 0;

      this.filtersList[1].children.push({
              name: "A-Z Company",
              checked: true,
              sort: { column: "company", order: "asc" }
            })
      this.filtersList[1].children.push({
              name: "Z-A Company",
              checked: false,
              sort: { column: "company", order: "desc" }
            })

      this.filtersList[1].children.push({
              name: "A-Z Country",
              checked: false,
              sort: { column: "country", order: "asc" }
            })
      this.filtersList[1].children.push({
              name: "Z-A Country",
              checked: false,
              sort: { column: "country", order: "desc" }
            })

      if(this.filmCountries.length > 0) {
        this.filtersList.push(
          {
            name: "Country",
            open: false,
            type: "checkbox",
            scrollable: true,
            children: [
              //mettere tutti i film
            ]
          });
          this.filtersList[this.filtersList.length-1].children = this.filmCountries.sort((x,y) => this.countryName(x).localeCompare(this.countryName(y))).map(
          (x:string) => {
            return {
              name: this.countryName(x),
              checked: false,
              filter: { column: "country", operator: "=", value: x }
            };
          }
        );
      }
      

      this.filtersList.push({
        name: "Main activities",
        open: false,
        type: "checkbox",
        scrollable: true,
        children: []
      });
      this.filtersList[this.filtersList.length-1].children = this.main_activities.map(
        (x:string) => {
          return {
            name: x,
            checked: false,
            filter: { column: "main_activity", operator: "like", value: x }
          };
        }
      );
    }else if(this.type == "streamers" ){
      this.filtersList[1].children.push({
              name: "A-Z Country",
              checked: false,
              sort: { column: "country", order: "asc" }
            })
      this.filtersList[1].children.push({
              name: "Z-A Country",
              checked: false,
              sort: { column: "country", order: "desc" }
            })

      if(this.filmCountries.length > 1) {
        this.filtersList.push(
          {
            name: "Country",
            open: false,
            type: "checkbox",
            scrollable: true,
            children: [
              //mettere tutti i film
            ]
          });
          this.filtersList[this.filtersList.length-1].children = this.filmCountries.sort((x,y) => this.countryName(x).localeCompare(this.countryName(y))).map(
          (x:string) => {
            return {
              name: this.countryName(x),
              checked: false,
              filter: { column: "country", operator: "=", value: x }
            };
          }
        );
      }
/*
      this.filtersList.push({
        name: "Main activities",
        open: false,
        type: "checkbox",
        scrollable: true,
        children: []
      });
      this.filtersList[3].children = this.main_activities.map(
        (x:string) => {
          return {
            name: x,
            checked: false,
            filter: { column: "main_activity", operator: "like", value: x }
          };
        }
      );
      */
    }else if(this.type == "users" ){
      this.filtersList[1].children.push({
              name: "A-Z Company",
              checked: false,
              sort: { column: "company", order: "asc" }
            })
      this.filtersList[1].children.push({
              name: "Z-A Company",
              checked: false,
              sort: { column: "company", order: "desc" }
            })
/*
      this.filtersList[2].children = this.main_activities.map(
        (x:string) => {
          return {
            name: x,
            checked: false,
            filter: { column: "main_activity", operator: "like", value: x }
          };
        }
      );
*/
      if(this.filmCountries.length > 1) {
        this.filtersList.push(
          {
            name: "Country",
            open: false,
            type: "checkbox",
            scrollable: true,
            children: [
              //mettere tutti i film
            ]
          });
          this.filtersList[this.filtersList.length-1].children = this.filmCountries.map(
          (x:string) => {
            return {
              name: x,
              checked: false,
              filter: { column: "country", operator: "=", value: x }
            };
          }
        );
      }
      this.filtersList.push({
        name: "Main activities",
        open: false,
        type: "checkbox",
        scrollable: true,
        children: []
      });
      this.filtersList[this.filtersList.length-1].children = this.main_activities.map(
        (x:string) => {
          return {
            name: x,
            checked: false,
            filter: { column: "main_activity", operator: "like", value: x }
          };
        }
      );
    }
    if(this.type == "screeningroom" ){
      this.filtersList.length = 2;

      this.filtersList[1].children.push({
              name: "A-Z Country",
              checked: false,
              sort: { column: "country", order: "asc" }
            })
      this.filtersList[1].children.push({
              name: "Z-A Country",
              checked: false,
              sort: { column: "country", order: "desc" }
            })

            
      this.filtersList[1].children.push({
              name: "0-9 Year",
              checked: false,
              sort: { column: "year", order: "asc" }
            })
      this.filtersList[1].children.push({
              name: "9-0 Year",
              checked: false,
              sort: { column: "year", order: "desc" }
            })
    /*
    if(this.disableMainActivity) {
      this.filtersList.length = 2;
    } else {
      this.filtersList[2].children = this.main_activities.map(
        (x:string) => {
          return {
            name: x,
            checked: false,
            filter: { column: "main_activity", operator: "like", value: x }
          };
        }
      );
    }
    if(this.companyOrder) {

      this.filtersList[1].children.length = 2

      this.filtersList[1].children.push({
              name: "A-Z Company",
              checked: false,
              sort: { column: "company", order: "asc" }
            })
      this.filtersList[1].children.push({
              name: "Z-A Company",
              checked: false,
              sort: { column: "company", order: "desc" }
            })
            
    }
    */
   
    if(this.filmCountries.length > 1) {
      this.filtersList.push(
        {
          name: "Country",
          open: false,
          type: "checkbox",
          scrollable: true,
          children: [
            //mettere tutti i film
          ]
        });
        this.filtersList[this.filtersList.length-1].children = this.filmCountries.map(
        (x:string) => {
          return {
            name: x,
            checked: false,
            filter: { column: "country_film", operator: "=", value: x }
          };
        }
      );
    }
    if(this.filmDirectors.length > 1) {
      this.filtersList.push(
        {
          name: "Director",
          open: false,
          type: "checkbox",
          scrollable: true,
          children: [
            //mettere tutti i film
          ]
        });
        this.filtersList[this.filtersList.length-1].children = this.filmDirectors.map(
        (x:string) => {
          return {
            name: x,
            checked: false,
            filter: { column: "director_film", operator: "=", value: x }
          };
        }
      );
    }
    if(this.filmYears.length > 1) {
      this.filtersList.push(
        {
          name: "Year",
          open: false,
          type: "checkbox",
          scrollable: true,
          children: [
            //mettere tutti i film
          ]
        });
        this.filtersList[this.filtersList.length-1].children = this.filmYears.map(
        (x:string) => {
          return {
            name: x,
            checked: false,
            filter: { column: "year_film", operator: "=", value: x }
          };
        }
      );
    }
    if(this.filmType.length > 1) {
      this.filtersList.push(
        {
          name: "Type",
          open: false,
          type: "checkbox",
          scrollable: true,
          children: [
            //mettere tutti i film
          ]
        });
        this.filtersList[this.filtersList.length-1].children = this.filmType.map(
        (x:string) => {
          return {
            name: x,
            checked: false,
            filter: { column: "type_film", operator: "like", value: x }
          };
        }
      );
    }
    if(this.filmLanguages.length > 1) {
      this.filtersList.push(
        {
          name: "Language",
          open: false,
          type: "checkbox",
          scrollable: true,
          children: [
            //mettere tutti i film
          ]
        });
        this.filtersList[this.filtersList.length-1].children = this.filmLanguages.sort((x,y) => this.getLanguage(x).localeCompare(this.getLanguage(y))).map(
        (x:string) => {
          //console.log(x);
          return {
            name: this.getLanguage(x),
            checked: false,
            filter: { column: "language_film", operator: "=", value: x }
          };
        }
      );
    }
    if(this.filmSubtitles.length > 1) {
      this.filtersList.push(
        {
          name: "Subtitles",
          open: false,
          type: "checkbox",
          scrollable: true,
          children: [
            //mettere tutti i film
          ]
        });
        this.filtersList[this.filtersList.length-1].children = this.filmSubtitles.sort((x,y) => this.getLanguage(x).localeCompare(this.getLanguage(y))).map(
        (x:string) => {
          return {
            name: this.getLanguage(x),
            checked: false,
            filter: { column: "subtitle_film", operator: "=", value: x }
          };
        }
      );
    }
    }
  },
  data(): {
    filtersList: MainFilterUi[];
    isFiltersListOpen: boolean;
    fullText:string;
  } {
    return {
      isFiltersListOpen: false,
      fullText: "",
      filtersList: [
        {
          name: "Fulltext",
          hidden: true,
          open: false,
          scrollable: false,
          type: 'string',
          children: [{
            name: "",
            checked: false
          }]
        },
        {
          name: "Sort",
          open: false,
          type: "radio",
          scrollable: false,
          children: [
            {
              name: "A-Z Name",
              checked: true,
              sort: { column: "title", order: "asc" }
            },
            {
              name: "Z-A Name",
              checked: false,
              sort: { column: "title", order: "desc" }
            }
          ]
        },
      ]
    };
  },
  methods: {
    countryName(name: string): string {
      return getModule(ValuesModule, this.$store).countries.find(x => x.name_it === name )?.name || name
    },
    getLanguage(id:string):string{
      return getModule(ValuesModule, this.$store).filmLanguages.find(x => x.id === parseInt(id))?.name+"";
    },
    emitNewFilters() {
      this.$emit("filterchange", this.appliedFilters);
    },
    resetAll() {
      this.fullText = ""
      this.filtersList.forEach(fl =>
        fl.children.forEach(c => (c.checked = false))
      );
      const newest = this.filtersList[0].children.find(x => x.name == 'Newest')
      if(newest) {
        newest.checked = true;
      }
      this.emitNewFilters();
    },
    toggle(name: string) {
      const current = this.filtersList.find(x => x.name === name);
      if (current) {
        const newValue = !current.open;
        this.filtersList.forEach(x => (x.open = false));
        current.open = newValue;
      }
    },
    checkRadio(e: Event, item: MainFilterUi, subitem: FilterValue) {
      //e.preventDefault();
      item.children.forEach(c => (c.checked = false));
      subitem.checked = true;
      item.open = false;
      this.isFiltersListOpen = false;
      this.emitNewFilters();
    },
    toggleCheckbox(e: Event, item: MainFilterUi, subitem: FilterValue) {
      //e.preventDefault();
      subitem.checked = !subitem.checked;
      //item.open = false;
      //this.isFiltersListOpen = false;
      this.emitNewFilters();
    },
    fullTextChange() {
      this.filtersList[0].children[0].name = this.fullText
      this.filtersList[0].children[0].fullText = this.fullText
      this.filtersList[0].children[0].checked = this.fullText.length > 0
      this.emitNewFilters();
    },
    openFilters() {
      this.isFiltersListOpen = true;
    },
    closeFilters() {
      this.isFiltersListOpen = false;
    }
  }
});
