
import Vue, { PropType } from 'vue';
import { mapGetters, mapState } from 'vuex';
import { getModule } from 'vuex-module-decorators';
import { RootState } from '../store/modules/root';
import ResourcesModule from '../store/modules/resources';

export interface BreadcrumbsItems {
    url:string;
    label:string;
}

export default Vue.extend({
  name: 'Breadcrumbs',
  props: {
    breadcrumbsItems: Array as PropType<BreadcrumbsItems[]>,
  }
});
