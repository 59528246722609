
import Vue, { PropType } from 'vue';

import mixins from 'vue-typed-mixins';
import LabelMixin from '@/mixins/labels';
import Swiper, { SwiperOptions } from 'swiper';
import TYPES from '@/ioc/types';

export default mixins(LabelMixin).extend({
  name: "WalktroughView",
  components: {
    
  },
  methods: {

  },
  watch: {

  },
  mounted() {
    const swiperOpt: SwiperOptions = {
        //slidesPerView: 1,
        //spaceBetween: 25,
        //slidesPerGroup: 1,
        preloadImages: true,
        // Enable lazy loading
        lazy: false,
        loop: true,
        //loopAdditionalSlides: 10,
        //centeredSlides: false,
        navigation: {
          nextEl: '.swiper-button-next',
          prevEl: '.swiper-button-prev',
        },
    };
    const swiperMobileOpt: SwiperOptions = {
        //slidesPerView: 1,
        //spaceBetween: 25,
        //slidesPerGroup: 1,
        preloadImages: true,
        // Enable lazy loading
        lazy: false,
        loop: true,
        //loopAdditionalSlides: 10,
        //centeredSlides: false,
    };
    setTimeout(() => new Swiper(this.$refs.swiperWalkthroughContainer as HTMLElement, swiperOpt), 0);
    setTimeout(() => new Swiper(this.$refs.swiperWalkthroughMobileContainer as HTMLElement, swiperMobileOpt), 0);
  },
});
