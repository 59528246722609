
import Vue, { PropType } from 'vue';
import { getModule } from 'vuex-module-decorators';
import FloatLabel from "vue-float-label/components/FloatLabel.vue";
import { UserResponse, Cv, UserPrivacy } from '@/client/user.client';
import UsersModule from '@/store/modules/users';
import ValuesModule from '@/store/modules/values';
import { format } from 'date-fns';


import {sanitizeNumber} from '../../service/form';

import mixins from 'vue-typed-mixins';
import LabelMixin from '@/mixins/labels'


export interface SubmitFormEmit {
  privacy: UserPrivacy;
}

export default mixins(LabelMixin).extend({
  components: {
    FloatLabel
  },
  props: {
    privacy:  {
      required: true,
      type: Object as PropType<UserPrivacy>,
    },
  },
  created() {
    getModule(UsersModule, this.$store).dispatchGetById(this.privacy.id);
  },
  methods: {
    onCancel(event: MouseEvent) {
      event.preventDefault();
      this.$emit('onCancel');
    },
    onSubmitForm(event: Event) {
      event.preventDefault();
      const submitEmit: SubmitFormEmit = {
        privacy: this.privacy,
      };
      this.$emit('onSubmit', submitEmit);
    },
  },
  computed: {
    profile(): UserResponse | undefined {
      return getModule(UsersModule, this.$store).selectById(this.privacy.id);
    },
    
  }
})
