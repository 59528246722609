
import Vue, { PropType } from "vue";
import FilmForm, {
  SubmitFormEmit
} from "../../components/heritage/forms/FilmForm.vue";
import { extractContainer } from "../../ioc/util";
import TYPES from "../../ioc/types";
import UserFilmClient, { getDefaultFilm } from "@/client/user-film.client";
import { Film, Cv } from "@/client/user.client";
import { getModule } from "vuex-module-decorators";
import SettingsModule from "../../store/modules/settings";
import DownloadClient from "../../client/download.client";
import { sub } from "date-fns";
import { getFilmFormPath, getProfilePath } from '@/router/toolbox';
import ValuesModule from '../../store/modules/values';
import { ValueResponse } from '@/client/value.client';
import UsersModule from '../../store/modules/users';

export default Vue.extend({
  name: "FormFilmView",
  props: {
    id: Number
  },
  components: {
    FilmForm
  },
  data(): { film: Film } {
    return {
      film: getDefaultFilm()
    };
  },
  methods: {
    async onFilmSubmit(submit: SubmitFormEmit) {
      const { film, newImage, subTitles,removeSubTitles } = submit;
      const container = extractContainer(this);
      const downloadClient = container.get<DownloadClient>(
        TYPES.DownloadClient
      );
      const userFilmClient = container.get<UserFilmClient>(TYPES.UserFilmClient);
      const settingsModule = getModule(SettingsModule, this.$store);
      const me = getModule(UsersModule, this.$store).me;
      
      let orgId = -1
      if(me) {
        orgId = me.user_organization[0].organization_id
      } 

      film.company_id = orgId;

      let filmId:number;
      if (film.id) {
        await userFilmClient.update(settingsModule.language, film);
        filmId = film.id; 
      } else {
        filmId = await userFilmClient.create(settingsModule.language, film);
      }

      if (newImage) {
        await downloadClient.uploadEdit(filmId, newImage, "user_films.image")
      }
      if(subTitles){
        subTitles.forEach(async(x) => {
          const subtitle_row_id = await userFilmClient.getSubtitleIdByFilmLang(settingsModule.language, filmId, x.id_subtitle);
          await downloadClient.uploadEdit(subtitle_row_id, x.file, "film_subtitles.subtitle_file")
        });
      }
      if(removeSubTitles){
        removeSubTitles.forEach(async(x) => {
          const subtitle_row_id = await userFilmClient.getSubtitleIdByFilmLang(settingsModule.language, filmId, x);
          await userFilmClient.removeSubtitleByFilmLang(settingsModule.language, filmId, x);
        });
      }

      // TODO AM mandare al chapter selected library dopo aver salvato il film
      // this.$router.push(getProfilePath(me?.id || -1));
      this.$router.go(-1);

      
    }
  },
  watch: {
    id: {
      immediate: true,
      async handler(id: number) {
        const container = extractContainer(this);
        const userFilmClient = container.get<UserFilmClient>(TYPES.UserFilmClient);
        const settingsModule = getModule(SettingsModule, this.$store);

        if (this.id !== null) {
          this.film = await userFilmClient.getById(
            settingsModule.language,
            this.id
          );
        }
      }
    }
  },
  inject: ["container"]
});
