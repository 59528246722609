
import Vue, { PropType } from 'vue';
import FilmLayout from '@/components/FilmLayout.vue';
import { ResourceLightResponse } from '@/client/resource.client';
import { UserResponse, Film } from '@/client/user.client';

import { Label } from '../../client/label.client';
import LabelsModule from '@/store/modules/labels';
import mixins from 'vue-typed-mixins'
import LabelMixin from '../../mixins/labels';
import { OrganizationFast } from '@/client/organization.client';
import { getModule } from 'vuex-module-decorators';
import ValuesModule from '@/store/modules/values';
import UserFilmClient, { FilmAnalytics, FilmLight } from '@/client/user-film.client';
import { extractContainer } from '@/ioc/util';
import TYPES from '@/ioc/types';
import OdImage from '@/components/ui/OdImage.vue';

interface FilmAnalyticsVM {
    film: FilmLight | undefined,
    users: FilmAnalytics[],
    name: string,
    total_views: number,
    total_trailer: number,
    total_film_view: number,
    open: boolean
}

export default mixins(LabelMixin).extend({
  name: 'ProfileAnalytics',
  inject: ["container"],
  props: {
    company: {
      required: true,
      type: Object as PropType<OrganizationFast>,
    }
  },
  components: {
      OdImage,
  },
  data():{ 
      totals:FilmAnalyticsVM[] ,
  } {
      return {
          totals: []

      }
  },
  async created() {
    const container = extractContainer(this);
    const filmClient =  container.get<UserFilmClient>(TYPES.UserFilmClient);
    const filmAnalitics = await filmClient.getAnalytics(this.company.id)
    const films = await filmClient.getCompanyFilms("en",20,this.company.id)

    const ids = [... new Set(filmAnalitics.map(x => x.film_id))]
    this.totals = ids.map(t => {
            const filmA = filmAnalitics.filter(x => x.film_id === t)
            const film = films.find(x => x.id === t)
            return {
                film: film,
                users: filmA,
                name: film ? film.title : '',
                total_views: filmA.map(x => x.views).reduce((x,y) => x + y),
                total_trailer: filmA.map(x => x.trailer_played).reduce((x,y) => x + y),
                total_film_view: filmA.map(x => x.full_film_played).reduce((x,y) => x + y),
                open: false
            }
        }).filter(x => x.film ? true : false)
      
  },
  methods: {
    toggleFilm(film:FilmAnalyticsVM) {
      console.log(film)
      film.open = !film.open
    }
  },
  computed: {

  }
});
