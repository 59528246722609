import { AxiosStatic } from 'axios';
import VueRouter from 'vue-router';
import { LOGIN_PATH } from '@/router/guard';
import AuthModule from '@/store/modules/auth';
import { getModule } from 'vuex-module-decorators';
import { Store } from 'vuex';
import { RootState } from '@/store/modules/root';

export interface DeleteResponse{
  count: number
}

export default function addAuthAxios(axios: AxiosStatic, store: Store<RootState>, router:VueRouter): AxiosStatic {
  axios.interceptors.response.use(async (response) => {
    return response;
  },
  async (error) => {
    const originalRequest = error.config;
    if (error.response.status === 401) {
      const response = await fetch('/auth/refresh/token')
      const authModule = getModule(AuthModule, store)
      if(response.ok) {
        authModule.setAuthToken(await response.text());
      } else {
        router.push(LOGIN_PATH);
      }
      return error;
    }
    return error;
  });
  return axios;
}
