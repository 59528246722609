
import Vue from "vue";
import mixins from 'vue-typed-mixins'
import LabelMixin from '@/mixins/labels';
import { authUrl } from '@/service/auth'
import { HERITAGE } from '../service/site';



export default mixins(LabelMixin).extend({
  name: "SplashDevView",
  inject: ["container"],
  computed: {
    loginPath(): String {
      return authUrl(HERITAGE)
    }
  }
});
