
import Vue, { PropType } from "vue";
import ProfileEditForm, {
  SubmitFormEmit
} from "../components/forms/ProfileEditForm.vue";
import { extractContainer } from "../ioc/util";
import TYPES from "../ioc/types";
import UserFilmClient, { getDefaultFilm } from "@/client/user-film.client";
import UserClient, { Film, Cv, UserProfile, toProfile, fromProfile } from "@/client/user.client";
import { getModule } from "vuex-module-decorators";
import SettingsModule from "../store/modules/settings";
import DownloadClient from "../client/download.client";
import { sub } from "date-fns";
import { getFilmFormPath } from '@/router/toolbox';
import mixins from 'vue-typed-mixins';
import LabelMixin from '@/mixins/labels'

export default mixins(LabelMixin).extend({
  name: "ProfileEditView",
  props: {
    id: Number
  },
  components: {
    ProfileEditForm
  },
  data(): { profile: UserProfile | null, showForm:boolean, loadingForm: boolean } {
    return {
      profile: null,
      showForm: true,
      loadingForm: false,
    };
  },
  methods: {
    async onProfileSubmit(submit: SubmitFormEmit) {
      this.loadingForm = true;

      const { profile, image, cv, removeCurrentCv } = submit;
      const container = extractContainer(this);
      const downloadClient = container.get<DownloadClient>(
        TYPES.DownloadClient
      );
      const userClient = container.get<UserClient>(TYPES.UserClient);
      const settingsModule = getModule(SettingsModule, this.$store);
      const currentUser = await userClient.getMe(settingsModule.language);
      
      if(currentUser) {
      
        profile.id = currentUser.id;
        const user = fromProfile(currentUser,profile,image?.name || null,cv?.name || null)

        if(removeCurrentCv){
          user.cv = [];
        }
        await userClient.update(settingsModule.language,user)
        const updatedUser = await userClient.getById(settingsModule.language,profile.id)
        if(image && updatedUser.image[0] && updatedUser.image[0].id) {
          await downloadClient.uploadEdit(updatedUser.image[0].id, image, "user_images.image")
        }
        if(cv && updatedUser.cv[0] && updatedUser.cv[0].id) {
          await downloadClient.uploadEdit(updatedUser.cv[0].id, cv, "user_cv.cv")
        }

        // this.showForm = false;
        this.$router.go(-1);
      }


    }
  },
  watch: {
    id: {
      immediate: true,
      async handler(id: number) {
        const container = extractContainer(this);
        const userClient = container.get<UserClient>(TYPES.UserClient);
        const settingsModule = getModule(SettingsModule, this.$store);

        if (this.id !== null) {
          const user = await userClient.getById(
            settingsModule.language,
            this.id
          );
          this.profile = toProfile(user);
        }
      }
    }
  },
  inject: ["container"]
});
