
import Vue, { PropType } from 'vue';
import ResourceListElementLayout from '@/components/ResourceListElementLayout.vue';
import FilmLayout from '@/components/FilmLayout.vue';
import { ResourceLightResponse } from '@/client/resource.client';
import { Film } from '@/client/user.client';
import { Label } from '../client/label.client';
import LabelsModule from '@/store/modules/labels';
import mixins from 'vue-typed-mixins'
import LabelMixin from '../mixins/labels';
import Swiper, { SwiperOptions } from 'swiper';

export default mixins(LabelMixin).extend({
  name: 'ProfileResourcesListMobile',
  props: {
    listClass: {
      type: String,
      validator: (c:string) => [
        'filmography',
        'recently-viewed',
        'your-resources',
        'favorites-resources',        
        'favorites-agenda',
        'favorites-forum',
      ].includes(c),
    },
    itemClass: {
      type: String,
      validator: (c:string) => [
        'film',
        'film-md',        
        'film-sm',      
        'resource-sm',
        'resource-slide'      
      ].includes(c),
    },
    title: String,
    link: String,
    resources: {
      type: Array as PropType<ResourceLightResponse[] | Film[]>,
      required: true,
    }
  },
  components: {
    ResourceListElementLayout,
    FilmLayout,
  },
  mounted() {
      const swiperOpt: SwiperOptions = {
        slidesPerView: 1,
        spaceBetween: 25,
        slidesPerGroup: 1,
        preloadImages: true,
        // Enable lazy loading
        lazy: false,
        loop: true,
        loopAdditionalSlides: 10,
        centeredSlides: false,
      
    };
    setTimeout(() => new Swiper(this.$refs.swiperRecentlyViewed as HTMLElement, swiperOpt), 0);
  },
});
