
import Vue, { PropType } from "vue";
import CompanyEditForm, { SubmitFormEmit } from "../../components/heritage/forms/CompanyEditForm.vue";
import { extractContainer } from "../../ioc/util";
import TYPES from "../../ioc/types";
import UserFilmClient, { getDefaultFilm } from "@/client/user-film.client";
import UserClient, { Film, Cv, UserProfile, toProfile, fromProfile } from "@/client/user.client";
import { getModule } from "vuex-module-decorators";
import SettingsModule from "../../store/modules/settings";
import DownloadClient from "../../client/download.client";
import { sub } from "date-fns";
import { getFilmFormPath } from '@/router/toolbox';
import mixins from 'vue-typed-mixins';
import LabelMixin from '@/mixins/labels'
import OrganizationClient, { Organization } from '@/client/organization.client';

export default mixins(LabelMixin).extend({
  name: "CompanyEditView",
  props: {
    id: Number
  },
  components: {
    CompanyEditForm
  },
  data(): { showForm:boolean, loadingForm: boolean } {
    return {
      showForm: true,
      loadingForm: false,
    };
  },
  asyncComputed: {
    async company():Promise<Organization | null> {
        const settingsModule = getModule(SettingsModule, this.$store);
        const container = extractContainer(this);
        const organizationClient = container.get<OrganizationClient>(TYPES.OrganizationClient);
        const org = await organizationClient.getFullById(settingsModule.language, this.id);
        return org;
    }
  },
  methods: {
    async onProfileSubmit(submit: SubmitFormEmit) {
      this.loadingForm = true;
      const { company, filmList, image  } = submit;

      const container = extractContainer(this);
      const settingsModule = getModule(SettingsModule, this.$store);

      const downloadClient = container.get<DownloadClient>(TYPES.DownloadClient);
      
      const organizationClient = container.get<OrganizationClient>(TYPES.OrganizationClient);
      
      if(filmList) {
        company.film_list_name = filmList.name
        company.film_list_mime = filmList.mime
      }

      if(!company.image[0]?.id){
        company.image.push({
          organization_id: company.id
        });
      }
      await organizationClient.update(settingsModule.language, company)

      const updatedCompany = await organizationClient.getFullById(settingsModule.language, company.id)

      if(filmList) {

        await downloadClient.uploadEdit(updatedCompany.id,filmList,"organization.film_list")

      }
      if(image && updatedCompany.image[0]?.id) {
        await downloadClient.uploadEdit(updatedCompany.image[0]?.id,image,"organization_image.image")
      }
      
      // this.showForm = false;



      this.$router.go(-1);



    }
  },
  inject: ["container"]
});
