
import Vue from 'vue';
import { getModule } from 'vuex-module-decorators';
import ResourceClient, { ResourceResponse } from '../../client/resource.client';
import ResourcesModule from '../../store/modules/resources';
import { formatPublication, formatDateRange } from '@/service/ui';
import { DEFAULT_LIST_OPTION_REQUEST } from '@/client/category.client';
import UserClient, { DefaultListOptionRequest, Film, UserResponse } from '@/client/user.client';
import { extractContainer } from '@/ioc/util';
import SettingsModule from '@/store/modules/settings';
import TYPES from '@/ioc/types';
import { SiteConfig } from '../../client/value.client';
import OdImage from '../../components/ui/OdImage.vue';
import ValuesModule from '../../store/modules/values';

import { Label } from '../../client/label.client';
import LabelsModule from '@/store/modules/labels';
import mixins from 'vue-typed-mixins'
import LabelMixin from '../../mixins/labels';

export default mixins(LabelMixin).extend({
  name: 'NewsDetails',
  props: {
    id: Number
  },
  inject: ['container'],
  components: {
    OdImage

  },
  beforeCreate: function() {
    document.body.className = '';
  },
  async created() {
      await getModule(ResourcesModule, this.$store).dispatchGetList(DEFAULT_LIST_OPTION_REQUEST);
  },
  filters: {
    formatDate(date: Date): string {
      return formatPublication(date);
    }    
  },
  asyncComputed: {
    async news(): Promise<ResourceResponse | null> {
      const settingsModule = getModule(SettingsModule, this.$store);
      const container = extractContainer(this);
      const resourcesClient =  container.get<ResourceClient>(TYPES.ResourceClient);
      const news = await resourcesClient.getById(settingsModule.language,this.id);

      if(news.visible){
        return news;
      }else{
        this.$router.push('/PageNotFound');
        return null;
      }
    },
  },
  computed: {
  }
});
