import Environment from '@/env/environment';
import TYPES from '@/ioc/types';
import { AxiosStatic } from 'axios';
import { inject, injectable } from 'inversify';
import { forEach } from 'lodash';
import { SupportedLanguage } from './supported-lang.type';


export interface Partner {
  id: number;
  name: string;
  link: number;
  image?: string,
  mime?: string,
  toolbox_partner_big: boolean,
  toolbox_partner_small: boolean,
  partner_id?: number,
  resource_id?: number,
}

export interface PartnerLite {
  id: number,
  partner_id: number,
  resource_id: number
}

export interface FilterOption {
  column: string;
  operator: 'in' | 'notin' | 'like' | '=' | 'not';
  value: string;
}

export interface PagingOption {
  pageLength: number;
  currentPage: number;
}

export interface SortOption {
  column: string;
  order: 'asc' | 'desc';
}
export interface ListOptionRequest {
  filter: FilterOption[];
  paging: PagingOption;
  sort: SortOption[];
}

@injectable()
export default class PartnerClient {
  constructor(
    @inject(TYPES.AxiosStatic) private readonly axios: AxiosStatic,
    @inject(TYPES.Environment) private readonly environment: Environment,
  ) { }

  async getById(lang: SupportedLanguage, id: number): Promise<Partner> {
    const response = this.axios.get<Partner>(`${this.environment.backendUrl}/api/v1/form/${lang}/partners/id/id::${id}`, {
      withCredentials: true,
    });
    return (await response).data;
  }

  async getPartnersBig(lang: SupportedLanguage): Promise<Partner[]> {

    const request:ListOptionRequest = {
      filter: [{column: 'toolbox_partner_big', operator: "=", value: "true"}],
      paging: {currentPage:1, pageLength: 10000},
      sort: []
    } 

    const response = this.axios.post<Partner[]>(`${this.environment.backendUrl}/api/v1/entity/${lang}/partners/list`, 
      request,{
      withCredentials: true,
    });
    return (await response).data;
  }

  async getPartnersSmall(lang: SupportedLanguage): Promise<Partner[]> {

    const request:ListOptionRequest = {
      filter: [{column: 'toolbox_partner_small', operator: "=", value: "true"}],
      paging: {currentPage:1, pageLength: 10000},
      sort: []
    } 

    const response = this.axios.post<Partner[]>(`${this.environment.backendUrl}/api/v1/entity/${lang}/partners/list`, 
    request,{
      withCredentials: true,
    });
    return (await response).data;
  }

}