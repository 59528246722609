
import Vue, { PropType } from 'vue';
import { ResourceLightResponse } from '@/client/resource.client';
import { formatPublication, formatDateRange, isSevenDays } from '@/service/ui';
import { format } from 'date-fns';
import { parse } from 'date-fns';
import Favorite from '@/components/ui/Favorite.vue';

import NotificationMessageClient, { NotificationResponse } from '@/client/notification-center.client';

import { Label } from '../client/label.client';
import LabelsModule from '@/store/modules/labels';
import mixins from 'vue-typed-mixins'
import LabelMixin from '../mixins/labels';
import { getModule } from 'vuex-module-decorators';
import UsersModule from '../store/modules/users';
import SettingsModule from '@/store/modules/settings';
import { extractContainer } from '@/ioc/util';
import TYPES from '@/ioc/types';
import UserClient from '@/client/user.client';
import NotificationModule from '@/store/modules/notifications';
import OdImage from '@/components/ui/OdImage.vue';
import UserMentionRenderer from '@/components/ui/UserMentionRenderer.vue';

export default mixins(LabelMixin).extend({
  name: 'NotificationLayout',
  components: {
    Favorite,
    OdImage,
    UserMentionRenderer
  },
  props: {
    className: {
      type: String,
      validator: (c:string) => [
        'notifications-resources',       
        'notifications-forum',
      ].includes(c),
    },
    notification: {
      type: Object as PropType<NotificationResponse>,
      required: true,
    },
    type:{
      type: Number,
      required: true
    }
  },
  computed: {
    expired(): boolean{
      return isSevenDays(this.notification.creation_date);
    }
  },
  methods:{
    async updateNotification(event: Event,state:boolean) {
      event.preventDefault();
      if(this.type == 1) {
        await getModule(NotificationModule, this.$store).updateNotificationResource({notification: this.notification,state: state});
      } else {
        await getModule(NotificationModule, this.$store).updateNotificationForum({notification: this.notification,state: state});
      } 
    }

  },
  filters: {
    resourceLabels(resource: ResourceLightResponse): string {
      return [
        (r: ResourceLightResponse) => r.has_video && 'Video',
        (r: ResourceLightResponse) => r.has_link && 'Link',
        (r: ResourceLightResponse) => r.has_documents && 'Document',
      ] .map(r => r(resource))
        .filter(r => !!r)
        .join(', ');
    },
    formatPublication(d: string): string {
      return formatPublication(parse(d, 'yyyy-MM-dd HH:mm', new Date()));
    },
    formatMonth(d: Date): string {
      return d ? format(d, 'MMM') : '';
    },
    formatDay(d: Date): string {
      return d ? format(d, 'd') : '';
    },
    formatYear(d: Date): string {
      return d ? format(d, 'yyyy') : '';
    },
    formatEventDate(r: ResourceLightResponse) {
      return formatDateRange(r.start, r.end);
    },
  },
  data(): {
    visible: boolean,
    readed: boolean
  } {
    return {
      visible: true,//!this.notification.readed,
      readed: this.notification.readed
    }
  },
  inject: ['container'],
});
