


import mixins from 'vue-typed-mixins'
import LabelMixin from '../../mixins/labels';


export default mixins(LabelMixin).extend({
  beforeCreate: function() {
    document.body.className = 'stepaway';
  },
  name: "stepAwayHeritageReview",
  inject: ["container"]
});
