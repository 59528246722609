
import Breadcrumbs, { BreadcrumbsItems } from '@/components/Breadcrumbs.vue';
import { getModule } from 'vuex-module-decorators';
import Filters, { FilterValue } from '@/components/FiltersUser.vue';
import UserClient, { UserHeritageResponse } from '@/client/user.client';
import { extractContainer } from '@/ioc/util';
import SettingsModule from '@/store/modules/settings';
import TYPES from '@/ioc/types';
import { DownloadStatus } from '@/client/download.client';

import mixins from 'vue-typed-mixins'
import LabelMixin from '../mixins/labels';

export default mixins(LabelMixin).extend({
  name: 'UsersView',
  inject: ['container'],
  components: {
    Breadcrumbs, 
    Filters,
  },
  props: {

  },
  beforeCreate: function() {
    document.body.className = '';
  },
  async created() {
      this.status = 'LOADING';
      const settingsModule = getModule(SettingsModule, this.$store);
      const container = extractContainer(this);
      const userClient =  container.get<UserClient>(TYPES.UserClient);
      this.status = 'SUCCESS';
      this.users = await userClient.getListToolbox(settingsModule.language,{currentPage: 1, pageLength: 1000});
      this.filteredUsers = [...this.users]

      this.userCountries = [...new Set(this.users.flatMap(x => {
        return x.country ? x.country.split(",").map(x => x.trim()) : []
      }))].sort((x,y) => x.localeCompare(y));

      this.loaded = true;
  },
  computed: {
    breadcrumbsItems(): BreadcrumbsItems[] {
      return [
        { url: '/members', label: 'Members' },
      ]

    },
  },
  methods: {
    onFilterChange(filters:FilterValue[]) {
      let newFilter = [...this.users]
      let newFilter1:number[] = []
      let newFilter2:number[] = []
      // check if filter is active
        let checkFilters = false;
        filters.forEach(element => {
          if(element.filter) {
            checkFilters = true;
          }
        });
        console.log(filters.length);
        if(checkFilters){
          newFilter.splice(0);

        filters.forEach(element => {
          if(element.filter) {
            if(element.filter.column == "country"){
            //console.log(element.filter.column);
            let allData = [...this.users]
            let tempFilter = allData.filter(x => {
              return x.country ? (x.country as string).includes((element.filter?.value as string)) : false
            })
            //newFilter = newFilter.concat(tempFilter);
            tempFilter.forEach(x => {
              newFilter1.push(x.id)
            })
            }
            if(element.filter.column == "main_activity"){
            //console.log(element.filter.column);
            let allData = [...this.users]
            let tempFilter = allData.filter(x => {
              return x.main_activities ? (x.main_activities as string).includes((element.filter?.value as string)) : false
            })
            //newFilter = newFilter.concat(tempFilter);
            tempFilter.forEach(x => {
              newFilter2.push(x.id)
            })
            }
          }
        });
        let intersection:number[];
        if(newFilter1.length > 0){
          intersection = newFilter1;
        if(newFilter2.length > 0)
            intersection = intersection.filter(x => newFilter2.includes(x));
        }else if(newFilter2.length > 0){  
          intersection = newFilter2
        }
        //newFilter = [ ...new Set(newFilter)];
        newFilter = [...this.users];
        newFilter = newFilter.filter(x => {
          //console.log(x.id + " CHECK IN "+ intersection + " result: "+ intersection.includes(x.id));
          return intersection.includes(x.id);
        })
        }

      filters.forEach(element => {
        if(element.fullText) {
          const q = (element.fullText as string).toLowerCase()
          newFilter = newFilter.filter(x => {
            
            return (x.first_name ? x.first_name.toLowerCase().includes(q) : false) || 
            (x.last_name ? x.last_name.toLowerCase().includes(q) : false)
          })
        }
        if(element.sort) {
          if(element.sort.order == "desc") {
            if(element.sort.column == "title") {
              newFilter = newFilter.sort((x,y) => -x.first_name.localeCompare(y.first_name))
            } else if(element.sort.column == "country") {
              //newFilter = newFilter.sort((x,y) => x.country&&y.country?-x.country.localeCompare(y.country):1000)
            }
          } else {
            if(element.sort.column == "title") {
              newFilter = newFilter.sort((x,y) => x.first_name.localeCompare(y.first_name))
            } else if(element.sort.column == "country") {
              //newFilter = newFilter.sort((x,y) => x.country&&y.country?x.country.localeCompare(y.country):-1000)
            }
          }
        }
      });
      this.filteredUsers = newFilter
    },
  },
  data(): {
    status: DownloadStatus; 
    users:UserHeritageResponse[];
    filteredUsers:UserHeritageResponse[];
    userCountries: String[];
    loaded: Boolean;
  } {
    return {
      status: 'NOT_YET',
      users: [],
      filteredUsers:[],
      userCountries:[],
      loaded: false
    };
  }
});
