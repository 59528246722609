
import Vue from 'vue';
import ResourcesModule from '@/store/modules/resources';
import { getModule } from 'vuex-module-decorators';

import LabelsModule from '@/store/modules/labels';
import mixins from 'vue-typed-mixins'
import LabelMixin from '../mixins/labels';
import PartnerClient, { Partner } from '../client/partner.client';
import SettingsModule from '@/store/modules/settings';
import { extractContainer } from '@/ioc/util';
import TYPES from '@/ioc/types';
import PageClient, {Page, PageBlock} from '@/client/page.client';
import BlockLayout from '../components/BlockLayout.vue';
import OdImage from '../components/ui/OdImage.vue';
import ValuesModule from '@/store/modules/values';
import ValueClient, { SiteConfig, Faq } from '@/client/value.client';
import Breadcrumbs, { BreadcrumbsItems } from '@/components/Breadcrumbs.vue';


import Swiper, { SwiperOptions } from 'swiper';

import { Site } from '@/service/site';

export default mixins(LabelMixin).extend({
  name: 'ModalView',
  components: {
    BlockLayout,
        Breadcrumbs,

    OdImage
  },
  props: {
    id: Number,
    slug: String
  },
  inject: ['container'],
  async created() {

  },
  filters: {
    
  },
  asyncComputed: {
    async pageSettings(): Promise<Page> {
        //this.status = 'LOADING';
        const settingsModule = getModule(SettingsModule, this.$store);
        const container = extractContainer(this);
        const PageClient = container.get<PageClient>(TYPES.PageClient);
        const pageSettings = await PageClient.getPageBySlug(settingsModule.language,this.id);
        this.breadcrumbsItems.push({ url: '/page/'+pageSettings.id+"/"+pageSettings.slug, label: pageSettings.title});
        return pageSettings;
        //this.status = 'SUCCESS';
    },
    async pageBlocks(): Promise<PageBlock[]> {
        //this.status = 'LOADING';
        const settingsModule = getModule(SettingsModule, this.$store);
        const container = extractContainer(this);
        const PageClient = container.get<PageClient>(TYPES.PageClient);
        return await PageClient.getBlocksBySlug(settingsModule.language,this.id);
        //this.status = 'SUCCESS';
    },
    async faq():Promise<Faq[]> {
      const settingsModule = getModule(SettingsModule, this.$store);
      const container = extractContainer(this);
      const resourcesClient =  container.get<ValueClient>(TYPES.ValueClient);
      return await resourcesClient.getSiteFaq();
    },
    async partner_big():Promise<Partner[]> {
      const settingsModule = getModule(SettingsModule, this.$store);
      const container = extractContainer(this);
      const partnerClient =  container.get<PartnerClient>(TYPES.PartnerClient);
      return await partnerClient.getPartnersBig(settingsModule.language);
    },
    async partner_small():Promise<Partner[]> {
      const settingsModule = getModule(SettingsModule, this.$store);
      const container = extractContainer(this);
      const partnerClient =  container.get<PartnerClient>(TYPES.PartnerClient);
      return await partnerClient.getPartnersSmall(settingsModule.language);
    }
  },
  computed: {
    site(): Site{
      const settingsModule = getModule(SettingsModule, this.$store);
      const container = extractContainer(this);
      return container.get<Site>(TYPES.Site);
    }
  },
  methods: {
  },
  mounted() {    
    const swiperOpt: SwiperOptions = {
        //slidesPerView: 1,
        //spaceBetween: 25,
        //slidesPerGroup: 1,
        preloadImages: true,
        // Enable lazy loading
        lazy: false,
        loop: true,
        //loopAdditionalSlides: 10,
        //centeredSlides: false,
        navigation: {
          nextEl: '.swiper-button-next',
          prevEl: '.swiper-button-prev',
        },
    };
    const swiperMobileOpt: SwiperOptions = {
        //slidesPerView: 1,
        //spaceBetween: 25,
        //slidesPerGroup: 1,
        preloadImages: true,
        // Enable lazy loading
        lazy: false,
        loop: true,
        //loopAdditionalSlides: 10,
        //centeredSlides: false,
    };
    setTimeout(() => new Swiper(this.$refs.swiperWalkthroughContainer as HTMLElement, swiperOpt), 0);
    setTimeout(() => new Swiper(this.$refs.swiperWalkthroughMobileContainer as HTMLElement, swiperMobileOpt), 0);
  },
  data(): {
    breadcrumbsItems: BreadcrumbsItems[],

    } {
      return {
        breadcrumbsItems: [],
    };
  },
});

