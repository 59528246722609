import { injectable, inject, interfaces } from 'inversify';
import { AxiosStatic } from 'axios';
import Environment from '@/env/environment';
import TYPES from '@/ioc/types';
import { SupportedLanguage } from './supported-lang.type';
import { PagingOption, ListOptionRequest, CountResponse, DefaultListOptionRequest } from './user.client';
import { format, formatDistance, formatRelative, subDays } from 'date-fns'
import { parse } from 'date-fns';
import { DeleteResponse } from './axios.util';

export const BACKEND_DATE_FORMAT = 'yyyy-MM-dd HH:mm';

export interface ResourceComment {
  body: string;
  id: number;
  title: string;
  author: string;
  author_id: number;
  like_count: number;
  comment_count: number;
  pubblication_date: Date;
  resource_id: number;
  is_favorite: boolean;
  visible: boolean;
  author_image_id:number|null;
}

export interface SendComment {
  author_id: number;
  body: string;
  parent_id: number;
  //pubblication_date: String;
  visible: boolean;
  resource_id: number;
}

export function emptyComment(user_id: number,parent_id: number,resource_id: number) {
  return {
      author_id: user_id,
      body: '',
      resource_id: resource_id,
      parent_id: parent_id,
      //pubblication_date: format(new Date(), "yyyy-MM-dd HH:mm:ss"),
      visible: true,
  }
}

export function emptyCommentInternal(user_id: number,parent_id: number,resource_id: number, body: string) {
  return {
      author_id: user_id,
      body: body,
      parent_id: parent_id,
      resource_id: resource_id,
      //pubblication_date: format(new Date(), "yyyy-MM-dd HH:mm:ss"),
      visible: true,
  }
}

export const DEFAULT_PAGING_OPTION: PagingOption = { pageLength: 100, currentPage: 1 }
export const DEFAULT_LIST_OPTION_REQUEST: ListOptionRequest = {paging: DEFAULT_PAGING_OPTION, sort: [], filter: []};


function deserializeResource<T extends { pubblication_date: Date;}>(r: any): T {
  return {
    ...r,
    pubblication_date: r.pubblication_date ? parse(r.pubblication_date, BACKEND_DATE_FORMAT, new Date()) : null,
  }
}

@injectable()
export default class ResourceCommentClient {
  constructor(
    @inject(TYPES.AxiosStatic) private readonly axios: AxiosStatic,
    @inject(TYPES.Environment) private readonly environment: Environment,
  ) { }

  async create(lang: SupportedLanguage, comment: SendComment): Promise<number> {
    const response = await this.axios.post<ResourceComment>(
      `${this.environment.backendUrl}/api/v1/entity/${lang}/resource_comments`,
      comment,
      { withCredentials: true, }
    );
    return +(response.data.id || -1);
  }

  async remove(lang: SupportedLanguage, id: Number): Promise<number>  {
    const response = await this.axios.delete<DeleteResponse>(
      `${this.environment.backendUrl}/api/v1/entity/${lang}/resource_comments/id/id::${id}`,{ withCredentials: true, }
    );
    
    return +(response.data.count || -1);
    /*
    const response = await this.axios.post<CreateResponse>(
      `${this.environment.backendUrl}/api/v1/entity/${lang}/resource_comments`,
      comment,
      { withCredentials: true, }
    );
    return +(response.data.id.find(v => v.key === 'id')?.value || -1);
    */
  }

  async getById(lang: SupportedLanguage, id: number): Promise<ResourceComment> {
    const response = this.axios.get<ResourceComment>(`${this.environment.backendUrl}/api/v1/form/${lang}/v_resource_topics/id/id::${id}`, {
      withCredentials: true,
    });
    return (await response).data;
  }

  async hideComment(lang: SupportedLanguage, id: number): Promise<void>{
    const response = this.axios.get<ResourceComment>(`${this.environment.backendUrl}/api/v1/entity/${lang}/resource_comments/id/id::${id}`, {
      withCredentials: true,
    });
    const obj = {
      ...(await response).data,
      visible: !(await response).data.visible
    }
    await this.axios.put<void>(
      `${this.environment.backendUrl}/api/v1/entity/${lang}/resource_comments/id/id::${id}`,
      obj,
      { withCredentials: true, });
  }

  async getTopicsByCategory(lang: SupportedLanguage,id: number): Promise<ResourceComment[]> {
    const fullRequest = {
      filter: [{column:"forum_category_id", operator: "=", value: ""+id}],
      sort: [
      ],
      //paging: request,
    };
    const response = this.axios.post<ResourceComment[]>(`${this.environment.backendUrl}/api/v1/entity/${lang}/v_resource_topics/list`, fullRequest, {
      withCredentials: true,
    });
    return (await response).data.map(r => <ResourceComment>(r));
  }

  async getTopicById(lang: SupportedLanguage,id: number): Promise<ResourceComment> {
    const fullRequest = {
      filter: [{column:"id", operator: "=", value: ""+id}],
      sort: [
      ],
      //paging: request,
    };
    const response = this.axios.post<ResourceComment>(`${this.environment.backendUrl}/api/v1/entity/${lang}/v_resource_topics/list`, fullRequest, {
      withCredentials: true,
    });
    return (await response).data;
  }

  async getCommentsByResource(lang: SupportedLanguage,id: number): Promise<ResourceComment[]> {
    const fullRequest = {
      filter: [{column:"resource_id", operator: "=", value: ""+id}],
      sort: [
      ],
      //paging: request,
    };
    const response = this.axios.post<ResourceComment[]>(`${this.environment.backendUrl}/api/v1/entity/${lang}/v_resource_comments/list`, fullRequest, {
      withCredentials: true,
    });
    return (await response).data.map(r => deserializeResource<ResourceComment>(r));
    //return (await response).data.map(r => <ResourceComment>(r));
  }
  async getCommentsByParent(lang: SupportedLanguage,id: number): Promise<ResourceComment[]> {
    const fullRequest = {
      filter: [{column:"parent_id", operator: "=", value: ""+id}],
      sort: [{column: "pubblication_date", order: "asc"}
      ],
      //paging: request,
    };
    const response = this.axios.post<ResourceComment[]>(`${this.environment.backendUrl}/api/v1/entity/${lang}/v_resource_comments/list`, fullRequest, {
      withCredentials: true,
    });
    //return (await response).data.map(r => <ResourceComment>(r));
    return (await response).data.map(r => deserializeResource<ResourceComment>(r));
  }
  

  async getPopular(
    lang: SupportedLanguage,
    limit: number,
  ): Promise<ResourceComment[]> {

    const request:ListOptionRequest = {
      filter: [],
      paging: {currentPage:1, pageLength: limit},
      sort: [{column: 'comment_count', order:'desc'}]
    } 

    const response = this.axios.post<ResourceComment[]>(`${this.environment.backendUrl}/api/v1/entity/${lang}/v_resource_topics/list`,
      request,
      {
        withCredentials: true,
      });
    return (await response).data.map(r => <ResourceComment>(r));
  }
  
  async setFavorite(
    lang: SupportedLanguage,
    forum_comment_id: number,
    user_id: number
  ): Promise<any> {
    const response = this.axios.post(`${this.environment.backendUrl}/api/v1/entity/${lang}/resource_comment_user_favorite`,
      {
        forum_comment_id: forum_comment_id,
        user_id: user_id
      },
      {
        withCredentials: true,
      });
    return (await response).data;
  }


  async unsetFavorite(
    lang: SupportedLanguage,
    forum_comment_id: number,
    user_id: number
  ): Promise<any> {
    const request:ListOptionRequest = {
      sort: [],
      paging: DefaultListOptionRequest.paging,
      filter: [
        {column: 'user_id', operator: '=', value: ""+user_id},
        {column: 'forum_comment_id', operator: '=', value: ""+forum_comment_id}
      ]
    }
    const response = this.axios.post<{id: number, forum_comment_id:number, user_id:number}[]>(`${this.environment.backendUrl}/api/v1/entity/${lang}/resource_comment_user_favorite/list`,
      request,
      {
        withCredentials: true,
      });
    const favorites = (await response).data
    if(favorites.length > 0 ) {
      const favId = favorites[0].id
      const deleteRequest = await this.axios.delete(`${this.environment.backendUrl}/api/v1/entity/${lang}/resource_comment_user_favorite/id/id::${favId}`)
    }
    
  }
  
  
}
