
import Vue, { PropType } from 'vue';
import { getModule } from 'vuex-module-decorators';
import UsersModule from '../store/modules/users';
import ResourceClient, { ResourceLightResponse } from '../client/resource.client';
import Breadcrumbs, { BreadcrumbsItems } from '@/components/Breadcrumbs.vue';
import Filters from '@/components/Filters.vue';
import ResourceListElementLayout from '@/components/ResourceListElementLayout.vue';
import CategoryClient, { Category } from '@/client/category.client';
import { ListOptionRequest, FilterOption, SortOption, CountResponse } from '../client/user.client';
import { DownloadStatus } from '@/client/download.client';
import { extractContainer } from '../ioc/util';
import TYPES from '../ioc/types';
import SettingsModule from '../store/modules/settings';
import { FilterValue } from '../components/Filters.vue';
import mixins from 'vue-typed-mixins'
import LabelMixin from '../mixins/labels';
import { flatten, pickBy, identity } from 'lodash';
import { RESOURCES_PATH } from '@/router/toolbox';

const PAGE_LENGTH = 20;
const PICKS_LENGTH = 20;
const CURRENT_PAGE_DEFAULT = 1;

export default mixins(LabelMixin).extend({
  name: 'ResourcesView',
  props: {
    categories: Array as PropType<number[] | null>,
    capabilities: Array as PropType<('has_video' | 'has_link' | 'has_document')[] | null>,
    sort: Array as PropType<('title' | '-title' | 'pubblication_date' | '-pubblication_date')[] | null>,
    tags: Array as PropType<string[] | null>,
    page: Number,
  },
  components: {
    Breadcrumbs,
    Filters,
    ResourceListElementLayout,
  },
  asyncComputed: {
    async category(): Promise<Category | null> {
      if(this.categories == null || this.categories.length == 0) return null
      const settingsModule = getModule(SettingsModule, this.$store);
      const container = extractContainer(this);
      const categoriesClient =  container.get<CategoryClient>(TYPES.CategoryClient);
      return await categoriesClient.getById(settingsModule.language,this.categories[0])
    },
  },
  created(){
    getModule(UsersModule,this.$store).dispatchUserMention()
  },
  computed: {
    currentPage(): number {
      return this.page || CURRENT_PAGE_DEFAULT
    },
    editorPicks(): ResourceLightResponse[] {
      return this.resources.filter(r => r.editor_pick);
    },
    listOptions(): ListOptionRequest {
      const sortOptions = this.sort && this.sort.length > 0 ? 
      this.sort.map(s => ({ column: s.replace(/^-/, ""), order: s.startsWith('-') ? 'desc' as const : 'asc' as const })) : [];
      return {
        filter: [
          ...flatten([
            this.categories && this.categories.length > 0 && {column: 'category_id', operator: 'in', value: this.categories.join(',')},
            this.capabilities && this.capabilities.length > 0 && this.capabilities.map(c => ({ column: c, operator: "=", value: "true" })),
            this.tags && this.tags.length > 0 && this.tags.map(t => ({ column: "tags", operator: "like", value: t })),
            {column: "has_expired", operator: "=", value: "false"}
          ]).filter(f => !!f) as FilterOption[],
        ],
        sort: sortOptions,
        paging: {pageLength: PAGE_LENGTH, currentPage: this.currentPage},
      };
    },
  },
  watch: {
    listOptions: {
      immediate: true,
      async handler(listOptions: ListOptionRequest) {
        this.loadMoreCounter = 0;
        this.resources = await this.fetchResources(listOptions);
        this.count = (await this.countResources(listOptions)).count;
      }
    }
  },
  methods: {
    onFilterChange(selected: FilterValue[]) {
      const categories = this.categories?.join(',') ?? null;
      const sort = selected.filter(f => f.sort)
            .map(f => f.sort as SortOption)
            .map((s: SortOption) => `${s.order === 'desc' ? '-' : ''}${s.column}`)
            .join(',');
      const capabilities = selected
        .filter(f => f.filter && f.filter?.column !== 'tags')
        .map(f => f.filter?.column as string)
        .join(',');
      const tags = selected
        .filter(f => f.filter && f.filter.column === 'tags')
        .map(f => f.filter?.value as string)
        .join(',');
      this.$router.push({
        path: RESOURCES_PATH,
        query: pickBy({
          sort,
          capabilities,
          tags,
          categories,
        }, identity),
      });
    },
    async loadMore() {
      if (this.loadMoreCounter === null){
        return;
      }
      this.loadMoreCounter += 1;
      const listOptionWithNewPage = {
        ...this.listOptions,
        paging: {
          ...this.listOptions.paging,
          currentPage: this.listOptions.paging.currentPage + this.loadMoreCounter,
        },
      };
      const moreResources = await this.fetchResources(listOptionWithNewPage);

      if (moreResources.length === 0) {
        // No more content
        this.loadMoreCounter = null;
        return;
      }

      this.resources = [
        ...this.resources,
        ...moreResources,
      ];
    },
    async fetchResources(listOptions: ListOptionRequest): Promise<ResourceLightResponse[]> {
        this.status = 'LOADING';
        const settingsModule = getModule(SettingsModule, this.$store);
        const container = extractContainer(this);
        const resourceClient = container.get<ResourceClient>(TYPES.ResourceClient);
        const resources = await resourceClient.getList(settingsModule.language, listOptions);
        this.status = 'SUCCESS';
        return resources;
    },
    async countResources(listOptions: ListOptionRequest): Promise<CountResponse> {
        const settingsModule = getModule(SettingsModule, this.$store);
        const container = extractContainer(this);
        const resourceClient = container.get<ResourceClient>(TYPES.ResourceClient);
        return await resourceClient.getListCount(settingsModule.language, listOptions);
    }
  },
  data(): {
    status: DownloadStatus; 
    resources: ResourceLightResponse[]; 
    loadMoreCounter: number | null;
    count: number;
    breadcrumbsItems: BreadcrumbsItems[],
  } {
    return {
      status: 'NOT_YET',
      resources: [],
      loadMoreCounter: 0,
      count: 0,
      breadcrumbsItems: [
        { url: '/resources', label: 'Resources' },
        // { url: '/resources/category', label: 'Category' }  // AM If category send category route and title
      ],
    };
  },
  inject: ["container"],
});
