
import Vue, { PropType } from 'vue';
import { mapGetters, mapState } from 'vuex';
import { getModule } from 'vuex-module-decorators';
import ValuesModule from '../../store/modules/values';
import videojs, { VideoJsPlayer } from 'video.js';
import VideoPlayerSubtitles from '@/components/ui/VideoPlayerSubtitles.vue';
import { Subtitle } from '@/client/user-film.client';


export interface BreadcrumbsItems {
    url:string;
    name:string;
}



export default Vue.extend({
  name: 'VideoPlayer',
  props: {
    video: {
      type: String,
      required: true
    },
    subtitles: {
      type: Array as PropType<Subtitle[]>,
      required: false
    }
  },
  components: {
    VideoPlayerSubtitles
  },
  data(): { 
      player: VideoJsPlayer | null,
    } {
    return {
      player: null
    }
  },
  mounted() {

      const tech = this.video.split(':')[0];
      const videoid = this.video.split(':').slice(1).join(':');

      function videoType() {
        switch(tech) {
          case 'm3u8': return 'application/x-mpegURL' 
          default: return "video/"+tech
        }
      }
  
      function url() {
        switch(tech) {
          case 'youtube': return "https://www.youtube.com/watch?v=" + videoid
          case 'vimeo': return "https://vimeo.com/" + videoid
          default: return videoid
        }
      }
  
      let options:any = { 
                "aspectRatio": "16:9",
                "sources": [
                  { 
                    type: videoType(), 
                    src: url()
                  }
                ], 
                "youtube": { "iv_load_policy": 1 },
                "fill": true
      }

      if(tech != 'm3u8') {
        options["techOrder"]=[tech]
      }
      
      this.player = videojs(this.$refs.videoPlayer as Element, options, function onPlayerReady() {
            
      })
      
      this.player.on('pause', (event) => {
        setTimeout(() => {
          if(this.player && this.player.paused()) {
            this.$emit('onPause')
          }
        }, 200);
      })
      
  },
  beforeDestroy() {
      if (this.player) {
          this.player.dispose()
      }
  },
  methods: {
    playVideo() {
      if(this.player) {
        this.player.play()
      }
    },
    stopVideo() {
      if(this.player) {
        this.player.pause()
      }
    },
  }
});
