const TYPES = {
  Environment: Symbol.for('Environment'),
  AuthClient: Symbol.for('AuthClient'),
  LabelClient: Symbol.for('LabelClient'),
  CategoryClient: Symbol.for('CategoryClient'),
  AxiosStatic: Symbol.for('AxiosStatic'),
  RootStore: Symbol.for('RootStore'),
  ResourceClient: Symbol.for('ResourceClient'),
  ForumCategoryClient: Symbol.for('ForumCategoryClient'),
  ForumCommentClient: Symbol.for('ForumCommentClient'),
  ResourceCommentClient: Symbol.for('ResourceCommentClient'),
  NotificationMessageClient: Symbol.for('NotificationMessageClient'),
  UserClient: Symbol.for('UserClient'),
  ValueClient: Symbol.for('ValueClient'),
  UserFilmClient: Symbol.for('UserFilmClient'),
  DownloadClient: Symbol.for('DownloadClient'),
  SuggestionClient: Symbol.for('SuggestionClient'),
  AccreditationClient: Symbol.for('AccreditationClient'),
  ForumNewTopicClient: Symbol.for('ForumNewTopicClient'),
  ForumSuggestionClient: Symbol.for('ForumSuggestionClient'),
  PartnerClient: Symbol.for('PartnerClient'),
  OrganizationClient: Symbol.for('OrganizationClient'),
  CompleteInviteClient: Symbol.for('CompleteInviteClient'),
  PageClient: Symbol.for('PageClient'),
  BannerClient: Symbol.for('BannerClient'),
  Site: Symbol.for('Site'),
};

export default TYPES;
