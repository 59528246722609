
import Vue, { PropType } from 'vue';
import { mixin as clickaway } from 'vue-clickaway';

import Breadcrumbs, { BreadcrumbsItems } from '@/components/Breadcrumbs.vue';
import { getModule } from 'vuex-module-decorators';
import ResourceClient, { ResourceLightResponse } from '../client/resource.client';
import ForumCommentClient, { ForumComment } from '../client/forum-comment.client';
import ResourcesModule from '../store/modules/resources';
import ProfileResourcesList from '@/components/ProfileResourcesList.vue';
import ProfileResourcesListMobile from '@/components/ProfileResourcesListMobile.vue';

import NotificationMessageClient, { NotificationResponse } from '../client/notification-center.client';

import ProfileNotificationsList from '@/components/ProfileNotificationsList.vue';

import ProfileSocial from '@/components/ui/ProfileSocial.vue';
import OdImage from '@/components/ui/OdImage.vue';
import OdDownloadButton from '@/components/ui/OdDownloadButton.vue';
import { UserResponse, Film, DefaultListOptionRequest, ListOptionRequest } from '../client/user.client';
import UsersModule from '@/store/modules/users';
import ValuesModule from '@/store/modules/values';
import NotificationModule from '../store/modules/notifications';
import { format } from 'date-fns';
import { extractContainer } from '../ioc/util';
import TYPES from '../ioc/types';
import DownloadClient, { DownloadStatus } from '@/client/download.client';

import { Label } from '../client/label.client';
import LabelsModule from '@/store/modules/labels';
import mixins from 'vue-typed-mixins'
import LabelMixin from '../mixins/labels';
import SettingsModule from '@/store/modules/settings';

const FILMOGRAPHY_COUNT = 3;

export default mixins(LabelMixin).extend({
  name: 'Profile',
  mixins: [clickaway],
  props: {
    id: Number,
    chapter: String
  },
  components: {
    Breadcrumbs, 
    ProfileResourcesList,
    ProfileNotificationsList,
    ProfileResourcesListMobile,
    ProfileSocial,
    OdImage,
    OdDownloadButton,
  },
  async created() {
    getModule(UsersModule, this.$store).dispatchGetById(this.id);
    await getModule(NotificationModule, this.$store).load();
  },
  asyncComputed: {
    async chapterResources(): Promise<ResourceLightResponse[] | Film[] | NotificationResponse[]> {
      switch(this.chapter) {
        case 'filmography': return this.filmographyResources;
        case 'recently-viewed': return await this.recent();
        case 'your-resources': return await this.own();
        case 'favorites-resources': return await this.favorites(this.chapter);
        case 'favorites-agenda': return await this.favoritesAgenda(this.chapter);
        case 'favorites-forum': return await this.favorites(this.chapter);
        /*
        case 'notifications-resources': return await this.recent();
        case 'notifications-forum': return await this.notificationsForum(this.chapter);
        */
        default: return this.filmographyResources;
      }
    },
    async recentResources(): Promise<ResourceLightResponse[]> {
      return this.recent()
    },
    async ownResources(): Promise<ResourceLightResponse[]> {
      return this.own()
    },
    async favoritesResources(): Promise<ResourceLightResponse[]> {
      return this.favorites(this.chapter) // hack to recalculate at every change of chapter
    },
    async favoritesAgendaResources(): Promise<ResourceLightResponse[]> {
      return this.favoritesAgenda(this.chapter) // hack to recalculate at every change of chapter
    },
    async favoritesForumList(): Promise<ForumComment[]> {
      return this.favoritesForum(this.chapter) // hack to recalculate at every change of chapter
    },
    /*
    async ownNotificationForum(): Promise<NotificationResponse[]> {
      return this.notificationsForum(this.chapter)
    },
    async ownNotificationResource(): Promise<NotificationResponse[]> {
      return this.notificationsResource(this.chapter)
    },
    */
  },
  computed: {
    title(): string {
      switch(this.chapter) {
        case 'filmography': return this.i18n('profile.filmography.title');
        case 'recently-viewed': return this.i18n('profile.recently.viewed.title');
        case 'your-resources': return this.i18n('profile.your.resources.title');
        case 'favorites-resources': return this.i18n('profile.favorites.title') + " " +  this.i18n('resources');
        case 'favorites-agenda': return this.i18n('profile.favorites.title') + " " +  this.i18n('agenda');
        case 'favorites-forum': return this.i18n('profile.favorites.title') + " " +  this.i18n('forum');
        case 'notifications-resources': return this.i18n('profile.notifications.resources.breadcrumbs');
        case 'notifications-forum': return this.i18n('profile.notifications.forum.breadcrumbs');
        default: return this.i18n('profile.filmography.title');
      }
    },
    breadcrumbsItems(): BreadcrumbsItems[] {
      return [
        { url: '/profile/'+this.id, label: 'Profile' },
        { url: '/profile/'+this.id+'/'+this.chapter, label: this.title }
      ]

    },
    filmographyResources(): Film[] {
      if(this.profile) {
        return this.profile.films;
      } else {
        return [];
      }
    },
    isOwner(): boolean {
      return this.id === getModule(UsersModule, this.$store).me?.id;
    },
    profile(): UserResponse | undefined {
      return getModule(UsersModule, this.$store).selectById(this.id);
    },
    
  },
  filters: {
    formatDate(date: Date): string {
      if (!date) {
        return '';
      }
      return format(date, 'd/M/yyyy');
    } 
  },
  methods: {
    formatWebsite(website: string) {
        // JavaScript/TypeScript
        if (website.substring(0, 7) !== 'http://' && website.substring(0, 8) !== 'https://'){
          website = 'http://' + website;
        }
          return website;
    },
    countryName(id: number): string {
      return getModule(ValuesModule, this.$store).getCountryName(id);
    },
    professionName(id: number): string {
      return getModule(ValuesModule, this.$store).getProfessionName(id);
    },
    toggleFavoriteSubmenu() {
        this.isNotificationsSubmenuOpen = false;
        this.isFavoriteSubmenuOpen = !this.isFavoriteSubmenuOpen;       
    },
    closeFavoriteSubmenu() {
      this.isFavoriteSubmenuOpen = false;
    },
    toggleNotificationsSubmenu() {
        this.isFavoriteSubmenuOpen = false;
        this.isNotificationsSubmenuOpen = !this.isNotificationsSubmenuOpen;       
    },
    closeNotificationsSubmenu() {
      this.isNotificationsSubmenuOpen = false;
    },
    hasSocial(profile: UserResponse | undefined): boolean {
      return profile ? profile.socials.some(x => x.value.length > 0) : false;
    },
    async recent(): Promise<ResourceLightResponse[]> {
      const settingsModule = getModule(SettingsModule, this.$store);
      const container = extractContainer(this);
      const resourcesClient =  container.get<ResourceClient>(TYPES.ResourceClient);
      return await resourcesClient.getRecentView(settingsModule.language,10);
    },
    async own(): Promise<ResourceLightResponse[]> {
      const settingsModule = getModule(SettingsModule, this.$store);
      const container = extractContainer(this);
      const resourcesClient =  container.get<ResourceClient>(TYPES.ResourceClient);
      return await resourcesClient.getOwn(settingsModule.language,DefaultListOptionRequest);
    },
    async favoritesAgenda(str: string): Promise<ResourceLightResponse[]> {
      const settingsModule = getModule(SettingsModule, this.$store);
      const container = extractContainer(this);
      const resourcesClient =  container.get<ResourceClient>(TYPES.ResourceClient);
      const request: ListOptionRequest = {
        ...DefaultListOptionRequest,
        filter: [{column: 'category_id',operator:'=', value: '7'}]
      }
      return await resourcesClient.getFavorites(settingsModule.language,request);
    },
    async favoritesForum(str: string): Promise<ForumComment[]> {
      const settingsModule = getModule(SettingsModule, this.$store);
      const container = extractContainer(this);
      const ForumCommentClient =  container.get<ForumCommentClient>(TYPES.ForumCommentClient);
      return await ForumCommentClient.getFavorites(settingsModule.language);
    },
    async favorites(str: string): Promise<ResourceLightResponse[]> {
      const settingsModule = getModule(SettingsModule, this.$store);
      const container = extractContainer(this);
      const resourcesClient =  container.get<ResourceClient>(TYPES.ResourceClient);
      const request: ListOptionRequest = {
        ...DefaultListOptionRequest,
        filter: [{column: 'category_id',operator:'not', value: '7'}]
      }
      return await resourcesClient.getFavorites(settingsModule.language,request);
    }
  },
  data(): {
    isFavoriteSubmenuOpen: boolean;
    isNotificationsSubmenuOpen: boolean;
  } {
    return {
      isFavoriteSubmenuOpen: false,
      isNotificationsSubmenuOpen: false,
    };
  },
  inject: ['container'],
});
