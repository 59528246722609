
import { getModule } from 'vuex-module-decorators';
import mixins from 'vue-typed-mixins'
import LabelMixin from '@/mixins/labels';
import UsersModule from '@/store/modules/users';
import { UserResponse } from '@/client/user.client';

export default mixins(LabelMixin).extend({
  beforeCreate: function() {
    document.body.className = 'login';
  },
  name: "WrongLoginView",
  inject: ["container"],
  components: {
    
  },
  methods: {
    async register() {
      let me:UserResponse | null = null;
      try{
        me = await getModule(UsersModule, this.$store).dispatchGetMe();
      } catch(e) {}
      if(me) {
        this.$router.push("/profile/accreditation/"+me.id)
      } else {
        this.$router.push("/splash")
      }
    }
  }
});
