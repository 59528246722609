
import Vue, { PropType } from 'vue';
import ResourceListElementLayout from '@/components/ResourceListElementLayout.vue';
import FilmLayout from '@/components/FilmLayout.vue';
import { ResourceLightResponse } from '@/client/resource.client';
import ForumCommentClient, { ForumComment } from '../client/forum-comment.client';
import { UserResponse, Film } from '@/client/user.client';

import TopicLayout from '@/components/TopicLayout.vue';

import { Label } from '../client/label.client';
import LabelsModule from '@/store/modules/labels';
import mixins from 'vue-typed-mixins'
import LabelMixin from '../mixins/labels';

export default mixins(LabelMixin).extend({
  name: 'ProfileResourcesList',
  props: {
    isOwner: Boolean,
    listClass: {
      type: String,
      validator: (c:string) => [
        'filmography',
        'recently-viewed',
        'your-resources',
        'favorites-resources',
        'favorites-agenda',  
        'favorites-forum',  
        'notifications-resources',  
        'notifications-forum', 
        'none',     
      ].includes(c),
    },
    resources: {
      type: Array as PropType<ResourceLightResponse[] | Film[] | ForumComment[]>,
      required: true
    },
  },
  components: {
    ResourceListElementLayout,
    FilmLayout,
    TopicLayout
  },
});
