
import Vue, { PropType } from "vue";

import { Label } from '../../client/label.client';
import LabelsModule from '@/store/modules/labels';
import mixins from 'vue-typed-mixins'
import LabelMixin from '../../mixins/labels';
import ResourceClient, { ResourceLightResponse, ResourceResponse } from '../../client/resource.client';
import { getModule } from 'vuex-module-decorators';
import SettingsModule from '@/store/modules/settings';
import { extractContainer } from '@/ioc/util';
import TYPES from '@/ioc/types';
import UserClient from '@/client/user.client';

export default mixins(LabelMixin).extend({
  name: "Favorite",
  props: {
    resource: {
      required: true,
      type: Object as PropType<ResourceLightResponse | ResourceResponse>,
    },
  },
  methods: {
    async addToFavorites() {
      this.resource.is_favorite = true
      const settingsModule = getModule(SettingsModule, this.$store);
      const container = extractContainer(this);
      const resourcesClient =  container.get<ResourceClient>(TYPES.ResourceClient);
      const user = await container.get<UserClient>(TYPES.UserClient).getMe(settingsModule.language);
      if(user) {
        await resourcesClient.setFavorite(settingsModule.language,this.resource.id,user.id)
      }
    },
    async removeFromFavorites() {
      this.resource.is_favorite = false
      const settingsModule = getModule(SettingsModule, this.$store);
      const container = extractContainer(this);
      const resourcesClient =  container.get<ResourceClient>(TYPES.ResourceClient);
      const user = await container.get<UserClient>(TYPES.UserClient).getMe(settingsModule.language);
      if(user) {
        await resourcesClient.unsetFavorite(settingsModule.language,this.resource.id,user.id)
      }
    }

  },
  inject: ['container'],
});
