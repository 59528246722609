
import Vue, { PropType } from 'vue'
import FloatLabel from "vue-float-label/components/FloatLabel.vue";
import { Film, Cv, DefaultListOptionRequest, UserResponse } from '@/client/user.client';
import {sanitizeNumber} from '../../service/form';
import mixins from 'vue-typed-mixins';
import LabelMixin from '@/mixins/labels';
import { getModule } from 'vuex-module-decorators';
import ValuesModule from '@/store/modules/values';
import CategoriesModule from '@/store/modules/categories';
import { CategoryLight } from '@/client/category.client';
import UsersModule from '@/store/modules/users';
import { NamedBlob } from '@/client/download.client';
import { Suggestion, emptySuggestion } from '@/client/suggestion.client';

function sanitizeSuggestionForm(suggestion: Suggestion): Suggestion {
  return {
    ...suggestion
  }
}

export interface SubmitFormEmit {
  suggestion: Suggestion;
  file: NamedBlob | null;
}

export default mixins(LabelMixin).extend({
  components: {
    FloatLabel
  },
  async created() {
    await getModule(CategoriesModule,this.$store).dispatchGetList()
    await getModule(UsersModule, this.$store).dispatchGetMe();
  },
  computed: {
    categories():CategoryLight[] {
      return getModule(CategoriesModule,this.$store).allLight
    },
    user():UserResponse | null {
      return getModule(UsersModule,this.$store).me
    }
  },
  methods: {
    uploadFile() {
      (this.$refs.resourceFile as HTMLInputElement).click()
    },
    onCancel(event: MouseEvent) {
      event.preventDefault();
      this.$emit('onCancel');
    },
    onSubmitForm(event: Event) {
      event.preventDefault();
      this.loadingForm = true;
      const submitEmit: SubmitFormEmit = {
        suggestion: sanitizeSuggestionForm(this.suggestion),
        file: this.newFile,
      };
      this.$emit('onSubmit', submitEmit);
    },
    onFileChange(event: Event) {
      const target: EventTarget | null = event?.target;
      if (!target) {
        return;
      }

      const file: File = ((target as HTMLInputElement).files ?? [])[0];

      if (!file) {
        return;
      }

      this.newFile = {
          name: file.name,
          blob: file
      }
    }
  },
  data(): { 
    newFile: NamedBlob | null,
    suggestion: Suggestion,
    loadingForm: boolean ,
  } {
    return {
      newFile: null,
      suggestion: emptySuggestion(getModule(UsersModule,this.$store).me?.id || -1),
      loadingForm: false,
    };
  }
})
