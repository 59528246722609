
import Vue from "vue";
import { getModule } from 'vuex-module-decorators';
import {  HOME_PATH } from '../../router/toolbox';

import mixins from 'vue-typed-mixins'
import LabelMixin from '../../mixins/labels';
import UsersModule from '@/store/modules/users';


export default mixins(LabelMixin).extend({
  beforeCreate: function() {
    document.body.className = 'stepaway';
  },
  name: "stepAwayHeritage",
  inject: ["container"],
  data() {
    return {
      link: "",
    };
  },
  components: {
  },
  async created() {
    await getModule(UsersModule, this.$store).dispatchGetMe()
    const link = getModule(UsersModule, this.$store).paymentLink
    if(link) {
      this.link = link
    }
    if(getModule(UsersModule, this.$store).heritageFull) {
      this.$router.push(HOME_PATH)
    }
  },
  computed: {

  },
  methods: {
    
  }
});
