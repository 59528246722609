
import Vue, { PropType } from 'vue';

import { Label } from '@/client/label.client';
import LabelsModule from '@/store/modules/labels';
import mixins from 'vue-typed-mixins'
import LabelMixin from '@/mixins/labels';
import { sanitizeNumber } from '@/service/form';

import { getModule } from 'vuex-module-decorators';

import CommentsChild from '@/components/CommentsChild.vue';
import UsersModule from '@/store/modules/users';

import Swiper, { SwiperOptions } from 'swiper';
import SettingsModule from '@/store/modules/settings';
import { extractContainer } from '@/ioc/util';
import TYPES from '@/ioc/types';

import ResourceCommentClient, { emptyComment,emptyCommentInternal, ResourceComment, SendComment } from '@/client/resource-comment.client';


import ResourceSubDiscussion, {
    SubmitSubFormEmit
} from '@/components/forms/ResourceSubDiscussion.vue';

import UserMention from '@/components/ui/UserMention.vue';

function sanitizeCommentForm(comment: SendComment): SendComment {
  return {
    ...comment
  }
}

export interface SubmitFormEmit {
  comment: SendComment;
}

export default mixins(LabelMixin).extend({
  name: 'ResourceDiscussion',
  components: {
    CommentsChild,
    ResourceSubDiscussion,
    UserMention
  },
  async created() {
    await getModule(UsersModule, this.$store).dispatchGetMe();
    //godown
    if(this.lastId != 0){
      setTimeout(() => this.scrollToElement(), 1000);
    }
  },
  inject: ['container'],
  props: {
    comments: {
      type: Array as PropType<ResourceComment[]>,
    },
    resource_id: { 
      type: Number 
    },
    parent_id: {
      type: Number,
    },
    id_comment:{
      type: Number
    },
    lastId:{
      type: Number
    },
    type: Number,
    id_parent: Number,
  },
  asyncComputed: {
    
    async getCommentsByParent(): Promise<ResourceComment[] | null> {
      const settingsModule = getModule(SettingsModule, this.$store);
      const container = extractContainer(this);
      const ResourceCommentClient = container.get<ResourceCommentClient>(TYPES.ResourceCommentClient);
      return await ResourceCommentClient.getCommentsByParent(settingsModule.language,this.resource_id);
    },
    
  },
  methods: {
    setMentionState(val: boolean){
      this.mentionState = val;
    },
    newline() {
      //this.comment.body = `${this.comment.body}<br/>`;
    },
    send() {
      if(this.comment.body != "" && !this.mentionState){
        this.onSubmitForm();
      }else{
        this.mentionState = false;
      }
    },
    onSubmitForm() {
      //event.preventDefault();
      this.loadingForm = true;
      const submitEmit: SubmitFormEmit = {
        comment: sanitizeCommentForm(this.comment)
      };
      this.$emit('onSubmit', submitEmit);
    },
    onSubmitFormInternal(event: Event, parent_id: number, resource_id: number) {
      event.preventDefault();
      this.comment.parent_id = parent_id;
      this.loadingForm = true;
      const submitEmit: SubmitFormEmit = {
        comment: sanitizeCommentForm(this.comment)
      };
      this.$emit('onSubmit', submitEmit);
    },
    scrollToElement() {
      const el = this.$el.getElementsByClassName('lastElementofList')[0];

      if (el) {
        el.scrollIntoView();
      }
    },
    showComments(event: Event, id: Number){
      event.preventDefault();      
      const element = document.getElementById("p"+id);
      if(element !== null){
        element.classList.add("active");
      }
    },
    scrollToInput(event: Event, classMove: string){
      const element_2 = document.getElementById("textarea");
      if(element_2 !== null){
        this.$scrollTo('#textarea', 500, { easing: 'ease-in-out', offset: -200})
        element_2.focus();
      }
    },
    async onCommentSubmit(submit: SubmitFormEmit) {
        const { comment } = submit;
        const container = extractContainer(this);
        const settingsModule = getModule(SettingsModule, this.$store);

        const ResourceCommentClient = container.get<ResourceCommentClient>(TYPES.ResourceCommentClient);
        this.lastIdInner = await ResourceCommentClient.create(settingsModule.language, comment)
        //this.showForm = false;
        /*
        console.log("p"+comment.parent_id);

        const element2 = document.getElementById("p"+comment.parent_id);
        if(element2 !== null){
          element2.classList.add("active");
        }
        */
        
      },
  },
  data(): {
    comment: SendComment,
    loadingForm: boolean,
    commentToSend: SendComment,
    lastIdInner: number,
    mentionState: boolean
  } {
    return {
      comment: emptyComment(getModule(UsersModule,this.$store).me?.id || -1,this.parent_id,this.resource_id),
      loadingForm: false,
      commentToSend: emptyComment(getModule(UsersModule,this.$store).me?.id || -1,this.parent_id,this.resource_id),
      lastIdInner: 0,
      mentionState: false
    };
  },
});
