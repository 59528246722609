
import Vue, { PropType } from 'vue';
import ResourcesModule from '@/store/modules/resources';
import { getModule } from 'vuex-module-decorators';
import Breadcrumbs, { BreadcrumbsItems } from '@/components/Breadcrumbs.vue';
import FiltersForum from '@/components/FiltersForum.vue';
import TopicLayout from '@/components/TopicLayout.vue';
import UserClient, { UserResponse } from '../client/user.client';
import UsersModule from '../store/modules/users';
import FavoriteForum from '@/components/ui/FavoriteForum.vue';
import TagResolver from '../components/containers/TagResolver.vue';
import { formatPublication, formatDateRange } from '@/service/ui';
import { format } from 'date-fns';
import { DownloadStatus } from '@/client/download.client';
import { ListOptionRequest, FilterOption, SortOption, CountResponse } from '../client/user.client';
import ResourceClient from '../client/resource.client';
import { flatten, pickBy, identity } from 'lodash';
import { FORUM_PATH, RESOURCES_PATH } from '@/router/toolbox';

import { Label } from '../client/label.client';
import PartnerClient, { Partner } from '../client/partner.client';
import ForumCommentClient, { ForumComment } from '../client/forum-comment.client';
import LabelsModule from '@/store/modules/labels';
import mixins from 'vue-typed-mixins'
import LabelMixin from '../mixins/labels';
import SettingsModule from '@/store/modules/settings';
import { FilterValue } from '../components/Filters.vue';
import { extractContainer } from '@/ioc/util';
import TYPES from '@/ioc/types';
import ForumCategoryClient, { ForumCategory } from '@/client/forum-category.client';

const PAGE_LENGTH = 50;
const CURRENT_PAGE_DEFAULT = 1;

export default mixins(LabelMixin).extend({
  name: 'ForumView',
  props: {
    id: Number,
    sort: Array as PropType<('title' | '-title' | 'pubblication_date' | '-pubblication_date' | 'comment_count' | '-comment_count')[] | null>,
    page: Number,
  },
  components: {
    Breadcrumbs,
    FiltersForum,
    TopicLayout,
  },
  inject: ['container'],
  async mounted() {

    const settingsModule = getModule(SettingsModule, this.$store);
    const container = extractContainer(this);    

    const forumCategoryClient = container.get<ForumCategoryClient>(TYPES.ForumCategoryClient);
    this.category = await forumCategoryClient.getById(settingsModule.language,this.id)

    this.breadcrumbsItems.push({ url: '/forum/'+this.id, label: this.category.name })

  },
  filters: {
    formatDate(date: Date): string {
      return formatPublication(date);
    },
  },
  computed: {
    currentPage(): number {
      return this.page || CURRENT_PAGE_DEFAULT
    },
    listOptions(): ListOptionRequest {
      console.log(this.sort)
      const sortOptions = this.sort && this.sort.length > 0 ? 
      this.sort.map(s => ({ column: s.replace(/^-/, ""), order: s.startsWith('-') ? 'desc' as const : 'asc' as const })) : [];
      return {
        filter: [],
        sort: sortOptions,
        paging: {pageLength: PAGE_LENGTH, currentPage: this.currentPage},
      };
    },
  },
  watch: {
    listOptions: {
      immediate: true,
      async handler(listOptions: ListOptionRequest) {
        console.log('AAAAAAA')
        this.loadMoreCounter = 0;
        this.forumTopics = await this.fetchTopics(listOptions);
        this.count = (await this.countTopics(listOptions)).count;
      }
    }
  },
  methods: {
    onFilterChange(selected: FilterValue[]) {
      const sort = selected.filter(f => f.sort)
            .map(f => f.sort as SortOption)
            .map((s: SortOption) => `${s.order === 'desc' ? '-' : ''}${s.column}`)
            .join(',');
      this.$router.push({
        path: FORUM_PATH + "/" + this.id,
        query: pickBy({
          sort
        }, identity),
      });
    },
    async loadMore() {
      if (this.loadMoreCounter === null){
        return;
      }
      this.loadMoreCounter += 1;
      const listOptionWithNewPage = {
        ...this.listOptions,
        paging: {
          ...this.listOptions.paging,
          currentPage: this.listOptions.paging.currentPage + this.loadMoreCounter,
        },
      };
      const moreResources = await this.fetchTopics(listOptionWithNewPage);

      if (moreResources.length === 0) {
        // No more content
        this.loadMoreCounter = null;
        return;
      }

      this.forumTopics = [
        ...this.forumTopics,
        ...moreResources,
      ];
    },
    async fetchTopics(listOptions: ListOptionRequest): Promise<ForumComment[]> {
        this.status = 'LOADING';
        const settingsModule = getModule(SettingsModule, this.$store);
        const container = extractContainer(this);
        const forumCommentClient = container.get<ForumCommentClient>(TYPES.ForumCommentClient);
        const topics =  await forumCommentClient.getTopicsByCategory(settingsModule.language,this.id,listOptions.sort);
        this.status = 'SUCCESS';
        return topics;
    },
    async countTopics(listOptions: ListOptionRequest): Promise<CountResponse> {
        const settingsModule = getModule(SettingsModule, this.$store);
        const container = extractContainer(this);
        const forumCommentClient = container.get<ForumCommentClient>(TYPES.ForumCommentClient);
        return await forumCommentClient.getListCount(settingsModule.language,this.id,listOptions.sort);
    }
  },
  data(): {
    status: DownloadStatus;
    forumTopics: ForumComment[]
    category:ForumCategory | null
    breadcrumbsItems: BreadcrumbsItems[]
    loadMoreCounter: number | null,
    count: number
  } { 
    return {
      status: 'NOT_YET',
      forumTopics: [],
      category: null,
      breadcrumbsItems: [
        { url: '/forum', label: 'Forum Area' }
      ],
      loadMoreCounter: 0,
      count: 0
    };
  },
});

