
import Vue from "vue";
import Footer from "@/components/ui/Footer.vue";
import { getModule } from "vuex-module-decorators";

export default Vue.extend({
  name: "FooterContainer",
  components: {
    Footer,
  },
});
