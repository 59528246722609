
import Vue, { PropType } from 'vue';
import NotificationLayout from '@/components/NotificationLayout.vue';
import { UserResponse, Film } from '@/client/user.client';
import { getModule } from 'vuex-module-decorators';



import NotificationMessageClient, { NotificationResponse } from '@/client/notification-center.client';

import { Label } from '../client/label.client';
import LabelsModule from '@/store/modules/labels';
import mixins from 'vue-typed-mixins'
import LabelMixin from '../mixins/labels';
import UsersModule from '../store/modules/users';
import SettingsModule from '@/store/modules/settings';
import { extractContainer } from '@/ioc/util';
import TYPES from '@/ioc/types';
import { mapState } from 'vuex';
import { RootState } from '@/store/modules/root';
import UserClient from '@/client/user.client';
import NotificationModule from '@/store/modules/notifications';

export default mixins(LabelMixin).extend({
  name: 'ProfileNotificationsList',
  props: {
    isOwner: Boolean,
    listClass: {
      type: String,
      validator: (c:string) => [
        'notifications-resources',  
        'notifications-forum', 
        'none',     
      ].includes(c),
    },
    type:{
      type: Number,
      required: true
    }
  },
  computed: mapState({
      notificationsResource: (state:any) => (state as RootState).notifications.listResource,
      notificationsForum: (state:any) => (state as RootState).notifications.listForum,
  }),
  components: {
    NotificationLayout
  },
  methods: {
    async markAllAsRead(event: Event){
      const settingsModule = getModule(SettingsModule, this.$store);
      const container = extractContainer(this);
      const notificationMessageClient =  container.get<NotificationMessageClient>(TYPES.NotificationMessageClient);
      const user = await container.get<UserClient>(TYPES.UserClient).getMe(settingsModule.language);
      await notificationMessageClient.updateAllReaded(settingsModule.language,this.notificationsResource, this.notificationsForum,this.type);
      getModule(NotificationModule, this.$store).removeAllNotifications(this.type);
      /*
      event.preventDefault();
      const settingsModule = getModule(SettingsModule, this.$store);
      const container = extractContainer(this);
      const notificationMessageClient =  container.get<NotificationMessageClient>(TYPES.NotificationMessageClient);
      const user = await container.get<UserClient>(TYPES.UserClient).getMe(settingsModule.language);
      if(user) {
        console.log("markAllAsRead");
        await notificationMessageClient.updateAllReaded(settingsModule.language,this.notifications,this.type);
        if(this.type == 1){
          this.showAllNotificationResource = false;
          this.notificationSize = 0;
        }else{
          this.showAllNotificationForum = false;
          this.notificationSize = 0;
        }
      }
      */
    }
  },
  data(): {
    showAllNotificationResource: boolean,
    showAllNotificationForum: boolean
  } {
    return {
      showAllNotificationResource: true,
      showAllNotificationForum: true
    }
  },
  inject: ['container'],
});
