
import Vue, { PropType } from 'vue';
import RightholderLayout from '@/components/heritage/RightholderLayout.vue';
import { getModule } from 'vuex-module-decorators';
import Filters, { FilterValue } from '@/components/heritage/Filters.vue';
import { DEFAULT_LIST_OPTION_REQUEST } from '@/client/category.client';
import CategoriesModule from '../../store/modules/categories';
import UserClient, { DefaultListOptionRequest, Film, UserResponse } from '@/client/user.client';
import { extractContainer } from '@/ioc/util';
import SettingsModule from '@/store/modules/settings';
import { ListOptionRequest, FilterOption, SortOption, CountResponse } from '../../client/user.client';
import TYPES from '@/ioc/types';
import { SiteConfig } from '../../client/value.client';
import ValuesModule from '../../store/modules/values';
import UserFilmClient from '../../client/user-film.client';
import { DownloadStatus } from '@/client/download.client';

import { Label } from '../../client/label.client';
import LabelsModule from '@/store/modules/labels';
import mixins from 'vue-typed-mixins'
import LabelMixin from '../../mixins/labels';
import { flatten, pickBy, identity } from 'lodash';
import { Organization, OrganizationLight, fromHeritageUser } from '../../client/organization.client';
import { filter } from 'vue/types/umd';

const PAGE_LENGTH = 20;
const PICKS_LENGTH = 20;
const CURRENT_PAGE_DEFAULT = 1;

export default mixins(LabelMixin).extend({
  name: 'RightholdersView',
  inject: ['container'],
  props: {
    page: Number,
  },
  components: {
    RightholderLayout,
    Filters,
  },
  beforeCreate: function() {
    document.body.className = '';
  },
  async created() {
    this.status = 'LOADING';
    const settingsModule = getModule(SettingsModule, this.$store);
    const container = extractContainer(this);
    const userClient =  container.get<UserClient>(TYPES.UserClient);
    const result = await userClient.getListHeritage(settingsModule.language,{currentPage: 1, pageLength: 1000}, {column: 'roles', operator: 'like', value:'JP-Heritage-Rightholder'});
    this.status = 'SUCCESS';
    this.rightHolders = result.map(fromHeritageUser).filter((v,i,a)=>a.findIndex(t=>(t.id === v.id))===i).filter(x => x.name ? true : false).sort((x,y) => x.name.localeCompare(y.name)) //removes duplicated
    
    /*const temp = this.rightHolders.map(x => {
      return getModule(ValuesModule, this.$store).countries.find(y => y.name_it === x.country )?.name || x.country;
    })
    //this.rightHolders = [...temp];

/*
    this.temp = this.rightHolders.map(x => {
      return getModule(ValuesModule, this.$store).countries.find(y => y.name_it === x.country )?.name || x.country;
    })
*/
    this.filteredRightHolders = [...this.rightHolders];
    this.main_activities = this.mainActivities(this.filteredRightHolders)
    
    
      this.filmCountries = [...new Set(this.rightHolders.flatMap(x => {
        //return x.country ? x.country.split(",").map(x => x.trim()) : []
        return x.country ? x.country.split(",").map(x => getModule(ValuesModule, this.$store).countries.find(y => y.name === x )?.name_it || x.trim()) : []
      }))].sort((x,y) => x.localeCompare(y))
    
      /*
      this.filmCountries = this.filmCountries.map(x => {
        console.log(x);
        return getModule(ValuesModule, this.$store).countries.find(y => y.name_it == x )?.name || x;
      })
      */
      this.loaded = true;
  },
  computed: {
    currentPage(): number {
      return this.page || CURRENT_PAGE_DEFAULT
    },
  },
  methods: {
    mainActivities(users:OrganizationLight[]):string[] {
      return [...new Set(users.flatMap(x => x.main_activities))].sort((x,y) => x.localeCompare(y))
    },
    onFilterChange(filters:FilterValue[]) {
      console.log(filters)
      let newRightHolders = [...this.rightHolders]
      let newFilter1:number[] = []
      let newFilter2:number[] = []
            
      // check if filter is active
        let checkFilters = false;
        filters.forEach(element => {
          if(element.filter) {
            checkFilters = true;
          }
        });
        console.log(filters.length);
        if(checkFilters){
          newRightHolders.splice(0);

        filters.forEach(element => {
          if(element.filter) {
            if(element.filter.column == "country"){
            //console.log(element.filter.column);
            let allData = [...this.rightHolders]
            let tempFilter = allData.filter(x => {
              return x.country ? (x.country as string).includes((element.filter?.value as string)) : false
            })
            tempFilter = allData.filter(x => {
              return x.country ? ((getModule(ValuesModule, this.$store).countries.find(y => y.name === x.country )?.name_it || x.country) as String).includes((element.filter?.value as string)) : false
            })
            //newRightHolders = newRightHolders.concat(tempFilter);
            tempFilter.forEach(x => {
              newFilter1.push(x.id)
            })
            }
            if(element.filter.column == "main_activity"){
            //console.log(element.filter.column);
            let allData = [...this.rightHolders]
            let tempFilter = allData.filter(x => {
              return x.main_activities.some( ma => ma === (element.filter?.value as string))
            })
            //newRightHolders = newRightHolders.concat(tempFilter);
            tempFilter.forEach(x => {
              newFilter2.push(x.id)
            })
            }
          }
        });
        let intersection:number[];
        if(newFilter1.length > 0){
          intersection = newFilter1;
        if(newFilter2.length > 0)
            intersection = intersection.filter(x => newFilter2.includes(x));
        }else if(newFilter2.length > 0){  
          intersection = newFilter2
        }
          newRightHolders = [...this.rightHolders];
          newRightHolders = newRightHolders.filter(x => {
            //console.log(x.id + " CHECK IN "+ intersection + " result: "+ intersection.includes(x.id));
            return intersection.includes(x.id);
          })
          //newRightHolders = [ ...new Set(newRightHolders)];
        }

      filters.forEach(element => {
        if(element.fullText) {
          const q = (element.fullText as string).toLowerCase()
          newRightHolders = newRightHolders.filter(x => {
            return x.name.toLowerCase().includes(q) || 
            x.country.toLowerCase().includes(q) || 
            x.main_activities.some(ma => ma.toLowerCase().includes(q))
          })
        }
        if(element.sort) {
          //console.log(element.sort)
          if(element.sort.order == "desc") {
            if(element.sort.column == "company") {
              newRightHolders = newRightHolders.sort((x,y) => -x.name.localeCompare(y.name))
            } else if(element.sort.column == "country") {
              newRightHolders = newRightHolders.sort((x,y) => -x.country.localeCompare(y.country))
            }
          } else {
            if(element.sort.column == "company") {
              newRightHolders = newRightHolders.sort((x,y) => x.name.localeCompare(y.name))
            } else if(element.sort.column == "country") {
              newRightHolders = newRightHolders.sort((x,y) => x.country.localeCompare(y.country))
            }
          }
        }
      });
      this.filteredRightHolders = newRightHolders
    },
  },
  data(): {
    status: DownloadStatus; 
    rightHolders: OrganizationLight[]
    filteredRightHolders: OrganizationLight[]
    main_activities: string[] | null
    filmCountries: String[]
    loaded: Boolean
  } {
    return {
      status: 'NOT_YET',
      rightHolders: [],
      filteredRightHolders: [],
      main_activities: null,
      filmCountries: [],
      loaded: false
    };
  }
});
