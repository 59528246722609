
import Vue from 'vue';
import FilmLayout from '@/components/FilmLayout.vue';
import { getModule } from 'vuex-module-decorators';
import Filters, { FilterValue } from '@/components/heritage/Filters.vue';
import { DEFAULT_LIST_OPTION_REQUEST } from '@/client/category.client';
import UserClient, { DefaultListOptionRequest, Film, UserResponse } from '@/client/user.client';
import { extractContainer } from '@/ioc/util';
import SettingsModule from '@/store/modules/settings';
import TYPES from '@/ioc/types';
import { SiteConfig } from '../../client/value.client';
import ValuesModule from '../../store/modules/values';
import FilmModule from '../../store/modules/films';
import UserFilmClient, { FilmLight } from '../../client/user-film.client';

import { Label } from '../../client/label.client';
import LabelsModule from '@/store/modules/labels';
import mixins from 'vue-typed-mixins'
import LabelMixin from '../../mixins/labels';

export default mixins(LabelMixin).extend({
  name: 'ScreeningRoomView',
  inject: ['container'],
  components: {
    FilmLayout,
    Filters
  },
  beforeCreate: function() {
    document.body.className = '';
  },
  async created() {
      const filmModule = getModule(FilmModule, this.$store);
      await filmModule.dispatchGetFilmList()
      const result = await filmModule.allScreeningRoom
      this.films = result;
      this.filteredFilms = [...this.films]
      //get only film types
      
      this.filmTypes = [...new Set(this.films.flatMap(x => {
        return x.genre_name ? x.genre_name.split(",").map(x => x.trim()) : []
      }))].sort((x,y) => x.localeCompare(y))

      this.filmYears = [...new Set(this.films.flatMap(x => {
        return x.year ? x.year.toString() : []
      }))].sort((x,y) => (parseInt(x) > parseInt(y))?1:-1)
      
      this.filmDirectors = [...new Set(this.films.flatMap(x => {
        return x.director ? x.director.split(",").map(x => x.trim()) : []
      }))].sort((x,y) => x.localeCompare(y))

      this.filmLanguages = [...new Set(this.films.flatMap(x => {
        //console.log(x.id + ' ' +x.languages.split(",").map(x => x.trim()));
        return x.languages ? x.languages.split(",").map(x => x.trim()) : []
      }))].sort((x,y) => x.localeCompare(y))

      this.filmSubtitles = [...new Set(this.films.flatMap(x => {
        return x.subtitles ? x.subtitles.split(",").map(x => x.trim()) : []
      }))].sort((x,y) => x.localeCompare(y))

      this.filmCountries = [...new Set(this.films.flatMap(x => {
        return x.countries ? x.countries.split(",").map(x => x.trim()) : []
      }))].sort((x,y) => x.localeCompare(y))


      this.loaded = true;
  },
  data():{

    films: FilmLight[],
    filteredFilms: FilmLight[],
    filmTypes: String[],
    filmYears: String[],
    filmDirectors: String[],
    filmCountries: String[],
    filmLanguages: String[],
    filmSubtitles: String[],
    loaded: Boolean,
  } {
    return {
      films: [],
      filteredFilms: [],
      filmTypes: [],
      filmYears: [],
      filmDirectors: [],
      filmCountries: [],
      filmLanguages: [],
      filmSubtitles: [],
      loaded: false
    }
  },
  asyncComputed: {/*
    async films(): Promise<FilmLight[]> { 
      const settingsModule = getModule(SettingsModule, this.$store);
      const container = extractContainer(this);
      const filmClient =  container.get<UserFilmClient>(TYPES.UserFilmClient);
      return await filmClient.getScreeningRoom(settingsModule.language,1000);
    },*/
  },
  computed: {
  
  },
  methods: {
    
    filmGendre(id: number): string | undefined {
      return getModule(ValuesModule, this.$store).filmGendres.find(x => x.id === id)?.name;
    },
    languages(ids:{lang_id:number}[]):string[] {
      return getModule(ValuesModule, this.$store).filmLanguages.filter(x => ids.map(y => y.lang_id).includes(x.id)).map(x => x.name);
    },
    onFilterChange(filters:FilterValue[]) {
      let newFilter = [...this.films]
      let newFilter1:number[] = []
      let newFilter2:number[] = []
      let newFilter3:number[] = []
      let newFilter4:number[] = []
      let newFilter5:number[] = []
      let newFilter6:number[] = []


      // check if filter is active
        let checkFilters = false;
        filters.forEach(element => {
          if(element.filter) {
            checkFilters = true;
          }
        });
        console.log(filters.length);
        if(checkFilters){
          newFilter.splice(0);/*
          newFilter1.splice(0);
          newFilter2.splice(0);
          newFilter3.splice(0);
          newFilter4.splice(0);
          newFilter5.splice(0);
          newFilter6.splice(0);
          */

        filters.forEach(element => {
          if(element.filter) {
            if(element.filter.column == "type_film"){
            //console.log(element.filter.column);
            let allData = [...this.films]
            let tempFilter = allData.filter(x => {
              return x.genre_name ? (x.genre_name as string).includes((element.filter?.value as string)) : false
            })
            //newFilter = newFilter.concat(tempFilter);
            tempFilter.forEach(x => {
              newFilter1.push(x.id)
            })
            }else if(element.filter.column == "year_film"){
            //console.log(element.filter.column);
            let allData = [...this.films]
            let tempFilter = allData.filter(x => {
              //return x.year ? (x.year.toString()) == (element.filter?.value as string) : false
              return x.year ? (x.year.toString()).includes((element.filter?.value as string)) : false              
            })
            
            //newFilter = newFilter.concat(tempFilter);
            tempFilter.forEach(x => {
              newFilter2.push(x.id)
            })
            }else if(element.filter.column == "director_film"){
            //console.log(element.filter.column);
            let allData = [...this.films]
            let tempFilter = allData.filter(x => {
              return x.director ? (x.director as string).includes((element.filter?.value as string)) : false
              //return x.director ? (x.director as string) == (element.filter?.value as string) : false
            })
            
            //newFilter = newFilter.concat(tempFilter);
            tempFilter.forEach(x => {
              newFilter3.push(x.id)
            })
            }else if(element.filter.column == "language_film"){
            //console.log(element.filter.column);
            let allData = [...this.films]
            let tempFilter = allData.filter(x => {
              const toCheck = x.languages?.split(",").map(x => x.trim());
              return x.languages ? toCheck.includes((element.filter?.value as string)): false
              //return x.languages ? x.languages.split(",").map(x => x.trim()): false
              //return x.languages ? (x.languages as string).includes((element.filter?.value as string)) : false
              //return x.languages ? (x.languages as string) == (element.filter?.value as string) : false
            })
            
            //newFilter = newFilter.concat(tempFilter);
            tempFilter.forEach(x => {
              newFilter4.push(x.id)
            })
            }else if(element.filter.column == "subtitle_film"){
            //console.log(element.filter.column);
            let allData = [...this.films]
            let tempFilter = allData.filter(x => {
              const toCheck = x.subtitles?.split(",").map(x => x.trim());
              return x.subtitles ? toCheck.includes((element.filter?.value as string)): false
              //return x.subtitles ? (x.subtitles as string).includes((element.filter?.value as string)) : false
              //return x.subtitles ? (x.subtitles as string) == (element.filter?.value as string) : false
            })
            
            //newFilter = newFilter.concat(tempFilter);
            tempFilter.forEach(x => {
              newFilter5.push(x.id)
            })
            }else if(element.filter.column == "country_film"){
            //console.log(element.filter.column);
            let allData = [...this.films]
            let tempFilter = allData.filter(x => {
              const toCheck = x.countries?.split(",").map(x => x.trim());
              return x.countries ? toCheck.includes((element.filter?.value as string)): false
              //return x.countries ? (x.countries as string).includes((element.filter?.value as string)) : false
              //return x.countries ? (x.countries as string) == (element.filter?.value as string) : false
            })
            
            //newFilter = newFilter.concat(tempFilter);
            tempFilter.forEach(x => {
              newFilter6.push(x.id)
            })
            }
          }
        });
        let intersection:number[];
        if(newFilter1.length > 0){
          intersection = newFilter1;
          if(newFilter2.length > 0)
            intersection = intersection.filter(x => newFilter2.includes(x));
          if(newFilter3.length > 0)
            intersection = intersection.filter(x => newFilter3.includes(x));
          if(newFilter4.length > 0)
            intersection = intersection.filter(x => newFilter4.includes(x));
          if(newFilter5.length > 0)
            intersection = intersection.filter(x => newFilter5.includes(x));
          if(newFilter6.length > 0)
            intersection = intersection.filter(x => newFilter6.includes(x));
        }else if(newFilter2.length > 0){
          intersection = newFilter2;
          if(newFilter3.length > 0)
            intersection = intersection.filter(x => newFilter3.includes(x));
          if(newFilter4.length > 0)
            intersection = intersection.filter(x => newFilter4.includes(x));
          if(newFilter5.length > 0)
            intersection = intersection.filter(x => newFilter5.includes(x));
          if(newFilter6.length > 0)
            intersection = intersection.filter(x => newFilter6.includes(x));
        }else if(newFilter3.length > 0){
          intersection = newFilter3;
          if(newFilter4.length > 0)
            intersection = intersection.filter(x => newFilter4.includes(x));
          if(newFilter5.length > 0)
            intersection = intersection.filter(x => newFilter5.includes(x));
          if(newFilter6.length > 0)
            intersection = intersection.filter(x => newFilter6.includes(x));
        }else if(newFilter4.length > 0){
          intersection = newFilter4;
          if(newFilter5.length > 0)
            intersection = intersection.filter(x => newFilter5.includes(x));
          if(newFilter6.length > 0)
            intersection = intersection.filter(x => newFilter6.includes(x));
        }else if(newFilter5.length > 0){
          intersection = newFilter5;
          if(newFilter6.length > 0)
            intersection = intersection.filter(x => newFilter6.includes(x));
        }else if(newFilter6.length > 0){
          intersection = newFilter6
        }

        newFilter = [...this.films];
        newFilter = newFilter.filter(x => {
          //console.log(x.id + " CHECK IN "+ intersection + " result: "+ intersection.includes(x.id));
          return intersection.includes(x.id);
        })
        }
        //newFilter = [ ...new Set(newFilter)];
        //console.log("stop");


      filters.forEach(element => {
        if(element.fullText) {
          const q = (element.fullText as string).toLowerCase()
          newFilter = newFilter.filter(x => {
            return x.title.toLowerCase().includes(q) || 
            x.countries.toLowerCase().includes(q) //|| 
            //x.main_activities.some(ma => ma.toLowerCase().includes(q))
          })
        }/*
        if(element.filter) {
          newFilter = newFilter.filter(x => {
            return x.main_activities.some( ma => ma === (element.filter?.value as string))
          })
        }*/
        if(element.sort) {
          if(element.sort.order == "desc") {
            if(element.sort.column == "title") {
              newFilter = newFilter.sort((x,y) => -x.title.localeCompare(y.title))
            } else if(element.sort.column == "country") {
              newFilter = newFilter.sort((x,y) => -x.countries.localeCompare(y.countries))
            } else if(element.sort.column == "company_name") {
              newFilter = newFilter.sort((x,y) => -x.company_name.localeCompare(y.company_name))
            } else if(element.sort.column == "year") {
              newFilter = newFilter.sort((x,y) => x.year < y.year?1:-1)
            }
          } else {
            if(element.sort.column == "title") {
              newFilter = newFilter.sort((x,y) => x.title.localeCompare(y.title))
            } else if(element.sort.column == "country") {
              newFilter = newFilter.sort((x,y) => x.countries.localeCompare(y.countries))
            } else if(element.sort.column == "company_name") {
              newFilter = newFilter.sort((x,y) => x.company_name.localeCompare(y.company_name))
            } else if(element.sort.column == "year") {
              newFilter = newFilter.sort((x,y) =>  x.year > y.year?1:-1)
            }
          }
        }
      });
      this.filteredFilms = newFilter
    }
  }
});
