import { injectable, inject } from 'inversify';
import TYPES from '@/ioc/types';
import Environment from '@/env/environment';
import { AxiosStatic } from 'axios';

export type DownloadStatus = 'ERROR' | 'SUCCESS' | 'NOT_YET' | 'LOADING';

export interface NamedBlob {
  blob: Blob;
  name: string;
  mime?:string
}

@injectable()
export default class DownloadClient {
  constructor(
    @inject(TYPES.AxiosStatic) private readonly axios: AxiosStatic,
    @inject(TYPES.Environment) private readonly environment: Environment,
  ) { }

  getDownloadUrl(id: number, resource: string): string {
    return `${this.environment.backendUrl}/api/v1/file/${resource}/id::${id}`;
  }


  private _download(data:any,name:string) {
    const objectUrl = window.URL.createObjectURL(data);
    const anchor = document.createElement("a");
    document.body.appendChild(anchor);
    anchor.href = objectUrl;
    anchor.download = name;
    anchor.click();

    window.URL.revokeObjectURL(objectUrl);
  }

  async download(id: number, name: string, resource: string): Promise<void> {
    const resp = await this.axios.get(`${this.environment.backendUrl}/api/v1/file/${resource}/id::${id}`, { responseType: 'blob' })
    this._download(resp.data,name)
  }

  async downloadFile(file: string, name: string, mime:string): Promise<void> {
    const resp = await this.axios.get(`data:${mime};base64,${file}`, { responseType: 'blob' })
    this._download(resp.data,name)
  }

  async uploadEdit(id: number, file: NamedBlob, resource: string): Promise<void> {
    const url = `${this.environment.backendUrl}/api/v1/file/${resource}/id::${id}`;
    const formData = new FormData();
    formData.set('file', file.blob, file.name);
    await this.axios.post(url, formData, {headers: {'content-type': 'multipart/form-data'}})
    await this.axios.get<string>(url);
  }
}