
import Vue from "vue";
import { getModule } from 'vuex-module-decorators';
import AuthModule from '../../store/modules/auth';
import { LoginStatus } from '../../store/modules/auth/state';
import { REGISTER_PATH, HOME_PATH, COMPLETE_PROFILE } from '../../router/toolbox';
import { Label } from '../../client/label.client';
import LabelsModule from '@/store/modules/labels';
import mixins from 'vue-typed-mixins'
import LabelMixin from '../../mixins/labels';
import { authUrl } from '@/service/auth'
import { extractContainer } from '@/ioc/util';
import UserClient from '@/client/user.client';
import TYPES from '@/ioc/types';
import SettingsModule from '@/store/modules/settings';
import UsersModule from '@/store/modules/users';
import ValuesModule from '../../store/modules/values';
import { HERITAGE, TOOLBOX } from '../../service/site';

export default mixins(LabelMixin).extend({
  beforeCreate: function() {
    document.body.className = 'stepaway';
  },

  name: "stepAwayHeritageDemo",
  inject: ["container"],
  data() {
    return {
      isVideoPlaying: false,
    };
  },
  beforeDestroy() {
    document.body.className = '';
  },
  async created() {
    const me = await getModule(UsersModule, this.$store).dispatchGetMe()
    if(getModule(UsersModule, this.$store).heritageFull) {
      this.$router.push(HOME_PATH)
    }
  },
  components: {
  },
  computed: {
    loginStatus(): LoginStatus {
      return getModule(AuthModule, this.$store).loginStatus;
    },
    intro():string {
      return getModule(ValuesModule, this.$store).siteConfig.intro
    },
    loginPath(): String {
      return authUrl(HERITAGE)
    }
  }
});
