
import Vue, { PropType } from 'vue';
import UserClient, { DefaultListOptionRequest, Film, UserResponse } from '@/client/user.client';
import ValuesModule from '@/store/modules/values';
import { getModule } from 'vuex-module-decorators';
import ResourceClient, { ResourceLightResponse } from '../../client/resource.client';
import { formatPublication, formatDateRange } from '@/service/ui';
import { Label } from '../../client/label.client';
import LabelsModule from '@/store/modules/labels';
import mixins from 'vue-typed-mixins'
import LabelMixin from '../../mixins/labels';

export default mixins(LabelMixin).extend({
  name: 'NewsLayout',
  components: {
  },
  props: {
    isOwner: Boolean,
    className: {
      type: String,
      validator: (c:string) => [
        'news',
        'news-list'
      ].includes(c),
    },
    news: {
      type: Array as PropType<ResourceLightResponse[]>,
      required: true,
    }
  },
  filters: {
    formatDate(date: Date): string {
      return formatPublication(date);
    }    
  },
  methods: {
    countryName(id: number): string {
      return getModule(ValuesModule, this.$store).getCountryName(id);
    }
  }
});
