import TYPES from "@/ioc/types";
import { AxiosResponse, AxiosStatic } from "axios";
import { inject, injectable } from "inversify";

export interface InvitationData {
    newCompany: boolean,
    needMainActivity:boolean,
    needActivities:boolean,
    needDispatchExistingCompany:boolean,
    existingCompanies: {id:string, company_name:string}[]
}

export interface ExtraInvitationData{
  activities?: number[]
  company_name?: string,
  company_activity?:string,
  company_country?: string,
  company_email?: string,
  company_website?: string,
  company_profile?: string,
  personal_job_title?: string,
  company_address?: string,
  company_city?: string,
  jpardo_org_id?: string
}

@injectable()
export default class CompleteInviteClient {
  constructor(
    @inject(TYPES.AxiosStatic) private readonly axios: AxiosStatic,
  ) { }

  async inviteData(token:String):Promise<AxiosResponse<InvitationData>> {
      const response = this.axios.get<InvitationData>('/auth/invitation/complete/' + token)
      return await response
  }

  async acceptInvitation(token:string,extraData:ExtraInvitationData):Promise<AxiosResponse<InvitationData>> {
    const response = this.axios.post<InvitationData>('/auth/invitation/accept/'+token,extraData)
    return await response
}

}