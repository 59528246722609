import Environment from '@/env/environment';
import TYPES from '@/ioc/types';
import { AxiosStatic } from 'axios';
import { inject, injectable } from 'inversify';
import { SupportedLanguage } from './supported-lang.type';
import { PagingOption } from './user.client';

export interface Label {
  id: number;
  key: string;
  value: string;
}

@injectable()
export default class LabelClient {
  constructor(
    @inject(TYPES.AxiosStatic) private readonly axios: AxiosStatic,
    @inject(TYPES.Environment) private readonly environment: Environment,
  ) { }

  async getById(lang: SupportedLanguage, id: number): Promise<Label> {
    const response = this.axios.get<Label>(`${this.environment.backendUrl}/api/v1/public/labels/id/id::${id}`);
    return (await response).data;
  }

  async getList(
    lang: SupportedLanguage,
    request: PagingOption,
  ): Promise<Label[]> {
    const fullRequest = {
      filter: [
      ],
      sort: [
      ],
      paging: request,
    };
    const response = this.axios.post<Label[]>(`${this.environment.backendUrl}/api/v1/public/labels/list`,
      fullRequest);
    return (await response).data;
  }

  async getHeritageList(
    lang: SupportedLanguage,
    request: PagingOption,
  ): Promise<Label[]> {
    const fullRequest = {
      filter: [
      ],
      sort: [
      ],
      paging: request,
    };
    const response = this.axios.post<Label[]>(`${this.environment.backendUrl}/api/v1/public/heritage_labels/list`,
      fullRequest);
    return (await response).data;
  }
}
