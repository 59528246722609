import Environment from '@/env/environment';
import { injectable, inject } from 'inversify';
import { AxiosStatic, AxiosError } from 'axios';
import TYPES from '@/ioc/types';
import { LoginRequest } from './login-request.interface';

export const authTokenHeader = 'x-box-auth';

export function isAxiosError(e: Error): e is AxiosError {
  return !!(e as AxiosError).response || !!(e as AxiosError).request;
}
@injectable()
export default class AuthClient {
  constructor(
    @inject(TYPES.AxiosStatic) private readonly axios: AxiosStatic,
    @inject(TYPES.Environment) private readonly environment: Environment,
  ) { }

  async authorize(request: LoginRequest): Promise<string> {
    await fetch('/cms/api/v1/logout')
    const response = await this.axios.post<string>(`${this.environment.backendUrl}/api/v1/authorize`, request);
    return response.headers[authTokenHeader];
  }

  async login(request: LoginRequest): Promise<string> {
    const response = this.axios.post<string>(`${this.environment.backendUrl}/api/v1/login`, request, {
      withCredentials: true,
    });
    return (await response).headers[authTokenHeader];
  }

  async fetchCookie(authToken:string):Promise<string> {
    const config:{withCredentials: boolean, headers: any} = {
      withCredentials: true,
      headers: {}
    }
    config.headers[authTokenHeader] = authToken 
    const response = this.axios.get<string>(`${this.environment.backendUrl}/api/v1/auth/cookie`,config);
    return (await response).data
  }

}
