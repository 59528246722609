
import TYPES from "@/ioc/types";
import { extractContainer } from "@/ioc/util";
import mixins from "vue-typed-mixins";
import LabelMixin from "../../mixins/labels";
import CompleteInviteClient, {ExtraInvitationData, InvitationData} from "../../client/complete-invite.client";
import AccreditationClient, { Accreditation, emptyAccreditation, ActivityResponse, Activities } from '@/client/accreditation.client';
import { Country, ValueResponse } from "@/client/value.client";
import cloneDeep from "clone-deep";
import { getModule } from "vuex-module-decorators";
import ValuesModule from "@/store/modules/values";
import SettingsModule from "@/store/modules/settings";

export default mixins(LabelMixin).extend({
  name: "CompleteInvite",
  inject: ["container"],
  props: {
    token: String,
  },
  async created() {
    const container = extractContainer(this);
    const completeInviteClient = container.get<CompleteInviteClient>(TYPES.CompleteInviteClient);
    const result = await completeInviteClient.inviteData(this.token);
    if(result.status == 200) {
        this.inviteData = result.data
    } else {
        window.location.href = '/auth/invitation/accept/' + this.token;
    }


    const settingsModule = getModule(SettingsModule, this.$store);
    const accreditationClient = container.get<AccreditationClient>(TYPES.AccreditationClient);

    this.activityRightholder = await accreditationClient.getActivitiesRightholder(settingsModule.language);
    this.activityRightholder.forEach(v =>  this.activityRightholderChecked.push(false) )

    this.activityStreamer = await accreditationClient.getActivitiesStreamer(settingsModule.language);
    this.activityStreamer.forEach(v =>  this.activityStreamerChecked.push(false) )

    this.activityOther = await accreditationClient.getActivitiesOther(settingsModule.language);
    this.activityOther.forEach(v =>  this.activityOtherChecked.push(false) )

    this.setActivityValidity(false)
    this.setCompanyValidity(false)

  },
  computed: {
    countries(): ValueResponse[] {
      //const allowedCountries = getModule(ValuesModule,this.$store).siteConfig.allowed_countries
      return cloneDeep(getModule(ValuesModule, this.$store).countries).sort(
        function (a: Country, b: Country) {
          return a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1;
        }
      ); //.filter(x => allowedCountries.includes(x.id));
    },
  },
  methods: {
      toggleActivity(value:boolean, activity: number) {
      
        this.activityChecked[0] = false;
        this.activityChecked[1] = false;
        this.activityChecked[2] = false;

         if(value){
          this.activityChecked[activity] = true;
          this.extraData.company_activity = activity==0?'Rightholder':activity==1?'Streamer':'Other';
        }else{
          this.extraData.company_activity = '';
        }
        /*
      if(value){
        this.activityChecked[activity] = true;
        this.extraData.company_activity = activity==0?'Rightholder':activity==1?'Streamer':'Other';
      }
      */
     
      //console.log(this.extraData.company_activity);
      
      this.$forceUpdate()
    },
    setActivityValidity(valid:boolean) {
      const activityElement = (this.$refs['activity'] as HTMLInputElement)
      if(activityElement) {
        if(!valid)
          activityElement.setCustomValidity("Please select at least one activity");
        else 
          activityElement.setCustomValidity("")
      }
    },
    checkActivity() {

      const checks = [... this.activityRightholderChecked,this.activityStreamerChecked,this.activityOtherChecked]
      const valid = checks.some(x => x)
      this.setActivityValidity(valid);
      
    },
    setCompanyValidity(valid:boolean) {
      const companyElement = (this.$refs['company'] as HTMLInputElement)
      if(companyElement) {
        if(!valid)
          companyElement.setCustomValidity("Please select at least one company");
        else 
          companyElement.setCustomValidity("")
      }
    },
    setCompany(id:string) {
      Object.keys(this.companies).forEach(k => {
        if(k != id)
          this.companies[k] = false;
      });
      this.extraData.jpardo_org_id = id;
      this.setCompanyValidity(true);
      
    },
    async onSubmitForm(event: Event) {
      event.preventDefault();

      this.loadingForm = true;

      //metti attivita
      this.activityChecked.forEach((value, index) => {
            if(value){
              this.extraData.company_activity = this.activity[index];
              //concatena subactivity
              let activitiesChecked:boolean[] = [];
              let activities: ActivityResponse[];

              switch(this.activity[index]) {
                case "Rightholder":
                  activitiesChecked = this.activityRightholderChecked;
                  activities = this.activityRightholder
                  break;
                case "Streamer":
                  activitiesChecked = this.activityStreamerChecked;
                  activities = this.activityStreamer
                  break;
                case "Other":
                  activitiesChecked = this.activityOtherChecked;
                  activities = this.activityOther
                  break;
              }
              

              let activitiesId:Set<number> = new Set();

              activitiesChecked.forEach((value2, index2) => {
                    if(value2){
                      activitiesId.add(activities[index2].id);
                    }
              });

              this.extraData.activities = [...activitiesId]

            }
        });

      

      const container = extractContainer(this);
      const completeInviteClient = container.get<CompleteInviteClient>(TYPES.CompleteInviteClient);
      const result = await completeInviteClient.acceptInvitation(this.token,this.extraData);
      if(result.status == 200) {
        this.$router.replace('/')
      } else {
        this.loadingForm = false;
        console.log(result.data)
      }
    }
  },
  data(): {
    showForm:boolean;
    inviteData: InvitationData | null;
    extraData: ExtraInvitationData;
    loadingForm: boolean;
    activity: string[];
    activityChecked: boolean[];
    activityRightholder: ActivityResponse[];
    activityRightholderChecked: boolean[];
    activityStreamer: ActivityResponse[];
    activityStreamerChecked: boolean[];
    activityOther: ActivityResponse[];
    activityOtherChecked: boolean[];
    companies: any;
  } {
    return {
      showForm: true,
      inviteData: null,
      extraData: {},
      loadingForm: false,
      activity: ['Rightholder','Streamer','Other'],
      activityChecked: [],
      activityRightholder: [],
      activityRightholderChecked: [],
      activityStreamer: [],
      activityStreamerChecked: [],
      activityOther: [],
      activityOtherChecked: [],
      companies: {}
    };
  },
});
