

import mixins from 'vue-typed-mixins'
import LabelMixin from '@/mixins/labels';
// @ts-ignore
import { Mentionable } from 'vue-mention';
import { extractContainer } from '@/ioc/util';
import UserClient from '@/client/user.client';
import TYPES from '@/ioc/types';
import slugify from 'slugify'

export default mixins(LabelMixin).extend({
    components: {
        Mentionable,
    },
    inject: ['container'],
    data():{
        users: {value:string, label:string}[],
        loading: boolean
    } {
        return {
            users: [],
            loading: true
        }
    },
    methods: {
        onOpen(){
            const submitEmit: boolean = true;
            this.$emit('onOpen', submitEmit);
        },
        async loadUsers () {
            this.loading = true
            const container = extractContainer(this);
            const userClient = container.get<UserClient>(TYPES.UserClient);
            const users = await userClient.getListToolbox("en",{currentPage: 1, pageLength: 1000})
            this.users = users.map(u => {
                return {
                    label: u.first_name + " " + u.last_name,
                    value: "[" + slugify(u.first_name.toLowerCase() + "-" + u.last_name.toLowerCase()) + "-" + u.id + "]"
                }
            })
            this.loading = false
        }
    },
    async mounted() {
        this.loadUsers()
    }
})


