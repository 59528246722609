
import { getModule } from 'vuex-module-decorators';
import {  HOME_PATH } from '../../router/toolbox';
import mixins from 'vue-typed-mixins'
import LabelMixin from '../../mixins/labels';
import UsersModule from '@/store/modules/users';


export default mixins(LabelMixin).extend({
  beforeCreate: function() {
    document.body.className = 'stepaway';
  },
  name: "stepAwayHeritage",
  inject: ["container"],

  components: {
  },
  async created() {
    const me = await getModule(UsersModule, this.$store).dispatchGetMe()
    if(getModule(UsersModule, this.$store).heritageFull) {
      this.$router.push(HOME_PATH)
    }
  },
  computed: {

  },
  methods: {
    register() {
        const userModule = getModule(UsersModule, this.$store)
        const id = userModule.me?.id;
        this.$router.push("/profile/accreditation/"+id)
    }
  }
});
