import { render, staticRenderFns } from "./NewsLayout.vue?vue&type=template&id=38296412&"
import script from "./NewsLayout.vue?vue&type=script&lang=ts&"
export * from "./NewsLayout.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports