
import Vue from 'vue';

import mixins from 'vue-typed-mixins'
import LabelMixin from '../../mixins/labels';

export default mixins(LabelMixin).extend({
    name: 'PageNotFound',
    inject: ['container'],
    components: {
    },
    beforeCreate: function() {
        document.body.className = '';
    },    
    async created() {

    },
})
