
import Vue, { PropType } from 'vue'
import FloatLabel from "vue-float-label/components/FloatLabel.vue";
import { DefaultListOptionRequest, UserResponse } from '@/client/user.client';
import { sanitizeNumber } from '../../service/form';
import mixins from 'vue-typed-mixins';
import LabelMixin from '@/mixins/labels';
import { getModule } from 'vuex-module-decorators';
import ValuesModule from '@/store/modules/values';
import UsersModule from '@/store/modules/users';
import { ForumSuggestion, emptySuggestion } from '@/client/forum.suggestion.client';

function sanitizeSuggestionForm(suggestion: ForumSuggestion): ForumSuggestion {
  return {
    ...suggestion
  }
}

export interface SubmitFormEmit {
  suggestion: ForumSuggestion;
}

export default mixins(LabelMixin).extend({
  components: {
    FloatLabel
  },
  async created() {
    await getModule(UsersModule, this.$store).dispatchGetMe();
  },
  computed: {
    user():UserResponse | null {
      return getModule(UsersModule,this.$store).me
    }
  },
  methods: {
    onCancel(event: MouseEvent) {
      event.preventDefault();
      this.$emit('onCancel');
    },
    onSubmitForm(event: Event) {
      event.preventDefault();
      this.loadingForm = true;
      const submitEmit: SubmitFormEmit = {
        suggestion: sanitizeSuggestionForm(this.suggestion),
      };
      this.$emit('onSubmit', submitEmit);
    },
  },
  data(): { 
    suggestion: ForumSuggestion,
    loadingForm: boolean ,
  } {
    return {
      suggestion: emptySuggestion(getModule(UsersModule,this.$store).me?.id || -1),
      loadingForm: false,
    };
  }
})
