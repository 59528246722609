
import Vue, { PropType } from 'vue'
import FloatLabel from "vue-float-label/components/FloatLabel.vue";
import { DefaultListOptionRequest, UserResponse } from '@/client/user.client';
import { sanitizeNumber } from '../../service/form';
import mixins from 'vue-typed-mixins';
import LabelMixin from '@/mixins/labels';
import { getModule } from 'vuex-module-decorators';
import ValuesModule from '@/store/modules/values';
import UsersModule from '@/store/modules/users';
import { ForumNewTopic, emptyTopic } from '@/client/forum.new.topic.client';

function sanitizeTopicForm(topic: ForumNewTopic): ForumNewTopic {
  return {
    ...topic
  }
}

export interface SubmitFormEmit {
  topic: ForumNewTopic;
}

export default mixins(LabelMixin).extend({
  components: {
    FloatLabel
  },
  props: {
    category: Number,
  },
  async created() {
    await getModule(UsersModule, this.$store).dispatchGetMe();
  },
  computed: {
    user():UserResponse | null {
      return getModule(UsersModule,this.$store).me
    }
  },
  methods: {
    onCancel(event: MouseEvent) {
      event.preventDefault();
      this.$emit('onCancel');
    },
    onSubmitForm(event: Event) {
      event.preventDefault();
      this.loadingForm = true;
      const submitEmit: SubmitFormEmit = {
        topic: sanitizeTopicForm(this.topic),
      };
      this.$emit('onSubmit', submitEmit);
    },
  },
  data(): { 
    topic: ForumNewTopic,
    loadingForm: boolean ,
  } {
    return {
      topic: emptyTopic(getModule(UsersModule,this.$store).me?.id || -1, this.category),
      loadingForm: false,
    };
  }
})
