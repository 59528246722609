
import Vue from 'vue';
import DownloadClient, { DownloadStatus } from '@/client/download.client';
import { extractContainer } from '@/ioc/util';
import TYPES from '@/ioc/types';
import { getModule } from 'vuex-module-decorators';
import AuthModule from '../../store/modules/auth';

export interface ExtractedFile{
  file:string
  name:string
  mime:string
}

export default Vue.extend({
  props: {
    label: {
      type: String,
      required: true,
    },
    fileId: {
      type: Number,
      required: false,
    },
    fileResource: {
      type: String,
      required: false,
    },
    file: {
      type: String,
      required: false,
    },
    fileName: {
      type: String,
      required: false,
    },
    fileMime: {
      type: String,
      required: false,
    },
    fileFetcher: {
      type: Function,
      required: false
    },
    fileExtractor: {
      type: Function,
      required: false
    }
  },
  methods: {
    async download(e: MouseEvent) {
      e.preventDefault();
      const container = extractContainer(this);
      const downloader = container.get<DownloadClient>(TYPES.DownloadClient);

      if(this.fileFetcher && this.fileExtractor) {
        const data = await this.fileFetcher()
        const extracted = await this.fileExtractor(data)
        if(extracted) {
          try {
            downloader.downloadFile(extracted.file, extracted.name, extracted.mime);
            this.status = 'SUCCESS';
          } catch (e) { 
            console.log(e)
            this.status = 'ERROR';
          }
        }
      } else if(this.fileResource) {

        if (this.fileMime === 'application/pdf') {
          return this.showPdf();
        }

        if (this.status === 'LOADING') {
          return;
        }
        

        this.status = 'LOADING';
        try {
          await downloader.download(this.fileId, this.fileName, this.fileResource);
          this.status = 'SUCCESS';
        } catch (e) { 
          this.status = 'ERROR';
        }
      } else {
         try {
          downloader.downloadFile(this.file, this.fileName, this.fileMime);
          this.status = 'SUCCESS';
        } catch (e) { 
          console.log(e)
          this.status = 'ERROR';
        }
      }
    },
    showPdf() {
      const container = extractContainer(this);
      const downloader = container.get<DownloadClient>(TYPES.DownloadClient);
      const authModule = getModule(AuthModule, this.$store);
      window.location.href = `/pdf/viewer.html?url=${encodeURIComponent(downloader.getDownloadUrl(this.fileId, this.fileResource))}&token=${encodeURIComponent(authModule.authToken || '')}`;
    },
  },
  data(): {status: DownloadStatus} {
    return {
      status: 'NOT_YET',
    };
  },
  inject: ["container"],
})
