function getVimeoId(url: string): string {
    const regExp = /https:\/\/(www\.)?vimeo.com\/(\d+)($|\/)/;
  
    const match = url.match(regExp);
  
    if (match){
        return match[2];
    }
    else{
        console.log('Not a vimeo url', url);
        return '';
    }
}

function getYoutubeId(url: string): string {
    let ID = '';
    const parts = url.replace(/(>|<)/gi,'').split(/(vi\/|v=|\/v\/|youtu\.be\/|\/embed\/)/);
    if(url[2] !== undefined) {
        ID = parts[2].split(/[^0-9a-z_\-]/i)[0];
    }
    else {
        ID = url;
    }
    return ID;
}

export function getVideoUrl(trailerUrl: string):string | null {
    if (trailerUrl.toLowerCase().includes('youtu')) {
      return `youtube:${getYoutubeId(trailerUrl)}`;
    }
    else if (trailerUrl.toLowerCase().includes('vimeo.com')) {
      return `vimeo:${getVimeoId(trailerUrl)}`
    }
    console.log(`${trailerUrl} not recognized as valid trailer url`);
    return null;
}

export function toVideoUrl(trailer:string | null | undefined):string {
    if (!trailer) {
        return '';
    }
    const [service, trailerId] = trailer.split(':');
    switch (service) {
    case 'youtube':
        return `https://www.youtube.com/watch?v=${trailerId}`;
        break;
    
    case 'vimeo':
        return `https://vimeo.com/${trailerId}`;
        break;
    
    default:
        console.log('Trailer service not supported', trailer);
        return ``;
        break;
    }
}
