
import Vue, { PropType } from "vue";
import SuggestResourceForm, {
  SubmitFormEmit
} from "../components/forms/SuggestResourceForm.vue";
import { extractContainer } from "../ioc/util";
import TYPES from "../ioc/types";
import UserFilmClient, { getDefaultFilm } from "@/client/user-film.client";
import { Film, Cv } from "@/client/user.client";
import { getModule } from "vuex-module-decorators";
import SettingsModule from "../store/modules/settings";
import DownloadClient from "../client/download.client";
import { sub } from "date-fns";
import { getFilmFormPath } from '@/router/toolbox';
import mixins from 'vue-typed-mixins'
import LabelMixin from '@/mixins/labels'
import SuggestionClient from '../client/suggestion.client';

export default mixins(LabelMixin).extend({
  name: "SuggestResourceView",
  components: {
    SuggestResourceForm
  },
  data(): { showForm:boolean } {
    return {
      showForm: true
    };
  },
  methods: {
    async onSuggestionSubmit(submit: SubmitFormEmit) {
      const { suggestion, file } = submit;
      const container = extractContainer(this);
      const settingsModule = getModule(SettingsModule, this.$store);
      

      const suggestionClient = container.get<SuggestionClient>(TYPES.SuggestionClient);
      if(file) {
        file.blob
        const reader = new FileReader();
        reader.readAsDataURL(file.blob); 
        const self = this
        reader.onloadend = async function() {
            suggestion.resource = (reader.result as string).split(',')[1]               
            await suggestionClient.create(settingsModule.language, suggestion)
            self.showForm = false;
        }
      } else {
        await suggestionClient.create(settingsModule.language, suggestion)
        this.showForm = false;
      }
      
      
    }
  },
  watch: {
    
    
  },
  inject: ["container"]
});
