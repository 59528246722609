
import Vue, { PropType } from 'vue';
import UserClient, { DefaultListOptionRequest, Film, UserResponse } from '@/client/user.client';
import ValuesModule from '@/store/modules/values';
import { getModule } from 'vuex-module-decorators';

import { Label } from '../../client/label.client';
import LabelsModule from '@/store/modules/labels';
import mixins from 'vue-typed-mixins'
import LabelMixin from '../../mixins/labels';
import { OrganizationLight } from '../../client/organization.client';

export default mixins(LabelMixin).extend({
  name: 'SearchStreamerLayout',
  components: {

  },
  props: {
    className: {
      type: String,
      validator: (c:string) => [
        'streamer',
      ].includes(c),
    },
    streamer: {
      type: Array as PropType<OrganizationLight[]>,
      required: true,
    }
  },
  methods: {
    countryName(name: string): string {
      return getModule(ValuesModule, this.$store).countries.find(x => x.name_it === name )?.name || name
    }
  }
});
