
import Vue from 'vue';
import SwiperScreeningRoom from '@/components/heritage/SwiperScreeningRoom.vue';
import RecentFilms from '@/components/heritage/RecentFilms.vue';
import NewsLayout from '@/components/heritage/NewsLayout.vue';
import FilmLayout from '@/components/FilmLayout.vue';
import Agenda from '@/components/Agenda.vue';
import SwiperAgenda from '@/components/SwiperAgenda.vue';
import PopularResources from '@/components/PopularResources.vue';
import { getModule } from 'vuex-module-decorators';
import ResourceClient, { ResourceLightResponse } from '../../client/resource.client';
import ResourcesModule from '../../store/modules/resources';
import { DEFAULT_LIST_OPTION_REQUEST } from '@/client/category.client';
import UserClient, { DefaultListOptionRequest, Film, UserResponse } from '@/client/user.client';
import { extractContainer } from '@/ioc/util';
import SettingsModule from '@/store/modules/settings';
import TYPES from '@/ioc/types';
import { SiteConfig } from '../../client/value.client';
import ValuesModule from '../../store/modules/values';
import UserFilmClient from '../../client/user-film.client';

import { Label } from '../../client/label.client';
import LabelsModule from '@/store/modules/labels';
import mixins from 'vue-typed-mixins'
import LabelMixin from '../../mixins/labels';

const AGENDA_SWIPE_RESOURCE_COUNT = 5;
const AGENDA_RESOURCE_COUNT = 10;
const RECENT_RESOURCE_COUNT = 10;
const EDITOR_PICK_RESOURCE_COUNT = 10;
const POPULAR_RESOURCE_COUNT = 8;

const AGENDA_CATEGORY_ID = 7;

export default mixins(LabelMixin).extend({
  name: 'NewsView',
  inject: ['container'],
  components: {
    NewsLayout,
  },
  beforeCreate: function() {
    document.body.className = '';
  },
  async created() {
      await getModule(ResourcesModule, this.$store).dispatchGetList(DEFAULT_LIST_OPTION_REQUEST);
  },
  asyncComputed: {
    async news(): Promise<ResourceLightResponse[]> {
      const settingsModule = getModule(SettingsModule, this.$store);
      const container = extractContainer(this);
      const resourcesClient =  container.get<ResourceClient>(TYPES.ResourceClient);
      return await resourcesClient.getHeritageNews(settingsModule.language,4);
    },
    async resources(): Promise<ResourceLightResponse[]> { 
      const settingsModule = getModule(SettingsModule, this.$store);
      const container = extractContainer(this);
      const resourcesClient =  container.get<ResourceClient>(TYPES.ResourceClient);
      const result = await resourcesClient.getHeritageNews(settingsModule.language,10);
      console.log(result)
      return result
    }

  },
  computed: {
  
  }
});
