
import Vue, { PropType } from 'vue';
import { mixin as clickaway } from 'vue-clickaway';

import Breadcrumbs, { BreadcrumbsItems } from '@/components/Breadcrumbs.vue';
import VideoPlayer from '@/components/ui/VideoPlayer.vue';

import { getModule } from 'vuex-module-decorators';
import OdImage from '@/components/ui/OdImage.vue';
import { UserResponse, Film } from '../client/user.client';
import UsersModule from '@/store/modules/users';
import ValuesModule from '@/store/modules/values';
import { format } from 'date-fns';
import { extractContainer } from '../ioc/util';
import TYPES from '../ioc/types';

import { Label } from '../client/label.client';
import LabelsModule from '@/store/modules/labels';
import mixins from 'vue-typed-mixins'
import LabelMixin from '../mixins/labels';
import UserFilmClient from '../client/user-film.client';
import SettingsModule from '@/store/modules/settings';


export default mixins(LabelMixin).extend({
  name: 'FilmView',
  inject: ["container"],
  props: {
    id: Number,
  },
  components: {
    Breadcrumbs,
    OdImage,
    VideoPlayer
  },
  asyncComputed: {
    async film():Promise<Film> {
      const settingsModule = getModule(SettingsModule, this.$store);
      const container = extractContainer(this);
      const filmClient =  container.get<UserFilmClient>(TYPES.UserFilmClient);

      const film = await filmClient.getById(settingsModule.language, this.id)
      
      const me = getModule(UsersModule, this.$store).selectMe;
      this.isOwner = film.user_id === me?.id || false

      if(film.user_id)
        this.breadcrumbsItems = [
          { url: '/profile/'+film.user_id, label: 'Profile' }, // AM User ID instead Film ID
          { url: '/profile/'+film.user_id +'/filmography', label: 'Filmography' }  // AM User ID instead Film ID
        ]

      return film;
    }
  },
  data(): { 
      breadcrumbsItems: BreadcrumbsItems[],
      isVideoPlaying: Boolean,
      isOwner: Boolean,
    } {
    return {
      breadcrumbsItems: [],
      isVideoPlaying: false,
      isOwner: false,
    }
  },
  methods: {
    playVideo() {
        this.isVideoPlaying = !this.isVideoPlaying;
        // Play video
        (this.$refs.player as any).playVideo()
    },
    stopVideo() {
      // Stop video
      (this.$refs.player as any).stopVideo()
      this.isVideoPlaying = !this.isVideoPlaying;
    },
    countryName(id: number): string {
      return getModule(ValuesModule, this.$store).getCountryName(id);
    },
    professionName(id: number): string {
      return getModule(ValuesModule, this.$store).getProfessionName(id);
    },
    filmGendre(id: number): string | undefined {
      return getModule(ValuesModule, this.$store).filmGendres.find(x => x.id === id)?.name;
    },
    
    async deleteMovie(event: Event){
       if(confirm("Do you really want to delete?")){
        const settingsModule = getModule(SettingsModule, this.$store);
        const container = extractContainer(this);
        const filmClient =  container.get<UserFilmClient>(TYPES.UserFilmClient);

        const film = await filmClient.getById(settingsModule.language, this.id)
        await filmClient.delete(film);
        
        getModule(UsersModule, this.$store).dispatchGetMe();
        
        this.$router.push('/profile/'+film.user_id+'/filmography')

      }
    }
   
  }
 
});

