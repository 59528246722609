import { injectable, inject, interfaces } from 'inversify';
import { AxiosStatic } from 'axios';
import Environment from '@/env/environment';
import TYPES from '@/ioc/types';
import { SupportedLanguage } from './supported-lang.type';
import { PagingOption, ListOptionRequest, CountResponse, DefaultListOptionRequest } from './user.client';

export const BACKEND_DATE_FORMAT = 'yyyy-MM-dd HH:mm';

export interface Page {
  id: number,
  summary: string,
  body: string,
  title: string,
  slug: string,
  visible: boolean,
  navigation_label: string,
  navigation_order: string,
  navigation_visible: boolean,
  site_id: number,
  show_breadcrumb: boolean
}

export interface PageBlock {
  id: number,
  page_id: number,
  order: number,
  text_1: string,
  text_2: string,
  text_3: string,
  text_4: string,
  image_1_filename: string,
  image_2_filename: string,
  image_3_filename: string,
  image_4_filename: string,
  type: number,
  visible: boolean,
  margin_bottom: boolean,
}

export const DEFAULT_PAGING_OPTION: PagingOption = { pageLength: 100, currentPage: 1 }
export const DEFAULT_LIST_OPTION_REQUEST: ListOptionRequest = {paging: DEFAULT_PAGING_OPTION, sort: [], filter: []};


@injectable()
export default class PageClient {
  constructor(
    @inject(TYPES.AxiosStatic) private readonly axios: AxiosStatic,
    @inject(TYPES.Environment) private readonly environment: Environment,
  ) { }
/*
  async getIdBySlug(lang: SupportedLanguage,slug: string): Promise<number> {
    const response = this.axios.get<Page>(`${this.environment.backendUrl}/api/v1/form/${lang}/pages/id/slug::${slug}`, {
      withCredentials: true,
    });
    return (await response).data.id;
  }
  */

  async getPageBySlug(lang: SupportedLanguage,id: Number): Promise<Page> {
    const response = this.axios.get<Page>(`${this.environment.backendUrl}/api/v1/entity/${lang}/pages/id/id::${id}`, {
      withCredentials: true,
    });
    return (await response).data;
  }

  async getBlocksBySlug(lang: SupportedLanguage,id: Number): Promise<PageBlock[]> {
    const fullRequest = {
      filter: [{column:"page_id", operator: "=", value: ""+id},{column:"visible", operator: "=", value: "true"}],
      sort: [{column: 'order', order:'asc'}],
      //paging:[]
    };
    const response = this.axios.post<PageBlock[]>(`${this.environment.backendUrl}/api/v1/entity/${lang}/page_blocks/list`, fullRequest, {
      withCredentials: true,
    });

    return (await response).data;
  }

  async getCustomPagesMenu(lang: SupportedLanguage, site: number): Promise<Page[]> {
    const fullRequest = {
      filter: [
        {column:"site_id", operator: "=", value: ""+site},
        {column:"navigation_visible", operator: "=", value: "true"}
      ],
      sort: [{column: 'navigation_order', order:'asc'}],
      //paging:[]
    };
    const response = this.axios.post<Page[]>(`${this.environment.backendUrl}/api/v1/entity/${lang}/pages/list`, fullRequest, {
      withCredentials: true,
    });

    return (await response).data;
  }
  
  
}
