import Environment from '@/env/environment';
import TYPES from '@/ioc/types';
import { AxiosStatic } from 'axios';
import { inject, injectable } from 'inversify';
import { SupportedLanguage } from './supported-lang.type';
import { PagingOption, ListOptionRequest, CountResponse, DefaultListOptionRequest } from './user.client';


export interface ActivityResponse{
  id: number,
  name: string,
  rigtholder: boolean,
  other: boolean,
  streamer: boolean,
}

export interface Accreditation {
  id_user: number;  
  company_name: string,
  company_country: string,
  company_address: string,
  company_postalcode: string,
  company_city: string,
  company_email: string,
  company_website: string,
  company_phone: string,
  company_activity: string,
  company_sub_activity: string,
  company_profile: string,
  company_filmography: string,
  company_trailer: string,
  company_trailer_password: string,
  company_library?: string,
  company_library_filename?: string,
  company_presentation?: string,
  company_presentation_filename?: string,
  personal_job_title: string,
  personal_phone: string,
  personal_mobile: string
  company_exist: boolean
  accreditation_request_activities: Activities[]
}

export interface Activities {
  activity_id: number
}

export function emptyAccreditation(user_id: number) {
    return {
        id_user: user_id,
        company_name: '',
        company_country: '',
        company_address: '',
        company_postalcode: '',
        company_city: '',
        company_email: '',
        company_website: '',
        company_phone: '',
        company_activity: '',
        company_sub_activity: '',
        company_profile: '',
        company_filmography: '',
        company_trailer: '',
        company_trailer_password: '',
        personal_job_title: '',
        personal_phone: '',
        personal_mobile: '',
        company_exist: false,
        accreditation_request_activities: []
        /*
        company_logo_url
        company_image_filename
        company_image_mime
        company_image
        */
    }
}

@injectable()
export default class AccreditationClient {
  constructor(
    @inject(TYPES.AxiosStatic) private readonly axios: AxiosStatic,
    @inject(TYPES.Environment) private readonly environment: Environment,
  ) { }

  async create(lang: SupportedLanguage, accreditation: Accreditation): Promise<number> {
    const response = await this.axios.post<any>(
      `${this.environment.backendUrl}/api/v1/form/${lang}/accreditation_request`,
      accreditation,
      { withCredentials: true, }
    );
    return +(response.data.id || -1);
  }

  async getActivitiesRightholder(
    lang: SupportedLanguage,
  ): Promise<ActivityResponse[]> {

    const request:ListOptionRequest = {
      filter: [{column: 'rightholder', operator: "=", value: "true"}],
      paging: {currentPage:1, pageLength: 10000},
      sort: [{column: 'name', order:'asc'}]
    } 

    const response = this.axios.post<ActivityResponse[]>(`${this.environment.backendUrl}/api/v1/public/val_activities/list`,
      request,
      {
        withCredentials: true,
      });
    return (await response).data;
  }

  async getActivitiesStreamer(
    lang: SupportedLanguage,
  ): Promise<ActivityResponse[]> {

    const request:ListOptionRequest = {
      filter: [{column: 'streamer', operator: "=", value: "true"}],
      paging: {currentPage:1, pageLength: 10000},
      sort: [{column: 'name', order:'asc'}]
    } 

    const response = this.axios.post<ActivityResponse[]>(`${this.environment.backendUrl}/api/v1/public/val_activities/list`,
      request,
      {
        withCredentials: true,
      });
    return (await response).data;
  }

  async getActivitiesOther(
    lang: SupportedLanguage,
  ): Promise<ActivityResponse[]> {

    const request:ListOptionRequest = {
      filter: [{column: 'other', operator: "=", value: "true"}],
      paging: {currentPage:1, pageLength: 10000},
      sort: [{column: 'name', order:'asc'}]
    } 

    const response = this.axios.post<ActivityResponse[]>(`${this.environment.backendUrl}/api/v1/public/val_activities/list`,
      request,
      {
        withCredentials: true,
      });
    return (await response).data;
  }
}
