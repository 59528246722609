
import Vue, { PropType } from "vue";
import ForumNewTopicForm, {
  SubmitFormEmit
} from "../components/forms/ForumNewTopicForm.vue";
import { extractContainer } from "../ioc/util";
import TYPES from "../ioc/types";
import UserFilmClient, { getDefaultFilm } from "@/client/user-film.client";
import { getModule } from "vuex-module-decorators";
import SettingsModule from "../store/modules/settings";
import { sub } from "date-fns";
import mixins from 'vue-typed-mixins'
import LabelMixin from '@/mixins/labels'
import ForumNewTopicClient from '../client/forum.new.topic.client';

export default mixins(LabelMixin).extend({
  name: "ForumNewTopicView",
  components: {
    ForumNewTopicForm
  },
  props: {
    category: Number,
  },
  data(): { showForm:boolean } {
    return {
      showForm: true
    };
  },
  methods: {
    async onSuggestionSubmit(submit: SubmitFormEmit) {
      const { topic } = submit;
      const container = extractContainer(this);
      const settingsModule = getModule(SettingsModule, this.$store);
      const ForumNewTopicClient = container.get<ForumNewTopicClient>(TYPES.ForumNewTopicClient);
      await ForumNewTopicClient.create(settingsModule.language, topic)
      this.showForm = false;
    }
  },
  watch: {
    
  },
  inject: ["container"]
});
