
import Vue from "vue";
import LabelsModule from '@/store/modules/labels';
import { getModule } from 'vuex-module-decorators';

export default Vue.extend({
    methods: {
        i18n(key: string): string {
          const labelsModule: LabelsModule = getModule(LabelsModule, this.$store);
          return labelsModule.getValue(key);
        }
      },
});