
import Vue, { PropType } from "vue";
import { ValueResponse } from "@/client/value.client";
import { FilterOption, SortOption } from "../client/user.client";

import mixins from "vue-typed-mixins";
import LabelMixin from "../mixins/labels";
import { isEqual, flatten } from 'lodash';

export interface FilterValue {
  name: string;
  checked: boolean;
  filter?: FilterOption;
  sort?: SortOption;
}

interface MainFilterUi {
  name: 'Sort' | 'Filter' | '#Tag';
  open: boolean;
  type: string;
  scrollable: boolean;
  children: FilterValue[];
}

export default mixins(LabelMixin).extend({
  name: "FiltersForum",
  props: {
    category: Number,
    addButton: Boolean,
    alignCenter: Boolean,
    filter: {
      type: Array as PropType<FilterOption[]>,
      required: true,
    },
    sort: {
      type: Array as PropType<SortOption[]>,
      required: true,
    },
    count: {
      type: Number,
      required: true,
    }
  },
  computed: {
    appliedFilters(): FilterValue[] {
      return this.filtersList.flatMap(x => x.children).filter(c => c.checked);
    },
    hasResources():boolean {
      return this.count > 0
    }
  },
  created() {
   
  },
  data(): {
    filtersList: MainFilterUi[];
    isFiltersListOpen: boolean;
  } {
    return {
      isFiltersListOpen: false,
      filtersList: [
        {
          name: "Sort",
          open: false,
          type: "radio",
          scrollable: false,
          children: [
            {
              name: "Newest",
              checked: true,
              sort: { column: "pubblication_date", order: "desc" }
            },
            {
              name: "Oldest",
              checked: false,
              sort: { column: "pubblication_date", order: "asc" }
            },
            {
              name: "A-Z",
              checked: false,
              sort: { column: "title", order: "asc" }
            },
            {
              name: "Z-A",
              checked: false,
              sort: { column: "title", order: "desc" }
            },
            {
              name: "Most commented",
              checked: false,
              sort: { column: "comment_count", order: "desc" }
            }
          ]
        }
      ]
    };
  },
  watch: {
    sort: {
      immediate: true,
      handler(sortProp: SortOption[]) {
        const filterList = this.filtersList.find(l => l.name === 'Sort');
        if (filterList) {
          filterList.children.forEach(f => f.checked = sortProp.some(p => isEqual(p, f.sort)))
        }
      },
    }
  },
  methods: {
    emitNewFilters() {
      this.$emit("filterchange", this.appliedFilters);
    },
    resetAll() {
      this.filtersList.forEach(fl =>
        fl.children.forEach(c => (c.checked = false))
      );
      const newest = this.filtersList[0].children.find(x => x.name == 'Newest')
      if(newest) {
        newest.checked = true;
      }
      this.emitNewFilters();
    },
    toggle(name: string) {
      const current = this.filtersList.find(x => x.name === name);
      if (current) {
        const newValue = !current.open;
        this.filtersList.forEach(x => (x.open = false));
        current.open = newValue;
      }
    },
    checkRadio(e: Event, item: MainFilterUi, subitem: FilterValue) {
      //e.preventDefault();
      item.children.forEach(c => (c.checked = false));
      subitem.checked = true;
      item.open = false;
      this.isFiltersListOpen = false;
      this.emitNewFilters();
    },
    toggleCheckbox(e: Event, item: MainFilterUi, subitem: FilterValue) {
      //e.preventDefault();
      subitem.checked = !subitem.checked;
      item.open = false;
      this.isFiltersListOpen = false;
      this.emitNewFilters();
    },
    openFilters() {
      this.isFiltersListOpen = true;
    },
    closeFilters() {
      this.isFiltersListOpen = false;
    }
  }
});
