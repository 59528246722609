
import Vue from "vue";
import { Cropper } from "vue-advanced-cropper";
import { NamedBlob } from "@/client/download.client";

import { Label } from '../../client/label.client';
import LabelsModule from '@/store/modules/labels';
import mixins from 'vue-typed-mixins'
import LabelMixin from '../../mixins/labels';
import Compressor from 'compressorjs';

export interface CropEvent {
  file: NamedBlob | null;
}

interface DataComponent {
  original: string | null;
  cropped: Blob | null;
  fileName: string | null;
}

function restoreData(): DataComponent {
  return {
    original: null,
    cropped: null,
    fileName: null,
  };
}

const COMPRESSION_OPTIONS: Compressor.Options = {
  quality: 0.6,
  maxWidth: 1200,
  // If > 3MB convert to JPEG
  convertSize: 1024,
}

export default mixins(LabelMixin).extend({
  name: "ImageCropper",
  components: {
    Cropper
  },
  props: {
    stencilProps: Object,
    className: String,
    label: String,
  },
  data(): DataComponent {
    return restoreData();
  },
  methods: {
    onCrop({ canvas }: { canvas: HTMLCanvasElement }) {
      canvas.toBlob(async (b) => {
        this.cropped = await this.compress(b as Blob);
        this.emitOnCrop();
      });
    },
    onCancel() {
      Object.assign(this, restoreData());
      this.emitOnCrop();
    },
    async compress(file: Blob): Promise<Blob> {
      return new Promise<Blob>((success, error) => {
        new Compressor(file, {
          ...COMPRESSION_OPTIONS,
          success: f => success(f),
          error: e => error(e),
        });
      });
    },
    async emitOnCrop() {
      const cropEvent: CropEvent = {
        file: this.cropped
          ? { 
            blob: this.cropped as Blob,
            name: this.fileName as string
          }
          : null
      };
      this.$emit("onCrop", cropEvent);
    },
    onNewOriginal(event: Event) {
      const target: EventTarget | null = event?.target;
      if (!target) {
        return;
      }

      const file: File = ((target as HTMLInputElement).files ?? [])[0];

      if (!file) {
        return;
      }

      this.fileName = file.name;

      const reader = new FileReader();
      reader.onload = e => {
        this.original = e.target?.result as string;
      };

      reader.readAsDataURL(file);
    }
  }
});
