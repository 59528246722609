import AuthModule from '@/store/modules/auth';
import { NavigationGuard, RawLocation, Route } from 'vue-router';
import UsersModule from '@/store/modules/users';
import { COMPLETE_PROFILE } from './toolbox';
import { Site, HERITAGE } from '@/service/site';
import ValuesModule from '@/store/modules/values';
import { HOME_PATH, STEP_AWAY, STEP_AWAY_LOGIN, RIGHTHOLDER_LIST, STREAMERS_LIST, USER_LIST, COMPANY_PATH, FILM_PATH, STEP_AWAY_DEMO, SCREENING_ROOM_LIST, STEP_AWAY_PAY, STEP_AWAY_REVIEW } from './heritage';
import NotificationModule from '@/store/modules/notifications';
import {onLogin} from '@/onLogin'
import Vue from 'vue'
import VueCookies from 'vue-cookies'
import { UserResponse } from '@/client/user.client';

export const LOGIN_PATH = '/login';


export function isSimpleUserAllowed(route:Route):boolean {
  if(route.name == "PageNotFound") return true;
  switch(route.path) {
    case LOGIN_PATH:
    case HOME_PATH:
    case RIGHTHOLDER_LIST:
    case STREAMERS_LIST:
    case USER_LIST:
    case STEP_AWAY:
    case STEP_AWAY_LOGIN:
    case STEP_AWAY_DEMO:
    case STEP_AWAY_PAY:
    case STEP_AWAY_REVIEW:
      //Aggiungi eventuali pagine per Trial
     
      return true
    default: 
      return route.path.includes("accreditation")
  }
}


const INTENTION_COOKIE = 'after_login'

export function setIntention(route:Route):void {
  Vue.$cookies.set(INTENTION_COOKIE,route.path)
}

export function goToIntentionOrHome(next: (to?: false | void | RawLocation | ((vm: Vue) => any) | undefined) => void):void {
  if(Vue.$cookies.isKey(INTENTION_COOKIE)) {
    const intention = Vue.$cookies.get(INTENTION_COOKIE)
    Vue.$cookies.remove(INTENTION_COOKIE)
    next(intention)
  } else {
    return next(HOME_PATH)
  }
}

function tryNext(usersModule: UsersModule,to:Route,next: (to?: RawLocation | false | ((vm: Vue) => any) | void) => void, me:UserResponse | null) {
  if(isSimpleUserAllowed(to) || usersModule.heritageFull) {
    return next();
  } else if(me) {
    // if(me.status && me.status.includes("active")) {
    //   return next(STEP_AWAY_DEMO);
    // } else 
    if(me.status && me.status.includes("review")) {
      return next(STEP_AWAY_REVIEW);
    } else if(me.status && me.status.includes("pending")) {
      return next(STEP_AWAY_PAY);
    } else {
      return next(STEP_AWAY);
    }
  }
}

async function doLogout(authModule: AuthModule,to:Route,next: (to?: RawLocation | false | ((vm: Vue) => any) | void) => void) {
  setIntention(to)
  await fetch('/auth/logout');
  authModule.setAuthToken(null);
  return next(LOGIN_PATH);
}

export function permitAuthenticatedUser(authModule: AuthModule, usersModule: UsersModule, valuesModule:ValuesModule, notificationModule:NotificationModule, site: Site): NavigationGuard {
  
  return async (to, from, next) => {

 
    // when navigating already logged in
    if (authModule.isLoggedIn) {
        let me = null;
        try{
          me = await usersModule.dispatchGetMe()
        } catch (e){
          return await doLogout(authModule,to,next)
        }
        if(!me) {
          return await doLogout(authModule,to,next)
        } else {
          return tryNext(usersModule,to,next,me)
        }
    } else {


      const response = await fetch('/auth/refresh/token')
      if(response.ok) {
        await authModule.setAuthToken(await response.text());
        console.log("guard")
        let me = null;
        try{
          me = await usersModule.dispatchGetMe()
        } catch (e) {
          return await doLogout(authModule,to,next)
        }
        if(!me) {
          return await doLogout(authModule,to,next)
        }
        await onLogin(notificationModule,valuesModule)
        tryNext(usersModule,to,next,me)
        
      } else {
          setIntention(to)
          next(LOGIN_PATH);
      }
    }
  }
}
