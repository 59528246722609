
import Vue, { PropType } from 'vue';
import TopicLayout from '@/components/TopicLayout.vue';
import { ForumComment } from '@/client/forum-comment.client';

import { Label } from '../client/label.client';
import LabelsModule from '@/store/modules/labels';
import mixins from 'vue-typed-mixins'
import LabelMixin from '../mixins/labels';

export default mixins(LabelMixin).extend({
  name: 'PopularTopics',
  props: {
    topics: {
      type: Array as PropType<ForumComment[]>,
      required: true,
    }
  },
  components: {
    TopicLayout,
  },
});
