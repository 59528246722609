
import Vue, { PropType } from 'vue'
import { Film, Cv, DefaultListOptionRequest } from '@/client/user.client';
import UserFilmClient, { getDefaultFilm } from "@/client/user-film.client";
import {sanitizeNumber} from '../../../service/form';
import { Cropper } from 'vue-advanced-cropper';
import ImageCropper, { CropEvent } from '../../ui/ImageCropper.vue';
import OdImage from '../../ui/OdImage.vue';
import { NamedBlob } from '../../../client/download.client';

import { Label } from '../../../client/label.client';
import LabelsModule from '@/store/modules/labels';
import mixins from 'vue-typed-mixins'
import LabelMixin from '../../../mixins/labels';
import ValueClient, { ValueResponse, Continent, Country } from '../../../client/value.client';
import ValuesModule from '../../../store/modules/values';
import { getModule } from 'vuex-module-decorators';
import Multiselect from 'vue-multiselect'
import { toVideoUrl, getVideoUrl } from '@/service/videoUrl';
import SettingsModule from '@/store/modules/settings';
import { extractContainer } from '@/ioc/util';
import TYPES from '@/ioc/types';
import { DEFAULT_PAGING_OPTION } from '@/client/category.client';


function sanitizeFilmForm(film: Film, imageName: string | null): Film {
  const img = imageName ? imageName : film.image_name
  return {
    ...film,
    year: sanitizeNumber(film.year),
    user_id: sanitizeNumber(film.user_id),
    length: sanitizeNumber(film.length),
    genre_id: sanitizeNumber(film.genre_id),
    image_name: img,
  }
}



export interface SubmitFormEmit {
  film: Film;
  newImage: NamedBlob | null;
  subTitles: {id_subtitle:number,file:NamedBlob}[];
  removeSubTitles: number[];
}

export default mixins(LabelMixin).extend({
  components: {
    ImageCropper,
    OdImage,
    Multiselect 
  },
  props: {
    film: {
      required: true,
      type: Object as PropType<Film>,
    },
  },
  inject: ['container'],
  methods: {
    srt2webvtt(data:string):string {
      // remove dos newlines
      var srt = data.replace(/\r+/g, '');
      // trim white space start and end
      srt = srt.replace(/^\s+|\s+$/g, '');

      // get cues
      var cuelist = srt.split('\n\n');
      var result = "";

      if (cuelist.length > 0) {
        result += "WEBVTT\n\n";
        for (var i = 0; i < cuelist.length; i=i+1) {
          result += this.convertSrtCue(cuelist[i]);
        }
      }

      return result;
    },
    convertSrtCue(caption:string):string {
      // remove all html tags for security reasons
      //srt = srt.replace(/<[a-zA-Z\/][^>]*>/g, '');

      var cue = "";
      var s = caption.split(/\n/);

      // concatenate muilt-line string separated in array into one
      while (s.length > 3) {
          for (var i = 3; i < s.length; i++) {
              s[2] += "\n" + s[i]
          }
          s.splice(3, s.length - 3);
      }

      var line = 0;

      // detect identifier
      if (!s[0].match(/\d+:\d+:\d+/) && s[1].match(/\d+:\d+:\d+/)) {
        cue += s[0].match(/\w+/) + "\n";
        line += 1;
      }

      // get time strings
      if (s[line].match(/\d+:\d+:\d+/)) {
        // convert time string
        var m = s[1].match(/(\d+):(\d+):(\d+)(?:,(\d+))?\s*--?>\s*(\d+):(\d+):(\d+)(?:,(\d+))?/);
        if (m) {
          cue += m[1]+":"+m[2]+":"+m[3]+"."+m[4]+" --> "
                +m[5]+":"+m[6]+":"+m[7]+"."+m[8]+"\n";
          line += 1;
        } else {
          // Unrecognized timestring
          return "";
        }
      } else {
        // file format error or comment lines
        return "";
      }

      // get cue text
      if (s[line]) {
        cue += s[line] + "\n\n";
      }

      return cue;
    },
    checkFileExists(id:number):boolean{
      return true
    },
    onNewSubtitle(event: Event,lang_id:number) {
      /*this.removeCurrentCv = false;
      this.newSelection = true;*/
      const target: EventTarget | null = event?.target;
      if (!target) {
        return;
      }

      const file: File = ((target as HTMLInputElement).files ?? [])[0];

      if(file.name.substr(file.name.length - 4) == '.srt'){
        //const subtitleValue:string = file.text();
        file.text().then(text => {
          //var srtRegex = /(.*\n)?(\d\d:\d\d:\d\d),(\d\d\d --> \d\d:\d\d:\d\d),(\d\d\d)/g;
          //let finalText = 'WEBVTT\n\n' + text.replace(srtRegex, '$1$2.$3.$4');
          let finalText = this.srt2webvtt(text);
          const fileBlob = new Blob([finalText], {type : 'text/vtt'}); 
          if (!file) {
            return;
          }
          this.removeSubtitleFile.filter(x=>{ x != lang_id})
          this.subtitleFiles.push({id_subtitle:lang_id,file:{blob:fileBlob,name:file.name}});
          this.film.subtitles.map(x=>{
            if(x.lang_id == lang_id){ 
                x.subtitle_file_name = file.name.substr(0,file.name.length - 4) + '.vtt'
            }
          });
        })
      }else{
        if (!file) {
          return;
        }
        this.removeSubtitleFile.filter(x=>{ x != lang_id})
        this.subtitleFiles.push({id_subtitle:lang_id,file:{blob:file,name:file.name}});
        this.film.subtitles.map(x=>{
          if(x.lang_id == lang_id){ 
              x.subtitle_file_name = file.name
          }
        })
      }
      this.removeSubtitleFile.filter(x=>{ x != lang_id})
      this.subtitleFiles.push({id_subtitle:lang_id,file:{blob:file,name:file.name}});
      this.film.subtitles.map(x=>{
        if(x.lang_id == lang_id){ 
            x.subtitle_file_name = file.name
        }
      })
    },
    removeSubtitle(lang_id:number){
      this.removeSubtitleFile.push(lang_id);
      this.film.subtitles.map(x=>{
        if(x.lang_id == lang_id){ 
            x.subtitle_file_name = '';
            x.subtitle_file = null
        }
      })
    },
    language(lang_id:number):string {
      //console.log(getModule(ValuesModule, this.$store).filmLanguages.filter(x => x.id == lang_id).map(x => x.name));
      return this.languages.filter(x => x.id == lang_id).map(x => x.name)[0];
    },
    onCancel(event: MouseEvent) {
      event.preventDefault();
      this.$emit('onCancel');
    },
    onSubmitForm(event: Event) {
      event.preventDefault();
      this.loadingForm = true;
      this.film.license_territories = this.countriesChecked.map((x,i) => {
        return {film_id: this.film.id, country_id: i, active: x}
      }).filter(x => x.active)
      this.film.restriction_countries = this.countriesRestrictionChecked.map((x,i) => {
        return {film_id: this.film.id, country_id: i, active: x}
      }).filter(x => x.active)
      this.film.restriction_activities = this.activitiesRestrictionChecked.map((x,i) => {
        return {film_id: this.film.id, activity_id: i, active: x}
      }).filter(x => x.active)
      
      const submitEmit: SubmitFormEmit = {
        film: sanitizeFilmForm(this.film, this.coverCropped?.name ?? null),
        newImage: this.coverCropped,
        subTitles: this.subtitleFiles,
        removeSubTitles: this.removeSubtitleFile
      };

      this.$emit('onSubmit', submitEmit);
    },
    onCoverCrop(event: CropEvent) {
      this.coverCropped = event.file;
    },
    isExclusivityChecked(e:ValueResponse): boolean {
      return this.film.license_exclusivity?.find(x => x.exclusivity_id === e.id) ? true : false
    },
    toggleExclusivity(e:ValueResponse) {
      if(this.isExclusivityChecked(e)) {
        this.film.license_exclusivity = this.film.license_exclusivity?.filter(x => x.exclusivity_id !== e.id)
      } else {
        const newEl = {film_id: this.film.id, exclusivity_id: e.id}
        this.film.license_exclusivity ? this.film.license_exclusivity.push(newEl) : [newEl]
      }
    },
    isLicenseTypeChecked(e:ValueResponse): boolean {
      return this.film.license_type?.find(x => x.license_type_id === e.id) ? true : false
    },
    toggleLicenseType(e:ValueResponse) {
      if(this.isLicenseTypeChecked(e)) {
        this.film.license_type = this.film.license_type?.filter(x => x.license_type_id !== e.id)
      } else {
        const newEl = {film_id: this.film.id, license_type_id: e.id}
        this.film.license_type ? this.film.license_type.push(newEl) : [newEl]
      }
    },
    continentActive(id:number):boolean {
      return this.continentOpen.find(x => x.id === id)?.open || false
    },
    continentActiveRestriction(id:number):boolean {
      return this.continentOpenRestriction.find(x => x.id === id)?.open || false
    },
    toggleContinent(el:HTMLElement,id:number) {
      this.continentOpen.filter(x => x.id != id).forEach(x => x.open = false)
      const co = this.continentOpen.find(x => x.id === id)
      if(co) {
        co.open = !co.open
      } else {
        this.continentOpen.push({id:id, open:true})
      }
      if(this.continentOpen.find(x => x.id === id)?.open) {
        setTimeout(() => {
          this.$scrollTo(el, 500, { easing: 'ease-in-out', container: '.modal', offset: -15})
        }, 0);
      }
    },
    toggleContinentRestriction(el:HTMLElement,id:number) {
      this.continentOpenRestriction.filter(x => x.id != id).forEach(x => x.open = false)
      const co = this.continentOpenRestriction.find(x => x.id === id)
      if(co) {
        co.open = !co.open
      } else {
        this.continentOpenRestriction.push({id:id, open:true})
      }
      if(this.continentOpenRestriction.find(x => x.id === id)?.open) {
        setTimeout(() => {
          this.$scrollTo(el, 500, { easing: 'ease-in-out', container: '.modal', offset: -15})
        }, 0);
      }
    },
    
    toggleAll(value:boolean, countries:Country[]) {
      countries.forEach(lt => this.countriesChecked[lt.id] = value)
      this.$forceUpdate()
    },
    toggleAllRestriction(value:boolean, countries:Country[]) {
      countries.forEach(lt => this.countriesRestrictionChecked[lt.id] = value)
      this.$forceUpdate()
    },
    isAllSelected(countries:Country[]):boolean {
      return countries ? countries.every(lt => this.countriesChecked[lt.id]) : false
    },
    isAllSelectedRestrictions(countries:Country[]):boolean {
      return countries ? countries.every(lt => this.countriesRestrictionChecked[lt.id]) : false
    }/*,
    setAllCheckboxs(){
      this.film.license_territories.forEach(lt => this.countriesChecked[lt.country_id] = true)
      this.film.restriction_countries.forEach(lt => this.countriesRestrictionChecked[lt.country_id] = true)  
    }*/
  },
  mounted() {
    const maxId = Math.max(...getModule(ValuesModule, this.$store).countries.filter(x => x.continent_id !== 1).map(x => x.id))
    this.countriesChecked.fill(false,0,maxId+1)
    //this.film.license_territories.forEach(lt => this.countriesChecked[lt.country_id] = true)
    //this.film.restriction_countries.forEach(lt => this.countriesRestrictionChecked[lt.country_id] = true)
    //this.film.restriction_activities.forEach(lt => this.activitiesRestrictionChecked[lt.activity_id] = true)
    setTimeout(() => this.film.license_territories.forEach(lt => this.countriesChecked[lt.country_id] = true), 1000);
    setTimeout(() => this.film.restriction_countries.forEach(lt => this.countriesRestrictionChecked[lt.country_id] = true), 1000);
  setTimeout(() => this.film.restriction_activities.forEach(lt => this.activitiesRestrictionChecked[lt.activity_id] = true), 1000);
    setTimeout(() => this.$forceUpdate(), 1500);
    
    //this.setAllCheckboxs();
    
  },
  data(): { coverCropped: NamedBlob | null, loadingForm: boolean, continentOpen:{id:number, open:boolean}[], continentOpenRestriction:{id:number, open:boolean}[], countriesChecked:boolean[], countriesRestrictionChecked:boolean[], activitiesRestrictionChecked:boolean[], subtitleFiles:{id_subtitle:number,file:NamedBlob}[],removeSubtitleFile:number[], fileCheck:string} {
    return {
      coverCropped: null,
      loadingForm: false,
      continentOpen: [],
      continentOpenRestriction: [],
      countriesChecked: [],
      countriesRestrictionChecked: [],
      activitiesRestrictionChecked: [],
      subtitleFiles: [],
      removeSubtitleFile: [],
      fileCheck: ""
    };
  },
  asyncComputed: {
    async continents(): Promise<Continent[]> {
      const settingsModule = getModule(SettingsModule, this.$store);
      const container = extractContainer(this);
      const userClient = container.get<ValueClient>(TYPES.ValueClient)
      const continents =  await userClient.getContinents(settingsModule.language);
      return continents.filter(x => x.id !== 1)
    },
    async activities(): Promise<ValueResponse[]> {
      const settingsModule = getModule(SettingsModule, this.$store);
      const container = extractContainer(this);
      const userClient = container.get<ValueClient>(TYPES.ValueClient)
      return await userClient.getValActivities();
    }
  },
  computed: {
    countries(): ValueResponse[] {
      return getModule(ValuesModule, this.$store).countries.filter(x => x.continent_id !== 1); //filter out inavtive continents
    },
    filmGendres(): ValueResponse[] {
      return getModule(ValuesModule, this.$store).filmGendres;
    },
    featureTypes(): ValueResponse[] {
      return getModule(ValuesModule, this.$store).filmFeaturesType;
    },
    languages(): ValueResponse[] {
      return getModule(ValuesModule, this.$store).filmLanguages;
    },
    licenseExclusivities(): ValueResponse[] {
      return getModule(ValuesModule, this.$store).licenseExclusivity;
    },
    licenseTypes(): ValueResponse[] {
      return getModule(ValuesModule, this.$store).licenseType;
    },
    trailerUrl: {
      get(): string {
        return toVideoUrl(this.film.trailer)
      },
      set(trailerUrl: string) {
          this.film.trailer = getVideoUrl(trailerUrl) || '';
      },
    },
    languagesModel: {
      get():{id:number, name:string | undefined}[] {
        const l = getModule(ValuesModule, this.$store).filmLanguages
        return this.film.languages ? this.film.languages.map(x => {
          return {
            id: x.lang_id,
            name: l.find(a => a.id === x.lang_id)?.name
          }
         } ) : []
      },
      set(values: {id:number, name:string | undefined}[]) {
        this.film.languages = values.map(x => {
          return {lang_id: x.id, film_id: this.film.id}
        })
      }
    },
    subtitlesModel: {
      get():{id:number, name:string | undefined}[] {
        const l = getModule(ValuesModule, this.$store).filmLanguages
        return this.film.subtitles ? this.film.subtitles.map(x => {
          return {
            id: x.lang_id,
            name: l.find(a => a.id === x.lang_id)?.name,
          }
         } ) : []
      },
      set(values: {id:number, name:string | undefined}[]) {
        //cerca se esiste, se non c'è aggiunge il campo
        const oldValues = this.film.subtitles;
        this.film.subtitles = [];
        console.log(values.length);
        if(values.length > 0){
          values.forEach(x => {
            if(oldValues.filter(y => { return y.lang_id == x.id }).length == 0){
              this.film.subtitles.push({lang_id: x.id, film_id: this.film.id})
            }else{
              const oldLang = oldValues.filter(z => { return z.lang_id == x.id});
              if(oldLang && oldLang[0].subtitle_file_name != ''){
                this.film.subtitles.push({lang_id: oldLang[0].lang_id, film_id: oldLang[0].film_id, subtitle_file_name: oldLang[0].subtitle_file_name, id: oldLang[0].id})
              }else{
                this.film.subtitles.push({lang_id: x.id, film_id: this.film.id})
              }
            }
          });
        }/*
        this.film.subtitles = values.map(x => {
          const subtitle_file_name = this.film.subtitles.find(y => {
            if(y.lang_id = x.id){
              const subtitle_file_name = y.subtitle_file_name ? y.subtitle_file_name : "";
              return true;
            }
            })
          return {lang_id: x.id, film_id: this.film.id, subtitle_file_name: subtitle_file_name}
        })*/
      }
    },
    countriesModel: {
      get():{id:number, name:string | undefined}[] {
        const l = getModule(ValuesModule, this.$store).countries
        return this.film.countries ? this.film.countries.map(x => {
          return {
            id: x.country_id,
            name: l.find(a => a.id === x.country_id)?.name
          }
         } ) : []
      },
      set(values: {id:number, name:string | undefined}[]) {
        this.film.countries = values.map(x => {
          return {country_id: x.id, film_id: this.film.id}
        })
      }
    },
    exclusivity: {
       get():boolean[] {
        return getModule(ValuesModule, this.$store).licenseExclusivity.map( x => {
          const current = this.film.license_exclusivity ? this.film.license_exclusivity.map(x => x.exclusivity_id) : [];
          return current.includes(x.id)
        })
        
      },
      set(values: boolean[]) {
        this.film.license_exclusivity = getModule(ValuesModule, this.$store).licenseExclusivity.filter( (x,i) => values[i]).map(x => {
          return {film_id: this.film.id, exclusivity_id: x.id }
        })
      }
    }
  }
})
