
import Vue, { PropType } from 'vue'
import { Social } from '@/client/user.client'


function resolveSocialSlot(social: Social): string {
      switch (social.social_id) {
        case 1:
          return 'facebook';
        case 2:
          return 'instagram';
        case 3:
          return 'linkedin';
        default:
          return 'unknown';
      }
    }

function resolveUrl(social: Social): string {
  const resolver: {[id: string]: (s: Social) => string} = 
  {
    facebook: (s: Social) => s.value, //`https://www.facebook.com/${s.value}/`,
    linkedin: (s: Social) => s.value, //`https://www.linkedin.com/in/${s.value}/`,
    instagram: (s: Social) => s.value, //`https://www.instagram.com/${s.value}/`,
  };
  const res = resolver[resolveSocialSlot(social)];
  if (!res) {
    return 'unknown';
  }

  return res(social);
}

export default Vue.extend({
  data(): {FACEBOOK_ID: number; LINKEDIN_ID: number; INSTAGRAM_ID: number} {
    return {
      FACEBOOK_ID: 1,
      INSTAGRAM_ID: 2,
      LINKEDIN_ID: 3,
    };
  },
  props: {
    socials: {
      type: Array as PropType<Social[]>,
    },
  },
  methods: {
    resolveSocialSlot,
    resolveUrl,
  }
})
