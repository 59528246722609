import { Module, VuexModule, Mutation, Action } from 'vuex-module-decorators';
import { UsersState, UserStatus } from './state';
import { RootState } from '../root';
import UserClient, { UserResponse, UserLightResponse,UserHeritageResponse, PagingOption, MeResponse } from '@/client/user.client';
import TYPES from '@/ioc/types';
import { uniqBy } from 'lodash';
import getDecorators from 'inversify-inject-decorators';
import container from '@/ioc/inversify.config';
import { byId } from '../resources';

const { lazyInject } = getDecorators(container);

@Module({ namespaced: true, name: 'users' })
export default class UsersModule extends VuexModule<ThisType<UsersState>, RootState> implements UsersState {
  status = 'NOT_YET' as UserStatus;
  all: UserResponse[] = [];
  allLight: UserLightResponse[] = [];
  me: UserResponse | null = null;
  meView: MeResponse | null = null;
  listMentionUsers: UserHeritageResponse[] = [];

  @lazyInject(TYPES.UserClient)
  private readonly userClient!: UserClient;

  get selectById() {
    return (id: number): UserResponse | undefined => this.all.find((r) => r.id === id);
  }


  get selectMe() {
    return this.me;
  }

  get profileComplete(): boolean {
    const complete =  this.me ? 
      this.me.country_id != null && this.me.profession_id != null && this.me.gender != null
    : false;
    return complete
  }

  get heritageFull():boolean {
    const result = this.meView && this.meView.status && this.meView.status.includes("active") ? true : false;
    return result
  }

  get inReview():boolean {
    const result = this.meView && this.meView.status && this.meView.status.includes("review") ? true : false;
    return result
  }

  get paymentLink():string | null | undefined {
    if(this.meView && this.meView.status && this.meView.status.includes("pending_payment"))
      return this.meView?.payment_link
    
  }

  
  @Mutation
  setInReview() {
    if(this.me)
      this.me.status = "review";
    if(this.meView)
      this.meView.status = "review";
  }

  @Mutation
  addLightList(lightList: UserLightResponse[]) {
    this.allLight = uniqBy([...lightList, ...this.allLight], byId);
  }

  @Mutation
  addUser(resource: UserResponse | null) {
    if(resource) 
      this.all = uniqBy([resource, ...this.all], byId);
  }

  @Mutation
  setStatus(status: UserStatus) {
    this.status = status;
  }

  @Mutation
  setMe(me: UserResponse | null) {
    this.me = me;
  }

  @Mutation
  setMeView(me: MeResponse | null) {
    this.meView = me;
  }

  @Mutation
  private setUsersMention(n: UserHeritageResponse[]) {
      this.listMentionUsers = n;
  }

  @Action
  async dispatchGetMe(force:boolean = false): Promise<UserResponse | null> {
    if(this.me && !force) return this.me
    this.setStatus('LOADING');
    let view: MeResponse | null = null;
    let user: UserResponse | null = null;
    try {
      view = await this.userClient.getMeView((this.context.rootState as RootState).settings.language);
      if(view) {
        user = await this.userClient.getById((this.context.rootState as RootState).settings.language,view.id);
        user.status = view.status
      }
    } catch (e) {
      this.setStatus('ERROR');
      throw e;
    }
    this.setStatus('SUCCESS');
    this.addUser(user);
    this.setMe(user)
    this.setMeView(view)
    return user;
  }

  @Action
  async dispatchGetById(id: number): Promise<UserResponse> {
    this.setStatus('LOADING');
    let user: UserResponse | null = null;
    try {
      user = await this.userClient.getById((this.context.rootState as RootState).settings.language,id);
    } catch (e) {
      this.setStatus('ERROR');
      throw e;
    }
    this.setStatus('SUCCESS');
    this.addUser(user);
    return user;
  }

  @Action
    public async dispatchUserMention(): Promise<boolean> { 
                
        this.setUsersMention(await this.userClient.getListToolbox('en',{currentPage: 1, pageLength: 1000}))

        return true
    }
}
