
import Vue from "vue";
import CompleteProfileForm, { SubmitFormEmit } from "../components/forms/CompleteProfileForm.vue";
import { getModule } from 'vuex-module-decorators';
import AuthModule from '../store/modules/auth';
import { LoginStatus } from '../store/modules/auth/state';
import { REGISTER_PATH, HOME_PATH } from '../router/toolbox';

import { Label } from '../client/label.client';
import LabelsModule from '@/store/modules/labels';
import mixins from 'vue-typed-mixins'
import LabelMixin from '../mixins/labels';
import { extractContainer } from '@/ioc/util';
import UserClient, { toProfile, UserProfile, fromProfile } from '@/client/user.client';
import TYPES from '@/ioc/types';
import SettingsModule from '@/store/modules/settings';
import DownloadClient from '@/client/download.client';
import UsersModule from '@/store/modules/users';

export default mixins(LabelMixin).extend({
  name: "CompleteProfileView",
  components: {
    CompleteProfileForm,
  },
  data():{showForm: boolean} {
    return {
      showForm: true
    }
  },
  asyncComputed: {
    async profile():Promise<UserProfile | undefined> {
      const container = extractContainer(this);
        const userClient = container.get<UserClient>(TYPES.UserClient);
        const settingsModule = getModule(SettingsModule, this.$store);
        const user = await userClient.getMe(
          settingsModule.language
        );
        if(user) {
          return toProfile(user);
        }
    }
  },
  methods: {
    async onProfileSubmit(submit: SubmitFormEmit) {
      const { profile, image, cv  } = submit;
      const container = extractContainer(this);
      const downloadClient = container.get<DownloadClient>(
        TYPES.DownloadClient
      );
      const userClient = container.get<UserClient>(TYPES.UserClient);
      const settingsModule = getModule(SettingsModule, this.$store);
      const currentUser = await userClient.getMe(settingsModule.language);
      
      if(currentUser) {
      
        profile.id = currentUser.id;
        const user = fromProfile(currentUser,profile,image?.name || null,cv?.name || null)
        await userClient.update(settingsModule.language,user)
        const updatedUser = await userClient.getById(settingsModule.language,profile.id)
        if(image && updatedUser.image[0] && updatedUser.image[0].id) {
          await downloadClient.uploadEdit(updatedUser.image[0].id, image, "user_images.image")
        }
        if(cv && updatedUser.cv[0] && updatedUser.cv[0].id) {
          await downloadClient.uploadEdit(updatedUser.cv[0].id, cv, "user_cv.cv")
        }
        this.showForm = false;
      }
    }
  },
  beforeCreate: function() {
    document.body.className = '';
  },
  inject: ["container"]
});
