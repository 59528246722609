
import Vue, { PropType } from 'vue';
import { mixin as clickaway } from 'vue-clickaway';
import { getModule } from 'vuex-module-decorators';
import UsersModule from '@/store/modules/users';
import ValuesModule from '@/store/modules/values';
import OdImage from '@/components/ui/OdImage.vue';
import { UserResponse, Film } from '../../client/user.client';
import { CategoryLight } from '@/client/category.client';
import CategoriesModule from '@/store/modules/categories';
import AuthModule from '../../store/modules/auth';
import { LOGIN_PATH } from '../../router/guard';
import SettingsModule from '@/store/modules/settings';
import { extractContainer } from '@/ioc/util';
import TYPES from '@/ioc/types';
import { mapState } from 'vuex';
import { RootState } from '@/store/modules/root';

import PageClient, {Page, PageBlock} from '@/client/page.client';

interface MenuItemChild {
  url: MenuUrl;
  name: string;
  nameMobile?: string;
  marginTop?: boolean;
  target?: string;
  icon?: string;
  children?: MenuItemChild[];
}

export interface MenuUrl {
    local?: string;
    absolute?: string;
}

export interface MenuItem {
    url: MenuUrl;
    name: string;
    open?: boolean;
    children?: MenuItemChild[];
}

function resourceToMenuChild(r: CategoryLight) {
  return {
    url: { local:`/resources?categories=${r.id}`},
    name: r.name,
    nameMobile: r.name_mobile,
    target: "_self",
  };
}


/**
 * UI component for Header
 * It gets menuItems from the properties.
 */
export default Vue.extend({
  name: 'Header',
  mixins: [clickaway],
  components: {
    OdImage,
  },
  props: {
    categories: {
        type: Array as PropType<CategoryLight[]>,
        required: true,
    }
  },
  inject: ['container'],
  created() {

    getModule(UsersModule, this.$store).dispatchGetMe();
     this.menuItems = [
        {
          url: { local:"/"},
          name: "Resources",
          open: true,
          children: [
            {
              url: { local:"/resources"},
              name: "All",
            },
            ...this.categories.map(resourceToMenuChild)
          ]
        },
        { url: { local:"/forum" }, name: "Forum Area" },
        { url: { local:"/members" }, name: "Members" },
        { url: { local:"/page" }, name: "dynamicMenu" },
        {
          url: { local:"/" },
          name: "Connect",
          open: false,
          children: [
            {
              url: { absolute:"https://locarnofestival.ch/opendoors" },
              name: "Open Doors Activities"
            },
            {
              url: { absolute:"https://locarnofestival.ch" },
              name: "Locarno Film Festival"
            },
            {
              url: { absolute:"https://locarnofestival.ch" },
              name: "Open Doors. Follow us!",
              marginTop: true,
              children: [
                {
                  url: { absolute:"https://www.facebook.com/Opendoorslocarno"},
                  icon: '<i class="fab fa-facebook-square"></i>',
                  name: "Facebook",
                }
              ]
            },
            {
              url: { absolute:"https://locarnofestival.ch"},
              name: "Locarno Film Festival. Follow us!",
              children: [
                {
                  url: { absolute:"https://www.facebook.com/LocarnoFilmFestival" },
                  icon: '<i class="fab fa-facebook"></i>',
                  name: "Facebook",
                },
                {
                  url: { absolute:"https://www.instagram.com/filmfestlocarno"} ,
                  icon: '<i class="fab fa-instagram"></i>',
                  name: "Instagram",
                  target: "_blank"
                }
              ]
            }
          ]
        }
      ];

  },
  asyncComputed: {
    async menuPages(): Promise<Page[]> {
        //this.status = 'LOADING';
        const settingsModule = getModule(SettingsModule, (this as any).$store);
        const container = extractContainer(this);
        const PageClient = container.get<PageClient>(TYPES.PageClient);
        return await PageClient.getCustomPagesMenu(settingsModule.language,1);
        //this.status = 'SUCCESS';
    },
  },
  computed: mapState({
    profile(): UserResponse | null {
        return getModule(UsersModule, (this as any).$store).me;
    },
    navbarProfileList() {
        const me = getModule(UsersModule, (this as any).$store).me;
        if(me) {
            return [
                { url: '/profile/'+me.id, name: 'Profile' },
                { url: '/profile/'+me.id+'/notifications-resources', name: 'Notifications' },
                { url: '/profile/'+me.id+'/settings', name: 'Settings' }
            ]
        } else return []
    },
    navbarProfileListMobile() {
        const me = getModule(UsersModule, (this as any).$store).me;
        if(me) {
            return [
                { url: '/profile/'+me.id, name: 'Profile' },
                { url: '/profile/'+me.id+'/filmography', name: 'Filmography' },
                { url: '/profile/'+me.id+'/recently-viewed', name: 'Recently viewed' },
                { url: '/profile/'+me.id+'/your-resources', name: 'Your resources' },
                { url: '/profile/'+me.id+'/favorites-resources', name: 'Favorites' },   
                { url: '/profile/'+me.id+'/notifications-resources', name: 'Notifications' },   
                { url: '/profile/'+me.id+'/settings', name: 'Settings' },
            ]
        } else return []
    },
  }),
  methods: {
    submitSearch() {
        this.toggleSearchPanel();
        this.$router.push('/search/'+this.searchWords);
        this.searchWords = "";
    }, 
    toggleMenu(menu: MenuItem) {
      if (this.isNavbarProfileOpen) {
        this.toggleProfilePanel();
      }
      if (this.isNavbarSearchOpen) {
        this.toggleSearchPanel();
      }
      menu.open = !menu.open;
    },
    toggleMainNavigationMobile() {
        if (this.isNavbarSearchOpen) {
            this.toggleSearchPanel();
        } else if (this.isNavbarProfileOpen) {
            this.toggleProfilePanel();
        } else if (this.isNavbarProfileOpenMain) {
            this.isNavbarMainOpen = false;
            this.isNavbarProfileOpen = !this.isNavbarProfileOpen;   
            this.isNavbarProfileOpenMain = !this.isNavbarProfileOpenMain;
            
        } else {
            this.isNavbarMainOpen = !this.isNavbarMainOpen;  
        }
    },
    toggleMainNavigation() {
        if(window.innerWidth <= 850) {
            return this.toggleMainNavigationMobile()
        }
        if (this.isNavbarSearchOpen) {
            this.toggleSearchPanel();
        }
        if (this.isNavbarProfileOpen) {
            this.toggleProfilePanel();
        }
        if (this.isNavbarProfileOpenMain) {
            this.isNavbarMainOpen = false;
            this.isNavbarProfileOpen = !this.isNavbarProfileOpen;   
            this.isNavbarProfileOpenMain = !this.isNavbarProfileOpenMain;
            
        } else {
            this.isNavbarMainOpen = !this.isNavbarMainOpen;  
        }
    },
    toggleProfilePanel() {
        if (this.isNavbarSearchOpen) {
            this.toggleSearchPanel();
        }
        this.isNavbarProfileOpen = !this.isNavbarProfileOpen;   
        this.isNavbarProfileOpenMain = !this.isNavbarProfileOpenMain;    
    },
    toggleSearchPanel() {
        if (this.isNavbarProfileOpen) {
            this.toggleProfilePanel();
        }
        this.isNavbarSearchOpen = !this.isNavbarSearchOpen;    
        const field: any = this.$refs.searchInput;
        this.$nextTick(() => field.focus())   
    },
    closeAll() {
      this.isNavbarSearchOpen = false;
      this.isNavbarProfileOpen = false;
      this.isNavbarProfileOpenMain = false;
      this.isNavbarSearchOpen = false;
      this.isNavbarMainOpen = false;
    },
    async logout() {
        const authModule = getModule(AuthModule, this.$store)
        await authModule.setAuthToken(null);
        this.$router.push(LOGIN_PATH);
    }
  },/*
  watch: {
    '$route': function(to, from) {
      console.log("test");
      this.forumTopics();
    }
  },*/
  data(): {
      menuItems: MenuItem[];
      isNavbarMainOpen: boolean;
      isNavbarMainElementOpen: boolean;
      isNavbarSearchOpen: boolean;
      isNavbarProfileOpen: boolean;
      isNavbarProfileOpenMain: boolean;
      searchWords: String;     
  } {
    return {
      menuItems: [],
      isNavbarMainOpen: false,
      isNavbarMainElementOpen: false,
      isNavbarSearchOpen: false,
      isNavbarProfileOpen: false,
      isNavbarProfileOpenMain: false,
      searchWords: "", 
    };
  },
});
