
import Vue, { PropType } from 'vue';
import { mixin as clickaway } from 'vue-clickaway';

import VideoPlayer from '@/components/ui/VideoPlayer.vue';

import { getModule } from 'vuex-module-decorators';
import OdImage from '@/components/ui/OdImage.vue';
import { UserResponse, Film } from '../../client/user.client';
import UsersModule from '@/store/modules/users';
import ValuesModule from '@/store/modules/values';
import { format } from 'date-fns';
import { extractContainer } from '../../ioc/util';
import TYPES from '../../ioc/types';

import { Label } from '../../client/label.client';
import LabelsModule from '@/store/modules/labels';
import mixins from 'vue-typed-mixins'
import LabelMixin from '../../mixins/labels';
import UserFilmClient, { ScreeningRoomFilm } from '../../client/user-film.client';
import SettingsModule from '@/store/modules/settings';
import ValueClient, { Continent, ValueResponse } from '@/client/value.client';
import { Controller } from 'swiper/js/swiper.esm';
import { STEP_AWAY_DEMO } from '@/router/heritage';
import { Organization, OrganizationLight, fromHeritageUser } from '../../client/organization.client';


export default mixins(LabelMixin).extend({
  name: 'FilmView',
  inject: ["container"],
  props: {
    id: Number,
  },
  components: {
    OdImage,
    VideoPlayer
  },
  computed:{
    profile(): UserResponse | null {
        return getModule(UsersModule, this.$store).me;
    },
  },
  asyncComputed: {
    async continents(): Promise<Continent[]> {
      const settingsModule = getModule(SettingsModule, this.$store);
      const container = extractContainer(this);
      const valueClient = container.get<ValueClient>(TYPES.ValueClient)
      const continents =  await valueClient.getContinents(settingsModule.language);
      return continents.filter(x => x.id !== 1)
    },
    async film():Promise<Film> {
      const settingsModule = getModule(SettingsModule, this.$store);
      const container = extractContainer(this);
      const filmClient =  container.get<UserFilmClient>(TYPES.UserFilmClient);

      const film = await filmClient.getById(settingsModule.language, this.id)

      if(film.screening_room_link) {
        this.screening_room = await filmClient.getScreeningRoomFilm(settingsModule.language,film.screening_room_link)
      }

      const me = getModule(UsersModule, this.$store).selectMe;

      this.filmOwner = film.user_id === me?.id || me?.user_organization.map(x => x.organization_id).includes(film.company_id || -1) || false
      
    // check activity
    if(this.profile?.user_organization[0]){
      this.profile.user_organization[0].organization[0].main_activities.forEach(o => {
        film.restriction_activities.forEach(a => {
          if(o.activity_id == a.activity_id){
            if(!this.filmOwner){
              this.$router.push('/PageNotFound');
            }
          }
        })
      })
    }

    //check country
    //console.log(this.profile?.country_id);
    //console.log('country: '+this.profile?.country_id);
    if(typeof(this.profile?.country_id) == 'undefined' || this.profile?.country_id == null){
      this.accessFilmCountry = true;
    }else if(film.license_territories.length == 0){
      this.accessFilmCountry = true;
      this.showWorldwide = true;
    }else if(film.license_territories.filter(c => c.country_id == this.profile?.country_id).length == 0){
        this.accessFilmCountry = false;
    }


      return film;
    },
    async demoUser(): Promise<Boolean> {
      const me = await getModule(UsersModule, this.$store).dispatchGetMe();
      return me && me.roles && me.roles.includes("Demo") ? true : false
    }
  },
  async created() {
    const me = getModule(UsersModule, this.$store).selectMe;
    if(me) {

      const container = extractContainer(this);
      const filmClient =  container.get<UserFilmClient>(TYPES.UserFilmClient);
      await filmClient.touchView(this.id,me.id)
    }
  },
  data(): { 
      isVideoPlaying: Boolean,
      isVideoPlayingFull: Boolean,
      isFilmPaused: Boolean,
      filmOwner: boolean,
      screening_room: ScreeningRoomFilm | null,
      accessFilmCountry: boolean,
      subtitlesClean: String[][],
      texttrailer: String,
      showWorldwide: Boolean
    } {
    return {
      isVideoPlaying: false,
      isVideoPlayingFull: false,
      isFilmPaused: false,
      filmOwner: false,
      screening_room: null,
      accessFilmCountry: true,
      subtitlesClean: [],
      texttrailer: "Play trailer",
      showWorldwide: false
    }
  },
  methods: {
    async playTrailer() {
        this.isVideoPlaying = true;
        this.isVideoPlayingFull = false;
        this.texttrailer = "Continue with the trailer";
        // Play video
        /*
        try {
          (this.$refs.playerFull as any).stopVideo()
        } catch(e) {}
        */
        (this.$refs.player as any).playVideo()
        const me = getModule(UsersModule, this.$store).selectMe;
        if(me) {
          const container = extractContainer(this);
          const filmClient =  container.get<UserFilmClient>(TYPES.UserFilmClient);
          await filmClient.touchTrailer(this.id,me.id)
        }
        return
    },
    async playFullMovie(demoUser:Boolean) {
        if(demoUser) {
          this.$router.push(STEP_AWAY_DEMO)
          return;
        }
        this.isVideoPlayingFull = true;
        // Play video
        try {
          (this.$refs.player as any).stopVideo()
        } catch(e) {}
        (this.$refs.playerFull as any).playVideo()
        const me = getModule(UsersModule, this.$store).selectMe;
        if(me) {
          const container = extractContainer(this);
          const filmClient =  container.get<UserFilmClient>(TYPES.UserFilmClient);
          await filmClient.touchFull(this.id,me.id)
        }
        return
    },
    stopVideo() {

      if(this.isVideoPlayingFull){
        this.isFilmPaused = true;
      }
      this.isVideoPlaying = false;
      this.isVideoPlayingFull = false;
      // Stop video
      try {
        (this.$refs.player as any).stopVideo()
      } catch(e) {}

      try {
        (this.$refs.playerFull as any).stopVideo()
      } catch(e) {}
  },
    professionName(id: number): string {
      return getModule(ValuesModule, this.$store).getProfessionName(id);
    },
    professionNames(professions: ValueResponse[]): string[] {
      let professions_list:string[] = []
      professions.forEach(c => {
        if(c.activity_id){
          professions_list.push(getModule(ValuesModule, this.$store).getActivityName(c.activity_id));
        }
      });
      return professions_list;
    },
    countryName(continents:Continent[],ids: {country_id:number}[]): any[] {
      //console.log("teeeee"+continents.length);
      if(ids.length == 0)
        return ['Worldwide']

      if(ids && continents) {
        let country_ids = [... ids.map(x => x.country_id)]
        const countries = getModule(ValuesModule, this.$store).countries
      
        if(continents.flatMap(x => x.countries).every(c => country_ids.includes(c.id))) {
          return ['Worldwide']
        }

        let cont:string[] = []
        continents.forEach(c => {
          if(c.countries.every(c => country_ids.includes(c.id))) { //all country in the continent
            cont.push(c.name) //add continent name
            country_ids = country_ids.filter(x => !c.countries.map(x => x.id).includes(x)) //remove all country from the selected continent
          }
        })

        
        return [...cont, ...countries.filter(x => country_ids.includes(x.id)).map(x => x.name)];
      } else {
        return []
      }

    },
    filmGendre(id: number): string | undefined {
      return getModule(ValuesModule, this.$store).filmGendres.find(x => x.id === id)?.name;
    },
    featureType(id:number):string | undefined {
      return getModule(ValuesModule, this.$store).filmFeaturesType.find(x => x.id === id)?.name;
    },
    languages(ids:{lang_id:number}[]):string[] {
      return getModule(ValuesModule, this.$store).filmLanguages.filter(x => ids.map(y => y.lang_id).includes(x.id)).map(x => x.name);
    },
    licenseType(ids:{license_type_id:number}[]):string[] {
      return getModule(ValuesModule, this.$store).licenseType.filter(x => ids.map(y => y.license_type_id).includes(x.id)).map(x => x.name);
    },
    licenseExclusivity(ids:{exclusivity_id:number}[]):string[] {
      return getModule(ValuesModule, this.$store).licenseExclusivity.filter(x => ids.map(y => y.exclusivity_id).includes(x.id)).map(x => x.name);
    },
    async deleteFilm(film:Film) {
      if(confirm("Are you sure you want to delete the film?")) {
        const container = extractContainer(this);
        const filmClient =  container.get<UserFilmClient>(TYPES.UserFilmClient);
        filmClient.delete(film)
        this.$router.go(-1);
      }
    }
   
  }
 
});

