
import { Subtitle } from '@/client/user-film.client';
import Vue, { PropType } from 'vue';
import { getModule } from 'vuex-module-decorators';
import ValuesModule from '../../store/modules/values';


export interface BreadcrumbsItems {
    url:string;
    name:string;
}



export default Vue.extend({
  name: 'VideoPlayerSubtitles',
  props: {
    item: {
      type: Object as PropType<Subtitle>,
      required: true
    }
  },
  methods: {
    language(lang_id:number):string {
      const language =  getModule(ValuesModule, this.$store).filmLanguages.filter(x => x.id == lang_id).map(x => x.name)[0];
      return language
    }
  }
});
