import { PagingOption, ListOptionRequest } from '@/client/user.client';
import TYPES from '@/ioc/types';
import { uniqBy } from 'lodash';
import getDecorators from 'inversify-inject-decorators';
import {
  Action, Module, Mutation, VuexModule,
} from 'vuex-module-decorators';
import container from '@/ioc/inversify.config';
import ResourceClient, { ResourceResponse, ResourceLightResponse } from '@/client/resource.client';
import { ResourcesState, ResourceStatus } from './state';
import { RootState } from '../root';

export const byId = (r: { id: number }) => r.id;

const { lazyInject } = getDecorators(container);


@Module({ namespaced: true, name: 'resources' })
export default class ResourcesModule extends VuexModule<ThisType<ResourcesState>, RootState> implements ResourcesState {
  status = 'NOT_YET' as ResourceStatus;
  all: ResourceResponse[] = [];
  allLight: ResourceLightResponse[] = [];

  @lazyInject(TYPES.ResourceClient)
  private readonly resourceClient!: ResourceClient;

  get selectById() {
    return (id: number): ResourceResponse | undefined => this.all.find((r) => r.id === id);
  }

  get lightFilterBy() {
    return (filters: { categories?: number[] | null; isEditorPick?: boolean | null; isExpired?: boolean | null } = {}): ResourceLightResponse[] => {
      const {
        categories = null,
        isEditorPick = null,
        isExpired = null,
      } = filters;
      let resources = this.allLight;

      if (isEditorPick !== null) {
        resources = resources.filter(r => r.editor_pick === isEditorPick)
      }

      if (categories !== null) {
        resources = resources.filter(r => categories.includes(r.category_id))
      }

      if (isExpired !== null) {
        resources = resources.filter(r => r.has_expired === isExpired)
      }

      return resources;
    };
  }

  @Mutation
  addLightList(lightList: ResourceLightResponse[]) {
    this.allLight = uniqBy([...lightList, ...this.allLight], byId);
  }

  @Mutation
  addResource(resource: ResourceResponse) {
    this.all = uniqBy([resource, ...this.all], byId);
  }

  @Mutation
  setStatus(status: ResourceStatus) {
    this.status = status;
  }

  @Action
  async dispatchGetList(
    request: ListOptionRequest,
  ): Promise<ResourceLightResponse[]> {
    this.setStatus('LOADING');
    let resources: ResourceLightResponse[] | null = null;
    try {
      resources = await this.resourceClient
        .getList(this.context.rootState.settings.language, request);
    } catch (e) {
      this.setStatus('ERROR');
      throw e;
    }
    this.setStatus('SUCCESS');
    this.addLightList(resources);
    return resources;
  }

  @Action
  async dispatchGetById(id: number): Promise<ResourceResponse> {
    this.setStatus('LOADING');
    let resource: ResourceResponse | null = null;
    try {
      resource = await this.resourceClient
        .getById((this.context.rootState as RootState).settings.language, id);
    } catch (e) {
      this.setStatus('ERROR');
      throw e;
    }
    this.setStatus('SUCCESS');
    this.addResource(resource);
    return resource;
  }
}
