
import Vue from 'vue';
import ResourcesModule from '@/store/modules/resources';
import { getModule } from 'vuex-module-decorators';
import ResourceClient, { ResourceResponse, Attachment, Embedded } from '../client/resource.client';
import Breadcrumbs, { BreadcrumbsItems } from '@/components/Breadcrumbs.vue';
import UserClient, { UserResponse } from '../client/user.client';
import UsersModule from '../store/modules/users';
import OdImage from '../components/ui/OdImage.vue';
import VideoPlayer from '../components/ui/VideoPlayer.vue';
import OdDownloadButton from '../components/ui/OdDownloadButton.vue';
import Favorite from '@/components/ui/Favorite.vue';

import ResourceDiscussion, {
    SubmitFormEmit
} from '@/components/forms/ResourceDiscussion.vue';
import TagResolver from '../components/containers/TagResolver.vue';
import { formatPublication, formatDateRange } from '@/service/ui';
import { format } from 'date-fns';

import { Label } from '../client/label.client';
import PartnerClient, { Partner } from '../client/partner.client';

import ResourceCommentClient, { ResourceComment } from '../client/resource-comment.client';
import LabelsModule from '@/store/modules/labels';
import mixins from 'vue-typed-mixins'
import LabelMixin from '../mixins/labels';
import SettingsModule from '@/store/modules/settings';
import { extractContainer } from '@/ioc/util';
import TYPES from '@/ioc/types';
import { Category, CategoryLight } from '@/client/category.client';
import CategoriesModule from '@/store/modules/categories';

export default mixins(LabelMixin).extend({
  name: 'ResourceView',
  props: {
    id: Number,
    type: Number,
    id_parent: Number,
    id_comment: Number
  },
  inject: ['container'],
  async created() {
    const resourcesModule = getModule(ResourcesModule, this.$store);
    const resource = await resourcesModule.dispatchGetById(this.id);
    const usersModule: UsersModule = getModule(UsersModule, this.$store);

    if (resource.suggestor_id) {
        await usersModule.dispatchGetById(resource.suggestor_id);
    }

    const settingsModule = getModule(SettingsModule, this.$store);
    const container = extractContainer(this);
    const resourcesClient =  container.get<ResourceClient>(TYPES.ResourceClient);
    const user = await usersModule.dispatchGetMe();
    if(user) {
      await resourcesClient.touch(settingsModule.language,resource.id,user.id)
    }

    await getModule(CategoriesModule, this.$store).dispatchGetList()
    

  },
  filters: {
    formatDate(date: Date): string {
      return formatPublication(date);
    },
    resourceLabels(resource: ResourceResponse): string {
      return [
        (r: ResourceResponse) => r.embedded?.length > 0 && 'Video',
        (r: ResourceResponse) => r.links?.length > 0 && 'Link',
        (r: ResourceResponse) => r.attachments.length > 0 && 'Document',
      ] .map(r => r(resource))
        .filter(r => !!r)
        .join(', ');
    },
    formatEventDate(r: ResourceResponse) {
      return formatDateRange(r.start, r.end);
    },
  },
  asyncComputed: {
    
    async singlePartners(): Promise<Partner[] | null> {
      if(this.resource) {
        const settingsModule = getModule(SettingsModule, this.$store);
        const container = extractContainer(this);
        const partnerClient =  container.get<PartnerClient>(TYPES.PartnerClient);
        const partner = await partnerClient.getById(settingsModule.language, this.resource.partner_id)
        if(partner) {
          return [partner]
        } else return null
      } else {
        return null
      }
    },
    async partners(): Promise<Partner[] | null> {
      if(this.resource) {
        
        const settingsModule = getModule(SettingsModule, this.$store);
        const container = extractContainer(this);
        const partnerClient =  container.get<PartnerClient>(TYPES.PartnerClient);
          const partners:Partner[][] = await Promise.all (this.resource.partners.map(async (partner) => {
            if(partner.partner_id)
            return [await partnerClient.getById(settingsModule.language, partner.partner_id)]
            else
            return []
          } ));
          if(partners){
            return partners.flat()
          }else{
            return null
          }
      } else {
        return null
      }
    },
    async totalComments(): Promise<number> {
      const container = extractContainer(this);
        const settingsModule = getModule(SettingsModule, this.$store);
        const ResourceClient = container.get<ResourceClient>(TYPES.ResourceClient);
        const count = await ResourceClient.getTotalComments(settingsModule.language, this.id);
        console.log(count);
        if(count){
          return count;
        }else{
          return 0;
        }
    },
  },
  computed: {
    resource(): ResourceResponse | null {
      const resourcesModule: ResourcesModule = getModule(ResourcesModule, this.$store);
      return resourcesModule.selectById(this.id) || null;
    },
    suggestor(): UserResponse | null {
      if (!this.resource) {
        return null;
      }
      const usersModule: UsersModule = getModule(UsersModule, this.$store);
      const suggestor = usersModule.selectById(this.resource.suggestor_id) || null;
      return suggestor;
    },
    sanitizedAttachments(): Attachment[] {
      if (!this.resource) {
        return [];
      }
      return this.resource.attachments.filter(a => !!a.filename);
    },
    category():CategoryLight | undefined {
      return getModule(CategoriesModule, this.$store).allLight.find(c => c.id == this.resource?.category_id)
    },
    breadcrumbsItems(): BreadcrumbsItems[] {
      const result =  [
        { url: '/resources', label: 'Resources' }
      ]
      if(this.category) {
        result.push(
          { url: '/resources?categories='+this.category.id, label: this.category.name }
        )
      }
      return result;
    }
  },
  components: {
    Breadcrumbs,
    OdImage,
    OdDownloadButton,
    TagResolver,
    Favorite,
    VideoPlayer,
    ResourceDiscussion
  },
  data(): {
    lastId: number,
    reloadComments0: number
  } { 
    return {
      lastId: 0,
      reloadComments0: 0
    };
  },
  methods: {
    async onCommentSubmit(submit: SubmitFormEmit) {
        const { comment } = submit;
        const container = extractContainer(this);
        const settingsModule = getModule(SettingsModule, this.$store);

        const ResourceCommentClient = container.get<ResourceCommentClient>(TYPES.ResourceCommentClient);
        this.lastId = await ResourceCommentClient.create(settingsModule.language, comment)
        this.reloadComments0++;
        //this.showForm = false;
        
      },
      scrollToDiscussion(){
        this.$scrollTo('#discussionpart', 500, { easing: 'ease-in-out', offset: -200})
      },
    async getPartner(id: number): Promise<number | null> {
        const settingsModule = getModule(SettingsModule, this.$store);
        const container = extractContainer(this);
        const partnerClient =  container.get<PartnerClient>(TYPES.PartnerClient);
        const partner = await partnerClient.getById(settingsModule.language, this.id)
        if(partner) {
          return partner.id
        } else return null
    },
  },
  mounted() {
    /*
  setTimeout(() => {
    console.log("mounted");
      if(this.type == 1){
        const classMove = "f"+this.id_parent;
        const yOffset = -300; 
        const element_1 = document.getElementById(classMove);
        if(element_1 != null){
          const y = element_1.getBoundingClientRect().top + window.pageYOffset + yOffset;
          window.scrollTo({top: y, behavior: 'smooth'});
        }
      }else if(this.type == 2){
        const element2 = document.getElementById("p"+this.id_parent);
        if(element2 !== null){
          element2.classList.add("active");

          const classMove2 = "ip"+this.id_comment;
          const yOffset = -300; 
          const element_1 = document.getElementById(classMove2);
          if(element_1 != null){
            const y = element_1.getBoundingClientRect().top + window.pageYOffset + yOffset;
            window.scrollTo({top: y, behavior: 'smooth'});
          }
        }
      }
  },500)*/
  }
});

