
import Vue, { PropType } from "vue";

import { Label } from '../../client/label.client';
import LabelsModule from '@/store/modules/labels';
import mixins from 'vue-typed-mixins'
import LabelMixin from '../../mixins/labels';
import ForumCommentClient, { ForumComment } from '@/client/forum-comment.client';
import { getModule } from 'vuex-module-decorators';
import SettingsModule from '@/store/modules/settings';
import { extractContainer } from '@/ioc/util';
import TYPES from '@/ioc/types';
import UserClient from '@/client/user.client';

export default mixins(LabelMixin).extend({
  name: "FavoriteForum",
  props: {
    topic: {
      required: true,
      type: Object as PropType<ForumComment>,
    },
  },
  methods: {
    async addToFavorites() {
      this.topic.is_favorite = true
      const settingsModule = getModule(SettingsModule, this.$store);
      const container = extractContainer(this);
      const forumCommentsClient =  container.get<ForumCommentClient>(TYPES.ForumCommentClient);
      const user = await container.get<UserClient>(TYPES.UserClient).getMe(settingsModule.language);
      if(user) {
        await forumCommentsClient.setFavorite(settingsModule.language,this.topic.forum_comment_id?this.topic.forum_comment_id:this.topic.id,user.id)
      }
    },
    async removeFromFavorites() {
      this.topic.is_favorite = false
      const settingsModule = getModule(SettingsModule, this.$store);
      const container = extractContainer(this);
      const forumCommentsClient =  container.get<ForumCommentClient>(TYPES.ForumCommentClient);
      const user = await container.get<UserClient>(TYPES.UserClient).getMe(settingsModule.language);
      if(user) {
        await forumCommentsClient.unsetFavorite(settingsModule.language,this.topic.forum_comment_id?this.topic.forum_comment_id:this.topic.id,user.id)
      }
    }

  },
  inject: ['container'],
});
