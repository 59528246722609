import Vue from 'vue';
import Vuex, { Store } from 'vuex';
import { RootState } from './modules/root';
import ResourceModule from './modules/resources';
import SettingsModule from './modules/settings';
import AuthModule from './modules/auth';
import LabelsModule from './modules/labels';
import CategoriesModule from './modules/categories';
import VueDOMPurifyHTML from 'vue-dompurify-html'
import UsersModule from './modules/users';
import ValuesModule from './modules/values';
import { SearchModule } from './modules/search';
import { SearchModuleHeritage } from './modules/search';
import NotificationModule from './modules/notifications';
import FilmModule from './modules/films';

Vue
  .use(Vuex)
  .use(VueDOMPurifyHTML);

const debug = process.env.NODE_ENV !== 'production';


const odStore: Store<RootState> = new Vuex.Store<RootState>({
  modules: {
    auth: AuthModule,
    categories: CategoriesModule,
    resources: ResourceModule,
    settings: SettingsModule,
    labels: LabelsModule,
    users: UsersModule,
    values: ValuesModule,
    search: SearchModule,
    searchHeritage: SearchModuleHeritage,
    notifications: NotificationModule,
    films: FilmModule
  },
  strict: debug,
});

export default odStore;
