
import Vue from 'vue';
import ResourcesModule from '@/store/modules/resources';
import { getModule } from 'vuex-module-decorators';

import Breadcrumbs, { BreadcrumbsItems } from '@/components/Breadcrumbs.vue';
import LabelsModule from '@/store/modules/labels';
import mixins from 'vue-typed-mixins'
import LabelMixin from '../mixins/labels';
import PartnerClient, { Partner } from '../client/partner.client';
import SettingsModule from '@/store/modules/settings';
import { extractContainer } from '@/ioc/util';
import TYPES from '@/ioc/types';
import PageClient, {Page, PageBlock} from '@/client/page.client';
import BlockLayout from '../components/BlockLayout.vue';
import OdImage from '../components/ui/OdImage.vue';
import ValuesModule from '@/store/modules/values';
import ValueClient, { SiteConfig, Faq } from '@/client/value.client';

import { Site } from '@/service/site';

export default mixins(LabelMixin).extend({
  name: 'PageView',
  components: {
    BlockLayout,
    Breadcrumbs,
    OdImage
  },
  props: {
    id: Number,
    slug: String
  },
  inject: ['container'],
  async created() {

  },
  filters: {
    
  },
  asyncComputed: {
    async pageSettings(): Promise<Page> {
        //this.status = 'LOADING';
        const settingsModule = getModule(SettingsModule, this.$store);
        const container = extractContainer(this);
        const PageClient = container.get<PageClient>(TYPES.PageClient);
        const pageSettings = await PageClient.getPageBySlug(settingsModule.language,this.id);
        this.breadcrumbsItems.push({ url: '/page/'+pageSettings.id+"/"+pageSettings.slug, label: pageSettings.title});
        return pageSettings;
        //this.status = 'SUCCESS';
    },
    async pageBlocks(): Promise<PageBlock[]> {
        //this.status = 'LOADING';
        const settingsModule = getModule(SettingsModule, this.$store);
        const container = extractContainer(this);
        const PageClient = container.get<PageClient>(TYPES.PageClient);
        return await PageClient.getBlocksBySlug(settingsModule.language,this.id);
        //this.status = 'SUCCESS';
    },
    async faq():Promise<Faq[]> {
      const settingsModule = getModule(SettingsModule, this.$store);
      const container = extractContainer(this);
      const resourcesClient =  container.get<ValueClient>(TYPES.ValueClient);
      return await resourcesClient.getSiteFaq();
    },
    async partner_big():Promise<Partner[]> {
      const settingsModule = getModule(SettingsModule, this.$store);
      const container = extractContainer(this);
      const partnerClient =  container.get<PartnerClient>(TYPES.PartnerClient);
      return await partnerClient.getPartnersBig(settingsModule.language);
    },
    async partner_small():Promise<Partner[]> {
      const settingsModule = getModule(SettingsModule, this.$store);
      const container = extractContainer(this);
      const partnerClient =  container.get<PartnerClient>(TYPES.PartnerClient);
      return await partnerClient.getPartnersSmall(settingsModule.language);
    }
  },
  computed: {
    site(): Site{
      const settingsModule = getModule(SettingsModule, this.$store);
      const container = extractContainer(this);
      return container.get<Site>(TYPES.Site);
    }
  },
  methods: {
  },
  mounted() {    
  },
  data(): {
    breadcrumbsItems: BreadcrumbsItems[],

    } {
      return {
        breadcrumbsItems: [],
    };
  },

});

