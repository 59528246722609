
import Vue, { PropType } from 'vue';
import { getModule } from 'vuex-module-decorators';
import UsersModule from '@/store/modules/users';
import ValuesModule from '@/store/modules/values';

export default Vue.extend({
  name: 'Footer',
  components: {

  },
  props: {

  },

});
