
import Vue, { PropType } from 'vue'
import UserClient, { Film, Cv, UserProfile, UserResponse } from '@/client/user.client';
import {sanitizeNumber} from '../../service/form';
import mixins from 'vue-typed-mixins';
import LabelMixin from '@/mixins/labels';
import { extractContainer } from '@/ioc/util';
import TYPES from '@/ioc/types';
import SettingsModule from '@/store/modules/settings';
import { getModule } from 'vuex-module-decorators';
import { ValueResponse } from '@/client/value.client';
import ValuesModule from '@/store/modules/values';
import { Cropper } from 'vue-advanced-cropper';
import ImageCropper, { CropEvent } from '../ui/ImageCropper.vue';
import { NamedBlob } from '../../client/download.client';

function sanitizeUserProfileForm(profile: UserProfile): UserProfile {
  return {
    ...profile,
    profession_id: sanitizeNumber(profile.profession_id),
    country_id: sanitizeNumber(profile.country_id),
    gender: sanitizeNumber(profile.gender),
  }
}

export interface FileModel<T> {
  file: File;
  model: T;
}

export interface SubmitFormEmit {
  profile: UserProfile;
  image: NamedBlob | null;
  cv: NamedBlob | null;
}

export default mixins(LabelMixin).extend({
components: {
    ImageCropper,
  },
  props: {
    profile: {
      required: true,
      type: Object as PropType<UserProfile>,
    },
  },
  methods: {
    uploadCv() {
      (this.$refs.cvFile as HTMLInputElement).click()
    },
    onCancel(event: MouseEvent) {
      event.preventDefault();
      this.$emit('onCancel');
    },
    onSubmitForm(event: Event) {
      event.preventDefault();
      const submitEmit: SubmitFormEmit = {
        profile: sanitizeUserProfileForm(this.profile),
        image: this.avatarCropped,
        cv: this.cv
      };
      this.$emit('onSubmit', submitEmit);
    },
    onAvatarCrop(event: CropEvent) {
      this.avatarCropped = event.file;
    },
    onCvChange(event: Event) {
      const target: EventTarget | null = event?.target;
      if (!target) {
        return;
      }

      const file: File = ((target as HTMLInputElement).files ?? [])[0];

      if (!file) {
        return;
      }

      this.cv = {
          name: file.name,
          blob: file
      }
    },
  },
  data(): { 
    avatarCropped: NamedBlob | null;
    cv: NamedBlob | null;
  } {
    return {
      avatarCropped: null,
      cv: null
    };
  },
  computed: {
    countries(): ValueResponse[] {
      //const allowedCountries = getModule(ValuesModule,this.$store).siteConfig.allowed_countries
      return getModule(ValuesModule, this.$store).countries.sort(function(a, b){
        return (a.name.toLowerCase() > b.name.toLowerCase()) ? 1 : -1;
      });//.filter(x => allowedCountries.includes(x.id));
    },
    filmGendres(): ValueResponse[] {
      return getModule(ValuesModule, this.$store).filmGendres;
    },
    professions(): ValueResponse[] {
      return getModule(ValuesModule, this.$store).professions;
    },
    genders(): ValueResponse[] {
      return getModule(ValuesModule, this.$store).gendres;
    }
  },
  asyncComputed: {
    async user(): Promise<UserResponse | null> { 
      const container = extractContainer(this);
      const userClient = container.get<UserClient>(TYPES.UserClient);
      const settingsModule = getModule(SettingsModule, this.$store);
      const user = await userClient.getMe(
        settingsModule.language
      );
      return user;
    }
  },
  inject: ['container']
})
