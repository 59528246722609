
import Vue, { PropType } from 'vue';
import { AuthorIndex, DefaultListOptionRequest, UserResponse } from '../client/user.client';
import OdImage from '@/components/ui/OdImage.vue';

import { Label } from '../client/label.client';
import LabelsModule from '@/store/modules/labels';
import mixins from 'vue-typed-mixins'
import LabelMixin from '../mixins/labels';
import { getModule } from 'vuex-module-decorators';
import UsersModule from '../store/modules/users';

export default mixins(LabelMixin).extend({
  name: 'AuthorLayout',
  components: {
    OdImage,
  },
  props: {
    className: {
      type: String,
      validator: (c:string) => [
        'author',
      ].includes(c),
    },
    author: {
      type: Object as PropType<AuthorIndex>,
      required: true,
    }
  },
  computed: {
    authorDescription():string {
      return [this.author.profession,this.author.country].filter(x => x && x.length > 0).join(", ")
    }
  },
  filters: {

  }
});


