
import Vue, { PropType } from 'vue';
import { formatPublication } from '@/service/ui';
import { format } from 'date-fns';

import PageClient, {Page, PageBlock} from '@/client/page.client';

import { Label } from '../client/label.client';
import LabelsModule from '@/store/modules/labels';
import mixins from 'vue-typed-mixins'
import LabelMixin from '../mixins/labels';
import { getModule } from 'vuex-module-decorators';
import UsersModule from '../store/modules/users';

export default mixins(LabelMixin).extend({
  name: 'BlockLayout',
  props: {
    block: {
      type: Object as PropType<PageBlock> || null,
      required: true,
    }
  },
  computed: {

  },
  filters: {
     
  }
});
