
import Vue, { PropType } from "vue";
import SuggestForumCategoryForm, {
  SubmitFormEmit
} from "../components/forms/SuggestForumCategoryForm.vue";
import { extractContainer } from "../ioc/util";
import TYPES from "../ioc/types";
import UserFilmClient, { getDefaultFilm } from "@/client/user-film.client";
import { getModule } from "vuex-module-decorators";
import SettingsModule from "../store/modules/settings";
import { sub } from "date-fns";
import mixins from 'vue-typed-mixins'
import LabelMixin from '@/mixins/labels'
import ForumSuggestionClient from '../client/forum.suggestion.client';

export default mixins(LabelMixin).extend({
  name: "SuggestForumCategoryView",
  components: {
    SuggestForumCategoryForm
  },
  data(): { showForm:boolean } {
    return {
      showForm: true
    };
  },
  methods: {
    async onSuggestionSubmit(submit: SubmitFormEmit) {
      const { suggestion } = submit;
      const container = extractContainer(this);
      const settingsModule = getModule(SettingsModule, this.$store);
      const suggestionClient = container.get<ForumSuggestionClient>(TYPES.ForumSuggestionClient);
      await suggestionClient.create(settingsModule.language, suggestion)
      this.showForm = false;
    }
  },
  watch: {
    
  },
  inject: ["container"]
});
