
import Vue, { PropType } from 'vue'
import { Film, Cv } from '@/client/user.client';
import {sanitizeNumber} from '../../service/form';
import { Cropper } from 'vue-advanced-cropper';
import ImageCropper, { CropEvent } from '../ui/ImageCropper.vue';
import OdImage from '../ui/OdImage.vue';
import { NamedBlob } from '../../client/download.client';

import { Label } from '../../client/label.client';
import LabelsModule from '@/store/modules/labels';
import mixins from 'vue-typed-mixins'
import LabelMixin from '../../mixins/labels';
import { ValueResponse } from '../../client/value.client';
import ValuesModule from '../../store/modules/values';
import { getModule } from 'vuex-module-decorators';
import { getVideoUrl, toVideoUrl } from '@/service/videoUrl'


function sanitizeFilmForm(film: Film, imageName: string | null): Film {
  const img = imageName ? imageName : film.image_name
  return {
    ...film,
    year: sanitizeNumber(film.year),
    user_id: sanitizeNumber(film.user_id),
    length: sanitizeNumber(film.length),
    genre_id: sanitizeNumber(film.genre_id),
    image_name: img,
  }
}



export interface SubmitFormEmit {
  film: Film;
  newImage: NamedBlob | null;
}

export default mixins(LabelMixin).extend({
  components: {
    ImageCropper,
    OdImage,
  },
  props: {
    film: {
      required: true,
      type: Object as PropType<Film>,
    },
  },
  methods: {
    onCancel(event: MouseEvent) {
      event.preventDefault();
      this.$emit('onCancel');
    },
    onSubmitForm(event: Event) {
      event.preventDefault();
      const submitEmit: SubmitFormEmit = {
        film: sanitizeFilmForm(this.film, this.coverCropped?.name ?? null),
        newImage: this.coverCropped,
      };
      this.$emit('onSubmit', submitEmit);
    },
    onCoverCrop(event: CropEvent) {
      this.coverCropped = event.file;
    },
  },
  data(): { coverCropped: NamedBlob | null} {
    return {
      coverCropped: null,
    };
  },
  computed: {
    countries(): ValueResponse[] {
      return getModule(ValuesModule, this.$store).countries;
    },
    filmGendres(): ValueResponse[] {
      return getModule(ValuesModule, this.$store).filmGendres;
    },
    trailerUrl: {
      get(): string {
        return toVideoUrl(this.film.trailer)
      },
      set(trailerUrl: string) {
          this.film.trailer = getVideoUrl(trailerUrl) || '';
      },
    }
  }
})
