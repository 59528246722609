import '@devmobiliza/videojs-vimeo/dist/videojs-vimeo.esm'; //register plugin
import { AxiosStatic } from 'axios';
import 'swiper/css/swiper.css';
import 'video.js/dist/video-js.css';
import 'videojs-youtube'; //register plugin
import Vue from 'vue';
import VueGtag from "vue-gtag";
import AsyncComputed from 'vue-async-computed';
import VueAwesomeSwiper from 'vue-awesome-swiper';
import VueMoment from 'vue-moment';
import VueRouter from 'vue-router';
import VueScrollTo from 'vue-scrollto'
import VueCookies from 'vue-cookies'
// @ts-ignore
import VueTippy, { TippyComponent } from "vue-tippy";
import App from './App.vue';
import addAuthAxios from './client/axios.util';
import Environment from './env/environment';
import container from './ioc/inversify.config';
import TYPES from './ioc/types';
import store from './store';
import Rollbar from 'rollbar';
import { Site } from './env/site';


export default function bootstrap(router: VueRouter) {
  const environment = container.get<Environment>(TYPES.Environment);
  const site = container.get<Site>(TYPES.Site);

  Vue.config.productionTip = false;

  Vue
    .use(VueAwesomeSwiper, /* { default options with global component } */)
    .use(AsyncComputed)
    .use(VueMoment)
    .use(VueTippy)
    .use(VueScrollTo)
    .use(VueCookies)
    .use(VueGtag, {
        config: { id: "G-VY97MQ6ZPS" }
    });
      

  Vue.component("tippy", TippyComponent);

  Vue.$cookies.config('7d')



  if(environment.production) {

    let envLabel = 'dev'
    if(environment.production && window.location.origin.includes("stg")) envLabel = 'stg'
    else if(environment.production) envLabel = 'prod'
  
    console.log("Running: " + site.name.toLowerCase() + "-" + envLabel)

    // Set the Rollbar instance in the Vue prototype
    // before creating the first Vue instance.
    // This ensures it is available in the same way for every
    // instance in your app.
    Vue.prototype.$rollbar = new Rollbar({
      accessToken: '3ead53f9ced04d05a94f434969fdb299',
      captureUncaught: true,
      captureUnhandledRejections: true,
      payload: {
        environment: site.name.toLowerCase() + "-" + envLabel
      }
    });

    // If you have already set up a global error handler,
    // just add `vm.$rollbar.error(err)` to the top of it.
    // If not, this simple example will preserve the app’s existing
    // behavior while also reporting uncaught errors to Rollbar.
    Vue.config.errorHandler = (err, vm, info) => {
      (vm as any).$rollbar.error(err);
      throw err; // rethrow
    };
  }


  const axios = container.get<AxiosStatic>(TYPES.AxiosStatic);

  addAuthAxios(axios,store,router);

  new Vue({
    router: router,
    store,
    render: (h) => h(App),
  }).$mount('#app');
}
