import { UserResponse, UserLightResponse, UserHeritageResponse } from '@/client/user.client';

export function isHeritageOther(u:UserResponse | UserHeritageResponse  | UserLightResponse | null | undefined):boolean {
    return u && u.roles ? u.roles.includes('JP-Heritage-Other') : false;
}

export function isHeritageRightHolder(u:UserResponse | UserHeritageResponse | UserLightResponse | null | undefined):boolean {
    return u && u.roles ? u.roles.includes('JP-Heritage-Rightholder') : false;
}

export function isHeritageStreamer(u:UserResponse | UserHeritageResponse  | UserLightResponse | null | undefined):boolean {
    return u && u.roles ? u.roles.includes('JP-Heritage-Streamer') : false;
}



