/* eslint-disable @typescript-eslint/camelcase */
import Environment from '@/env/environment';
import TYPES from '@/ioc/types';
import { AxiosStatic } from 'axios';
import { inject, injectable } from 'inversify';
import { SupportedLanguage } from './supported-lang.type';
import { PagingOption, ListOptionRequest, CountResponse, DefaultListOptionRequest } from './user.client';
import { parse } from 'date-fns';
import { List } from 'lodash';

export const BACKEND_DATE_FORMAT = "yyyy-MM-dd'T'HH:mm";

export interface Embedded {
  resource_id: number;
  id: number;
  reference: string;
}

export interface Link {
  resource_id: number;
  link: string;
  id: number;
  label: string;
}

export interface ResourceBlock {
  id: number,
  page_id: number,
  order: number,
  text_1: string,
  text_2: string,
  text_3: string,
  text_4: string,
  image_1_filename: string,
  image_2_filename: string,
  image_3_filename: string,
  image_4_filename: string,
  type: number,
  visible: boolean,
}
/*
export interface Partner {
  id: number,
  partner_id: number,
  resource_id: number,
}
*/
export interface Partner {
  id: number;
  name: string;
  link: number;
  image?: string,
  mime?: string,
  toolbox_partner_big?: boolean,
  toolbox_partner_small?: boolean,
  partner_id?: number,
  resource_id?: number,
}

export interface Attachment {
  mime: string;
  id: number;
  filename: string;
  resource_id: number;
  attachment_type_id: number;
}

export interface Tag {
  id: number;
  resource_id: number;
  tag_id: number;
};

export interface ResourceResponse {
  embedded: Embedded[];
  partners: Partner[];
  author: string;
  body: string;
  suggestor_id: number;
  partner_id: number;
  editor_pick: boolean;
  pubblication_date: Date;
  links: Link[];
  id: number;
  title_mobile: string;
  category_id: number;
  title: string;
  attachments: Attachment[];
  summary: string;
  tags: Tag[];
  start?: Date | null;
  end?: Date | null;
  location?: string | null;
  is_favorite: boolean | null;
  comment_count: number;
  blocks: ResourceBlock[];
  visible: boolean;
}
export interface ResourceLightResponse {
  id: number;
  author?: string;
  suggestor_id?: number;
  suggestor: string;
  category_id: number;
  category: string;
  category_mobile?: string;
  subcategory_id?: number;
  subcategory?: string;
  editor_pick?: boolean;
  pubblication_date: Date;
  title: string;
  title_mobile?: string;
  summary?: string;
  has_documents: boolean;
  has_video: boolean;
  has_link: boolean;
  tags?: string;
  start?: Date | null;
  end?: Date | null;
  location?: string | null;
  image: string | null;
  square_image: string | null;
  is_favorite: boolean | null;
  comment_count: number;
  has_expired: boolean | null;
  visible: boolean;
}

export function indexToLight(r:ResourceIndexResponse):ResourceLightResponse {
  return {
    id: r.id,
    author: r.author,
    suggestor: r.suggestor,
    suggestor_id: r.suggestor_id,
    category: r.category,
    category_id: r.category_id,
    subcategory: r.subcategory,
    subcategory_id: r.subcategory_id,
    pubblication_date: r.pubblication_date,
    title: r.title,
    summary: r.summary,
    has_documents: false,
    has_link: false,
    has_video:false,
    start: r.start,
    end: r.end,
    location: r.location,
    is_favorite: r.is_favorite,
    square_image: null,
    image: null,
    comment_count: r.comment_count,
    has_expired: null,
    visible: r.visible
  }
}

export interface ResourceIndexResponse {
  id: number;
  title: string;
  summary?:string;
  body: string;
  author?:string;
  suggestor:string;
  suggestor_id:number;
  category:string;
  category_id: number;
  subcategory?: string;
  subcategory_id?: number;
  location?:string | null;
  start?: Date | null;
  end?: Date | null;
  pubblication_date: Date;
  is_favorite: boolean | null;
  comment_count: number;
  visible: boolean;
}




function deserializeResource<T extends { pubblication_date: Date; start?: Date | null; end?: Date | null; is_favorite: boolean | null }>(r: any): T {
  return {
    ...r,
    pubblication_date: r.pubblication_date ? parse(r.pubblication_date, BACKEND_DATE_FORMAT, new Date()) : null,
    start: r.start ? parse(r.start, BACKEND_DATE_FORMAT, new Date()) : null,
    end: r.end ? parse(r.end, BACKEND_DATE_FORMAT, new Date()) : null,
    is_favorite: r.is_favorite ? r.is_favorite : (r.favorites ? r.favorites.length > 0 : false),
  }
}

@injectable()
export default class ResourceClient {
  constructor(
    @inject(TYPES.AxiosStatic) private readonly axios: AxiosStatic,
    @inject(TYPES.Environment) private readonly environment: Environment,
  ) { }

  async getList(
    lang: SupportedLanguage,
    request: ListOptionRequest,
  ): Promise<ResourceLightResponse[]> {
    const response = this.axios.post<ResourceLightResponse[]>(`${this.environment.backendUrl}/api/v1/entity/${lang}/v_resources/list`,
      request,
      {
        withCredentials: true,
      });
    return (await response).data.map(r => deserializeResource<ResourceLightResponse>(r));
  }


  /*
    Risorse:
    - title
    - summary
    - body (senza base64)
    - author
    - category
    - subcategory
   */
  async getIndexList(
    lang: SupportedLanguage
  ): Promise<ResourceIndexResponse[]> {

    const request = DefaultListOptionRequest
    request.paging.pageLength = 100000

    const response = this.axios.post<ResourceIndexResponse[]>(`${this.environment.backendUrl}/api/v1/entity/${lang}/v_resources_index/list`,
      request,
      {
        withCredentials: true,
      });
    return (await response).data.map(r => deserializeResource<ResourceIndexResponse>(r));;
  }

  /*
  Agenda:
  - title
  - summary
  - body (senza base64)
  - author
  - category
  - subcategory
  - location
  */
  async getIndexAgenda(
    lang: SupportedLanguage
  ): Promise<ResourceIndexResponse[]> {
    

    const request = DefaultListOptionRequest
    request.paging.pageLength = 100000

    const response = this.axios.post<ResourceIndexResponse[]>(`${this.environment.backendUrl}/api/v1/entity/${lang}/v_agenda_index/list`,
      request,
      {
        withCredentials: true,
      });
    return (await response).data.map(r => deserializeResource<ResourceIndexResponse>(r));;
  }


  async getFavorites(
    lang: SupportedLanguage,
    request: ListOptionRequest,
  ): Promise<ResourceLightResponse[]> {
    const response = this.axios.post<ResourceLightResponse[]>(`${this.environment.backendUrl}/api/v1/entity/${lang}/v_resources_favorite/list`,
      request,
      {
        withCredentials: true,
      });
    return (await response).data.map(r => deserializeResource<ResourceLightResponse>(r));
  }

  async getOwn(
    lang: SupportedLanguage,
    request: ListOptionRequest,
  ): Promise<ResourceLightResponse[]> {
    const response = this.axios.post<ResourceLightResponse[]>(`${this.environment.backendUrl}/api/v1/entity/${lang}/v_resources_own/list`,
      request,
      {
        withCredentials: true,
      });
    return (await response).data.map(r => deserializeResource<ResourceLightResponse>(r));
  }

  async getTotalComments(lang: SupportedLanguage,id: number): Promise<number> {
    const fullRequest = {
      filter: [{column:"id", operator: "=", value: ""+id}],
      sort: [
      ],
    };
    const response = this.axios.post<ResourceResponse[]>(`${this.environment.backendUrl}/api/v1/entity/${lang}/v_resources/list`, fullRequest, {
      withCredentials: true,
    });
    return (await response).data[0].comment_count;
  }

  async setFavorite(
    lang: SupportedLanguage,
    resource_id: number,
    user_id: number
  ): Promise<any> {
    const response = this.axios.post(`${this.environment.backendUrl}/api/v1/entity/${lang}/resource_user_favorite`,
      {
        resource_id: resource_id,
        user_id: user_id
      },
      {
        withCredentials: true,
      });
    return (await response).data;
  }

  async touch(
    lang: SupportedLanguage,
    resource_id: number,
    user_id: number
  ): Promise<any> {
    const response = this.axios.post(`${this.environment.backendUrl}/api/v1/entity/${lang}/resource_user_view`,
      {
        resource_id: resource_id,
        user_id: user_id
      },
      {
        withCredentials: true,
      });
    return (await response).data;
  }

  async unsetFavorite(
    lang: SupportedLanguage,
    resource_id: number,
    user_id: number
  ): Promise<any> {
    const request:ListOptionRequest = {
      sort: [],
      paging: DefaultListOptionRequest.paging,
      filter: [
        {column: 'user_id', operator: '=', value: ""+user_id},
        {column: 'resource_id', operator: '=', value: ""+resource_id}
      ]
    }
    const response = this.axios.post<{id: number, resource_id:number, user_id:number}[]>(`${this.environment.backendUrl}/api/v1/entity/${lang}/resource_user_favorite/list`,
      request,
      {
        withCredentials: true,
      });
    const favorites = (await response).data
    if(favorites.length > 0 ) {
      const favId = favorites[0].id
      const deleteRequest = await this.axios.delete(`${this.environment.backendUrl}/api/v1/entity/${lang}/resource_user_favorite/id/id::${favId}`)
    }
    
  }

  async getRecentView(
    lang: SupportedLanguage,
    limit: number,
  ): Promise<ResourceLightResponse[]> {

    const request:ListOptionRequest = {
      filter: [],
      paging: {currentPage:1, pageLength: limit},
      sort: [{column: 'last_visit', order:'desc'}]
    } 

    const response = this.axios.post<ResourceLightResponse[]>(`${this.environment.backendUrl}/api/v1/entity/${lang}/v_resources_recent/list`,
      request,
      {
        withCredentials: true,
      });
    return (await response).data.map(r => deserializeResource<ResourceLightResponse>(r));
  }

  async getHeritageNews(
    lang: SupportedLanguage,
    limit: number,
  ): Promise<ResourceLightResponse[]> {

    const request:ListOptionRequest = {
      filter: [{column: 'visible', operator: "=", value: "true"}],
      paging: {currentPage:1, pageLength: limit},
      sort: [{column: 'pubblication_date', order:'desc'}]
    } 

    const response = this.axios.post<ResourceLightResponse[]>(`${this.environment.backendUrl}/api/v1/entity/${lang}/v_heritage_resources/list`,
      request,
      {
        withCredentials: true,
      });
    return (await response).data.map(r => deserializeResource<ResourceLightResponse>(r));
  }


  async getPopular(
    lang: SupportedLanguage,
    limit: number,
  ): Promise<ResourceLightResponse[]> {

    const request:ListOptionRequest = {
      filter: [],
      paging: {currentPage:1, pageLength: limit},
      sort: [{column: 'view_count', order:'asc'}]
    } 

    const response = this.axios.post<ResourceLightResponse[]>(`${this.environment.backendUrl}/api/v1/entity/${lang}/v_resources_popular/list`,
      request,
      {
        withCredentials: true,
      });
    return (await response).data.map(r => deserializeResource<ResourceLightResponse>(r));
  }


  async getListCount(
    lang: SupportedLanguage,
    request: ListOptionRequest,
  ): Promise<CountResponse> {
    const response = this.axios.post<CountResponse>(`${this.environment.backendUrl}/api/v1/entity/${lang}/v_resources/ids`,
      request,
      {
        withCredentials: true,
      });
    return (await response).data;
  }

  async getById(lang: SupportedLanguage, id: number): Promise<ResourceResponse> {
    const response = this.axios.get<ResourceResponse>(`${this.environment.backendUrl}/api/v1/form/${lang}/Resources/id/id::${id}`, {
      withCredentials: true,
    });
    return deserializeResource<ResourceResponse>((await response).data);
  }
}
