
import Vue, { PropType } from 'vue';

import { Label } from '@/client/label.client';
import LabelsModule from '@/store/modules/labels';
import mixins from 'vue-typed-mixins'
import LabelMixin from '@/mixins/labels';
import { sanitizeNumber } from '@/service/form';

import { getModule } from 'vuex-module-decorators';

import CommentsChild from '@/components/CommentsChild.vue';
import UsersModule from '@/store/modules/users';
import ForumSubDiscussion, {
    SubmitSubFormEmit
} from '@/components/forms/ForumSubDiscussion.vue';

import Swiper, { SwiperOptions } from 'swiper';
import SettingsModule from '@/store/modules/settings';
import { extractContainer } from '@/ioc/util';
import TYPES from '@/ioc/types';
import UserMention from '@/components/ui/UserMention.vue';



import ForumCommentClient, { emptyComment,emptyCommentInternal, ForumComment, SendComment } from '@/client/forum-comment.client';

function sanitizeCommentForm(comment: SendComment): SendComment {
  return {
    ...comment
  }
}

export interface SubmitFormEmit {
  comment: SendComment;
}

export default mixins(LabelMixin).extend({
  name: 'ForumDiscussion',
  components: {
    CommentsChild,
    ForumSubDiscussion,
    UserMention
  },
  async created() {
    await getModule(UsersModule, this.$store).dispatchGetMe();
    //godown
    if(this.lastId != 0){
      setTimeout(() => this.scrollToElement(), 1000);
    }
  },
  inject: ['container'],
  props: {
    comments: {
      type: Array as PropType<ForumComment[]>,
    },
    forum_category_id: { 
      type: Number 
    },
    parent_id: {
      type: Number,
    },
    lastId:{
      type: Number
    },
    type: Number,
    id_parent: Number,
    id_comment: Number
  },
  asyncComputed: {
    
    async getCommentsByTopic(): Promise<ForumComment[] | null> {
      const settingsModule = getModule(SettingsModule, this.$store);
      const container = extractContainer(this);
      const ForumCommentClient = container.get<ForumCommentClient>(TYPES.ForumCommentClient);
      return await ForumCommentClient.getCommentsByParent(settingsModule.language,this.parent_id);
    },
    
  },
  methods: {
    setMentionState(val: boolean){
      this.mentionState = val;
    },
    newline() {
      //this.comment.body = `${this.comment.body}<br/>`;
    },
    send() {
      if(this.comment.body != "" && !this.mentionState){
        this.onSubmitForm();
      }else{
        this.mentionState = false;
      }
    },
    onSubmitForm() {
      //event.preventDefault();
      this.loadingForm = true;
      const submitEmit: SubmitFormEmit = {
        comment: sanitizeCommentForm(this.comment)
      };
      this.$emit('onSubmit', submitEmit);
    },
    onSubmitFormInternal(event: Event, parent_id: number, forum_category_id: number) {
      event.preventDefault();
      this.comment.parent_id = parent_id;
      this.comment.forum_category_id = parent_id;
      this.loadingForm = true;
      const submitEmit: SubmitFormEmit = {
        comment: sanitizeCommentForm(this.comment)
      };
      this.$emit('onSubmit', submitEmit);
    },
    scrollToElement() {
      const el = this.$el.getElementsByClassName('lastElementofList')[0];

      if (el) {
        el.scrollIntoView();
      }
    },
    scrollToInput(event: Event, classMove: string){
      const element_2 = document.getElementById("textarea");
      if(element_2 !== null){
        this.$scrollTo('#textarea', 500, { easing: 'ease-in-out', offset: -200})
        element_2.focus();
      }
    },
    async onCommentSubmit(submit: SubmitFormEmit) {
        const { comment } = submit;
        const container = extractContainer(this);
        const settingsModule = getModule(SettingsModule, this.$store);

        const ForumCommentClient = container.get<ForumCommentClient>(TYPES.ForumCommentClient);
        this.lastIdInner = await ForumCommentClient.create(settingsModule.language, comment)
        
      },
  },
  data(): {
    comment: SendComment,
    loadingForm: boolean,
    commentToSend: SendComment,
    lastIdInner: number,
    mentionState: boolean
  } {
    return {
      comment: emptyComment(getModule(UsersModule,this.$store).me?.id || -1,this.parent_id,this.forum_category_id),
      loadingForm: false,
      commentToSend: emptyComment(getModule(UsersModule,this.$store).me?.id || -1,this.parent_id,this.forum_category_id),
      lastIdInner: 0,
      mentionState: false
    };
  },/*
  mounted() {
    setTimeout(() => {
      if(this.type == 1){
          this.$scrollTo('#f'+this.id_parent, 500, { easing: 'ease-in-out', offset: -200})
        }else if(this.type == 2){
          console.log('#ip'+this.id_comment);
          this.$scrollTo('#ip'+this.id_comment, 500, { easing: 'ease-in-out', offset: -200})
        }
    },2000)
  }*/
});
