import { DefaultListOptionRequest, PagingOption } from '@/client/user.client';
import TYPES from '@/ioc/types';
import { uniqBy } from 'lodash';
import getDecorators from 'inversify-inject-decorators';
import {
  Action, Module, Mutation, VuexModule,
} from 'vuex-module-decorators';
import container from '@/ioc/inversify.config';
import { RootState } from '../root';
import { CategoriesState } from './state';
import CategoryClient, { CategoryLight } from '@/client/category.client';

const byId = (r: { id: number }) => r.id;

const { lazyInject } = getDecorators(container);


@Module({ namespaced: true, name: 'categories' })
export default class CategoriesModule extends VuexModule<ThisType<CategoriesState>, RootState> implements CategoriesState {
  isLoading = false;

  allLight: CategoryLight[] = [];

  @lazyInject(TYPES.CategoryClient)
  private readonly categoryClient!: CategoryClient;



  @Mutation
  addLightList(lightList: CategoryLight[]) {
    this.allLight = uniqBy([...lightList, ...this.allLight], byId);
  }


  @Action
  async dispatchGetList(): Promise<CategoryLight[]> {
    if(this.allLight.length > 0) return this.allLight 
    const categories = await this.categoryClient
      .getList(this.context.rootState.settings.language, DefaultListOptionRequest.paging);
    this.addLightList(categories);
    return categories;
  }

}
