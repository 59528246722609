import Environment from '@/env/environment';
import TYPES from '@/ioc/types';
import { AxiosStatic } from 'axios';
import { inject, injectable } from 'inversify';
import { SupportedLanguage } from './supported-lang.type';
import { parseISO, format } from 'date-fns';
import { Organization } from './organization.client';

export interface Image {
  mime?: string;
  user_id: number;
  id?: number;
  filename: string;
}

export interface Film {
  role: string;
  production_country_id: number | null;
  year: number | null;
  imdb: string | null;
  user_id: number | null;
  company_id?:number;
  festival_awards: string;
  id: number | null;
  production_company: string;
  director: string;
  logline: string;
  length: number | null;
  title: string;
  producer: string;
  genre_id: number | null;
  genre_name: string | null;
  trailer: string;
  image_name: string | null;
  title_international?: string | null; 
  genre?: string | null;
  feature_type_id?: number | null; 
  languages: {film_id?: number | null, lang_id:number}[]; 
  subtitles: {film_id?: number | null, lang_id:number, subtitle_file?: string | null, subtitle_file_name?: string | null, code?: string | null, id?: number | null}[]; 
  countries: {film_id?: number | null, country_id:number}[];  
  coproduction_company?: string | null; 
  cast?: string | null; 
  crew?: string | null; 
  license_type: {film_id?: number | null, license_type_id: number}[];
  license_exclusivity: {film_id?: number | null, exclusivity_id: number}[];
  license_availability: string | null;
  license_territories: {film_id?: number | null, country_id:number}[];
  restriction_countries: {film_id?: number | null, country_id:number}[];
  restriction_activities: {film_id?: number | null, activity_id:number}[];
  sales_agent?: string | null; 
  swiss_distributor?: string | null; 
  files_format?: string | null; 
  request_screening_room?: boolean | null; 
  company: {id:number, name:string}[];
  screening_room_link?: string | null;
}

export interface Cv {
  mime?: string;
  user_id: number;
  id?: number;
  filename: string;
}

export interface Social {
  user_id: number;
  value: string;
  id?: number;
  social_id: number;
}

export interface UserOrganization{
  user_id: number;
  organization_id: number;
  organization: Organization[]
}

export interface UserPrivacy{
  email?: boolean;
  birthday?: boolean;
  cv?: boolean;
  filmography?: boolean;
  recent_views?: boolean;
  gender?: boolean;
  listed?: boolean;
  res_new_comment?: boolean;
  res_new_comment_favourite?: boolean;
  res_new_comment_on_own?: boolean;
  fo_new_comment?: boolean;
  fo_new_comment_favourite?: boolean;
  fo_new_comment_on_own?: boolean;

  res_new_mention?: boolean;
  res_new_resource?: boolean;
  fo_new_mention?: boolean;
}

export interface UserResponse {
  first_name: string;
  website: string;
  email: string;
  image: Image[];
  profession_id: number | null;
  films: Film[];
  cv: Cv[];
  bio: string;
  id: number;
  last_name: string;
  socials: Social[];
  country_id: number | null;
  birth_date: Date | null;
  gender: number | null;
  active: boolean;
  privacy: UserPrivacy[];
  user_organization: UserOrganization[];
  roles?: string;
  position?: string | null;
  public_name?: string | null;
  public_email?: string | null;
  admin_forum: number | boolean | null;
  status?: string | null;
}

export interface MeResponse {
  first_name: string;
  website: string;
  email: string;
  profession_id: number | null;
  bio: string;
  id: number;
  last_name: string;
  country_id: number | null;
  birth_date: Date | null;
  gender: number | null;
  active: boolean;
  roles?: string;
  position?: string | null;
  public_name?: string | null;
  public_email?: string | null;
  admin_forum: number | boolean | null;
  status?: string | null;
  free?: boolean | null;
  payment_link?: string | null;
}

export interface DateVM{
  day: number | null;
  month: number | null;
  year: number | null;
}

export interface UserProfile {
  id: number;
  website: string;
  image: Image | null;
  profession_id: number | null;
  cv: Cv;
  bio: string;
  facebook: string | null;
  instagram: string | null;
  linkedin: string | null;
  country_id: number | null;
  birth_date: DateVM;
  gender: number  | null;
  position?: string | null;
  public_name?: string | null;
  public_email?: string | null;
  admin_forum: number | boolean | null;

  // Heritage TODO
  main_activity_id?: number | null;
  phone?: string | null;
  mobile?: string | null;
}

export interface AuthorIndex {
  id: number;
  first_name:string;
  last_name:string;
  profession_id:number;
  profession:string;
  country_id:number;
  country:string;
  has_avatar:boolean;
  image_id:number
}

const FACEBOOK_ID = 1
const INSTAGRAM_ID = 2
const LINKEDIN_ID = 3

export function toProfile(user: UserResponse): UserProfile {

  const birth_date: DateVM = {
    day: null,
    month: null,
    year: null 
  }

  if(user.birth_date) {
    birth_date.day = user.birth_date.getDate()
    birth_date.month = user.birth_date.getMonth() + 1
    birth_date.year = user.birth_date.getFullYear() 
    
  }

  const facebook = user.socials.find(x => x.social_id == FACEBOOK_ID)?.value
  const instagram = user.socials.find(x => x.social_id == INSTAGRAM_ID)?.value
  const linkedin = user.socials.find(x => x.social_id == LINKEDIN_ID)?.value

  return {
    id: user.id,
    website: user.website,
    image: user.image[0],
    profession_id: user.profession_id,
    cv: user.cv[0],
    bio: user.bio,
    facebook: facebook || null,
    instagram: instagram || null,
    linkedin: linkedin || null,
    country_id: user.country_id,
    birth_date: birth_date,
    gender: user.gender,
    position: user.position,
    public_email: user.public_email,
    public_name: user.public_name,
    admin_forum: user.admin_forum
  }
}

export function fromProfile(user: UserResponse, profile: UserProfile, image: string | null, cv: string | null): UserResponse {

  let birth_date = null
  if(profile.birth_date.year && profile.birth_date.month && profile.birth_date.day) {
    birth_date = new Date(profile.birth_date.year, profile.birth_date.month-1, profile.birth_date.day)
  }

  const result = {
    ...user,
    website: profile.website,
    profession_id: profile.profession_id,
    bio: profile.bio,
    country_id: profile.country_id,
    gender: profile.gender,
    birth_date: birth_date,
    position: profile.position,
    public_name: profile.public_name,
    public_email: profile.public_email
  }

  if(image) {
    if(user.image[0]) {
      user.image[0].filename = image
    } else {
      user.image.push({filename: image, user_id: user.id})
    }
  }
  if(cv) {
    if(user.cv[0]) {
      user.cv[0].filename = cv
    } else {
      user.cv.push({filename: cv, user_id: user.id})
    }
  }

  if(profile.facebook) {
    const fb = result.socials.find(x => x.social_id == FACEBOOK_ID)
    if(fb) {
      fb.value = profile.facebook
    } else {
      result.socials.push({social_id: FACEBOOK_ID, value: profile.facebook, user_id: user.id})
    }
  }

  if(profile.instagram) {
    const im = result.socials.find(x => x.social_id == INSTAGRAM_ID)
    if(im) {
      im.value = profile.instagram
    } else {
      result.socials.push({social_id: INSTAGRAM_ID, value: profile.instagram, user_id: user.id})
    }
  }

  if(profile.linkedin) {
    const im = result.socials.find(x => x.social_id == LINKEDIN_ID)
    if(im) {
      im.value = profile.linkedin
    } else {
      result.socials.push({social_id: LINKEDIN_ID, value: profile.linkedin, user_id: user.id})
    }
  }

  return result
}

export interface UserPrivacy {
  id: number;
  privacy_email?: boolean;
  privacy_birthday?: boolean;
  privacy_cv?: boolean;
  privacy_filmography?: boolean;
  privacy_recent_views?: boolean;
  privacy_gender?: boolean;
  privacy_listed?: boolean;
  
  privacy_res_new_comment?: boolean;
  privacy_res_new_comment_favourite?: boolean;
  privacy_res_new_comment_on_own?: boolean;
  privacy_fo_new_comment?: boolean;
  privacy_fo_new_comment_favourite?: boolean;
  privacy_fo_new_comment_on_own?: boolean;

  privacy_res_new_mention?: boolean;
  privacy_res_new_resource?: boolean;
  privacy_fo_new_mention?: boolean;

}

export function toPrivacy(user: UserResponse): UserPrivacy {
  return {
    id: user.id,
    privacy_email: user.privacy[0].email,
    privacy_birthday: user.privacy[0].birthday, 
    privacy_cv: user.privacy[0].cv,
    privacy_filmography: user.privacy[0].filmography,
    privacy_recent_views: user.privacy[0].recent_views,
    privacy_gender: user.privacy[0].gender,
    privacy_listed: user.privacy[0].listed,

    privacy_res_new_comment: user.privacy[0].res_new_comment,
    privacy_res_new_comment_favourite: user.privacy[0].res_new_comment_favourite,
    privacy_res_new_comment_on_own: user.privacy[0].res_new_comment_on_own,
    privacy_fo_new_comment: user.privacy[0].fo_new_comment,
    privacy_fo_new_comment_favourite: user.privacy[0].fo_new_comment_favourite,
    privacy_fo_new_comment_on_own: user.privacy[0].fo_new_comment_on_own,

    privacy_res_new_mention: user.privacy[0].res_new_mention,
    privacy_res_new_resource: user.privacy[0].res_new_resource,
    privacy_fo_new_mention: user.privacy[0].fo_new_mention
  }
}

export function fromPrivacy(user: UserResponse, privacy: UserPrivacy): UserResponse {

  const up:UserPrivacy = {
    id: user.privacy[0].id,
    email: privacy.privacy_email,
    birthday: privacy.privacy_birthday,
    cv: privacy.privacy_cv,
    filmography: privacy.privacy_filmography,
    recent_views: privacy.privacy_recent_views,
    gender: privacy.privacy_gender,
    listed: privacy.privacy_listed,
    res_new_comment: privacy.privacy_res_new_comment,
    res_new_comment_favourite: privacy.privacy_res_new_comment_favourite,
    res_new_comment_on_own: privacy.privacy_res_new_comment_on_own,
    fo_new_comment: privacy.privacy_fo_new_comment,
    fo_new_comment_favourite: privacy.privacy_fo_new_comment_favourite,
    fo_new_comment_on_own: privacy.privacy_fo_new_comment_on_own,

    res_new_mention: privacy.privacy_res_new_mention,
    res_new_resource: privacy.privacy_res_new_resource,
    fo_new_mention: privacy.privacy_fo_new_mention,
  }

  return {
    ...user,
    privacy: [up],
  }
}


export interface UserLightResponse {
  id: number;
  first_name: string;
  last_name: string;
  email: string;
  gender: string;
  birth_date: Date | null;
  active?: boolean;
  roles?: string;
  admin_forum: number | boolean | null;
}

export interface UserHeritageResponse {
  id: number;
  first_name: string;
  last_name: string;
  country: string;
  country_id: number;
  company_name: string;
  organization_id: number;
  company_country: string;
  roles: string;
  film_list: boolean;
  film_in_library: number;
  position?:string;
  main_activities?:string;
}

export interface Id{
  id: number;
}

export interface FilterOption {
  column: string;
  operator: 'in' | 'notin' | 'like' | '=' | 'not';
  value: string;
}

export interface PagingOption {
  pageLength: number;
  currentPage: number;
}

export interface SortOption {
  column: string;
  order: 'asc' | 'desc';
}

export interface ListOptionRequest {
  filter: FilterOption[];
  paging: PagingOption;
  sort: SortOption[];
}

export interface CountResponse{
  isLastPage: boolean;
  currentPage: number;
  count: number;
  ids: string[];
}

//searchHeritage
export function indexStreamersToLight(r:UserHeritageIndex):UserHeritageIndex {
  return {
    id: r.id,
    name: r.name,
    company_country: r.company_country,
    organization_id: r.organization_id
  }
}

export interface UserHeritageIndex {
  id: number;
  name: string;
  organization_id: number;
  company_country: string;
}

export const DefaultListOptionRequest: ListOptionRequest = {
  filter: [],
  sort: [],
  paging: {currentPage: 1, pageLength: 100}
}

function parseUser<T extends UserResponse | UserLightResponse>(u: any): T {

  return {
    ...u,
    birth_date: u && u.birth_date ? parseISO(u.birth_date) : null,
  }
}
@injectable()
export default class UserClient {
  constructor(
    @inject(TYPES.AxiosStatic) private readonly axios: AxiosStatic,
    @inject(TYPES.Environment) private readonly environment: Environment,
  ) { }

  async getIndexList(
    lang: SupportedLanguage
  ): Promise<AuthorIndex[]> {
    
    const request = DefaultListOptionRequest
    request.paging.pageLength = 100000

    const response = this.axios.post<AuthorIndex[]>(`${this.environment.backendUrl}/api/v1/entity/${lang}/v_authors_index/list`,
      request,
      {
        withCredentials: true,
      });
    return (await response).data;
  }

  async getListHeritage(
    lang: SupportedLanguage,
    request: PagingOption,
    filter?: FilterOption
  ): Promise<UserHeritageResponse[]> {
    const fullRequest:ListOptionRequest = {
      filter: filter ? [filter] : [],
      sort: [
        {column: "first_name", order: "asc"},
        {column: "last_name", order: "asc"},
      ],
      paging: request,
    };
    const response = this.axios.post<UserHeritageResponse[]>(`${this.environment.backendUrl}/api/v1/entity/${lang}/v_heritage_users/list`,
      fullRequest,
      {
        withCredentials: true,
      });
    return (await response).data;
  }

  async getListToolbox(
    lang: SupportedLanguage,
    request: PagingOption,
    filter?: FilterOption
  ): Promise<UserHeritageResponse[]> {
    const fullRequest:ListOptionRequest = {
      filter: filter ? [filter] : [],
      sort: [
        {column: "first_name", order: "asc"},
        {column: "last_name", order: "asc"},
      ],
      paging: request,
    };
    const response = this.axios.post<UserHeritageResponse[]>(`${this.environment.backendUrl}/api/v1/entity/${lang}/v_toolbox_users/list`,
      fullRequest,
      {
        withCredentials: true,
      });
    return (await response).data;
  }

  async getById(lang: SupportedLanguage, id: number): Promise<UserResponse> {
    const response = await this.axios.get<UserResponse>(`${this.environment.backendUrl}/api/v1/form/${lang}/users/id/id::${id}`, {
      withCredentials: true,
    });
    const responseOrganization = await this.axios.post<UserOrganization[]>(`${this.environment.backendUrl}/api/v1/form/${lang}/user_organization/list`,
    {
      filter: [{column: "user_id", value: ""+response.data.id}],
      sort: [],
      paging: {currentPage: 1, pageLength: 100}
    }
    , {
      withCredentials: true,
    });

    response.data.user_organization = responseOrganization.data;
    return parseUser<UserResponse>(response.data);
  }

  async getMe(lang: SupportedLanguage): Promise<UserResponse | null> {

    const response = await this.axios.post<Id>(`${this.environment.backendUrl}/api/v1/entity/${lang}/v_me/list`,DefaultListOptionRequest, {
      withCredentials: true,
    });
    if(response.data && Array.isArray(response.data)) {
      return await this.getById(lang,response.data[0].id);
    }
    return null;
  }

  async getMeView(lang: SupportedLanguage): Promise<MeResponse | null> {

    const response = await this.axios.post<MeResponse>(`${this.environment.backendUrl}/api/v1/entity/${lang}/v_me/list`,DefaultListOptionRequest, {
      withCredentials: true,
    });
    if(response.data && Array.isArray(response.data)) {
      return parseUser<UserResponse>(response.data[0]);
    }
    return null;
  }


  async getListXLS():Promise<Blob> {
    const response = await this.axios.get<Blob>(`${this.environment.backendUrl}/api/v1/entity/en/v_heritage_users_export/xlsx?fk=resolve_fk&fields=first_name,last_name,email,country,company_name,company_country,main_activities,position&q={  "filter" : [  ],  "sort" : [  ],  "paging" : null}`, {
      withCredentials: true,
      responseType: 'blob'
    })
    return response.data
  }

  async update(lang: SupportedLanguage, user: UserResponse): Promise<void> {

    const obj = {
      ...user,
      birth_date: user.birth_date ? format(user.birth_date,'yyyy-MM-dd'): null
    }

    await this.axios.put<void>(
      `${this.environment.backendUrl}/api/v1/form/${lang}/users/id/id::${user.id}`,
      obj,
      { withCredentials: true, });
  }

  async getStreamersIndexList(
    lang: SupportedLanguage,
  ): Promise<UserHeritageResponse[]> {
    const fullRequest:ListOptionRequest = {
      sort: [
        {column: "first_name", order: "asc"},
        {column: "last_name", order: "asc"}
      ],
      filter: [
        {column: 'roles', operator: 'like', value:'JP-Heritage-Streamer'}
      ],
      paging: {pageLength: 100000, currentPage: 1}
    };
    const response = this.axios.post<UserHeritageResponse[]>(`${this.environment.backendUrl}/api/v1/entity/${lang}/v_heritage_users/list`,
      fullRequest,
      {
        withCredentials: true,
      });
    return (await response).data;
  }

}
