import { injectable, inject, interfaces } from 'inversify';
import { AxiosStatic } from 'axios';
import Environment from '@/env/environment';
import TYPES from '@/ioc/types';
import { SupportedLanguage } from './supported-lang.type';
import { PagingOption, ListOptionRequest } from './user.client';

export interface ForumCategory {
  name: string;
  id: number;
  order: number;
  description: string;
  topics_count: number;
  image: string;
}

export const DEFAULT_PAGING_OPTION: PagingOption = { pageLength: 100, currentPage: 1 }
export const DEFAULT_LIST_OPTION_REQUEST: ListOptionRequest = {paging: DEFAULT_PAGING_OPTION, sort: [], filter: []};

@injectable()
export default class ForumCategoryClient {
  constructor(
    @inject(TYPES.AxiosStatic) private readonly axios: AxiosStatic,
    @inject(TYPES.Environment) private readonly environment: Environment,
  ) { }

  async getById(lang: SupportedLanguage, id: number): Promise<ForumCategory> {
    const response = this.axios.get<ForumCategory>(`${this.environment.backendUrl}/api/v1/form/${lang}/forum_categories/id/id::${id}`, {
      withCredentials: true,
    });
    return (await response).data;
  }

  async getList(
    lang: SupportedLanguage,
    //request: PagingOption,
  ): Promise<ForumCategory[]> {
    const fullRequest = {
      filter: [
      ],
      sort: [
        {column:"order", order:"asc"}
      ],
      //paging: request,
    };
    const response = this.axios.post<ForumCategory[]>(`${this.environment.backendUrl}/api/v1/entity/${lang}/v_forum_categories/list`,
    fullRequest,
    {
      withCredentials: true,
    });
    return (await response).data.map(r => <ForumCategory>(r));
  }
  
}
