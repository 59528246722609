
import Vue, { PropType } from 'vue';
import { mixin as clickaway } from 'vue-clickaway';
import lunr from "lunr";

import Breadcrumbs, { BreadcrumbsItems } from '@/components/Breadcrumbs.vue';
import { getModule } from 'vuex-module-decorators';
import { ResourceLightResponse, indexToLight } from '../client/resource.client';
import ResourcesModule from '../store/modules/resources';
import { AuthorIndex, DefaultListOptionRequest, UserResponse } from '../client/user.client';
import UsersModule from '@/store/modules/users';
import ValuesModule from '@/store/modules/values';
import { format } from 'date-fns';
import { extractContainer } from '../ioc/util';
import TYPES from '../ioc/types';
import ResourceListElementLayout from '@/components/ResourceListElementLayout.vue';
import AuthorLayout from '@/components/AuthorLayout.vue';
import TopicLayout from '@/components/TopicLayout.vue';

import { Label } from '../client/label.client';
import LabelsModule from '@/store/modules/labels';
import mixins from 'vue-typed-mixins'
import LabelMixin from '../mixins/labels';
import SettingsModule from '@/store/modules/settings';
import {SearchModule} from '@/store/modules/search';
import { ForumIndex } from '@/client/forum-comment.client';

export default mixins(LabelMixin).extend({
  name: 'SearchView',
  mixins: [clickaway],
  props: {
    words: String,
    chapter: String,
  },
  components: {
    Breadcrumbs, 
    ResourceListElementLayout,
    AuthorLayout,
    TopicLayout,
  },
  computed: {
    breadcrumbsItems(): BreadcrumbsItems[] {
      return [
        { url: '/search/'+this.words+'/'+this.chapter, label: 'Search' },
      ]

    },
  },
  watch: {
    words: {
      immediate: false,
      handler(w: string) {
        this.doSearch(w)
      },
    }
  },
  async mounted() {  
    await getModule(SearchModule, this.$store).loadResources();
    await getModule(SearchModule, this.$store).loadForm();
    await getModule(SearchModule, this.$store).loadAuthors();
    await getModule(SearchModule, this.$store).loadAgenda();

    await this.doSearch(this.words)

  },
  data():{
      resources:ResourceLightResponse[],
      agenda:ResourceLightResponse[],
      discussions:ForumIndex[],
      authors:AuthorIndex[],
      loading: boolean
  } {
      return {
          resources: [],
          agenda: [],
          discussions: [],
          authors: [],
          loading: true
      }
  },
  methods: {
    async doSearch(q:string) {

      const searchModule = getModule(SearchModule, this.$store)

      const resultResources = searchModule.indexResources.search('*'+q+'*')
      this.resources = resultResources.flatMap(r => {
          const obj = searchModule.resources.find(x => x.id == parseInt(r.ref))
          return obj ? [indexToLight(obj)] : []
      })

      const resultAgenda = searchModule.indexAgenda.search('*'+q+'*')
      this.agenda = resultAgenda.flatMap(r => {
          const obj = searchModule.agenda.find(x => x.id == parseInt(r.ref))
          return obj ? [indexToLight(obj)] : []
      })

      const resultDiscussions = searchModule.indexForum.search('*'+q+'*')
      this.discussions = resultDiscussions.flatMap(r => {
          const obj = searchModule.discussions.find(x => x.id == parseInt(r.ref))
          return obj ? [obj] : []
      })

      const resultAuthors = searchModule.indexAuthors.search('*'+q+'*')
      this.authors = resultAuthors.flatMap(r => {
          const obj = searchModule.authors.find(x => x.id == parseInt(r.ref))
          return obj ? [obj] : []
      })

      if(this.chapter == 'any') {
        const results:number[] = [this.resources.length,this.agenda.length,this.discussions.length,this.authors.length]
        console.log(results.findIndex(x => x > 0))
        switch(results.findIndex(x => x > 0)) {
          case 1: this.$router.push('/search/'+this.words+'/agenda'); break;
          case 2: this.$router.push('/search/'+this.words+'/forum'); break;
          case 3: this.$router.push('/search/'+this.words+'/authors'); break;
          default: this.$router.push('/search/'+this.words+'/resources')
        }
      }
      this.loading = false;
    }
  },
});
