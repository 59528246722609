import { Module, VuexModule, Mutation, Action } from 'vuex-module-decorators';
import { LabelsState } from './state';
import { RootState } from '../root';
import getDecorators from 'inversify-inject-decorators';
import container from '@/ioc/inversify.config';
import TYPES from '@/ioc/types';
import LabelClient, { Label } from '@/client/label.client';
import { PagingOption } from '@/client/user.client';
import Environment from '@/env/environment';
import { Site } from '@/env/site';

const { lazyInject } = getDecorators(container);

@Module({ namespaced: true, name: 'labels' })
export default class LabelsModule extends VuexModule<ThisType<LabelsState>, RootState> implements LabelsState {
  all: Label[] = [];

  @lazyInject(TYPES.LabelClient)
  private readonly labelClient!: LabelClient;

  @lazyInject(TYPES.Site)
  private readonly site!: Site;

  @Mutation
  setAll(labels: Label[]) {
    this.all = labels;
  }

  get getValue() {
    return (key: string): string => {
      return this.all.find(l => l.key === key)?.value || key;
    }
  }

  @Action
  async dispatchGetAll(request: PagingOption): Promise<Label[]>{
    
    let labels:Label[] = []

    switch(this.site.name) {
      case 'HERITAGE': labels = await this.labelClient.getHeritageList(
          this.context.rootState.settings.language,
          request
        );
        break;
      default: labels = await this.labelClient.getList(
        this.context.rootState.settings.language,
        request
      );
    }

    
    this.setAll(labels);
    return labels;
  }
}