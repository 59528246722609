
import Vue, { PropType } from 'vue';
import StreamersLayout from '@/components/heritage/StreamersLayout.vue';
import { getModule } from 'vuex-module-decorators';
import { DEFAULT_LIST_OPTION_REQUEST } from '@/client/category.client';
import UserClient, { DefaultListOptionRequest, Film, UserResponse } from '@/client/user.client';
import { extractContainer } from '@/ioc/util';
import SettingsModule from '@/store/modules/settings';
import TYPES from '@/ioc/types';
import { SiteConfig } from '../../client/value.client';
import ValuesModule from '../../store/modules/values';
import UserFilmClient from '../../client/user-film.client';
import OdDownloadButton, { ExtractedFile } from '@/components/ui/OdDownloadButton.vue';

import { Label } from '../../client/label.client';
import LabelsModule from '@/store/modules/labels';
import mixins from 'vue-typed-mixins'
import LabelMixin from '../../mixins/labels';
import OrganizationClient, { Organization, OrganizationLight } from '../../client/organization.client';
import DownloadClient from '@/client/download.client';
import UsersModule from '@/store/modules/users';
import { STEP_AWAY_DEMO } from '@/router/heritage';

export default mixins(LabelMixin).extend({
  name: 'RightholderLayout',
  components: {
    OdDownloadButton
  },
  inject: ['container'],
  props: {
    isOwner: Boolean,
    className: {
      type: String,
      validator: (c:string) => [
        'rightholders-list'
      ].includes(c),
    },
    companies: {
      type: Array as PropType<OrganizationLight[]>,
      required: true,
    }
  },
  filters: {
    
  },
  asyncComputed: {
    async demoUser(): Promise<Boolean> {
      const me = await getModule(UsersModule, this.$store).dispatchGetMe();
      return me && me.roles && me.roles.includes("Demo") ? true : false
    }
  },
  methods: {
    countryName(name: string): string {
      return getModule(ValuesModule, this.$store).countries.find(x => x.name_it === name )?.name || name
    },
    async downloadList(id:number,demoUser:boolean)  {
        if(demoUser) {
          this.$router.push(STEP_AWAY_DEMO)
          return;
        }
        const settingsModule = getModule(SettingsModule, this.$store);
        const container = extractContainer(this);
        const downloadClient =  container.get<DownloadClient>(TYPES.DownloadClient);
        const organizationClient =  container.get<OrganizationClient>(TYPES.OrganizationClient);

        const company = await organizationClient.getById(settingsModule.language,id)
        console.log(company)
        return await downloadClient.download(company.id,company.film_list_name || 'film_list',"organization.film_list");
      
    },
    extractFile(o:Organization):Promise<ExtractedFile | null> {
      if(o.film_list && o.film_list_name && o.film_list_mime) {
        return Promise.resolve({
          file: o.film_list,
          name: o.film_list_name,
          mime: o.film_list_mime
        })
      } else {
        return Promise.resolve(null)
      }
    }

  }
});
