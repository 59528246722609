import ForumCommentClient, { ForumIndex } from '@/client/forum-comment.client';
import ResourceClient, { ResourceIndexResponse } from '@/client/resource.client';
import UserFilmClient, { FilmIndexResponse } from '@/client/user-film.client';
import UserClient, { AuthorIndex, UserHeritageIndex, UserHeritageResponse } from '@/client/user.client';
import container from '@/ioc/inversify.config';
import TYPES from '@/ioc/types';
import { id } from 'date-fns/locale';
import getDecorators from 'inversify-inject-decorators';
import { Builder, Index } from 'lunr';
import { Action, Module, Mutation, VuexModule } from 'vuex-module-decorators';
import { RootState } from '../root';
import { SearchState,SearchStateHeritage } from './state';
import { Organization, OrganizationLight, fromHeritageUser } from '@/client/organization.client';

const { lazyInject } = getDecorators(container);

@Module({ namespaced: true, name: 'search'})
export class SearchModule extends VuexModule<ThisType<SearchState>, RootState> implements SearchState {
    private _indexResources:Index | null = null
    private _indexAgenda:Index | null = null
    private _indexAuthors:Index | null = null
    private _indexForum:Index | null = null
    resources:ResourceIndexResponse[] = []
    agenda:ResourceIndexResponse[] = []
    authors:AuthorIndex[] = []
    discussions:ForumIndex[] = []

    get indexResources():Index {
        if(this._indexResources) {
            return this._indexResources
        } else {
            throw "Index not loaded"
        }
    }

    get indexAgenda():Index {
        if(this._indexAgenda) {
            return this._indexAgenda
        } else {
            throw "Index not loaded"
        }
    }

    get indexAuthors():Index {
        if(this._indexAuthors) {
            return this._indexAuthors
        } else {
            throw "Index not loaded"
        }
    }

    get indexForum():Index {
        if(this._indexForum) {
            return this._indexForum
        } else {
            throw "Index not loaded"
        }
    }

    @lazyInject(TYPES.ResourceClient)
    private readonly resourcesClient!: ResourceClient;

    @lazyInject(TYPES.ForumCommentClient)
    private readonly forumCommentClient!: ForumCommentClient;

    @lazyInject(TYPES.UserClient)
    private readonly userClient!: UserClient;

    @Mutation
    setIndexResources(idx: Index) {
        this._indexResources = idx;
    }

    @Mutation
    setResources(r: ResourceIndexResponse[]) {
        this.resources = r;
    }
    
    @Action
    public async loadResources(): Promise<Index> {


        if(this._indexResources) return this._indexResources

        const idxBuilder = new Builder
         idxBuilder.field('title',{boost: 2})
         idxBuilder.field('summary',{boost: 1.5})
         idxBuilder.field('body')
         idxBuilder.field('author',{boost: 1.5})
         idxBuilder.field('category')
         idxBuilder.field('subcategory')
         //Add here other fields if needed

         //reference field
         idxBuilder.ref('id')

         const documents = await this.resourcesClient.getIndexList("en")

         documents.forEach(d => idxBuilder.add(d))

         this.setResources(documents)
         this.setIndexResources(idxBuilder.build())

         console.log('index builded')

        return this.indexResources
    }

    @Mutation
    setIndexAgenda(idx: Index) {
        this._indexAgenda = idx;
    }

    @Mutation
    setAgenda(r: ResourceIndexResponse[]) {
        this.agenda = r;
    }
    
    @Action
    public async loadAgenda(): Promise<Index> {


        if(this._indexAgenda) return this._indexAgenda

        const idxBuilder = new Builder
        idxBuilder.field('title',{boost: 2})
        idxBuilder.field('summary',{boost: 1.5})
        idxBuilder.field('body')
        idxBuilder.field('author',{boost: 1.5})
        idxBuilder.field('category')
        idxBuilder.field('subcategory')
        idxBuilder.field('location', {boost: 1.8})

         //reference field
         idxBuilder.ref('id')

         const documents = await this.resourcesClient.getIndexAgenda("en")

         documents.forEach(d => idxBuilder.add(d))

         this.setAgenda(documents)
         this.setIndexAgenda(idxBuilder.build())

         console.log('index builded')

        return this.indexAgenda
    }

    @Mutation
    setIndexAuthors(idx: Index) {
        this._indexAuthors = idx;
    }

    @Mutation
    setAuthors(r: AuthorIndex[]) {
        this.authors = r;
    }
    
    @Action
    public async loadAuthors(): Promise<Index> {


        if(this._indexAuthors) return this._indexAuthors

        const idxBuilder = new Builder
        idxBuilder.field('first_name',{boost: 2})
        idxBuilder.field('last_name',{boost: 2})
        idxBuilder.field('profession')
        idxBuilder.field('country')
         //Add here other fields if needed

         //reference field
         idxBuilder.ref('id')

         const documents = await this.userClient.getIndexList("en")
         documents.forEach(d => idxBuilder.add(d))

         this.setAuthors(documents)
         this.setIndexAuthors(idxBuilder.build())

         console.log('index builded')

        return this.indexAuthors
    }

    @Mutation
    setIndexForum(idx: Index) {
        this._indexForum = idx;
    }

    @Mutation
    setDiscussions(r: ForumIndex[]) {
        this.discussions = r;
    }
    
    @Action
    public async loadForm(): Promise<Index> {


        if(this._indexForum) return this._indexForum

        const idxBuilder = new Builder
         idxBuilder.field('title',{boost: 2})
         idxBuilder.field('body')
         idxBuilder.field('author')
         //Add here other fields if needed

         //reference field
         idxBuilder.ref('id')

         const documents = await this.forumCommentClient.getIndex("en")
         documents.forEach(d => idxBuilder.add(d))

         this.setDiscussions(documents)
         this.setIndexForum(idxBuilder.build())

         console.log('index builded')

        return this.indexForum
    }
}

@Module({ namespaced: true, name: 'searchHeritage'})
export class SearchModuleHeritage extends VuexModule<ThisType<SearchState>, RootState> implements SearchStateHeritage {
    private _indexFilms:Index | null = null
    private _indexStreamers:Index | null = null
    private _indexRightholders:Index | null = null
    private _indexUsers:Index | null = null
    films:FilmIndexResponse[] = []
    streamers:UserHeritageResponse[] = []
    rightholders:UserHeritageResponse[] = []
    users:UserHeritageResponse[] = []

    

    get indexFilms():Index {
        if(this._indexFilms) {
            return this._indexFilms
        } else {
            throw "Index not loaded"
        }
    }
    get indexStreamers():Index {
        if(this._indexStreamers) {
            return this._indexStreamers
        } else {
            throw "Index not loaded"
        }
    }
    get indexRightholders():Index {
        if(this._indexRightholders) {
            return this._indexRightholders
        } else {
            throw "Index not loaded"
        }
    }
    get indexUsers():Index {
        if(this._indexUsers) {
            return this._indexUsers
        } else {
            throw "Index not loaded"
        }
    }


    @lazyInject(TYPES.UserFilmClient)
    private readonly userFilmClient!: UserFilmClient;

    @lazyInject(TYPES.UserClient)
    private readonly userClient!: UserClient;

    @Mutation
    setIndexFilms(idx: Index) {
        this._indexFilms = idx;
    }
    @Mutation
    setIndexStreamers(idx: Index) {
        this._indexStreamers = idx;
    }
    @Mutation
    setIndexRightholders(idx: Index) {
        this._indexRightholders = idx;
    }
    @Mutation
    setIndexUsers(idx: Index) {
        this._indexUsers = idx;
    }
    
    @Mutation
    setFilms(r: FilmIndexResponse[]) {
        this.films = r;
    }
    @Mutation
    setStreamers(r: UserHeritageResponse[]) {
        this.streamers = r;
    }
    @Mutation
    setRightholders(r: UserHeritageResponse[]) {
        this.rightholders = r;
    }
    @Mutation
    setUsers(r: UserHeritageResponse[]) {
        this.users = r;
    }

    @Action
    public async loadFilms(): Promise<Index> {


        if(this._indexFilms) return this._indexFilms

        const idxBuilder = new Builder
         idxBuilder.field('title',{boost: 2})
         idxBuilder.field('year',{boost: 1.5})
         idxBuilder.field('director')
         idxBuilder.field('length',{boost: 1.5})
         idxBuilder.field('company_id')
         idxBuilder.field('company_name')
         idxBuilder.field('screening_room')
         idxBuilder.field('image')
         idxBuilder.field('countries')
         //Add here other fields if needed

         //reference field
         idxBuilder.ref('id')

         const documents = await this.userFilmClient.getIndexList("en")

         documents.forEach(d => idxBuilder.add(d))

         this.setFilms(documents)
         this.setIndexFilms(idxBuilder.build())

         console.log('index builded')

        return this.indexFilms
    }
    @Action
    public async loadStreamers(): Promise<Index> {


        if(this._indexStreamers) return this._indexStreamers

        const idxBuilder = new Builder
         idxBuilder.field('company_name',{boost: 2})
         idxBuilder.field('company_country',{boost: 1.5})
         idxBuilder.field('organization_id')
         //Add here other fields if needed

         //reference field
         idxBuilder.ref('id')

         const documents = await this.userClient.getListHeritage("en",{currentPage: 1, pageLength: 1000}, {column: 'roles', operator: 'like', value:'JP-Heritage-Streamer'})
         //const documents = results.filter((v,i,a)=>a.findIndex(t=>(t.id === v.id))===i && v.company_name).sort((x,y) => x.company_name.localeCompare(y.company_name))
         //documents.map(fromHeritageUser).filter((v,i,a)=>a.findIndex(t=>(t.id === v.id))===i && v.name).sort((x,y) => x.name.localeCompare(y.name))
        
         documents.forEach(d => idxBuilder.add(d))

         this.setStreamers(documents)
         this.setIndexStreamers(idxBuilder.build())

         console.log('index builded')

        return this.indexStreamers
    }
    @Action
    public async loadRightholders(): Promise<Index> {


        if(this._indexRightholders) return this._indexRightholders

        const idxBuilder = new Builder
         idxBuilder.field('company_name',{boost: 2})
         idxBuilder.field('company_country',{boost: 1.5})
         idxBuilder.field('organization_id')
         //Add here other fields if needed

         //reference field
         idxBuilder.ref('id')

         const documents = await this.userClient.getListHeritage("en",{currentPage: 1, pageLength: 1000}, {column: 'roles', operator: 'like', value:'JP-Heritage-Rightholder'})
         //const documents = results.filter((v,i,a)=>a.findIndex(t=>(t.id === v.id))===i && v.company_name).sort((x,y) => x.company_name.localeCompare(y.company_name))
         //documents.map(fromHeritageUser).filter((v,i,a)=>a.findIndex(t=>(t.id === v.id))===i && v.name).sort((x,y) => x.name.localeCompare(y.name))

         documents.forEach(d => idxBuilder.add(d))

         this.setRightholders(documents)
         this.setIndexRightholders(idxBuilder.build())

         console.log('index builded')

        return this.indexRightholders
    }
    @Action
    public async loadUsers(): Promise<Index> {


        if(this._indexUsers) return this._indexUsers

        const idxBuilder = new Builder
         idxBuilder.field('public_name',{boost: 2})
         idxBuilder.field('first_name',{boost: 1.5})
         idxBuilder.field('last_name')
         idxBuilder.field('position')
         idxBuilder.field('country_id')
         idxBuilder.field('public_email')
         idxBuilder.field('email')
         idxBuilder.field('organization_id')
         
         //Add here other fields if needed

         //reference field
         idxBuilder.ref('id')

         const documents = await this.userClient.getListHeritage("en",{currentPage: 1, pageLength: 1000})
         //const documents = results.filter((v,i,a)=>a.findIndex(t=>(t.id === v.id))===i && v.company_name).sort((x,y) => x.company_name.localeCompare(y.company_name))
         //documents.map(fromHeritageUser).filter((v,i,a)=>a.findIndex(t=>(t.id === v.id))===i && v.name).sort((x,y) => x.name.localeCompare(y.name))

         documents.forEach(d => idxBuilder.add(d))

         this.setUsers(documents)
         this.setIndexUsers(idxBuilder.build())

         console.log('index builded')

        return this.indexUsers
    }

    //rightholders e users
}