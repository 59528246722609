
import Vue from "vue";
import Header, { MenuItem } from "@/components/heritage/ui/Header.vue";
import { getModule } from "vuex-module-decorators";
import CategoriesModule from "@/store/modules/categories";
import { CategoryLight } from "@/client/category.client";
import { DefaultListOptionRequest } from '../../../client/user.client';



/**
 * The smart part of Header.
 * It provides data for the main top Header.
 */
export default Vue.extend({
  name: "HeaderContainer",
  components: {
    Header,
  },
  created() {
    //getModule(CategoriesModule,this.$store).dispatchGetList()
  },
  computed: {
    categories():CategoryLight[] {
      return [] //getModule(CategoriesModule,this.$store).allLight
    } 
  }
});
