import { render, staticRenderFns } from "./ProfileNotificationsList.vue?vue&type=template&id=da8c4bdc&scoped=true&"
import script from "./ProfileNotificationsList.vue?vue&type=script&lang=ts&"
export * from "./ProfileNotificationsList.vue?vue&type=script&lang=ts&"
import style0 from "./ProfileNotificationsList.vue?vue&type=style&index=0&id=da8c4bdc&prod&scoped=true&lang=less&"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "da8c4bdc",
  null
  
)

export default component.exports