import { Site, HERITAGE } from './site';

export const AUTH_CALLBACK_PATH = '/authCallback';

export function authUrl(site: Site): string {
    const redirectUrl = encodeURIComponent(window.origin + "/auth/oauth")
    const state = encodeURIComponent("authorize&origin=" + window.origin)
    const clientId = "HeritageOnlineServicePlatform"
    
   
    
    const popupUrl = "https://account.locarnofestival.ch/realms/lff/protocol/openid-connect/auth" + 
        `?state=${state}` +
        `&redirect_uri=${redirectUrl}` +
        `&response_type=code` +
        `&client_id=${clientId}` +
        `&scope=openid`


    console.log(popupUrl)
    return popupUrl
}
