
import Vue from 'vue';
import SwiperScreeningRoom from '@/components/heritage/SwiperScreeningRoom.vue';
import RecentFilms from '@/components/heritage/RecentFilms.vue';
import StreamersLayout from '@/components/heritage/StreamersLayout.vue';
import NewsLayout from '@/components/heritage/NewsLayout.vue';
import Agenda from '@/components/Agenda.vue';
import SwiperAgenda from '@/components/SwiperAgenda.vue';
import PopularResources from '@/components/PopularResources.vue';
import OdImage from '@/components/ui/OdImage.vue';
import { getModule } from 'vuex-module-decorators';
import ResourceClient, { ResourceLightResponse } from '../../client/resource.client';
import ResourcesModule from '../../store/modules/resources';
import { DEFAULT_LIST_OPTION_REQUEST } from '@/client/category.client';
import CategoriesModule from '../../store/modules/categories';
import UserClient, { DefaultListOptionRequest, Film, UserResponse } from '@/client/user.client';
import { extractContainer } from '@/ioc/util';
import SettingsModule from '@/store/modules/settings';
import TYPES from '@/ioc/types';
import FilmModule from '../../store/modules/films';
import UserFilmClient, { FilmLight } from '../../client/user-film.client';
import BannerClient, { Banner } from '../../client/banner.client';
import { Organization, OrganizationLight, fromHeritageUser } from '../../client/organization.client';


const AGENDA_SWIPE_RESOURCE_COUNT = 5;
const AGENDA_RESOURCE_COUNT = 10;
const RECENT_RESOURCE_COUNT = 10;
const EDITOR_PICK_RESOURCE_COUNT = 10;
const POPULAR_RESOURCE_COUNT = 8;

const AGENDA_CATEGORY_ID = 7;



export default Vue.extend({
  name: 'Home',
  inject: ['container'],
  components: {
    SwiperScreeningRoom,
    RecentFilms,
    Agenda,
    SwiperAgenda,  
    StreamersLayout,
    NewsLayout,
    PopularResources,
    OdImage,
  },
  beforeCreate: function() {
    document.body.className = '';
  },
  async created() {
    console.log('Home')
      const settingsModule = getModule(SettingsModule, this.$store);
      const container = extractContainer(this);
      const resourcesClient =  container.get<ResourceClient>(TYPES.ResourceClient);
      const userClient =  container.get<UserClient>(TYPES.UserClient);
      const bannerClient =  container.get<BannerClient>(TYPES.BannerClient);
      const filmModule = getModule(FilmModule, this.$store);
      await filmModule.dispatchGetFilmList();

      const [news, films, screening_films, users,verticals,horizontals] = await Promise.all(
        [
          resourcesClient.getHeritageNews(settingsModule.language,20),
          filmModule.randomFilmsForHome,
          filmModule.randomScreeningRoomForHome,
          userClient.getListHeritage(settingsModule.language,{currentPage: 1, pageLength: 1000}, {column: 'roles', operator: 'like', value:'JP-Heritage-Streamer'}),
          bannerClient.getListVertical(settingsModule.language,2),
          bannerClient.getListHorizontal(settingsModule.language,2)

        ]);

      this.news = news;
      this.films = films
      this.screening_films = screening_films
      this.bannerVertical =  verticals[Math.floor(Math.random() * verticals.length)]
      this.bannerHorizontal = horizontals[Math.floor(Math.random() * horizontals.length)]
      this.streamers = users.map(fromHeritageUser).filter((v,i,a)=>a.findIndex(t=>(t.id === v.id))===i && v.name).sort((x,y) => x.name.localeCompare(y.name))

  },
  data():{ news: ResourceLightResponse[] | null, films: FilmLight[] | null, screening_films: FilmLight[] | null, streamers: OrganizationLight[] | null, bannerVertical: Banner | null, bannerHorizontal:Banner  | null } {
    return {
      news: null,
      films: null,
      screening_films: null,
      streamers: null,
      bannerVertical: null,
      bannerHorizontal: null
    };
  },
  asyncComputed: {


    

  },
  computed: {
  
  }
});
