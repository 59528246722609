
import Vue, { PropType } from 'vue';
import { getModule } from 'vuex-module-decorators';
import FloatLabel from "vue-float-label/components/FloatLabel.vue";
import { UserResponse, Cv, UserProfile, toProfile } from '@/client/user.client';
import UsersModule from '@/store/modules/users';
import ValuesModule from '@/store/modules/values';
import { format } from 'date-fns';

import {sanitizeNumber} from '../../../service/form';

import OdImage from '@/components/ui/OdImage.vue';
import ImageCropper from '@/components/ui/ImageCropper.vue';
import { ValueResponse } from '@/client/value.client';

import { Label } from '../../../client/label.client';
import LabelsModule from '@/store/modules/labels';
import mixins from 'vue-typed-mixins'
import LabelMixin from '../../../mixins/labels';
import { NamedBlob } from '@/client/download.client';
import { CropEvent } from '../../ui/ImageCropper.vue';

function sanitizeProfileForm(profile: UserProfile): UserProfile {
  return {
    ...profile,
    profession_id: sanitizeNumber(profile.profession_id),
    country_id: sanitizeNumber(profile.country_id),
    gender: sanitizeNumber(profile.gender),
  }
}


export interface SubmitFormEmit {
  profile: UserProfile;
  cv: NamedBlob | null;
  image: NamedBlob | null;
}

export default mixins(LabelMixin).extend({
  components: {
    FloatLabel,
    OdImage,
    ImageCropper
  },
  props: {
    profile: {
      required: true,
      type: Object as PropType<UserProfile>,
    },
  },
  created() {
    getModule(UsersModule, this.$store).dispatchGetById(this.profile.id);
  },
  methods: {
    uploadCv() {
      (this.$refs.cvFile as HTMLInputElement).click()
    },
    onCancel(event: MouseEvent) {
      event.preventDefault();
      this.$emit('onCancel');
    },
    onSubmitForm(event: Event) {
      event.preventDefault();
      this.loadingForm = true;
      const submitEmit: SubmitFormEmit = {
        profile: sanitizeProfileForm(this.profile),
        cv: this.cv,
        image: this.image
      };
      this.$emit('onSubmit', submitEmit);
    },
    onCvChange(event: Event) {
      const target: EventTarget | null = event?.target;
      if (!target) {
        return;
      }

      const file: File = ((target as HTMLInputElement).files ?? [])[0];

      if (!file) {
        return;
      }

      this.cv = {
          name: file.name,
          blob: file
      }
    },
    onAvatarCrop(event: CropEvent) {
      this.image = event.file;
    },
    professionName(id:Number):string {
      return getModule(ValuesModule, this.$store).getProfessionName(this.profile.id);
    },
    countryName(id:number):string {
      return getModule(ValuesModule, this.$store).getCountryName(id);
    }
  },
  data(): { cv: NamedBlob | null, image: NamedBlob | null, loadingForm: boolean } {
    return {
      cv: null,
      image: null,
      loadingForm: false,
    };
  },
  computed: {
    countries(): ValueResponse[] {
      return getModule(ValuesModule, this.$store).countries.filter(x => x.continent_id != 1)
    },
    filmGendres(): ValueResponse[] {
      return getModule(ValuesModule, this.$store).filmGendres;
    },
    user(): UserResponse | undefined {
      return getModule(UsersModule, this.$store).selectById(this.profile.id);
    },
    professions(): ValueResponse[] {
      return getModule(ValuesModule, this.$store).professions;
    },
  }
})
