
import Vue from 'vue';
import { getModule } from 'vuex-module-decorators';

import AuthModule from './store/modules/auth';
import LabelsModule from './store/modules/labels';
import ValuesModule from './store/modules/values';
import HeaderContainer from '@/components/containers/HeaderContainer.vue';
import CategoriesModule from './store/modules/categories';
import Environment from './env/environment';
import container from './ioc/inversify.config';
import { Container } from 'inversify';
import TYPES from './ioc/types';
import UsersModule from './store/modules/users';

export interface RootInject {
  container: Container;
}

export default Vue.extend({
  components: {
  },
  provide(): RootInject {
    return {
      container: this.container,
    }
  },
  async created() {
    await Promise.all([
      getModule(LabelsModule, this.$store).dispatchGetAll({pageLength: 1000, currentPage: 1}),
      getModule(ValuesModule, this.$store).dispatchGetAll({pageLength: 1000, currentPage: 1}),
    ]);
  },
  data() {
    return {
      container,
    };
  },
});
