
import Vue from "vue";
import { getModule } from 'vuex-module-decorators';
import AuthModule from '../store/modules/auth';
import { LoginStatus } from '../store/modules/auth/state';
import { REGISTER_PATH, HOME_PATH, COMPLETE_PROFILE } from '../router/toolbox';
import { Label } from '../client/label.client';
import LabelsModule from '@/store/modules/labels';
import mixins from 'vue-typed-mixins'
import LabelMixin from '../mixins/labels';
import { authUrl } from '@/service/auth'
import { extractContainer } from '@/ioc/util';
import UserClient from '@/client/user.client';
import TYPES from '@/ioc/types';
import SettingsModule from '@/store/modules/settings';
import UsersModule from '@/store/modules/users';
import ValuesModule from '../store/modules/values';
import { TOOLBOX } from '../service/site';
import NotificationModule from "@/store/modules/notifications";
import { onLogin } from "@/onLogin";

export default mixins(LabelMixin).extend({
  beforeCreate: function() {
    document.body.className = 'login';
  },
  name: "LoginView",
  inject: ["container"],
  data() {
    return {
      isVideoPlaying: false,
      loginOverlay: false,
      loginOverlayTimeout: false,
    };
  },
  components: {

  },
  computed: {
    loginStatus(): LoginStatus {
      return getModule(AuthModule, this.$store).loginStatus;
    },
    intro():string {
      return getModule(ValuesModule, this.$store).siteConfig.intro
    },
    loginUrl():string {
      return authUrl(TOOLBOX)
    }
  },
  methods: {
    overlayTimeout() {
      // timout per visualizzare il link per il redirect
      setTimeout(() => this.loginOverlayTimeout = true, 20000);// 20000 
    },
    playVideo() {
      this.isVideoPlaying = !this.isVideoPlaying;
      // Play video
      (this.$refs.videoBg as HTMLVideoElement).play()
    },
    stopVideo() {
      // Stop video
      (this.$refs.videoBg as HTMLVideoElement).pause()
      this.isVideoPlaying = !this.isVideoPlaying;
    },
  }
});
