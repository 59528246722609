
import Vue, { PropType } from 'vue';
import { Film } from '@/client/user.client';
import FilmLayout from '@/components/FilmLayout.vue';
import { Label } from '../../client/label.client';
import LabelsModule from '@/store/modules/labels';
import mixins from 'vue-typed-mixins'
import LabelMixin from '../../mixins/labels';
import Swiper, { SwiperOptions } from 'swiper';

export default mixins(LabelMixin).extend({
  name: 'RecentFilms',
  components: {
    FilmLayout,
  },  
  props: {
    films: {
      type: Array as PropType<Film[]>,
      required: true,
    }
  },
  computed: {
    filmList(): Film[] {
      return this.films;
    }
  }
});
