
import Vue, { PropType } from "vue";
import FilmForm, {
  SubmitFormEmit
} from "../components/forms/FilmForm.vue";
import { extractContainer } from "../ioc/util";
import TYPES from "../ioc/types";
import UserFilmClient, { getDefaultFilm } from "@/client/user-film.client";
import { Film, Cv } from "@/client/user.client";
import { getModule } from "vuex-module-decorators";
import SettingsModule from "../store/modules/settings";
import DownloadClient from "../client/download.client";
import { sub } from "date-fns";
import { getFilmFormPath, getProfilePath } from '@/router/toolbox';
import ValuesModule from '../store/modules/values';
import { ValueResponse } from '@/client/value.client';
import UsersModule from '../store/modules/users';

export default Vue.extend({
  name: "FormFilmView",
  props: {
    id: Number
  },
  components: {
    FilmForm
  },
  data(): { film: Film } {
    return {
      film: getDefaultFilm()
    };
  },
  methods: {
    async onFilmSubmit(submit: SubmitFormEmit) {
      const { film, newImage } = submit;
      const container = extractContainer(this);
      const downloadClient = container.get<DownloadClient>(
        TYPES.DownloadClient
      );
      const userFilmClient = container.get<UserFilmClient>(TYPES.UserFilmClient);
      const settingsModule = getModule(SettingsModule, this.$store);
      const me = getModule(UsersModule, this.$store).me;
      
      let userId = -1
      if(me) {
        userId = me.id
      } 

      film.user_id = userId;

      let filmId;
      if (film.id) {
        await userFilmClient.update(settingsModule.language, film);
        filmId = film.id; 
      } else {
        filmId = await userFilmClient.create(settingsModule.language, film);
      }

      if (newImage) {
        await downloadClient.uploadEdit(filmId, newImage, "user_films.image")
      }

      this.$router.push(getProfilePath(userId));
      
      
    }
  },
  watch: {
    id: {
      immediate: true,
      async handler(id: number) {
        const container = extractContainer(this);
        const userFilmClient = container.get<UserFilmClient>(TYPES.UserFilmClient);
        const settingsModule = getModule(SettingsModule, this.$store);

        if (this.id !== null) {
          this.film = await userFilmClient.getById(
            settingsModule.language,
            this.id
          );
        }
      }
    }
  },
  inject: ["container"]
});
