
import Vue, { PropType } from 'vue';
import { mixin as clickaway } from 'vue-clickaway';

import { getModule } from 'vuex-module-decorators';
import ResourceClient, { ResourceLightResponse } from '../../client/resource.client';
import ResourcesModule from '../../store/modules/resources';
import ProfileCompany from '@/components/heritage/ProfileCompany.vue';
import ProfileLibrary from '@/components/heritage/ProfileLibrary.vue';
import ProfileAnalytics from '@/components/heritage/ProfileAnalytics.vue';
import ProfileSocial from '@/components/ui/ProfileSocial.vue';
import OdImage from '@/components/ui/OdImage.vue';
import OdDownloadButton from '@/components/ui/OdDownloadButton.vue';
import { UserResponse, Film, DefaultListOptionRequest, ListOptionRequest } from '../../client/user.client';
import UsersModule from '@/store/modules/users';
import ValuesModule from '@/store/modules/values';
import { format } from 'date-fns';
import { extractContainer } from '../../ioc/util';
import TYPES from '../../ioc/types';
import DownloadClient, { DownloadStatus } from '@/client/download.client';

import { Label } from '../../client/label.client';
import LabelsModule from '@/store/modules/labels';
import mixins from 'vue-typed-mixins'
import LabelMixin from '../../mixins/labels';
import SettingsModule from '@/store/modules/settings';
import OrganizationClient, { Organization, OrganizationFast } from '../../client/organization.client';
import { isHeritageRightHolder, isHeritageOther } from '@/service/user'

export default mixins(LabelMixin).extend({
  name: 'Profile',
  mixins: [clickaway],
  props: {
    id: Number,
    chapter: String
  },
  components: {
    ProfileCompany,
    ProfileLibrary,
    ProfileSocial,
    ProfileAnalytics,
    OdImage,
    OdDownloadButton,
  },
  created() {
    getModule(UsersModule, this.$store).dispatchGetById(this.id);
  },
  asyncComputed: {
    async chapterResources(): Promise<ResourceLightResponse[] | Film[]> {
      switch(this.chapter) {
        case 'library': return this.filmographyResources;
        default: return this.filmographyResources;
      }
    },
    async company():Promise<OrganizationFast | null> {
      if(this.profile && this.profile.user_organization[0]) {
        const settingsModule = getModule(SettingsModule, this.$store);
        const container = extractContainer(this);
        const organizationClient = container.get<OrganizationClient>(TYPES.OrganizationClient);
        const org = await organizationClient.getById(settingsModule.language, this.profile.user_organization[0].organization_id);
        return org;
      } else {
        return null;
      }
    }
  },
  computed: {
    title(): string {
      switch(this.chapter) {
        case 'company': return this.i18n('profile.company.profile.title');
        case 'library': return this.i18n('profile.library.title');
        default: return this.i18n('profile.company.profile.title');
      }
    },
    filmographyResources(): Film[] {
      if(this.profile) {
        return this.profile.films;
      } else {
        return [];
      }
    },
    isOwner(): boolean {
      return this.id === getModule(UsersModule, this.$store).me?.id;
    },
    profile(): UserResponse | undefined {
      return getModule(UsersModule, this.$store).selectById(this.id);
    },
    rightholder():boolean {
      return isHeritageRightHolder(this.profile)
    },
    other():boolean {
      return isHeritageOther(this.profile)
    },
  },
  filters: {
    formatDate(date: Date): string {
      if (!date) {
        return '';
      }
      return format(date, 'd/M/yyyy');
    } 
  },
  methods: {
    countryName(id: number): string {
      return getModule(ValuesModule, this.$store).getCountryName(id);
    },
    professionName(id: number): string {
      return getModule(ValuesModule, this.$store).getProfessionName(id);
    },
    hasSocial(profile: UserResponse | undefined):boolean {
      return profile ? profile.socials.some(x => x.value.length > 0) : false;
    },
    formatWebsite(website: string) {
        // JavaScript/TypeScript
        if (website.substring(0, 7) !== 'http://' && website.substring(0, 8) !== 'https://'){
          website = 'http://' + website;
        }
          return website;
    },
  },
  data(): {
    isFavoriteSubmenuOpen: Boolean,
  } {
    return {
      isFavoriteSubmenuOpen: false,
    };
  },
  inject: ['container'],
});
