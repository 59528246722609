
import Vue from 'vue';
import ResourcesModule from '@/store/modules/resources';
import { getModule } from 'vuex-module-decorators';
import ResourceClient, { ResourceResponse, Attachment, Embedded } from '../client/resource.client';
import Breadcrumbs, { BreadcrumbsItems } from '@/components/Breadcrumbs.vue';

import UserClient, { UserResponse } from '../client/user.client';
import UsersModule from '../store/modules/users';
import OdImage from '../components/ui/OdImage.vue';
import VideoPlayer from '../components/ui/VideoPlayer.vue';
import OdDownloadButton from '../components/ui/OdDownloadButton.vue';
import Favorite from '@/components/ui/Favorite.vue';
import TagResolver from '../components/containers/TagResolver.vue';
import { formatPublication, formatDateRange } from '@/service/ui';
import { format } from 'date-fns';

import { Label } from '../client/label.client';
import PartnerClient, { Partner } from '../client/partner.client';
import ForumCategoryClient, { ForumCategory } from '../client/forum-category.client';
import LabelsModule from '@/store/modules/labels';
import mixins from 'vue-typed-mixins'
import LabelMixin from '../mixins/labels';
import SettingsModule from '@/store/modules/settings';
import { extractContainer } from '@/ioc/util';
import TYPES from '@/ioc/types';

export default mixins(LabelMixin).extend({
  name: 'ForumsView',
  props: {
    id: Number,
  },
  inject: ['container'],
  async created() {
    /*
    const resourcesModule = getModule(ResourcesModule, this.$store);
    const resource = await resourcesModule.dispatchGetById(this.id);
    if (resource.suggestor_id) {
        const usersModule: UsersModule = getModule(UsersModule, this.$store);
        await usersModule.dispatchGetById(resource.suggestor_id);
    }

    const settingsModule = getModule(SettingsModule, this.$store);
    const container = extractContainer(this);
    const resourcesClient =  container.get<ResourceClient>(TYPES.ResourceClient);
    const user = await container.get<UserClient>(TYPES.UserClient).getMe(settingsModule.language);
    if(user) {
      await resourcesClient.touch(settingsModule.language,resource.id,user.id)
    }
    */

  },
  filters: {
    formatDate(date: Date): string {
      return formatPublication(date);
    },
    resourceLabels(resource: ResourceResponse): string {
      return [
        (r: ResourceResponse) => r.embedded?.length > 0 && 'Video',
        (r: ResourceResponse) => r.links?.length > 0 && 'Link',
        (r: ResourceResponse) => r.attachments.length > 0 && 'Document',
      ] .map(r => r(resource))
        .filter(r => !!r)
        .join(', ');
    },
    formatEventDate(r: ResourceResponse) {
      return formatDateRange(r.start, r.end);
    },
  },
  asyncComputed: {
    async forumCategories(): Promise<ForumCategory[] | null> {
      const settingsModule = getModule(SettingsModule, this.$store);
      const container = extractContainer(this);
      const ForumCategoryClient = container.get<ForumCategoryClient>(TYPES.ForumCategoryClient);
      return await ForumCategoryClient.getList(settingsModule.language);
    },
  },
  computed: {
    resource(): ResourceResponse | null {
      const resourcesModule: ResourcesModule = getModule(ResourcesModule, this.$store);
      return resourcesModule.selectById(this.id) || null;
    },
    suggestor(): UserResponse | null {
      if (!this.resource) {
        return null;
      }
      const usersModule: UsersModule = getModule(UsersModule, this.$store);
      const suggestor = usersModule.selectById(this.resource.suggestor_id) || null;
      return suggestor;
    },
  },
  components: {
    Breadcrumbs,
    OdImage,
    TagResolver,
    Favorite,
  },
  data(): {
    breadcrumbsItems: BreadcrumbsItems[],
  } { 
    return {
      breadcrumbsItems: [
        { url: '/forum', label: 'Forum Area' },
      ],
    };
  },
});
