
import Vue, { PropType } from 'vue';
import ResourceListElementLayout from '@/components/ResourceListElementLayout.vue';
import { ResourceLightResponse } from '@/client/resource.client';
import { Label } from '../client/label.client';
import LabelsModule from '@/store/modules/labels';
import mixins from 'vue-typed-mixins'
import LabelMixin from '../mixins/labels';

export default mixins(LabelMixin).extend({
  name: 'RecentResources',
  components: {
    ResourceListElementLayout,
  },
  props: {
    resources: {
      type: Array as PropType<ResourceLightResponse[]>,
      required: true,
    }
  },
  computed: {
    sortedByDateResources(): ResourceLightResponse[] {
      //return this.resources;
      //return this.resources.concat().sort(r => -(r.pubblication_date?.valueOf() ?? 0));
      return this.resources.concat().sort((a, b) => a.pubblication_date.valueOf()<b.pubblication_date.valueOf()?1:-1)
    }
  }
});
