
import Vue, { PropType } from 'vue';
import UserClient, { DefaultListOptionRequest, Film, UserLightResponse, UserResponse } from '@/client/user.client';
import OdDownloadButton from '@/components/ui/OdDownloadButton.vue';
import ValuesModule from '@/store/modules/values';
import { getModule } from 'vuex-module-decorators';

import { Label } from '../../client/label.client';
import LabelsModule from '@/store/modules/labels';
import mixins from 'vue-typed-mixins'
import LabelMixin from '../../mixins/labels';
import { Organization } from '../../client/organization.client';
import { extractContainer } from '@/ioc/util';
import TYPES from '@/ioc/types';
import { ExtractedFile } from '../ui/OdDownloadButton.vue';
import UsersModule from '@/store/modules/users';
import { STEP_AWAY_DEMO } from '@/router/heritage';

export default mixins(LabelMixin).extend({
  name: 'UserLayout',
  inject: ['container'],
  components: {
    OdDownloadButton
  },
  props: {
    isOwner: Boolean,
    className: {
      type: String,
      validator: (c:string) => [
        'users',
      ].includes(c),
    },
    users: {
      type: Array as PropType<UserLightResponse[]>,
      required: true,
    },
    hideButton: {
      type: Boolean,
      required: false
    }
  },
  filters: {
    
  },
  asyncComputed: {
    async demoUser(): Promise<Boolean> {
      const me = await getModule(UsersModule, this.$store).dispatchGetMe();
      return me && me.roles && me.roles.includes("Demo") ? true : false
    }
  },
  methods: {
    stepAway() {
      this.$router.push(STEP_AWAY_DEMO)
    },
    countryName(name: string): string {
      return getModule(ValuesModule, this.$store).countries.find(x => x.name_it === name )?.name || name
    },
    mainActivities(company: Organization):string[] {
      const activities = getModule(ValuesModule, this.$store).activities
      return company.main_activities.flatMap(ma => {
        const name = activities.find(a => a.id === ma.activity_id)?.name
        return name ? [name] : []
      })
    },
    fetchFile() {
      return async () => {
        const container = extractContainer(this);
        const userClient =  container.get<UserClient>(TYPES.UserClient);
        return await userClient.getListXLS();
      }
    },
    extractFile(o:Blob):Promise<ExtractedFile | null> {
      return new Promise<ExtractedFile | null>((resolve, reject) => {
        if(o) {
          const reader = new FileReader();
          reader.addEventListener("load", function () {
            // convert image file to base64 string
            resolve({
              file: (reader.result as string).substring("data:application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64,".length),
              name: "heritage_users.xlsx",
              mime: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
            })
          }, false);

          reader.readAsDataURL(o)
          
          
        } else {
          resolve(null)
        }
      });
    }
  }
});
