import AuthClient, { isAxiosError } from '@/client/auth.client';
import Environment from '@/env/environment';
import {
  VuexModule, Module, Mutation, Action,
} from 'vuex-module-decorators';
import TYPES from '@/ioc/types';
import getDecorators from 'inversify-inject-decorators';
import container from '@/ioc/inversify.config';
import { AuthState, LoginStatus } from './state';

const { lazyInject } = getDecorators(container);

@Module({ namespaced: true, name: 'auth' })
export default class AuthModule extends VuexModule implements AuthState {
  authToken: string | null = null;
  loginStatus = 'NOT_YET' as LoginStatus;

  @lazyInject(TYPES.AuthClient)
  private readonly authClient!: AuthClient;

  @lazyInject(TYPES.Environment)
  private readonly environment!: Environment;

  get isLoggedIn(): boolean {
    return !!this.authToken;
  }
  

  @Mutation
  private _setAuthToken(authToken: string | null) {
    this.authToken = authToken;
  }

  @Action
  async setAuthToken(authToken: string | null) {
    this._setAuthToken(authToken)
    if(authToken && authToken.length > 0) {
      await this.authClient.fetchCookie(authToken)
    } else {
      await fetch('/auth/logout')
      await fetch('/cms/api/v1/logout')
    }
  }

  @Mutation
  setLoginStatus(status: LoginStatus) {
    this.loginStatus = status;
  }

}
