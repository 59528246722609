import { injectable, inject, interfaces } from 'inversify';
import { AxiosStatic } from 'axios';
import Environment from '@/env/environment';
import TYPES from '@/ioc/types';
import { SupportedLanguage } from './supported-lang.type';
import { PagingOption, ListOptionRequest } from './user.client';

export interface Banner {
  title: string;
  id: number;
  url: string;
  visible: boolean;
  site_id: number;
  image_v_filename: string;
  image_h_filename: string;
}

export const DEFAULT_PAGING_OPTION: PagingOption = { pageLength: 100, currentPage: 1 }
export const DEFAULT_LIST_OPTION_REQUEST: ListOptionRequest = {paging: DEFAULT_PAGING_OPTION, sort: [], filter: []};

@injectable()
export default class BannerClient {
  constructor(
    @inject(TYPES.AxiosStatic) private readonly axios: AxiosStatic,
    @inject(TYPES.Environment) private readonly environment: Environment,
  ) { }

  async getById(lang: SupportedLanguage, id: number): Promise<Banner> {
    const response = this.axios.get<Banner>(`${this.environment.backendUrl}/api/v1/form/${lang}/banners/id/id::${id}`, {
      withCredentials: true,
    });
    return (await response).data;
  }

  async getListVertical(
    lang: SupportedLanguage,
    site_id: number
    //request: PagingOption,
  ): Promise<Banner[]> {
    const fullRequest = {
      filter: [
        {column: "visible",operator: "=", value: "true"},{column: "site_id", operator: "=", value: ""+site_id},{column: "image_v_filename", operator: "not", value: ''}
      ],
      sort: [
      ],
      //paging: request,
    };
    const response = this.axios.post<Banner[]>(`${this.environment.backendUrl}/api/v1/entity/${lang}/banners/list`,
    fullRequest,
    {
      withCredentials: true,
    });
    return (await response).data.map(r => <Banner>(r));
  }
  async getListHorizontal(
    lang: SupportedLanguage,
    site_id: number
    //request: PagingOption,
  ): Promise<Banner[]> {
    const fullRequest = {
      filter: [
        {column: "visible",operator: "=", value: "true"},{column: "site_id", operator: "=", value: ""+site_id},{column: "image_h_filename", operator: "not", value: ''}
      ],
      sort: [
      ],
      //paging: request,
    };
    const response = this.axios.post<Banner[]>(`${this.environment.backendUrl}/api/v1/entity/${lang}/banners/list`,
    fullRequest,
    {
      withCredentials: true,
    });
    return (await response).data.map(r => <Banner>(r));
  }
  
}
