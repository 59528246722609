import Environment from '@/env/environment';
import TYPES from '@/ioc/types';
import { AxiosStatic } from 'axios';
import { inject, injectable } from 'inversify';
import { SupportedLanguage } from './supported-lang.type';
import { Film, UserHeritageResponse, ListOptionRequest } from './user.client';

export interface OrganizationMainActivity{
  organization_id:number;
  activity_id:number;
}

export interface Organization {
  id: number;
  type: string;
  name: string;
  description?: string,
  website?: string,
  address?: string,
  email?: string,
  city: string,
  country?: string,
  film_list?: string,
  film_list_name?:string,
  film_list_mime?:string,
  trailer?:string,
  main_activities: OrganizationMainActivity[],
  image: {id?:number, image?:string, image_name?:string, image_mime?:string, organization_id?:number}[]
}

export interface OrganizationFast {
  id: number;
  type: string;
  name: string;
  description?: string,
  website?: string,
  address?: string,
  email?: string,
  city?: string,
  country?: string,
  trailer?:string,
  main_activities?: string,
  image?: string,
  film_list_name?:string,
  film_list_mime?:string
}

export interface OrganizationLight {
  id: number;
  name: string;
  country: string;
  film_list: boolean
  film_in_library:number
  main_activities:string[]
}

export function fromHeritageUser(user:UserHeritageResponse):OrganizationLight {
  return {
    id: user.organization_id,
    name: user.company_name,
    country: user.company_country,
    film_list: user.film_list,
    film_in_library: user.film_in_library,
    main_activities: user.main_activities ? user.main_activities.split(",").map(x => x.trim() ) : []
  }
}

@injectable()
export default class OrganizationClient {
  constructor(
    @inject(TYPES.AxiosStatic) private readonly axios: AxiosStatic,
    @inject(TYPES.Environment) private readonly environment: Environment,
  ) { }

  async getById(lang: SupportedLanguage, id: number): Promise<OrganizationFast> {

    const request:ListOptionRequest = {
      sort: [],
      filter: [{column:"id", operator: "=", value: ""+id}],
      paging: {pageLength: 1, currentPage: 1}
    }


    const response = this.axios.post<OrganizationFast[]>(`${this.environment.backendUrl}/api/v1/entity/${lang}/v_heritage_company/list`,request, {
      withCredentials: true,
    });
    return (await response).data[0];
  }

  async getFullById(lang: SupportedLanguage, id: number): Promise<Organization> {
    const response = this.axios.get<Organization>(`${this.environment.backendUrl}/api/v1/form/${lang}/heritage_organization_fe/id/id::${id}`, {
      withCredentials: true,
    });
    return (await response).data;
  }

  async update(lang: SupportedLanguage, org: Organization): Promise<void> {

    await this.axios.put<void>(
      `${this.environment.backendUrl}/api/v1/form/${lang}/heritage_organization_fe/id/id::${org.id}`,
      org,
      { withCredentials: true, });
  }

}