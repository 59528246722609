
import Vue, { PropType } from 'vue';

import videojs, { VideoJsPlayer } from 'video.js';

export default Vue.extend({
  name: 'VideoView',
  data():{player: VideoJsPlayer | null} {
        return {
            player: null
        }
    },
    mounted() {
        this.player = videojs(this.$refs.videoPlayer as Element, {}, function onPlayerReady() {
            console.log('onPlayerReady');
        })
    },
    beforeDestroy() {
        if (this.player) {
            this.player.dispose()
        }
    },
});
