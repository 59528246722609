
import Vue, { PropType } from 'vue';
import { formatPublication, formatDateRange } from '@/service/ui';
import ResourceCommentClient, { ResourceComment } from '../client/resource-comment.client';
import { format } from 'date-fns';

import SettingsModule from '@/store/modules/settings';
import { extractContainer } from '@/ioc/util';

import { Label } from '../client/label.client';
import LabelsModule from '@/store/modules/labels';
import mixins from 'vue-typed-mixins'
import LabelMixin from '../mixins/labels';
import { getModule } from 'vuex-module-decorators';
import UsersModule from '../store/modules/users';
import TYPES from '@/ioc/types';
import OdImage from '@/components/ui/OdImage.vue';

import { mapState } from 'vuex';
import { RootState } from '@/store/modules/root';
import UserMentionRenderer from '@/components/ui/UserMentionRenderer.vue';

export default mixins(LabelMixin).extend({
  name: 'CommentsResourceChild',
  components: {
    OdImage,
    UserMentionRenderer
  },
  props: {
    comment: {
      type: Object as PropType<ResourceComment>,
      required: true
    },
    customValue:{
      type: Number
    },
    type: {
      type: Number
    },
    id_focus: {
      type: Number
    }
  },
  inject: ['container'],
  computed: mapState({
      isAdmin: (state:any) => {
        if((state as RootState).users.me?.admin_forum == 1){
          return true;
        }else if((state as RootState).users.me?.admin_forum == true){
          return true;
        }else{
          return false;
        }
      }
  }
 ),
  methods: {
    async removeCommentAdmin(id: Number) {
      const container = extractContainer(this);
      const settingsModule = getModule(SettingsModule, this.$store);
      const ResourceCommentClient = container.get<ResourceCommentClient>(TYPES.ResourceCommentClient);
      let values = await ResourceCommentClient.remove(settingsModule.language, id)
      this.showComment = false;
    },
    async hideCommentAdmin(id: number) {
      const container = extractContainer(this);
      const settingsModule = getModule(SettingsModule, this.$store);
      const ResourceCommentClient = container.get<ResourceCommentClient>(TYPES.ResourceCommentClient);
      let values = await ResourceCommentClient.hideComment(settingsModule.language, id)
      this.isHidden = !this.isHidden;

    },
  },
  data(): {
    showadmin: boolean,
    showComment: boolean,
    isHidden: boolean
  } {
    return {
      showadmin: false,
      showComment: true,
      isHidden: !this.comment.visible
    };
  },
  filters: {
    formatPublication(d: Date): string {
      return formatPublication(d);
    },
  },
  mounted() {
    if(this.type == 2 && this.id_focus == this.comment.id){
        this.$scrollTo('#ip'+this.comment.id, 500, { easing: 'ease-in-out', offset: -200})
    }
  }
});
