
import Vue, { PropType } from 'vue';
import FilmLayout from '@/components/FilmLayout.vue';
import { ResourceLightResponse } from '@/client/resource.client';
import { UserResponse, Film } from '@/client/user.client';
import OdImage from '@/components/ui/OdImage.vue';

import { Label } from '../../client/label.client';
import LabelsModule from '@/store/modules/labels';
import mixins from 'vue-typed-mixins'
import LabelMixin from '../../mixins/labels';
import { Organization, OrganizationFast } from '@/client/organization.client';
import { getModule } from 'vuex-module-decorators';
import ValuesModule from '@/store/modules/values';
import UserFilmClient, { FilmLight } from '@/client/user-film.client';
import SettingsModule from '@/store/modules/settings';
import { extractContainer } from '@/ioc/util';
import TYPES from '@/ioc/types';

export default mixins(LabelMixin).extend({
  name: 'ProfileLibrary',
  props: {
    isOwner: Boolean,
    resources: {
      type: Array as PropType<ResourceLightResponse[] | Film[]>,
      required: true,
    },
    company: {
      required: true,
      type: Object as PropType<OrganizationFast>,
    },
    profile: {
      required: true,
      type: Object as PropType<UserResponse>,
    }
  },
  asyncComputed: {
    async films():Promise<FilmLight[]> {
      if(this.company) {
        const settingsModule = getModule(SettingsModule, this.$store);
        const container = extractContainer(this);
        const filmClient =  container.get<UserFilmClient>(TYPES.UserFilmClient);
        if(this.isOwner){
          return await filmClient.getCompanyFilms(settingsModule.language,20,this.company.id);
        }else{
          return await filmClient.getCompanyFilmsGuest(settingsModule.language,20,this.company.id);
        }
      } else {
        return []
      }
    }
  },
  inject: ['container'],
  components: {
    FilmLayout,
    OdImage
  },
  computed: {
   
  }
});
