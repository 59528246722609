
import Vue, { PropType } from 'vue';

import {SearchModule} from '@/store/modules/search';
import { getModule } from 'vuex-module-decorators';
import { ResourceIndexResponse } from '@/client/resource.client';

export default Vue.extend({
  name: 'DemoLunr',
  data():{
      results:ResourceIndexResponse[],
      searchQuery: string
  } {
      return {
          results: [],
          searchQuery: ''
      }
  },
  async mounted() {
    await getModule(SearchModule, this.$store).loadResources();
  },
  methods: {
      async search(queryString:string) {

        const result = getModule(SearchModule, this.$store).indexResources.search(queryString)

        //vedi in console il risultato
        console.log(result)

        this.results = result.flatMap(r => {
            const obj = getModule(SearchModule, this.$store).resources.find(x => x.id == parseInt(r.ref))
            return obj ? [obj] : []
        })
      }

  }

});
